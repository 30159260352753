import {
  Avatar,
  Box,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import FacebookIcon from 'icons/FacebookIcon';
import IndustryIcon from 'icons/IndustryIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import LocationIcon from 'icons/LocationIcon';
import TwitterIcon from 'icons/TwitterIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { appTheme } from 'theme';

import SmallCompanyLogoComponent from './SmallCompanyLogoComponent';

export default function PersonSummary({ personData }) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const [hoverOnNotableInstitutions, setHoverOnNotableInstitutions] = useState(false);
  const { notable_companies: notableCompanies, notable_schools: notableSchools } = personData;

  const notableInstitutions = useMemo(() => {
    const institutions = [];
    if (notableCompanies) {
      institutions.push(
        ...notableCompanies.map((company) => ({
          name: company.name,
          imageUrl: company.image_url,
          info: company.role
        }))
      );
    }

    if (notableSchools) {
      institutions.push(
        ...notableSchools.map((school) => ({
          name: school.name,
          imageUrl: school.image_url,
          info: school.degrees
        }))
      );
    }
    return institutions;
  }, [notableCompanies, notableSchools]);

  return (
    <Stack direction="column" gap="48px">
      <Stack direction="row" gap="32px">
        <Box
          borderRadius="50%"
          padding="1px"
          border="1px solid"
          height="fit-content"
          borderColor="secondary.secondary2">
          <Avatar alt={personData.name} src={personData.pic_url} sx={{ width: 72, height: 72 }} />
        </Box>
        <Stack direction="column" gap="8px" justifyContent="center">
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            flexWrap={mediumViewport ? 'nowrap' : 'wrap'}>
            <Typography
              variant="paragraphBold"
              fontSize="16px"
              color="secondary.secondary2"
              textAlign="left">
              {personData.name}
            </Typography>
            <Divider orientation="vertical" flexItem />
            {personData.linkedin_url && (
              <Link
                href={`https://${personData.linkedin_url}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <LinkedinIcon />
              </Link>
            )}
            {personData.twitter_url && (
              <Link
                href={`https://${personData.twitter_url}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <TwitterIcon />
              </Link>
            )}
            {personData.facebook_url && (
              <Link
                href={`https://${personData.facebook_url}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <FacebookIcon />
              </Link>
            )}
          </Stack>
          <Typography variant="paragraph" color="colors.primary_text" textAlign="left">
            {personData.headline}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap="24px"
            flexWrap={mediumViewport ? 'nowrap' : 'wrap'}>
            <Stack direction="row" alignItems="center" gap="8px">
              <IndustryIcon />
              <Link
                href={`https://${personData.current_company_linkedin}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <Typography
                  variant="paragraphBold"
                  color="primary.primary2"
                  textTransform="capitalize">
                  {personData.current_company_name}
                </Typography>
              </Link>
              <Typography variant="paragraph" color="colors.primary_text">
                {personData.job_title}
              </Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" alignItems="center" gap="8px">
              <LocationIcon />
              <Typography variant="paragraph" color="colors.primary_text">
                {personData.location_country}
              </Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            {notableInstitutions.length > 0 && (
              <Tooltip
                open={hoverOnNotableInstitutions}
                title={
                  <Stack direction="column" gap="8px">
                    <Typography variant="paragraphMedium" color="colors.text_on_primary">
                      Notable Institution
                    </Typography>
                    {notableInstitutions.map((institution) => (
                      <Stack
                        key={institution.imageUrl}
                        direction="row"
                        alignItems="center"
                        gap="8px">
                        <SmallCompanyLogoComponent imageUrl={institution.imageUrl} />
                        <Typography
                          variant="paragraph"
                          color="colors.ui_border">{`${institution.name}-${institution.info}`}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                }
                arrow>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="8px"
                  onPointerEnter={() => setHoverOnNotableInstitutions(true)}
                  onPointerLeave={() => setHoverOnNotableInstitutions(false)}>
                  {notableInstitutions.map((institution) => (
                    <SmallCompanyLogoComponent
                      key={institution.imageUrl}
                      imageUrl={institution.imageUrl}
                    />
                  ))}
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

PersonSummary.propTypes = { personData: PropTypes.object };
