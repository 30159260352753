import { CSVLink } from 'react-csv';
import ReactDOM from 'react-dom';

import { sourceValueToHistory } from './history-utils';

export function downloadDiscoveryHistoryFromSourceValues(listId, values, history) {
  if (!values) {
    return [];
  }
  const historyObject = values.reduce((acc, value) => {
    if (value.record_id in acc) {
      acc[value.record_id].discovered += 1;
    } else {
      acc[value.record_id] = {
        ...value,
        discovered: 1
      };
    }
    return acc;
  }, {});
  if (history) {
    Object.values(history).forEach((item) => {
      if (!(item.record_id in historyObject) && item.iteration !== 0) {
        historyObject[item.record_id] = { ...item, discovered: 0 };
      }
    });
  }
  const historyArray = Object.values(historyObject)
    .map(sourceValueToHistory)
    .sort((item1, item2) => item1.index - item2.index);
  const element = (
    <CSVLink data={historyArray} target="_self" filename={`list_history_${listId}.csv`}>
      <div id="fake-div-inner" />
    </CSVLink>
  );

  const fakeDiv = document.createElement('div');
  fakeDiv.style = 'display:none;';
  fakeDiv.id = 'fake-div-outer';
  document.body.appendChild(fakeDiv);
  ReactDOM.render(element, document.getElementById('fake-div-outer'));
  const fakeLink = document.getElementById('fake-div-inner');
  fakeLink.click();
  document.body.removeChild(fakeDiv);
}
