import { Button, Stack, Typography } from '@mui/material';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import DotsAnimation from 'components/DotsAnimation';
import IconButtonComponent from 'components/IconButtonComponent';
import UserAvatar from 'components/UserAvatar';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import ArrowIcon from 'icons/ArrowIcon';
import AssistantWandAvatarIcon from 'icons/AssistantWandAvatarIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey, updatePostSavedQueryId } from 'reducer/custom-analytics';
import {
  getCustomAnalyticDataTypeByPostIdMap,
  getIsPostWorkflowByThreadIdAndPostId,
  getIsPostWorkflowRunByThreadIdAndPostId
} from 'selectors/custom-analytics';
import { getListCustomColumnNameByListIdAndColumnId } from 'selectors/explore';
import { isEmpty } from 'utils/objects-utils';
import { extractNodesAndEdges } from 'utils/work-flow-planner-utils';

import CustomAnalyticsFeedMessageComponent from './CustomAnalyticsFeedMessageComponent';
import CustomAnalyticsWorkflowMessageComponent from './CustomAnalyticsWorkflowMessageComponent';
import DiscoverySuggestionsComponent from './DiscoverySuggestionsComponent';
import PromptDisplayComponent from './PromptDisplayComponent';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
function CustomAnalyticsThread({
  post,
  listId,
  companyMetaId,
  companies,
  enrichButtonText,
  setEnrichPostId,
  setDomainKnowledgePostId,
  shouldShowAddToDomainKnowledge,
  showToolCallHistory,
  deepDiveId,
  setPromptConfig,
  placement
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const dispatch = useDispatch();
  const answerType = post.message?.answer_type || post.messages?.[0]?.message?.answer_type;
  const selectedCompaniesIds = post.body?.company_meta_ids;
  const plan = post?.plan;
  const suggestions = post?.suggestions;
  const dataType = useSelector((state) => getCustomAnalyticDataTypeByPostIdMap(state, post.postId));
  const columnName = useSelector((state) =>
    getListCustomColumnNameByListIdAndColumnId(state, listId, dataType)
  );
  const onGoToColumn = () => {
    dispatch(highlightColumnAction(listId, dataType, true));
  };

  const totalNumCompanies = post.body?.total_num_companies || 0;
  const messagesCount = post?.messages?.filter((mes) => mes?.message?.answer)?.length || 0;
  const isCurrentlyAddColumn =
    placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE &&
    post?.query?.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`) &&
    !post?.body?.is_sample;

  const isWorflow = useSelector((state) =>
    getIsPostWorkflowByThreadIdAndPostId(state, getThreadKey(listId), post.postId)
  );

  const isWorflowRun = useSelector((state) =>
    getIsPostWorkflowRunByThreadIdAndPostId(state, getThreadKey(listId), post.postId)
  );
  const areAllRecordsFinished = useMemo(() => {
    if (isEmpty(post?.records)) {
      return false;
    }
    return Object.values(post.records).every((record) => !record.isRunning);
  }, [post]);

  const stepIdToLabel = useMemo(() => {
    if (isWorflowRun) {
      const runningPlan = post?.body?.plan;
      if (runningPlan) {
        const { nodes } = extractNodesAndEdges(runningPlan.steps, runningPlan.question);
        return nodes
          ?.filter((node) => !node.id.includes('-'))
          .reduce((acc, node) => {
            if (node.type === 'workFlowGroup') {
              acc[node.id] = `${node.data.label} - running ${node.data.numberOfChildrens} queries`;
            } else {
              acc[node.id] = `${node.data.label} - ${node.data.query}`;
            }
            return acc;
          }, {});
      }
    }
    return [];
  }, [post, isWorflowRun]);

  const getStatusPercentages = () => {
    if (
      isCurrentlyAddColumn &&
      !areAllRecordsFinished &&
      messagesCount > 0 &&
      (parseFloat(post.status?.replace('%', '')) < (messagesCount * 100) / totalNumCompanies ||
        post.status === '100%')
    ) {
      return `${Math.round((messagesCount * 100) / totalNumCompanies)}%`;
    }
    if (isWorflowRun) {
      const steps = post?.body?.plan?.steps;
      const { currentStepId } = post;
      if (!currentStepId && !steps) {
        return '0%';
      }
      if (currentStepId === 'end') {
        return '100%';
      }
      const indexOfCurrentStep = currentStepId
        ? steps.findIndex((step) => step.step_id === currentStepId)
        : 0;
      return `${Math.round((indexOfCurrentStep / steps.length) * 100)}%`;
    }
    return post.status || '0%';
  };

  const getStatusMessge = () => {
    if (isCurrentlyAddColumn) {
      if (messagesCount === 0) {
        return post.statusMessage || `Waiting for ${totalNumCompanies} companies`;
      }
      return `${messagesCount} / ${totalNumCompanies} company results`;
    }
    if (isWorflowRun) {
      const { currentStepId } = post;
      if (!stepIdToLabel) {
        return post.statusMessage;
      }

      return stepIdToLabel[currentStepId || 'start'];
    }
    return post.statusMessage;
  };

  const companiesByMetaId = useMemo(() => {
    if (isEmpty(companies)) {
      return companies;
    }
    return Object.values(companies).reduce((acc, company) => {
      return {
        ...acc,
        [company.companyMetaId]: company
      };
    }, {});
  }, [companies]);

  const selectedCompanies = useMemo(() => {
    if (!listId || !companiesByMetaId || !selectedCompaniesIds) {
      return null;
    }
    const fullSelectedCompanies = selectedCompaniesIds
      ?.map((company_meta_ids) => companiesByMetaId[company_meta_ids])
      .filter((company) => company);
    return fullSelectedCompanies?.reduce((acc, company) => {
      return {
        ...acc,
        [company.companyListMetaId]: {
          id: company.companyListMetaId,
          name: company.name,
          companyMetaId: company.companyMetaId,
          logoUrl: company.logo_url
        }
      };
    }, {});
  }, [selectedCompaniesIds, companiesByMetaId, listId]);

  const filterRow = (row) => {
    if (!post || !selectedCompaniesIds || !row || !row.company_meta_id) {
      return true;
    }
    return selectedCompaniesIds.includes(row.company_meta_id);
  };

  const handleCollapseClick = useCallback(() => setIsCollapsed((prevValue) => !prevValue), []);

  const getCompanyNameFromRow = (row) => {
    if (!row || !row.company_meta_id) {
      return '';
    }
    return (
      companiesByMetaId[row.company_meta_id]?.cb_name ||
      companiesByMetaId[row.company_meta_id]?.name
    );
  };

  const renderEnrichButtons = (renderedPost) =>
    renderedPost.postId &&
    renderedPost.responseType !== 'DISCOVERY' && (
      <Stack
        direction="row"
        gap="8px"
        justifyContent="flex-end"
        sx={{ marginLeft: 'auto', paddingX: '8px', paddingBottom: '8px' }}>
        {renderedPost.postId &&
          renderedPost.status === '100%' &&
          enrichButtonText &&
          (deepDiveId ||
            renderedPost.query.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`)) && (
            <Button
              variant="outlined"
              sx={{ alignSelf: 'flex-start', height: '32px' }}
              onClick={() => setEnrichPostId(renderedPost.postId)}>
              {enrichButtonText}
            </Button>
          )}
        {renderedPost.postId && shouldShowAddToDomainKnowledge && (
          <Button
            variant="outlined"
            sx={{ alignSelf: 'flex-start', height: '32px' }}
            onClick={() => setDomainKnowledgePostId(renderedPost.postId)}>
            Add to domain knowledge
          </Button>
        )}
      </Stack>
    );
  const onSaveQuery = (savedQueryId) => {
    const threadKey = getThreadKey(listId, companyMetaId);
    dispatch(updatePostSavedQueryId({ threadKey, postId: post.postId, savedQueryId }));
    if (dataType) {
      dispatch(
        editDiscoveryCustomColumnInfoAction(
          listId,
          dataType,
          { saved_query_id: savedQueryId },
          true
        )
      );
    }
  };

  const disableSaveQuery =
    (isWorflow && !isWorflowRun) || post?.body?.action === 'prepare_workflow';

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="row" alignItems="center" gap="16px">
        <UserAvatar width={32} height={32} />
        <PromptDisplayComponent
          listId={listId}
          companyMetaId={companyMetaId}
          prompt={{ ...post.body, question: post.query }}
          answerType={answerType}
          selectedCompanies={selectedCompanies}
          plan={plan}
          deepDiveId={deepDiveId}
          isCollapsed={isCollapsed}
          handleCollapseClick={handleCollapseClick}
          isSample={post.body?.is_sample}
          columnName={columnName}
          onSaveQuery={!disableSaveQuery && onSaveQuery}
          isWorflowRun={isWorflowRun}
        />
      </Stack>
      {!isCollapsed && (
        <Stack direction="row" gap="16px" sx={{ boxSizing: 'border-box', width: '100%' }}>
          <Stack
            sx={{
              minWidth: 32,
              height: 32,
              backgroundColor: 'colors.primary',
              color: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 16
            }}>
            <AssistantWandAvatarIcon />
          </Stack>
          <Stack
            direction="column"
            sx={{
              flex: 1,
              border: '1px solid',
              borderColor: 'greyColors.grey50',
              borderRadius: '4px',
              padding: '8px',
              gap: '16px',
              maxWidth: 'calc(100% - 64px)'
            }}>
            {post.postId &&
              (post.status !== '100%' || (isCurrentlyAddColumn && !areAllRecordsFinished)) && (
                <Stack gap="16px">
                  <Stack direction="row" alignItems="center" gap="16px">
                    {!post.message?.answer && !post.messages && <DotsAnimation />}
                    <Typography variant="paragraphSemiBold" color="colors.primary">
                      {getStatusPercentages()}
                    </Typography>
                    <Typography textAlign="left" color="colors.primary" variant="paragraphSemiBold">
                      {getStatusMessge()}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            {post.responseType !== 'MULTIVALUE' && post.message?.answer && (
              <CustomAnalyticsFeedMessageComponent
                listId={listId}
                companyMetaId={companyMetaId}
                body={post.message?.answer}
                followUpQuestions={post.message?.follow_up_questions}
                sources={post.message?.sources}
                reasoning={post.message?.reasoning}
                perplexity={post.message?.perplexity}
                certainty={post.message?.certainty}
                answerType={post.message?.answer_type}
                toolCallHistory={showToolCallHistory ? post.message?.tool_call_history : null}
                responseType={post?.responseType}
                placement={placement}
                deepDiveId={deepDiveId}
              />
            )}
            {isCurrentlyAddColumn && post.messages && (
              <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
                {areAllRecordsFinished && (
                  <Typography variant="paragraph" textAlign="start">
                    {'Column '}
                    <Typography
                      variant="paragraphBold"
                      textAlign="start"
                      color="colors.link"
                      sx={{ cursor: 'pointer' }}
                      onClick={onGoToColumn}>
                      {columnName}
                    </Typography>
                    {' created'}
                    {post.body?.company_meta_ids?.length
                      ? ` for ${post.body.company_meta_ids.length} selected companies`
                      : ''}
                    .
                  </Typography>
                )}
                {areAllRecordsFinished && (
                  <IconButtonComponent
                    onClick={() => setShowAnswers(!showAnswers)}
                    tag={showAnswers ? 'Hide answers' : 'Show answers'}
                    sx={{ transform: showAnswers ? 'rotate(180deg)' : 'rotate(90deg)' }}>
                    <ArrowIcon />
                  </IconButtonComponent>
                )}
              </Stack>
            )}
            {(!isCurrentlyAddColumn || !areAllRecordsFinished || showAnswers) && post.messages && (
              <Stack gap="8px">
                {post.messages
                  ?.filter((row) => companiesByMetaId[row.company_meta_id])
                  ?.filter((row) => filterRow(row))
                  ?.sort((row1, row2) =>
                    getCompanyNameFromRow(row1).localeCompare(getCompanyNameFromRow(row2), {
                      sensitivity: 'base'
                    })
                  )
                  ?.map((row) => (
                    <CustomAnalyticsFeedMessageComponent
                      listId={listId}
                      companyMetaId={companyMetaId}
                      key={row.company_meta_id}
                      title={getCompanyNameFromRow(row)}
                      companyLogo={companiesByMetaId[row.company_meta_id].logo_url}
                      body={row.message?.answer}
                      followUpQuestions={row.message?.follow_up_questions}
                      sources={row.message?.sources}
                      reasoning={row.message?.reasoning}
                      perplexity={row.message?.perplexity}
                      certainty={row.message?.certainty}
                      answerType={row.message?.answer_type}
                      toolCallHistory={showToolCallHistory ? row.message?.tool_call_history : null}
                      isLoading={row.loading}
                      placement={placement}
                      deepDiveId={deepDiveId}
                    />
                  ))}
              </Stack>
            )}

            {companyMetaId && post.messages?.[0]?.message?.answer && (
              <CustomAnalyticsFeedMessageComponent
                listId={listId}
                companyMetaId={companyMetaId}
                body={post.messages[0].message.answer}
                followUpQuestions={post.messages[0].message?.follow_up_questions}
                sources={post.messages[0].message?.sources}
                reasoning={post.messages[0].message?.reasoning}
                perplexity={post.messages[0].message?.perplexity}
                certainty={post.messages[0].message?.certainty}
                answerType={post.messages[0].message?.answer_type}
                toolCallHistory={
                  showToolCallHistory ? post.messages[0].message?.tool_call_history : null
                }
                responseType={post.messages[0].message?.responseType}
                placement={placement}
                deepDiveId={deepDiveId}
              />
            )}

            {post.image && (
              <img style={{ maxWidth: '100%' }} src={`data:image/png;base64,${post.image}`} />
            )}
            {(placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE ||
              shouldShowAddToDomainKnowledge) &&
              renderEnrichButtons(post)}

            {isWorflow && post.status === '100%' && (
              <CustomAnalyticsWorkflowMessageComponent
                listId={listId}
                postId={post.postId}
                isWorflowRun={isWorflowRun}
              />
            )}
          </Stack>
        </Stack>
      )}
      {isCollapsed && renderEnrichButtons(post)}
      {!isEmpty(suggestions) && (
        <DiscoverySuggestionsComponent
          suggestions={suggestions}
          setPromptConfig={setPromptConfig}
        />
      )}
    </Stack>
  );
}

CustomAnalyticsThread.propTypes = {
  post: PropTypes.object.isRequired,
  listId: PropTypes.number.isRequired,
  thread: PropTypes.object,
  companies: PropTypes.object,
  enrichButtonText: PropTypes.string,
  setEnrichPostId: PropTypes.func,
  setDomainKnowledgePostId: PropTypes.func,
  shouldShowAddToDomainKnowledge: PropTypes.bool,
  showToolCallHistory: PropTypes.bool,
  companyMetaId: PropTypes.number,
  deepDiveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPromptConfig: PropTypes.func,
  placement: PropTypes.oneOf(Object.values(CUSTOM_ANALYTICS_PLACEMENT))
};

export default React.memo(CustomAnalyticsThread);
