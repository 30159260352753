import { addAllExploreSubdomains, addExploreSubdomains } from 'reducer/explore-slice';

export function loadListSubdomainsAction(listId, subdomainIds, streamed, onFinishLoading) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companiesLists.fetchListSubdomains(listId, subdomainIds).then((res) => {
      if (res.ok && res.data) {
        const { subdomains } = res.data;
        let formattedSubdomains = subdomains;
        if (streamed) {
          formattedSubdomains = subdomains.map((subdomain) => ({
            ...subdomain,
            streamed: true
          }));
        }
        if (subdomainIds) {
          dispatch(addExploreSubdomains({ listId, subdomains: formattedSubdomains }));
        } else {
          dispatch(addAllExploreSubdomains({ listId, subdomains: formattedSubdomains }));
        }
        onFinishLoading?.();
      }
    });
  };
}
