import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteExploreAction } from 'actions/explore/delete-explore-action';
import { deleteFolderResourceAction } from 'actions/folder-resources/delete-folder-resoure-action';
import { deletePersonDataAction } from 'actions/persons/delete-person-data-action';
import { deleteSearchRunAction } from 'actions/runs/delete-search-run-action';
import IconButtonComponent from 'components/IconButtonComponent';
import { TILE_TYPE } from 'constants/tiles';
import { dispatch } from 'hooks/AppStateProvider';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

export default function DeleteSearchDialog({
  open,
  recordId,
  onClose,
  recordType,
  shared,
  actualRecordId,
  folderId
}) {
  const reduxDispatch = useDispatch();
  const closeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  let title = 'research';
  if (recordType === TILE_TYPE.PERSON) {
    title = 'person';
  } else if (recordType === TILE_TYPE.DISCOVERY) {
    title = 'explore';
  }

  const submitRemove = (event) => {
    event.stopPropagation();
    event.preventDefault();
    switch (recordType) {
      case TILE_TYPE.RUN:
        dispatch(deleteSearchRunAction(recordId));
        break;
      case TILE_TYPE.PERSON:
        dispatch(deletePersonDataAction(recordId));
        break;
      case TILE_TYPE.DISCOVERY:
        reduxDispatch(deleteExploreAction(recordId));
        break;
      default:
        break;
    }
    if (folderId) {
      reduxDispatch(deleteFolderResourceAction(actualRecordId || recordId, recordType, folderId));
    }
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 'topBar' }}>
      <DialogTitle
        id="alert-dialog-title"
        border="1px solid"
        borderColor="colors.ui_border"
        backgroundColor="colors.grey_bg"
        sx={{
          padding: '12px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...appTheme.typography.paragraphBold,
          color: 'colors.primary_text',
          textTransform: 'capitalize'
        }}>
        {`Remove ${title}`}
        <IconButtonComponent tag="Close" onClick={closeHandler}>
          <CloseIcon />
        </IconButtonComponent>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            paddingTop: '40px',
            paddingBottom: '16px',
            ...appTheme.typography.paragraph,
            color: 'colors.primary_text'
          }}>
          {shared && (
            <Typography variant="paragraph">
              <strong>WARNING</strong>: This is a shared {title}.<br></br>
            </Typography>
          )}
          {`Are you sure you want to delete this ${title}${shared ? ' for all users' : ''}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '8px' }}>
        <Button
          variant="outlined"
          onClick={closeHandler}
          sx={{ ...appTheme.typography.paragraphBold }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={submitRemove}
          autoFocus
          sx={{ ...appTheme.typography.paragraphMedium }}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteSearchDialog.propTypes = {
  open: PropTypes.bool,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
  recordType: PropTypes.oneOf(Object.values(TILE_TYPE)),
  shared: PropTypes.bool,
  folderId: PropTypes.number,
  actualRecordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
