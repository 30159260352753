import { Avatar, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import RenderStr from './RenderStr';

export default function RenderAvatar({ value }) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Box
      onClick={() => openInNewTab(value.link_url)}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        gap: '5px',
        width: '100%'
      }}>
      <Avatar
        src={value.image_url}
        border="1px solid"
        bordercolor="colors.ui_border"
        sx={{ width: 24, height: 24 }}
      />
      <RenderStr value={value.value} />
    </Box>
  );
}

RenderAvatar.propTypes = {
  value: PropTypes.object
};
