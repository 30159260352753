import { Button, Input, Stack } from '@mui/material';
import {
  clearCurrentPersonsAutocomplete,
  makeNewPersonsAutocompleteAction
} from 'actions/autocomplete/make-new-persons-autocomplete-action';
import { makeNewPersonsSearchAction } from 'actions/persons/make-new-persons-search-action';
import ModalComponent from 'components/modal/ModalComponent';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import BigSearchIcon from 'icons/BigSearchIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPersonsAutocomplete } from 'selectors/persons-search';
import { appTheme } from 'theme';

import PersonSuggestionTile from './tile/PersonSuggestionTile';

export default function CreatePersonsSearchDialog({ open, onClose, folderId }) {
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();
  const searchSuggestions = getPersonsAutocomplete(state);

  const clearSearch = () => {
    dispatch(clearCurrentPersonsAutocomplete(name));
    setName('');
    setIsLoading(false);
  };

  const closeDialog = () => {
    clearSearch();
    onClose?.();
  };

  useEffect(() => {
    if (searchSuggestions) {
      setIsLoading(false);
    }
  }, [searchSuggestions]);

  const makeSearch = () => {
    setIsLoading(true);
    dispatch(makeNewPersonsAutocompleteAction(name, true));
  };

  const runSearch = (suggestion) => {
    dispatch(makeNewPersonsSearchAction(suggestion, folderId, reduxDispatch));
    closeDialog();
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      makeSearch();
    }
  };

  const haveSuggestions = searchSuggestions?.[name]?.length > 0;

  return (
    <ModalComponent
      title="New Person"
      open={open}
      onClose={closeDialog}
      maxWidth={738}
      backgroundColor={haveSuggestions ? 'colors.hover_on_gray_bg' : null}>
      <Stack
        direction="column"
        gap="32px"
        padding="24px"
        width="90%"
        sx={{ backgroundColor: haveSuggestions ? 'colors.hover_on_gray_bg' : null }}>
        <Stack direction="row" gap="16px" alignItems="center" width="100%">
          <BigSearchIcon />
          <Input
            placeholder="Enter person’s name"
            value={name}
            size="small"
            autoFocus
            disabled={isLoading || haveSuggestions}
            disableUnderline={true}
            onKeyDown={onDownKey}
            sx={{
              flex: 1,
              color: 'colors.primary_text',
              ...appTheme.typography.h1,
              backgroundColor: haveSuggestions ? 'colors.hover_on_gray_bg' : null
            }}
            onChange={(event) => setName(event.target.value)}
          />
          {haveSuggestions ? (
            <Button variant="text" disabled={isLoading} onClick={clearSearch}>
              Clear
            </Button>
          ) : (
            <Button
              variant="text"
              disabled={isLoading}
              onClick={makeSearch}
              sx={{ visibility: name?.length > 0 ? 'visible' : 'hidden' }}>
              Search
            </Button>
          )}
        </Stack>
        {searchSuggestions?.[name]?.map((suggestion, index) => (
          <PersonSuggestionTile
            key={index}
            suggestion={suggestion}
            onRunSearch={() => runSearch(suggestion)}
          />
        ))}
      </Stack>
    </ModalComponent>
  );
}

CreatePersonsSearchDialog.propTypes = {
  open: PropTypes.bool,
  folderId: PropTypes.number,
  onClose: PropTypes.func
};
