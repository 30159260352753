import { ACTIONS } from 'constants/actions';
import { dispatch } from 'hooks/AppStateProvider';
import { addPredefinedColumns, addPredefinedTableQuestions } from 'reducer/explore-table-ui';

function addCompaniesFilters(filters) {
  return { type: ACTIONS.ADD_COMPANIES_FILTERS, filters };
}

export function loadAllCompaniesFiltersAction() {
  return (reduxDispatch, _getState, apiClient) => {
    apiClient.companiesLists.fetchFilters().then((res) => {
      if (res.ok && res.data && res.data.filters) {
        const { filters, predefined_columns, predefined_table_questions } = res.data;
        const actualFilters = Object.keys(filters).reduce((acc, groupId) => {
          acc[groupId] = { ...filters[groupId], id: groupId };
          return acc;
        }, {});
        dispatch(addCompaniesFilters(actualFilters));
        reduxDispatch(addPredefinedColumns({ predefined_columns }));
        reduxDispatch(addPredefinedTableQuestions({ predefined_table_questions }));
      }
    });
  };
}
