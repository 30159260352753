import { Box, Stack, Typography } from '@mui/material';
import UploadIcon from 'icons/UploadIcon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';

function FileDropComponent({ handleFiles, sx, showTemplate }) {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    handleFiles(files);
  };
  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  const onDrop = (files) => {
    handleFiles(files);
    setDragging(false);
  };
  const onClick = (event) => {
    event.stopPropagation();
    const link = document.createElement('a');
    link.download = 'MathLabsTemplate.csv';
    link.href = '../../MathLabsTemplate.csv';
    link.click();
  };

  const resetInput = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };
  return (
    <FileDrop
      onDrop={onDrop}
      onTargetClick={onTargetClick}
      onDragOver={() => setDragging(true)}
      onDragLeave={() => setDragging(false)}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="24px"
        sx={{
          border: '1px dashed',
          borderColor: dragging ? 'colors.link' : 'colors.hover_on_selected',
          borderRadius: '4px',
          padding: '8px 24px',
          ...sx
        }}>
        <Stack direction="column" justifyContent="center" alignItems="center" gap="8px">
          <Box sx={{ color: dragging ? 'colors.link' : null }}>
            <UploadIcon />
          </Box>
          <Typography variant="paragraph" color="colors.primary_text">
            Drag and drop files here, or{' '}
            <Typography variant="paragraphBold" color="colors.link" sx={{ cursor: 'pointer' }}>
              browse
            </Typography>{' '}
            your computer.
          </Typography>
          {showTemplate && (
            <Typography variant="paragraph" color="colors.primary_text">
              Click{' '}
              <Typography
                variant="paragraphBold"
                color="colors.link"
                sx={{ cursor: 'pointer' }}
                onClick={onClick}>
                here
              </Typography>{' '}
              to download a template.
            </Typography>
          )}
        </Stack>
        <input
          onChange={onFileInputChange}
          onClick={resetInput}
          ref={fileInputRef}
          type="file"
          accept=".csv"
          style={{ display: 'none' }}
        />
      </Stack>
    </FileDrop>
  );
}

FileDropComponent.propTypes = {
  handleFiles: PropTypes.func,
  sx: PropTypes.object,
  showTemplate: PropTypes.bool
};

export default FileDropComponent;
