import { useMediaQuery } from '@mui/material';
import { ELEMENT_HEIGHTS } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import { dateStringToTick, getLineColorByIndex } from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';
import StackedBarTooltipContent from './StackedBarTooltipContent';

/* eslint-disable id-length */
export default function AreaChartComponent({
  title,
  labels,
  values,
  xAxisName,
  xIsDate,
  keyOrder,
  mode
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const dataKeys = keyOrder ? keyOrder : Object.keys(values);

  const data = useMemo(() => {
    return labels.map((xVal, index) => {
      return {
        label: xVal,
        ...Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: values[key][index] }), {})
      };
    });
  }, [labels, values]);

  const tickFormatter = (tick) => {
    return xIsDate ? dateStringToTick(tick, labels) : tick;
  };

  return (
    <RechartsBox
      title={title}
      mode={mode}
      height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
      <AreaChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        data={data}
        margin={{
          top: mediumViewport ? 20 : 0,
          right: mediumViewport ? 40 : 0,
          bottom: mediumViewport ? 40 : 0,
          left: mediumViewport ? 40 : 0
        }}>
        <XAxis
          dataKey="label"
          padding={{ left: 20, right: 20 }}
          name={xAxisName}
          label={{
            value: xAxisName,
            position: 'bottom',
            fill: appTheme.palette.greyColors.grey250
          }}
          tickFormatter={tickFormatter}
          stroke={appTheme.palette.greyColors.grey250}
          axisLine={false}
          tickLine={false}
        />
        <YAxis stroke={appTheme.palette.greyColors.grey250} axisLine={false} tickLine={false} />
        <Tooltip cursor={false} content={<StackedBarTooltipContent />} />
        <Legend
          wrapperStyle={{ paddingBottom: 10 }}
          verticalAlign={xAxisName ? 'top' : 'bottom'}
          align={xAxisName ? 'center' : 'right'}
          iconType="circle"
        />
        <CartesianGrid vertical={false} stroke={appTheme.palette.greyColors.grey50} />
        {dataKeys.map((dataKey, index) => (
          <Area
            stackId="a"
            key={dataKey}
            dataKey={dataKey}
            label={dataKey}
            stroke={getLineColorByIndex(index)}
            fill={getLineColorByIndex(index)}
          />
        ))}
      </AreaChart>
    </RechartsBox>
  );
}

AreaChartComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  values: PropTypes.object,
  keyOrder: PropTypes.array,
  xAxisName: PropTypes.string,
  xIsDate: PropTypes.bool,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
