import { createNewPostInCustomAnalyticsThreadAsyncAction } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';

export function generateWorkflowAction(listId, mainPrompt, isRegenerate) {
  return (dispatch, getState) => {
    const selectedWorkflowId = isRegenerate
      ? new Date().getTime()
      : getWorkflowSelectedPostIdByListId(getState(), listId);
    dispatch(
      createNewPostInCustomAnalyticsThreadAsyncAction({
        listId,
        givenPostId: selectedWorkflowId,
        query: mainPrompt,
        useGpt4: true,
        action: 'prepare_workflow'
      })
    );
    if (isRegenerate) {
      dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId }));
    }
  };
}
