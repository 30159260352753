import { Button, Stack } from '@mui/material';
import { generateWorkflowAction } from 'actions/workflows/generate-workflow-action';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanMainPromptByThreadAndPostId } from 'selectors/custom-analytics';

function WorkFlowGeneratePlanNode({ data }) {
  const dispatch = useDispatch();
  const { listId, postId } = data;

  const threadKey = getThreadKey(listId);
  const mainPrompt = useSelector((state) =>
    getCustomAnalyticsPlanMainPromptByThreadAndPostId(state, threadKey, postId)
  );

  const onGenerateButtonClick = () => {
    dispatch(generateWorkflowAction(listId, mainPrompt));
  };

  return (
    <Stack
      // disable node dragging
      className="nodrag"
      sx={{
        boxSizing: 'border-box',
        color: 'primary.primary150',
        border: 'solid 1px',
        borderColor: 'greyColors.grey100',
        borderRadius: '4px',
        margin: 0,
        cursor: 'pointer'
      }}>
      <Button
        disabled={!mainPrompt || mainPrompt.length === 0}
        sx={{ padding: '16px' }}
        onClick={onGenerateButtonClick}>
        Generate workflow
      </Button>
      <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
    </Stack>
  );
}

WorkFlowGeneratePlanNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowGeneratePlanNode;
