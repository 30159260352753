import { Button, Divider, Link, Menu, Stack, Typography } from '@mui/material';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { appTheme } from 'theme';
import { getObjectLength, isEmpty } from 'utils/objects-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderGrowthSignals({ value: payload }) {
  const value = payload?.value;
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loading = value?.loading || payload?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }

  if (isEmpty(value) || (!value?.percentage && value?.percentage !== 0)) return null;
  const { percentage, growth_sources, message } = value;

  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography visibility="hidden">{percentage < 0 ? '' : '-'}</Typography>
          <Typography color="primary.primary300" variant="paragrapBold" noWrap display="block">
            {percentage} %
          </Typography>
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PaperProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}
        MenuListProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}>
        <Stack
          sx={{
            width: '300px',
            backgroundColor: 'secondary.secondary1',
            color: 'white',
            padding: '4px',
            gap: '8px'
          }}>
          {message && (
            <Typography marginLeft="auto" variant="paragraphSemiBold">
              {message}
            </Typography>
          )}
          <Divider />
          {!isEmpty(growth_sources) && (
            <React.Fragment>
              <Typography variant="paragraphBold" color="#A8B2C2">
                Source{getObjectLength(growth_sources) === 1 ? '' : 's'}:
              </Typography>
              {growth_sources.map((source) => (
                <Stack key={source.source_name} direction="row">
                  {source.url && (
                    <Link
                      minHeight={0}
                      href={source.url}
                      underline="none"
                      target="_blank"
                      rel="noopener"
                      color="colors.text_on_primary"
                      padding="2px">
                      <Stack direction="row" gap="8px" paddingTop="4px" alignItems="center">
                        <GoToIcon />
                        <Typography variant="paragraphMedium">{source.name}</Typography>
                      </Stack>
                    </Link>
                  )}
                </Stack>
              ))}
            </React.Fragment>
          )}
        </Stack>
      </Menu>
    </Stack>
  );
}

RenderGrowthSignals.propTypes = {
  value: PropTypes.object
};
