import { Stack, keyframes, styled } from '@mui/material';
import PropTypes from 'prop-types';

const pulse = keyframes`
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60%,
  100% {
    transform: scale(0);
  }
`;

const Dot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.colors.primary,
  borderRadius: '50%',
  animation: `${pulse} 1.5s infinite linear`
}));

export default function DotsAnimation({ size, gap }) {
  return (
    <Stack direction="row" gap={gap}>
      <Dot />
      <Dot sx={{ animationDelay: '0.2s', width: size, height: size }} />
      <Dot sx={{ animationDelay: '0.4s', width: size, height: size }} />
      <Dot sx={{ animationDelay: '0.6s', width: size, height: size }} />
    </Stack>
  );
}

DotsAnimation.propTypes = {
  size: PropTypes.string,
  gap: PropTypes.string
};

DotsAnimation.defaultProps = {
  size: '16px',
  gap: '16px'
};
