import { Link, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FacebookIcon from 'icons/FacebookIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import TwitterIcon from 'icons/TwitterIcon';
import PropTypes from 'prop-types';
import React from 'react';

import SmallCompanyLogoComponent from './card/people/SmallCompanyLogoComponent';

export default function PersonItemComponent({ person, onClick }) {
  const num_years = person?.current_job_duration
    ? person?.current_job_duration.toString().concat(' years')
    : '';

  const onCardClick = (event) => {
    if (
      event.target.tagName !== 'svg' &&
      event.target.tagName !== 'path' &&
      event.target.tagName !== 'circle'
    ) {
      onClick(person.name);
    }
  };

  const isEmpty =
    !person.headline &&
    !num_years &&
    !person?.last_company &&
    person?.notable_companies.length === 0 &&
    person?.notable_schools?.length === 0;
  const hasExits =
    person?.competency_signals?.venture_experiences?.signals &&
    person.competency_signals.venture_experiences.signals.find(
      (signal) => signal?.data?.length > 0
    );
  return (
    <Stack
      onClick={onCardClick}
      flexWrap="wrap"
      sx={{
        height: '90%',
        padding: '24px',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0 0 10px 1px rgba(0, 19, 195, 0.15)',
          borderRadius: '4px'
        }
      }}>
      <Stack direction="column" gap="8px" textAlign="left">
        <Stack direction="row" gap="16px" alignItems="center">
          <Box
            borderRadius="50%"
            padding="1px"
            border="1px solid"
            borderColor="secondary.secondary2">
            <Avatar alt={person.name} src={person.pic_url} sx={{ width: 48, height: 48 }} />
          </Box>
          <Stack direction="column">
            <Typography
              variant="paragraphBold"
              color="secondary.secondary2"
              textTransform="uppercase"
              textAlign="left">
              {person.name}
            </Typography>
            <Typography variant="paragraphMedium" color="colors.primary_text" textAlign="left">
              {person.job_title} {num_years && `(${num_years})`}
            </Typography>
            <Stack direction="row" alignItems="center" gap="8px" marginTop="8px">
              {person.linkedin_url && (
                <Link
                  href={`https://${person.linkedin_url}`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  <LinkedinIcon />
                </Link>
              )}
              {person.twitter_url && (
                <Link
                  href={`https://${person.twitter_url}`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  <TwitterIcon />
                </Link>
              )}
              {person.facebook_url && (
                <Link
                  href={`https://${person.facebook_url}`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  <FacebookIcon />
                </Link>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Divider flexItem sx={{ margin: '8px 0' }} />
        {person.headline && (
          <Typography variant="paragraph" justifyContent="left">
            {person.headline}
          </Typography>
        )}
        {person?.last_company && (
          <React.Fragment>
            <Typography variant="paragraphMedium">Previous Role</Typography>
            <Stack direction="row" alignItems="center" gap="8px">
              <SmallCompanyLogoComponent
                tooltipText={person.last_company.name}
                linkUrl={person.last_company.linkedin_url}
                imageUrl={person.last_company.image_url}
                size="28px"
              />
              <Typography variant="paragraph">{person.last_company.tenure_info}</Typography>
            </Stack>
          </React.Fragment>
        )}
        {(person?.notable_schools?.length > 0 || person?.notable_companies.length > 0) && (
          <React.Fragment>
            <Typography variant="paragraphMedium">Notable Institutions</Typography>
            <Stack direction="row" gap="8px">
              {person?.notable_schools?.length > 0 &&
                person.notable_schools.map((school, index) => (
                  <SmallCompanyLogoComponent
                    imageUrl={school.image_url}
                    key={`school-${index}`}
                    tooltipText={school.name}
                    linkUrl={school.linkedin_url}
                    size="28px"
                  />
                ))}
              {person?.notable_companies?.length > 0 &&
                person.notable_companies.map((company, index) => (
                  <SmallCompanyLogoComponent
                    imageUrl={company.image_url}
                    key={`company-${index}`}
                    tooltipText={company.name}
                    linkUrl={company.linkedin_url}
                    size="28px"
                  />
                ))}
            </Stack>
          </React.Fragment>
        )}
        {hasExits && (
          <React.Fragment>
            <Typography variant="paragraphMedium">Past Exits</Typography>
            <Stack direction="row" gap="8px">
              {person.competency_signals.venture_experiences.signals.map((signal, index) => (
                <React.Fragment key={index}>
                  {signal?.data?.length > 0 &&
                    signal.data.map((exit, index2) => (
                      <SmallCompanyLogoComponent
                        imageUrl={exit.image_url}
                        key={`exit-${index}-${index2}`}
                        tooltipText={`${exit.name} (${exit.exit_year})`}
                        linkUrl={exit.linkedin_url}
                        size="28px"
                      />
                    ))}
                </React.Fragment>
              ))}
            </Stack>
          </React.Fragment>
        )}
        {isEmpty && (
          <Typography variant="paragraph" color="colors.primary_text" textAlign="left">
            No information gathered.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

PersonItemComponent.propTypes = {
  person: PropTypes.object,
  onClick: PropTypes.func
};
