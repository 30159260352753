import { Box, Button, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import ModalComponent from 'components/modal/ModalComponent';
import OutlinedInfoIcon from 'icons/OutlinedInfoIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { appTheme } from 'theme';
import { EXTEND_DOMAIN_TYPES, getInstructions } from 'utils/subdomains';

function GenerateFollowup({ open, onClose, onSubmit }) {
  const [customLine, setCustomLine] = useState('');

  const handleInputChange = (event) => {
    setCustomLine(event.target.value);
  };

  const handleClose = () => {
    onClose();
    setCustomLine('');
  };

  const handleSubmit = () => {
    onSubmit(EXTEND_DOMAIN_TYPES.USER_INPUT, customLine);
    handleClose();
  };

  return (
    <ModalComponent
      title="Add follow-up with a custom query"
      open={open}
      onClose={handleClose}
      maxWidth={500}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={!customLine.trim()}>
            Add follow-up
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="12px" sx={{ padding: '24px' }}>
        <Typography variant="paragraphMedium">Custom query</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField
            multiline
            value={customLine}
            maxRows={6}
            minRows={2}
            onChange={handleInputChange}
            placeholder="Enter the kind of follow-up data you're looking for..."
            fullWidth
            slotProps={{
              input: { sx: { color: 'greyColors.grey250', ...appTheme.typography.paragraph } }
            }}
          />
        </Box>
        <Stack direction="row" gap="6px" alignItems="center" paddingTop="8px">
          <Box color="secondary.secondary5_100" height="24px">
            <OutlinedInfoIcon />
          </Box>
          <Typography variant="paragraph" color="primary.primary150">
            This query will be used as part of a
          </Typography>
          <Tooltip
            title={<CustomMarkdown body={getInstructions()} />}
            placement="right"
            arrow
            slotProps={{
              tooltip: {
                sx: {
                  maxWidth: 350
                }
              }
            }}>
            <Typography variant="paragraph" color="primary.primary3_50">
              longer prompt
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

GenerateFollowup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default GenerateFollowup;
