import { Alert, IconButton, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function IconButtonComponent({
  tag,
  message,
  variant,
  sx,
  disabled,
  onClick,
  severity,
  id,
  extraProps,
  children
}) {
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const [messageAnchorEl, setMessageAnchorEl] = useState(null);

  useEffect(() => {
    if (!messageAnchorEl) return;
    const timerHandler = setTimeout(() => setMessageAnchorEl(null), 2000);
    return () => clearTimeout(timerHandler);
  }, [messageAnchorEl]);

  const handlePopoverOpen = (event) => {
    setTooltipAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTooltipAnchorEl(null);
  };

  const handleCloseMessage = () => {
    setMessageAnchorEl(null);
  };

  const openTooltip = Boolean(tooltipAnchorEl);
  const openMessage = Boolean(messageAnchorEl);

  const onButtonClick = (event) => {
    setMessageAnchorEl(event.currentTarget);
    onClick?.(event);
  };

  return (
    <React.Fragment>
      <IconButton
        id={id}
        disabled={disabled}
        onClick={onButtonClick}
        color={variant}
        sx={sx}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        {...extraProps}>
        {children}
      </IconButton>
      {tag && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none'
          }}
          open={openTooltip}
          anchorEl={tooltipAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handlePopoverClose}
          TransitionProps={{ transform: 'translateX(-50%)' }}
          disableRestoreFocus>
          <Typography variant="paragraph" color="colors.text_on_primary">
            {tag}
          </Typography>
        </Popover>
      )}
      {message && (
        <Popover
          open={openMessage}
          sx={{
            pointerEvents: 'none',
            '.MuiPopover-paper': {
              padding: 0,
              margin: 0,
              backgroundColor: 'success'
            }
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          anchorEl={messageAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleCloseMessage}
          TransitionProps={{ transform: 'translateX(-50%)' }}
          disableRestoreFocus>
          <Alert severity={severity ? severity : 'success'} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Popover>
      )}
    </React.Fragment>
  );
}

IconButtonComponent.propTypes = {
  tag: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  severity: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  extraProps: PropTypes.object,
  children: PropTypes.any
};

IconButtonComponent.defaultProps = {
  id: '',
  sx: {},
  extraProps: {}
};
