import React from 'react';

function ProductIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0217 4.27158C11.3179 4.0942 11.6553 4 12 4C12.3447 4 12.6821 4.0942 12.9783 4.27158L12.9794 4.2722L18.0349 7.27281C18.3309 7.45032 18.5745 7.70414 18.743 8.00696C18.9115 8.30967 18.9997 8.65169 19 8.99889V15.0006C18.9997 15.3478 18.9115 15.6903 18.743 15.993C18.5745 16.2959 18.3309 16.5497 18.0349 16.7272L18.033 16.7284L12.9794 19.7278L12.9782 19.7285C12.6821 19.9058 12.3446 20 12 20C11.6554 20 11.3179 19.9058 11.0218 19.7285L11.0206 19.7278L5.96702 16.7284L5.96508 16.7272C5.66906 16.5497 5.42551 16.2959 5.25699 15.993C5.08853 15.6903 5.00034 15.3483 5 15.0011L5 8.99938C5.00034 8.65218 5.08853 8.30967 5.25699 8.00696C5.42551 7.70414 5.66906 7.45032 5.96508 7.27281L5.96702 7.27164L11.0206 4.2722L11.0217 4.27158ZM12 5C11.838 5 11.6776 5.04423 11.5349 5.12981L11.533 5.13098L6.47937 8.13043L6.47851 8.13094C6.33616 8.21651 6.21562 8.34081 6.13079 8.49324C6.04576 8.64603 6.00021 8.82074 6 8.99966V15.0003C6.00021 15.1793 6.04576 15.354 6.13079 15.5068C6.21563 15.6592 6.33618 15.7835 6.47854 15.8691L11.5349 18.8702C11.6776 18.9558 11.838 19 12 19C12.162 19 12.3224 18.9558 12.4651 18.8702L12.467 18.869L17.5206 15.8696C17.663 15.784 17.7844 15.6592 17.8692 15.5068C17.9543 15.3539 17.9998 15.1791 18 15.0001V8.99988C17.9998 8.82088 17.9543 8.6461 17.8692 8.49324C17.7844 8.34081 17.6638 8.21652 17.5215 8.13095L17.5206 8.13043L12.4651 5.12981C12.3224 5.04424 12.162 5 12 5Z"
        fill="#5C5E76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56811 7.74807C5.70725 7.50954 6.01341 7.42897 6.25194 7.56811L12 10.9211L17.7481 7.56811C17.9866 7.42897 18.2927 7.50954 18.4319 7.74807C18.571 7.98659 18.4905 8.29275 18.2519 8.43189L12.2519 11.9319C12.0963 12.0227 11.9037 12.0227 11.7481 11.9319L5.74806 8.43189C5.50954 8.29275 5.42897 7.98659 5.56811 7.74807Z"
        fill="#5C5E76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.2761 11 12.5 11.2239 12.5 11.5V19C12.5 19.2761 12.2761 19.5 12 19.5C11.7239 19.5 11.5 19.2761 11.5 19V11.5C11.5 11.2239 11.7239 11 12 11Z"
        fill="#5C5E76"
      />
    </svg>
  );
}

export default ProductIcon;
