import { Box, Button, CardMedia, Stack, Typography } from '@mui/material';
import { clearCurrentSearchAutocomplete } from 'actions/autocomplete/make-new-search-autocomplete-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import RerunStack from 'components/tile/RerunStack';
import LinkStack from 'components/tile/company/LinkStack';
import RunningSearchAction from 'components/tile/company/RunningSearchAction';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { RESEARCH_TYPES } from 'constants/researches';
import { RESOURCE_TYPES } from 'constants/resources';
import { TILE_TYPE } from 'constants/tiles';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentFolderId } from 'selectors/folders';
import {
  getRunCompanyDescriptionById,
  getRunCompanyLogoUrlById,
  getRunCompanyNameById,
  getRunIsMineById,
  getRunParametersById,
  getRunSearchStatusById,
  getRunStatusesById
} from 'selectors/runs';
import { getUserHasRunsLeft, getUserId } from 'selectors/user';

import HomePageTile from './HomePageTile';

function HomePageCompanyTile({ id }) {
  const type = TILE_TYPE.RUN;
  const navigate = useNavigate();
  const { state } = useAppState();

  const [isOnHover, setIsOnHover] = useState(false);
  const userId = getUserId(state);
  const companyDescription = getRunCompanyDescriptionById(state, id);
  const searchStatus = getRunSearchStatusById(state, id);
  const searchTerm = getRunParametersById(state, id)?.name;
  const isMine = getRunIsMineById(state, id, userId);
  const statuses = getRunStatusesById(state, id);
  const companyName = getRunCompanyNameById(state, id);
  const logoUrl = getRunCompanyLogoUrlById(state, id);
  const folderId = useSelector(getCurrentFolderId);
  const userHasRunsLeft = getUserHasRunsLeft(state);

  const failed =
    statuses && Object.keys(statuses).filter((service) => statuses[service] === 'FAILED');
  const running =
    statuses && Object.keys(statuses).filter((service) => statuses[service] === 'RUNNING');

  const onClick = (event) => {
    event.stopPropagation();
    dispatch(sendUserEvent(USER_EVENTS.DEEP_DIVE_OPEN, { company: searchTerm }));
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${id}/${OVERVIEW_TAB.path}`);
    dispatch(clearCurrentSearchAutocomplete());
  };

  return (
    <HomePageTile
      Icon={RESEARCH_TYPES.DEEP_DIVE.Icon}
      showDelete={isOnHover && type === 'run' && searchStatus !== 'running'}
      recordId={id}
      isMine={isMine}
      searchId={id}
      recordType={TILE_TYPE.RUN}
      resourceType={RESOURCE_TYPES.DEEP_DIVE}
      researchName={companyName}
      folderId={folderId}
      isOnHover={isOnHover}
      showMove
      showRerun={userHasRunsLeft}
      setIsOnHover={setIsOnHover}
      onClick={onClick}
      content={
        <Stack
          direction="column"
          gap="8px"
          padding="16px"
          sx={{ height: '100%', boxSizing: 'border-box' }}>
          <Stack direction="row" alignItems="center" gap="8px">
            <CardMedia
              sx={{ width: '48px', height: '48px', borderRadius: '4px' }}
              component="img"
              image={logoUrl}
              alt={companyName}
            />
            <Typography
              variant="h4"
              color="colors.primary_text"
              sx={{ flex: 1, textAlign: 'left' }}>
              {companyName}
            </Typography>
          </Stack>
          <Typography
            variant="paragraph"
            color="colors.primary_text"
            align="left"
            alignSelf="flex-start"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              height: '60px',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical'
            }}>
            {companyDescription}
          </Typography>
          <Box>
            <LinkStack id={id} type={type} showTags={false} />
          </Box>
        </Stack>
      }
      actions={
        <React.Fragment>
          {!searchStatus && (
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              onClick={onClick}
              startIcon={<RerunIcon />}>
              Run Research
            </Button>
          )}
          {state.debugMode && searchStatus === 'running' && running && running.length > 0 && (
            <Typography variant="paragraph" color="orange">
              Waiting for: {running.join(', ')}
            </Typography>
          )}
          {state.debugMode && searchStatus !== 'running' && failed && failed.length > 0 && (
            <Typography variant="paragraph" color="red">
              Failed: {failed.join(', ')}
            </Typography>
          )}
          {searchStatus === 'running' && <RunningSearchAction id={id} />}
          {searchStatus && searchStatus !== 'running' && (
            <RerunStack
              id={id}
              type={type}
              isOnHover={isOnHover}
              showRerun={false}
              folderId={folderId}
            />
          )}
        </React.Fragment>
      }
    />
  );
}

HomePageCompanyTile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default wrapWithError(HomePageCompanyTile);
