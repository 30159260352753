/* eslint-disable max-statements */
import {
  CHAT_GPT_MODEL_VERSION,
  CUSTOM_ANALYTICS_PLACEMENT,
  PROMPT_FIELD_TO_PREFIX,
  PROMPT_PREFIX
} from 'constants/custom-analytics';
import { DISCOVERY_TYPES, LIST_ACTION_MAX_COMPANIES } from 'constants/discovery';
import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';

import { COLUMN_TYPES_TO_SERVER_TYPE, columnsFromMergedRows } from './companyList-utils';
import { isEmpty } from './objects-utils';
import { isValidUrl } from './string-utils';

export const DISCOVERY_PREFIXES = [
  'Discover Companies by Query',
  'Discover Companies by Peer Group',
  'Discover Companies by Custom URL'
];

/* eslint-disable max-lines */
export const NULL_PROMPT_CONFIG = Object.freeze({
  forEachCompany: false,
  companies: null,
  searchTheWeb: false,
  column: null,
  useColumn: false,
  columns: null,
  useColumns: false,
  resource: null,
  resources: null,
  useDomainKnowledge: false,
  useDiscover: false,
  discoverMethod: DISCOVERY_TYPES.TEXT,
  question: '',
  answerType: 'text',
  useGpt4: true,
  usePlanner: false,
  temperature: 0,
  useTable: true,
  useWorkFlow: false,
  action: null
});

export const INITIAL_PROMPT_CONFIG = Object.freeze({
  ...NULL_PROMPT_CONFIG,
  forEachCompany: true,
  searchTheWeb: true
});

export function simplifyCompanies(companyValues) {
  if (!companyValues) return new Map();
  return companyValues?.reduce((result, company) => {
    result.set(company.companyListMetaId, {
      id: company.companyListMetaId,
      name: company.name,
      companyMetaId: company.companyMetaId,
      logoUrl: company.logoUrl
    });
    return result;
  }, new Map());
}

export function getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev) {
  if (!rows) return { columns: {}, companies: new Map() };
  const allRows = columnsFromMergedRows(rows, customColumns, listId, columnsSettings, isDev);
  const columns = allRows.slice(1).reduce((result, column) => {
    if (column.name?.length > 0 && column.data_type !== 'custom_analytics_meta') {
      const serverColumnId = COLUMN_TYPES_TO_SERVER_TYPE[column.id]
        ? COLUMN_TYPES_TO_SERVER_TYPE[column.id]
        : column.id;
      result[serverColumnId] = {
        id: serverColumnId,
        name: column.name,
        columnType: column.data_type
      };
    }
    return result;
  }, {});

  const companies = simplifyCompanies(allRows[0].values);
  return { columns, companies };
}

export const promptConfigToPrompt = (promptConfig) => {
  const promptParts = [];
  if (promptConfig.forEachCompany) {
    promptParts.push('{{for each company}}');
  }
  if (promptConfig.searchTheWeb) {
    promptParts.push('{{search the web}}');
  }
  if (promptConfig.column && promptConfig.useColumn) {
    promptParts.push(`{{using column $${promptConfig.column}}}`);
  }
  if (promptConfig.columns?.length > 0 && promptConfig.useColumns) {
    promptParts.push(`{{using columns $${promptConfig.columns.join(',')}}}`);
  }
  if (promptConfig.resource && promptConfig.useDomainKnowledge) {
    promptParts.push(`{{using domain knowledge $${promptConfig.resource}}}`);
  } else if (promptConfig.resources?.length > 0 && promptConfig.useDomainKnowledge) {
    promptParts.push(`{{using domain knowledge $${promptConfig.resources.join(',')}}}`);
  }
  promptParts.push(promptConfig.question?.trim());
  return promptParts.join(' ');
};

export const promptToPromptConfig = (prompt, answerType, selectedCompanies, model, useTable) => {
  const promptConfig = { ...NULL_PROMPT_CONFIG };
  let question = prompt;
  // Check for discovery
  DISCOVERY_PREFIXES.forEach((prefix) => {
    if (prompt.startsWith(prefix)) {
      promptConfig.useDiscover = true;
      promptConfig.discoverMethod = DISCOVERY_TYPES[prefix.split('Discovery Companies by ')[1]];
      promptConfig.question = prompt.replace(prefix, '');
      return promptConfig;
    }
  });
  // For each company
  if (prompt.includes('{{for each company}}')) {
    promptConfig.forEachCompany = true;
    question = question.replace('{{for each company}}', '');
  }
  // Search the web
  if (prompt.includes('{{search the web}}')) {
    promptConfig.searchTheWeb = true;
    question = question.replace('{{search the web}}', '');
  }
  // Column
  if (prompt.includes('{{using column $')) {
    promptConfig.useColumn = true;
    [promptConfig.column] = prompt.split('{{using column $')[1].split('}}');
    question = question.replace(`{{using column $${promptConfig.column}}}`, '');
  }
  // Columns
  if (prompt.includes('{{using columns $')) {
    promptConfig.useColumns = true;
    const [promptColumns] = prompt.split('{{using columns $')[1].split('}}');
    promptConfig.columns = promptColumns.split(',');
    question = question.replace(`{{using columns $${promptColumns}}}`, '');
  }
  // Domain Knowledge
  if (prompt.includes('{{using domain knowledge $')) {
    promptConfig.useDomainKnowledge = true;
    const [promptResources] = prompt.split('{{using domain knowledge $')[1].split('}}');
    promptConfig.resources = promptResources.split(',').map((resource) => {
      const parsedResource = parseInt(resource, 10);
      return isNaN(parsedResource) ? resource : parsedResource;
    });
    question = question.replace(`{{using domain knowledge $${promptResources}}}`, '');
  }

  promptConfig.question = question.trim();
  if (typeof useTable === 'boolean') promptConfig.useTable = useTable;
  if (answerType) promptConfig.answerType = answerType;
  if (selectedCompanies) promptConfig.companies = selectedCompanies;
  if (model) promptConfig.useGpt4 = model === CHAT_GPT_MODEL_VERSION;
  return promptConfig;
};

export const extractFirstActivePromptField = (promptConfig) => {
  const activeFields = Object.keys(promptConfig).filter((field) => promptConfig[field]);
  if (activeFields.length === 0) return null;
  if (activeFields.includes('forEachCompany')) return 'forEachCompany';
  if (activeFields.includes('searchTheWeb')) return 'searchTheWeb';
  if (activeFields.includes('useColumn')) return 'useColumn';
  if (activeFields.includes('useColumns')) return 'useColumns';
  if (activeFields.includes('useDomainKnowledge')) return 'useDomainKnowledge';
  if (activeFields.includes('useDiscover')) return 'useDiscover';
  return null;
};

export const getPromptFieldText = (field, firstActiveField) => {
  const text = PROMPT_FIELD_TO_PREFIX[field]?.prefix;
  if (field === firstActiveField) {
    return text.slice(0, 1).toUpperCase() + text.slice(1);
  }
  return text;
};

export const promptConfigToDisplayPrefix = (
  promptConfig,
  columns,
  domainKnowledge,
  placement,
  isSample
) => {
  const firstActiveField = extractFirstActivePromptField(promptConfig);
  const dkResource =
    promptConfig.resource && promptConfig.useDomainKnowledge && promptConfig.resource;
  const domainKnowledgeIsSpecific =
    domainKnowledge &&
    dkResource &&
    domainKnowledge.map((item) => `${item.id}`).includes(dkResource);
  const promptParts = [];
  if (promptConfig.forEachCompany && placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE) {
    if (isSample) {
      promptParts.push(`Preview for ${Object.keys(promptConfig.companies).length} companies`);
    } else {
      promptParts.push(PROMPT_PREFIX.ADD_COLUMN.title);
      if (promptConfig.companies) {
        promptParts.push(`for ${Object.keys(promptConfig.companies).length} selected companies`);
      }
    }
  }
  if (promptConfig.searchTheWeb) {
    promptParts.push(getPromptFieldText('searchTheWeb', firstActiveField));
  }
  if (promptConfig.column && promptConfig.useColumn) {
    const columnName = columns?.[promptConfig.column]?.name || '[Deleted Column]';
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE) {
      promptParts.push(`${getPromptFieldText('useCard', firstActiveField)} "${columnName}"`);
    } else {
      promptParts.push(`${getPromptFieldText('useColumn', firstActiveField)} "${columnName}"`);
    }
  }
  if (promptConfig.columns?.length > 0 && promptConfig.useColumns && columns) {
    const columnsNames = promptConfig.columns
      .map((columnId) => columns[columnId]?.name || 'Deleted Column')
      .join(',');
    promptParts.push(`${getPromptFieldText('useColumns', firstActiveField)} "${columnsNames}"`);
  }
  if (dkResource) {
    const dkText = domainKnowledgeIsSpecific
      ? domainKnowledge?.find((item) => `${item.id}` === promptConfig.resource)?.name
      : `"${promptConfig.resource}"`;
    promptParts.push(`${getPromptFieldText('useDomainKnowledge', firstActiveField)} ${dkText}`);
  }
  if (promptConfig.resources?.length > 0 && promptConfig.useDomainKnowledge) {
    const resourceIdToResourcd = domainKnowledge.reduce((map, resource) => {
      map[resource.id] = resource;
      return map;
    }, {});
    const dkResources = promptConfig.resources
      .map((resource) => resourceIdToResourcd[resource]?.name || `All ${resource}`)
      .join(',');
    promptParts.push(
      `${getPromptFieldText('useDomainKnowledge', firstActiveField)} "${dkResources}"`
    );
  }
  if (promptConfig.useWorkFlow) {
    promptParts.push(getPromptFieldText('useWorkFlow', firstActiveField));
  }
  return promptParts.join(' ');
};

export function determineErrorStatus(
  promptConfig,
  numberOfSelectedCompanies,
  partners,
  industries,
  listLength
) {
  if (promptConfig.useDiscover) {
    if (promptConfig.discoverMethod === DISCOVERY_TYPES.TEXT) {
      if (promptConfig.question.trim().length < 1) {
        return ['Please write a query to discover companies'];
      }

      if (numberOfSelectedCompanies > 0) {
        return [
          'The discover can not run with a query and selected companies ',
          'Continue with query',
          'or',
          'Continue with peer group'
        ];
      }
    }

    if (promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS) {
      if (numberOfSelectedCompanies <= 0) {
        return ['Select companies from your list to discover more companies.'];
      }
      if (numberOfSelectedCompanies > LIST_ACTION_MAX_COMPANIES.EXTEND) {
        return [`Please select up to ${LIST_ACTION_MAX_COMPANIES.EXTEND} companies`];
      }
    }

    if (promptConfig.discoverMethod === DISCOVERY_TYPES.PARTNERS) {
      if (!partners?.length && !industries?.length) {
        return ['Please select at least one partner and industry to discover companies.'];
      }
      if (!partners?.length) {
        return ['Please select at least one partner to discover companies.'];
      }
      if (!industries?.length) {
        return ['Please select industry to discover companies.'];
      }
    }

    if (promptConfig.discoverMethod === DISCOVERY_TYPES.URL && !isValidUrl(promptConfig.question)) {
      return ['Please provide a valid custom url.'];
    }
  } else if (promptConfig.question.length <= 0) {
    return ['Please write a research ask.'];
  } else if (
    !promptConfig.useDiscover &&
    !promptConfig.useWorkFlow &&
    listLength <= 0 &&
    (!promptConfig.useDomainKnowledge || promptConfig.useColumn || promptConfig.forEachCompany)
  ) {
    return ['This list is empty. Add some companies to use this feature.'];
  } else if (
    !promptConfig.useDiscover &&
    !promptConfig.searchTheWeb &&
    !promptConfig.useTable &&
    !promptConfig.useDomainKnowledge
  ) {
    return ['Select search source.'];
  } else if (
    (promptConfig.useColumns && isEmpty(promptConfig.columns)) ||
    (promptConfig.useColumn && !promptConfig.column)
  ) {
    return ['Select source.'];
  }
  return [];
}

export const getDeepDiveDynamicCardsMetadata = (deepDiveDynamicCards) => {
  return (
    deepDiveDynamicCards &&
    Object.values(deepDiveDynamicCards).reduce((result, card) => {
      if (card?.data?.name?.length > 0) {
        result[card.id] = {
          id: card.id,
          name: card.data.name,
          columnType: 'deep_dive'
        };
      }
      return result;
    }, {})
  );
};

const togglePromptConfigField = (setPromptConfig, columnIds, field) => {
  setPromptConfig((prev) => {
    const copy = { ...prev };
    if (field in copy) {
      copy[field] = !copy[field];
    }
    if (field === 'useColumn') {
      if (prev.useColumn) {
        copy.column = null;
      } else if (columnIds && columnIds.length > 0) {
        copy.column = columnIds[0]?.id;
      }
    }
    if (field === 'useColumns') {
      copy.columns = null;
    }
    const resourcesTypes = Object.values(DOMAIN_KNOWLEDGE_RESOURCE_TYPE);
    if (field === 'forEachCompany') {
      if (prev.useColumn) {
        copy.companies = null;
      }
    }
    if (field === 'useDomainKnowledge') {
      if (prev.useDomainKnowledge) {
        copy.resource = null;
        copy.resources = null;
        copy.useSearch = true;
      } else if (resourcesTypes && resourcesTypes.length > 0) {
        [copy.resource] = resourcesTypes;
        copy.resources = resourcesTypes.slice(0, 1);
      }
    }
    if (field === 'useDiscover') {
      if (prev.useDiscover) {
        copy.discoverMethod = DISCOVERY_TYPES.TEXT;
        copy.searchTheWeb = true;
        copy.useTable = true;
        copy.forEachCompany = true;
        copy.useDiscover = false;
      } else {
        copy.useColumn = false;
        copy.useColumns = false;
        copy.columns = [];
        copy.domainKnowledge = null;
        copy.forEachCompany = false;
        copy.useDomainKnowledge = false;
        copy.searchTheWeb = false;
        copy.useTable = false;
        copy.action = null;
        copy.useDiscover = true;
      }
    }
    if (field === 'forEachCompany-on') {
      copy.forEachCompany = true;
      copy.action = null;
      copy.useWorkFlow = false;
    }
    if (field === 'forEachCompany-off') {
      copy.forEachCompany = false;
      copy.action = null;
      copy.useWorkFlow = false;
    }
    if (field === 'useWorkFlow') {
      copy.action = 'prepare_multivalue_question';
      copy.useColumn = false;
      copy.useColumns = false;
      copy.columns = [];
      copy.forEachCompany = false;
      copy.useDiscover = false;
      copy.searchTheWeb = true;
    }
    return copy;
  });
};

export const generatePromptConfigToggleField = (setPromptConfig, columnIds) => {
  return (field) => togglePromptConfigField(setPromptConfig, columnIds, field);
};
