import { Stack, Typography } from '@mui/material';
import { loadDomainReportDefaultConfigAction } from 'actions/domain_reports/load-domain-report-default-config';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDomainReportDefaultConfig,
  getLatestDomainReportByListId
} from 'selectors/domain-reports';
import NewDomainReportGraphic from 'svg/NewDomainReportGraphic';

import DomainReportButton from './DomainReportButton';
import DomainReportHeader from './DomainReportHeader';
import DomainReportCluster from './cluster/DomainReportCluster';
import DomainReportMarket from './market/DomainReportMarket';
import DomainReportProcess from './process/DomainReportProcess';
import DomainReportTopProblems from './top-problems/DomainReportTopProblems';

export const DOMAIN_REPORT_PANEL_ID = 'domain-report-panel';

function DomainReportTab({ listId }) {
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const defaultConfig = useSelector(getDomainReportDefaultConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!defaultConfig) {
      dispatch(loadDomainReportDefaultConfigAction());
    }
  }, [dispatch, defaultConfig]);

  if (!domainReport) {
    return (
      <Stack gap="12px" sx={{ marginTop: '100px', paddingBottom: '32px' }}>
        <Stack gap="16px" justifyContent="center" alignItems="center">
          <NewDomainReportGraphic />
          <Typography variant="h3" color="colors.primary_text">
            Discover domain insights
          </Typography>
          <Typography
            variant="paragraph"
            color="colors.primary_text"
            width="415px"
            sx={{ marginBottom: '16px' }}>
            Start by creating your first report, and update it anytime based on the latest data in
            the list.
          </Typography>
          <DomainReportButton listId={listId} />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      gap="12px"
      sx={{ marginTop: '16px', paddingBottom: '32px', maxWidth: '802px' }}
      textAlign="left">
      <DomainReportHeader listId={listId} />
      <DomainReportProcess listId={listId} />
      <DomainReportMarket listId={listId} />
      <DomainReportTopProblems listId={listId} />
      <DomainReportCluster listId={listId} />
    </Stack>
  );
}

DomainReportTab.propTypes = {
  listId: PropTypes.number
};

export default DomainReportTab;
