import { Box, Divider, Input, Modal, Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import CloseIcon from 'icons/CloseIcon';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { appTheme } from 'theme';

function ModalComponent({
  open,
  onClose,
  title,
  allowEditTitle,
  onTitleChange,
  titleIcon,
  height,
  width,
  minWidth,
  maxWidth,
  maxHeight,
  bulk,
  backgroundColor,
  bottomBar,
  topBarActions,
  children
}) {
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [localTitle, setLocalTitle] = useState('');

  useEffect(() => {
    setLocalTitle(title);
  }, [title]);

  if (!open) return null;

  const closeDialog = (event) => {
    setEditTitleMode(false);
    onClose?.(event);
  };

  const updateTitle = (event) => {
    setLocalTitle(event.target.value);
  };

  const onDownKey = (event) => {
    event.stopPropagation();
    if (!editTitleMode) return;
    if (event?.type === 'click' || event?.key === 'Enter') {
      setEditTitleMode(false);
      onTitleChange?.(localTitle);
    }
  };

  const sxExtra = {};
  if (bulk) {
    sxExtra.width = width ? width : 'fit-content';
  }

  return (
    <Modal
      open={open}
      onClose={closeDialog}
      onClick={onDownKey}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: minWidth ? minWidth : 'auto',
        maxWidth: maxWidth ? `${maxWidth}px` : '90%',
        height: '100%',
        maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
        margin: 'auto',
        overflow: 'auto',
        outline: 'none',
        borderRadius: '4px',
        '.MuiBackdrop-root': {
          backgroundColor: 'primary.primary1',
          opacity: '0.5 !important'
        },
        ...sxExtra
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'relative',
          top: height ? 0 : '50%',
          transform: height ? 'none' : 'translateY(-50%)',
          maxHeight: '100%',
          height: height ? height : 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          borderRadius: '4px',
          alignItems: 'center',
          backgroundColor: backgroundColor ? backgroundColor : 'colors.primary_bg'
        }}>
        {title && (
          <Box
            sx={{
              width: '100%',
              minHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'colors.grey_bg',
              borderBottom: '1px solid',
              borderColor: 'colors.ui_border'
            }}>
            {allowEditTitle && editTitleMode ? (
              <Input
                value={localTitle}
                disableUnderline={true}
                autoFocus={true}
                onChange={(event) => updateTitle(event)}
                onKeyPress={onDownKey}
                sx={{
                  input: {
                    color: 'colors.primary_text'
                  },
                  paddingLeft: '24px',
                  backgroundColor: 'transparent',
                  ...appTheme.typography.h3
                }}
              />
            ) : (
              <Stack direction="row" gap="8px" alignItems="center" sx={{ paddingLeft: '24px' }}>
                {titleIcon}
                <Typography variant="h3" color="colors.primary_text">
                  {title}
                </Typography>
                {allowEditTitle && (
                  <IconButtonComponent onClick={() => setEditTitleMode(true)}>
                    <EditIcon />
                  </IconButtonComponent>
                )}
              </Stack>
            )}
            <Stack direction="row" alignItems="center" gap="8px">
              {topBarActions && (
                <Stack direction="row" alignItems="center" gap="16px" sx={{ width: '100%' }}>
                  {topBarActions}
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ width: '1px', height: '30px', borderColor: 'greyColors.grey100' }}
                  />
                </Stack>
              )}
              <IconButtonComponent
                id="close-modal"
                tag="Close"
                variant="secondary"
                onClick={closeDialog}
                sx={{ marginRight: '24px' }}>
                <CloseIcon />
              </IconButtonComponent>
            </Stack>
          </Box>
        )}
        <Box sx={{ flex: 1, width: '100%', height: '100%', overflowY: 'scroll' }}>{children}</Box>
        {bottomBar && (
          <Box
            sx={{
              width: '100%',
              minHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'colors.grey_bg',
              borderTop: '1px solid',
              borderColor: 'colors.ui_border',
              borderRadius: '0px 0px 4px 4px'
            }}>
            {bottomBar}
          </Box>
        )}
      </Box>
    </Modal>
  );
}

ModalComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  allowEditTitle: PropTypes.bool,
  onTitleChange: PropTypes.func,
  titleIcon: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bulk: PropTypes.bool,
  backgroundColor: PropTypes.string,
  topBarActions: PropTypes.node,
  bottomBar: PropTypes.node,
  children: PropTypes.any
};

export default wrapWithError(ModalComponent);
