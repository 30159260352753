import Looks4Icon from '@mui/icons-material/Looks4';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { Menu, Slider, Stack, Switch, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import SettingsIcon from 'icons/SettingsIcon';
import React, { useState } from 'react';
import { PromptConfig } from 'utils/custom-analytics-utils';

interface PromptDevMenuProps {
  promptConfig: PromptConfig;
  setPromptConfig: React.Dispatch<React.SetStateAction<PromptConfig>>;
}

function PromptDevMenu({ promptConfig, setPromptConfig }: PromptDevMenuProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTemperatureChange = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setPromptConfig((prev) => ({ ...prev, temperature: newValue }));
    }
  };
  return (
    <React.Fragment>
      <IconButtonComponent onClick={handleMenuClick}>
        <SettingsIcon />
      </IconButtonComponent>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        sx={{
          color: 'colors.primary_text'
        }}>
        <Stack padding="8px" direction="row" gap="16px">
          <ThermostatIcon />
          <Slider
            value={promptConfig.temperature}
            onChange={handleTemperatureChange}
            min={0}
            max={10}
          />
        </Stack>
        <Stack
          direction="row"
          gap="4px"
          alignItems="center"
          sx={{
            paddingLeft: '8px',
            paddingRight: '4px'
          }}>
          <Typography variant="paragraph">Do tagging</Typography>
          <Switch
            checked={promptConfig.doTagging}
            onChange={() => setPromptConfig((prev) => ({ ...prev, doTagging: !prev.doTagging }))}
          />
          <Looks4Icon />
          <Switch
            checked={promptConfig.useGpt4}
            onChange={() => setPromptConfig((prev) => ({ ...prev, useGpt4: !prev.useGpt4 }))}
          />
          {promptConfig.useDiscover && (
            <React.Fragment>
              <Typography variant="paragraph">Global POC</Typography>
              <Switch
                checked={promptConfig.useGlobalTablePoc}
                onChange={() =>
                  setPromptConfig((prev) => ({
                    ...prev,
                    useGlobalTablePoc: !prev.useGlobalTablePoc
                  }))
                }
              />
            </React.Fragment>
          )}
        </Stack>
      </Menu>
    </React.Fragment>
  );
}

PromptDevMenu.propTypes = {};

export default PromptDevMenu;
