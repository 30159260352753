import { Button, Link, Stack } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import { DiscoveryTitle } from 'components/discovery/DiscoveryTitle';
import SharedStack from 'components/tile/SharedStack';
import { DiscoveryViewTabs } from 'components/tile/discovery/DiscoveryViewTabs';
import { RESOURCE_TYPES } from 'constants/resources';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import BackIcon from 'icons/BackIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getCurrentFolderId } from 'selectors/folders';
import { getUserCanCollaborate } from 'selectors/user';

function ListSummaryComponent({
  listId,
  name,
  onTitleChange,
  backTag,
  backHref,
  children,
  numCompanies,
  isCollapsed,
  paddingTop
}) {
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const canCollaborate = useOldStateSelector(getUserCanCollaborate);
  const currentFolderId = useSelector(getCurrentFolderId);
  const doCollaborate = () => {
    setCollaborateOpen(true);
  };

  return (
    <Stack
      gap="16px"
      sx={{
        position: 'sticky',
        top: '0px',
        paddingTop: `${paddingTop}px`,
        backgroundColor: 'colors.hover_on_gray_bg',
        zIndex: 1
      }}>
      <Stack direction="row" gap="16px" alignItems="center">
        {isCollapsed && (
          <React.Fragment>
            {backHref && (
              <IconButtonComponent variant="primary" tag={backTag} sx={{ padding: 0 }}>
                <Link
                  component={RouterLink}
                  to={backHref}
                  color="secondary.secondary1"
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <BackIcon />
                </Link>
              </IconButtonComponent>
            )}
            <DiscoveryTitle
              title={name}
              onTitleChange={onTitleChange}
              numCompanies={numCompanies}></DiscoveryTitle>
          </React.Fragment>
        )}
        <DiscoveryViewTabs listId={listId} />
        <Stack marginLeft="auto" direction="row" gap="16px">
          {children}
        </Stack>
        <Stack direction="row" gap="8px">
          {canCollaborate && (
            <Button variant="outlined" onClick={doCollaborate}>
              Collaborate
            </Button>
          )}
          {canCollaborate && (
            <SharedStack
              id={listId}
              resourceType={RESOURCE_TYPES.LIST}
              size={32}
              folderId={currentFolderId}
            />
          )}
        </Stack>
      </Stack>
      {listId && canCollaborate && (
        <CollaborateDialog
          open={collaborateOpen}
          onClose={() => setCollaborateOpen(false)}
          id={listId}
          folderId={currentFolderId}
          resourceType={RESOURCE_TYPES.LIST}
        />
      )}
    </Stack>
  );
}

ListSummaryComponent.propTypes = {
  listId: PropTypes.number,
  name: PropTypes.string,
  onTitleChange: PropTypes.func,
  backTag: PropTypes.string,
  backHref: PropTypes.string,
  children: PropTypes.any,
  numCompanies: PropTypes.number,
  isCollapsed: PropTypes.bool,
  paddingTop: PropTypes.number
};

export default wrapWithError(ListSummaryComponent);
