import { Box, Grow, Input, Menu, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { submitSubdomainAction } from 'actions/domain-research/submit-subdomain-action';
import IconButtonComponent from 'components/IconButtonComponent';
import RenderDiscoveryRunFeedMessage from 'components/discovery/RenderDiscoveryRunFeedMessage';
import RenderStr from 'components/elements/table/RenderStr';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import CheckIcon2 from 'icons/CheckIcon2';
import ViewFollowupIcon from 'icons/ViewFollowupIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { highlightExploreSubdomains } from 'reducer/explore-slice';
import {
  getDomainResearchIsDescriptionHidden,
  subdomainIdToChildPrefixes
} from 'selectors/explore';
import { appTheme } from 'theme';
import { EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

function SubdomainItem({
  subdomain,
  onClickSubdomain,
  isSelected,
  inputName,
  setInputName,
  onPressEnter,
  discoveryRecordId,
  placeholder,
  hasSubdomains,
  listId,
  backgroundStyle,
  width
}) {
  const name = subdomain ? subdomain.name : inputName;
  const id = subdomain ? subdomain.id : null;
  const description = subdomain ? subdomain.description : null;
  const dispatch = useDispatch();
  const childPrefixes = useSelector((state) => subdomainIdToChildPrefixes(state, listId, id));
  const [isHovered, setIsHovered] = useState(false);
  const hideSubdomainsDescription = useSelector((state) =>
    getDomainResearchIsDescriptionHidden(state, listId)
  );
  const [discoveryMenuAnchorEl, setDiscoveryMenuAnchorEl] = useState(false);
  const componentStyle = {
    backgroundColor: 'colors.primary_bg',
    maxWidth: '100%',
    borderRadius: '8px',
    padding: '16px 24px',
    paddingRight: '16px',
    justifyContent: 'center',
    border: isSelected ? '2px solid #2C96FF' : '2px solid white',
    cursor: id ? 'pointer' : 'auto'
  };

  if (subdomain && subdomain.loading) {
    return (
      <Box sx={{ paddingRight: '12px', maxWidth: '100%' }}>
        <Skeleton variant="rounded" sx={{ minWidth: width }} height={130} />
      </Box>
    );
  }

  const scrollToCategory = (event) => {
    event?.stopPropagation();
    const element = document.getElementById(`subdomain-category-${id}-${childPrefixes[0]}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
    dispatch(highlightExploreSubdomains({ listId, subdomainId: id, shouldHightlight: true }));
  };

  const submitItem = async (event) => {
    dispatch(
      submitSubdomainAction(
        EXTEND_DOMAIN_TYPES.SUBDOMAINS,
        listId,
        subdomain.id,
        subdomain.name,
        Number(subdomain.level) + 1
      )
    );
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 200));
    scrollToCategory(event);
  };

  const submitSubdomainButtonStyle = {
    position: 'absolute',
    left: '378px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'colors.primary_bg',
    borderRadius: '4px',
    boxShadow: `0px 2px 4px 0px #00000026`,
    height: '24px',
    padding: 0,
    ':hover': {
      backgroundColor: 'greyColors.grey50'
    }
  };
  const component = (
    <Stack
      id="subdomain-item"
      width="100%"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      boxSizing="border-box">
      <Stack
        direction="row"
        boxSizing="border-box"
        sx={{
          ...backgroundStyle,
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
          paddingRight: '12px'
        }}>
        {isHovered && !hasSubdomains && subdomain && (
          <Tooltip arrow title="Create a follow-up">
            <Box
              onClick={hasSubdomains ? scrollToCategory : submitItem}
              sx={{ ...submitSubdomainButtonStyle, cursor: 'pointer' }}>
              <ArrowRightIcon
                color={
                  hasSubdomains
                    ? appTheme.palette.colors.positive_green
                    : appTheme.palette.colors.icon
                }
              />
            </Box>
          </Tooltip>
        )}
        <Stack
          sx={{ ...componentStyle }}
          onClick={id ? () => onClickSubdomain(id) : null}
          width="100%">
          {subdomain ? (
            <Stack gap="8px" width={width}>
              <Stack direction="row" alignItems="center">
                <RenderStr color="primary.primary1" variant="h4" value={name} />
                <Stack direction="row" gap="4px" marginLeft="auto">
                  {discoveryRecordId && (
                    <IconButtonComponent
                      tag="View discovery details"
                      sx={{ marginLeft: 'auto', padding: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDiscoveryMenuAnchorEl(event.currentTarget);
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#BEFAE1',
                          direction: 'row',
                          borderRadius: '4px',
                          paddingRight: '4px',
                          paddingY: '2px'
                        }}>
                        <CheckIcon2 />
                        <Typography variant="paragraph">in list</Typography>
                      </Box>
                    </IconButtonComponent>
                  )}
                  {hasSubdomains && (
                    <IconButtonComponent
                      tag="View follow-up"
                      onClick={scrollToCategory}
                      sx={{ cursor: 'pointer', padding: 0 }}>
                      <ViewFollowupIcon />
                    </IconButtonComponent>
                  )}
                </Stack>
              </Stack>
              {!hideSubdomainsDescription && description && (
                <Typography
                  display="block"
                  variant="paragraph"
                  color="colors.secondary_text"
                  paddingRight="16px">
                  {description}
                </Typography>
              )}
            </Stack>
          ) : (
            <Input
              multiline
              sx={{ width }}
              value={inputName}
              disableUnderline
              onChange={(event) => setInputName(event.target.value)}
              placeholder={placeholder}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  onPressEnter();
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      {discoveryRecordId && (
        <Menu
          anchorEl={discoveryMenuAnchorEl}
          open={Boolean(discoveryMenuAnchorEl)}
          onClose={() => setDiscoveryMenuAnchorEl(null)}>
          <Stack padding="8px">
            <RenderDiscoveryRunFeedMessage
              searchId={discoveryRecordId}
              listId={listId}
              showOnlyDiscoveredCompanies={true}
            />
          </Stack>
        </Menu>
      )}
    </Stack>
  );

  if (subdomain?.streamed) {
    return <Grow in={true}>{component}</Grow>;
  }
  return component;
}

SubdomainItem.propTypes = {
  listId: PropTypes.number,
  subdomain: PropTypes.object,
  onClickSubmit: PropTypes.func,
  level: PropTypes.number,
  onAdd: PropTypes.func,
  parentId: PropTypes.number,
  onClickSubdomain: PropTypes.func,
  isSelected: PropTypes.bool,
  inputName: PropTypes.string,
  setInputName: PropTypes.func,
  onPressEnter: PropTypes.func,
  discoveryRecordId: PropTypes.bool,
  placeholder: PropTypes.string,
  hasSubdomains: PropTypes.bool,
  backgroundStyle: PropTypes.object,
  width: PropTypes.string
};

export default SubdomainItem;
