import { Popover, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanNodeDependencies({ anchorEl, onClose, dependsOn, usedBy }) {
  return (
    <Popover
      id="dependencies"
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      slotProps={{
        paper: {
          sx: { padding: 0, backgroundColor: 'white', boxShadow: '0px 4px 15px 0px #00000024' }
        }
      }}>
      <Stack
        direction="column"
        gap="16px"
        sx={{
          backgroundColor: 'primary.white',
          padding: '16px',
          boxShadow: '0px 4px 15px 0px #00000024',
          borderRadius: '8px'
        }}>
        {dependsOn?.length > 0 && (
          <Stack direction="column" gap="8px">
            <Typography variant="paragraphMedium" color="secondary.secondary5_100">
              Dependent on
            </Typography>
            {dependsOn.map((dependency) => (
              <Stack key={dependency.title} direction="row" alignItems="center" gap="8px">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: applyThemeColorOpacity(dependency.color, 12),
                    borderRadius: '6px'
                  }}
                  color={dependency.color}>
                  <dependency.Icon />
                </Stack>
                <Typography variant="paragraph" color="primary.primary150">
                  {dependency.title}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {usedBy?.length > 0 && (
          <Stack direction="column" gap="8px">
            <Typography variant="paragraphMedium" color="secondary.secondary5_100">
              Used by
            </Typography>
            {usedBy.map((dependency) => (
              <Stack key={dependency.title} direction="row" alignItems="center" gap="8px">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: applyThemeColorOpacity(dependency.color, 12),
                    borderRadius: '6px'
                  }}
                  color={dependency.color}>
                  <dependency.Icon />
                </Stack>
                <Typography variant="paragraph" color="primary.primary150">
                  {dependency.title}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Popover>
  );
}
WorkFlowPlanNodeDependencies.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  dependsOn: PropTypes.array,
  usedBy: PropTypes.array
};

export default WorkFlowPlanNodeDependencies;
