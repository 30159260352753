import { Stack } from '@mui/material';
import WorkFlowPlanEdge from 'components/work-flow/WorkFlowPlanEdge';
import WorkFlowPlanGroupNode from 'components/work-flow/WorkFlowPlanGroupNode';
import WorkFlowPlanNode from 'components/work-flow/WorkFlowPlanNode';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  ConnectionLineType,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import { appTheme } from 'theme';
import { applyThemeColorOpacity } from 'utils/theme-utils';
import { extractNodesAndEdges } from 'utils/work-flow-planner-utils';

import WorkFlowGeneratePlanNode from './WorkFlowGeneratePlanNode';
import WorkFlowPlanMainPromptNode from './WorkFlowPlanMainPromptNode';

const edgeTypes = { workFlow: WorkFlowPlanEdge };
const nodeTypes = {
  workFlow: WorkFlowPlanNode,
  workFlowGroup: WorkFlowPlanGroupNode,
  workFlowMainPrompt: WorkFlowPlanMainPromptNode,
  WorkFlowGeneratePlan: WorkFlowGeneratePlanNode
};

function WorkFlowPlanChart({ listId, postId, plan, mainPrompt }) {
  const [maxZoom, setMaxZoom] = useState(0.8);

  useEffect(() => setMaxZoom(2), []);

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(
    () => extractNodesAndEdges(plan, mainPrompt, listId, postId),
    [plan, mainPrompt, listId, postId]
  );
  const [nodes, , onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: ConnectionLineType.SmoothStep,
            animated: true
          },
          eds
        )
      ),
    [setEdges]
  );

  return (
    <Stack width="100%" height="800px">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        panOnScroll
        maxZoom={maxZoom}
        translateExtent={[
          [-4000, -3000],
          [4000, 3000]
        ]}
        fitView>
        <MiniMap
          position="top-right"
          nodeBorderRadius={16}
          nodeStrokeColor={(node) => applyThemeColorOpacity(node.data.color, 100)}
          nodeColor={(node) => applyThemeColorOpacity(node.data.color, 10)}
          maskColor={appTheme.palette.greyColors.grey100}
        />
        <Controls position="top-left" />
        <Background />
      </ReactFlow>
    </Stack>
  );
}

WorkFlowPlanChart.propTypes = {
  listId: PropTypes.number,
  postId: PropTypes.number,
  plan: PropTypes.array,
  mainPrompt: PropTypes.string
};

export default WorkFlowPlanChart;
