import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Dialog, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { changePictureAction } from 'actions/users/change-picture-action';
import { changeUsernameAction } from 'actions/users/change-username-action';
import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import { updateUserRecieveMonitoringEmailsAction } from 'actions/users/update-user-recieve-monitoring-emails';
import IconButtonComponent from 'components/IconButtonComponent';
import CustomAvatar from 'components/tile/CustomAvatar';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import CheckIcon from 'icons/CheckIcon';
import CloseIcon from 'icons/CloseIcon';
import EditIcon from 'icons/EditIcon';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { getUser, getUserOrganizationUsers } from 'selectors/user';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
export default function ProfileScreen() {
  const { state } = useAppState();
  const user = getUser(state);
  const fileInputRef = useRef(null);
  const editorRef = useRef(null);
  const orgUsers = getUserOrganizationUsers(state);
  const [picHover, setPicHover] = useState(false);
  const [avatarImageFile, setAvatarImageFile] = useState(null);
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [sliderZoom, setSliderZoom] = useState(120);
  const [nameEdit, setNameEdit] = useState(false);
  const [newUsername, setNewUsername] = useState(null);
  const [newIsRecieveMonitoringEmails, setNewIsRecieveMonitoringEmails] = useState(true);
  const { addToast } = useAppUtils();
  const size = 90;
  const usernameAlreadyExists =
    orgUsers &&
    orgUsers
      .filter((orgUser) => orgUser.username !== user.username)
      .some((orgUser) => orgUser.username === newUsername);

  useEffect(() => {
    dispatch(loadUserOrganizationUsersAction());
  }, []);

  useEffect(() => {
    setNewUsername(user?.username);
  }, [user]);

  useEffect(() => {
    setNewIsRecieveMonitoringEmails(user?.user_settings?.monitoring_updates);
  }, [user]);

  const onPicClick = () => {
    fileInputRef.current.click();
  };

  const cancelNameEdit = () => {
    setNameEdit(false);
    setNewUsername(user?.username);
  };

  const openNameEdit = () => {
    setNewUsername(user?.username);
    setNameEdit(true);
  };

  const submitChangeUsername = () => {
    dispatch(changeUsernameAction(newUsername, addToast));
    setNameEdit(false);
  };
  const resetInput = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  const closeEditor = () => {
    setAvatarDialogOpen(false);
    setAvatarImageFile(null);
    setSliderZoom(120);
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    if (files.length > 1) {
      /* eslint-disable no-alert */
      alert('Please only submit one file!');
      return;
    }
    if (files[0].size > 1048576 * 10) {
      /* eslint-disable no-alert */
      alert('Max file size is 10MB!');
      return;
    }
    setAvatarImageFile(files[0]);
    setAvatarDialogOpen(true);
  };

  const handleSaveImage = () => {
    const canvas = editorRef.current.getImageScaledToCanvas();
    const dataURL = canvas.toDataURL('image/jpeg');
    dispatch(changePictureAction(dataURL, addToast));
    closeEditor();
  };

  return (
    <Stack
      sx={{
        padding: '32px',
        minHeight: 'calc(100vh - 50px)',
        backgroundColor: 'colors.hover_on_gray_bg'
      }}
      gap="32px"
      alignItems="center">
      <Typography variant="h1">Profile</Typography>
      <Stack
        gap="16px"
        sx={{
          padding: '24px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          width: '450px',
          textAlign: 'left',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_bg',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
        <Stack direction="row" gap="32px" alignItems="center">
          <Box
            onMouseEnter={() => setPicHover(true)}
            onMouseLeave={() => setPicHover(false)}
            onClick={onPicClick}
            sx={{ cursor: 'pointer' }}>
            {picHover ? (
              <Box
                sx={{
                  width: size,
                  height: size,
                  borderRadius: size / 2,
                  backgroundColor: 'greyColors.grey250'
                }}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  sx={{ color: 'white' }}>
                  <FileUploadIcon />
                  <Typography variant="paragraph">UPLOAD</Typography>
                </Stack>
              </Box>
            ) : (
              <CustomAvatar
                name={user?.username}
                pictureUrl={user?.picture}
                size={size}
                noTooltip
              />
            )}
          </Box>
          <Stack gap="8px" sx={{ textAlign: 'left', color: 'colors.primary_text' }}>
            <Stack direction="row" alignItems="center">
              {nameEdit ? (
                <TextField
                  variant="standard"
                  error={usernameAlreadyExists}
                  helperText={usernameAlreadyExists ? 'Username already in use' : ''}
                  sx={{ backgroundColor: 'colors.grey_bg' }}
                  value={newUsername}
                  onChange={(event) => setNewUsername(event.target.value)}
                />
              ) : (
                <Typography variant="h2" color="secondary.secondary1">
                  {user?.username}
                </Typography>
              )}
              {nameEdit ? (
                <Stack direction="row">
                  <IconButtonComponent tag="Cancel" onClick={cancelNameEdit}>
                    <CloseIcon />
                  </IconButtonComponent>
                  <IconButtonComponent
                    tag="Confirm"
                    onClick={submitChangeUsername}
                    disabled={usernameAlreadyExists}>
                    <CheckIcon />
                  </IconButtonComponent>
                </Stack>
              ) : (
                <IconButtonComponent tag="Edit Username" onClick={openNameEdit}>
                  <EditIcon />
                </IconButtonComponent>
              )}
            </Stack>
            <Typography variant="paragraphSemiBold">Email:</Typography>
            <Typography variant="paragraph">{user?.email}</Typography>
            {user.organization && (
              <Stack gap="8px">
                <Typography variant="paragraphSemiBold">Organization:</Typography>
                <Stack gap="8px" direction="row" alignItems="center">
                  <CustomAvatar
                    name={user.organization.name}
                    pictureUrl={user.organization.logo_url}
                    size={24}
                    org
                  />
                  <Typography variant="paragraph">{user.organization.name}</Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
        {user.is_limited && (
          <Typography
            variant="paragraph"
            color="colors.primary_text"
            sx={{ borderLeft: '2px solid', borderColor: 'sentiment.red', paddingLeft: '8px' }}>
            You have limited access as a trial user. You have used {user.submissions_count} of your{' '}
            {user.submissions_limit} submissions.
          </Typography>
        )}
      </Stack>
      <input
        onChange={onFileInputChange}
        onClick={resetInput}
        ref={fileInputRef}
        type="file"
        accept=".png, .jpg, .jpeg, .gif"
        style={{ display: 'none' }}
      />
      <Stack
        gap="16px"
        direction="row"
        alignItems="center"
        sx={{
          padding: '24px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          width: '450px',
          textAlign: 'left',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_bg',
          display: 'flex',
          gap: '16px'
        }}>
        <Typography variant="paragraph">Receive emails for new monitoring events</Typography>
        <Switch
          sx={{ marginLeft: 'auto' }}
          checked={newIsRecieveMonitoringEmails}
          onChange={(event) => {
            dispatch(updateUserRecieveMonitoringEmailsAction(event.target.checked));
            setNewIsRecieveMonitoringEmails(event.target.checked);
            event.stopPropagation();
          }}
        />
      </Stack>
      <Dialog open={avatarDialogOpen} onClose={closeEditor}>
        {avatarImageFile && (
          <Stack gap="16px" padding="16px">
            <AvatarEditor
              ref={editorRef}
              image={avatarImageFile}
              width={200}
              height={200}
              border={50}
              color={[0, 0, 0, 0.7]}
              borderRadius={100}
              scale={sliderZoom / 100}
              rotate={0}
              style={{ borderRadius: '8px' }}
            />
            <Stack spacing={2} direction="row" alignItems="center">
              <RemoveIcon />
              <Slider
                min={10}
                max={200}
                value={sliderZoom}
                onChange={(_event, newValue) => setSliderZoom(newValue)}
              />
              <AddIcon />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Button>Cancel</Button>
              <Button variant="contained" onClick={handleSaveImage}>
                Save Changes
              </Button>
            </Stack>
          </Stack>
        )}
      </Dialog>
    </Stack>
  );
}
