export const getCustomAnalytics = (state) => state.customAnalytics;
export const getCustomAnalyticsThreads = (state) => getCustomAnalytics(state).threads;
export const getCustomAnalyticsThreadById = (state, threadKey) =>
  getCustomAnalyticsThreads(state)[threadKey];
export const getIsCustomAnalyticsThreadIsReadyById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isReady;
export const getCustomAnalyticsThreadIdById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.threadId;
export const getCustomAnalyticsThreadPostsById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.posts;
export const getCustomAnalyticsThreadSpecificPostById = (state, threadKey, postId) =>
  getCustomAnalyticsThreadPostsById(state, threadKey)?.[postId];
export const getCustomAnalyticsPlanByThreadAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return post?.plan || post?.body?.plan;
};
export const getCustomAnalyticsPlanStepsByThreadAndPostId = (state, threadKey, postId) =>
  getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, postId)?.steps;
export const getCustomAnalyticsPlanMainPromptByThreadAndPostId = (state, threadKey, postId) =>
  getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, postId)?.question;
export const getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) => getCustomAnalyticsPlanStepsByThreadAndPostId(state, threadKey, postId)?.[stepIndex];
export const getCustomAnalyticsPlanStepActionByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) =>
  getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex(state, threadKey, postId, stepIndex)
    ?.objective;
export const getCustomAnalyticsPlanStepQueriesByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) =>
  getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex(state, threadKey, postId, stepIndex)
    ?.function_use?.action_variables?.queries;
export const getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex = (
  state,
  threadKey,
  postId,
  stepIndex,
  queryIndex
) =>
  getCustomAnalyticsPlanStepQueriesByThreadAndPostIdAndStepIndex(
    state,
    threadKey,
    postId,
    stepIndex,
    queryIndex
  )?.[queryIndex];
export const getIsCustomAnalyticsThreadProccessingById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isProccessing;
export const getCustomAnalyticClipboard = (state) => getCustomAnalytics(state).clipboard;
export const getCustomAnalyticsOpen = (state) => getCustomAnalytics(state).customAnalyticsOpen;
export const getCustomAnalyticClipboardById = (state, threadKey) =>
  getCustomAnalyticClipboard(state)?.[threadKey];
export const getCustomAnalyticDataTypeToPostIdMap = (state) =>
  getCustomAnalytics(state).dataTypeToPostIdMap;
export const getCustomAnalyticPostIdByDataType = (state, dataType) =>
  getCustomAnalyticDataTypeToPostIdMap(state)[dataType];
export const getCustomAnalyticDataTypeByPostIdMap = (state, postId) => {
  const dataTypeToPostIdMap = getCustomAnalyticDataTypeToPostIdMap(state);
  return Object.entries(dataTypeToPostIdMap).find(([, id]) => id === postId)?.[0];
};
export const getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const posts = getCustomAnalyticsThreadPostsById(state, threadKey);
  if (!posts) {
    return null;
  }
  return Object.values(posts).find((post) => post?.discoveryId === discoveryId);
};

export const getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.postId;
};

export const getCustomAnalyticsDiscoverySuggestionsByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.suggestions;
};

export const getIsPostWorkflowByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return post?.body?.plan?.is_workflow || post?.plan?.is_workflow;
};
export const getIsPostWorkflowRunByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  const isWorflow = getIsPostWorkflowByThreadIdAndPostId(state, threadKey, postId);
  const isWorkflowPlanning = isWorflow && post?.responseType === 'PREPARATION';
  const isWorflowRun = isWorflow && !isWorkflowPlanning;
  return isWorflowRun;
};
