import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';

export const BottomBarComponent = ({ children }) => {
  return (
    <Draggable cancel="button">
      <Stack
        id="bottom-bar"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: 'fixed',
          width: 'fit-content',
          bottom: '6%',
          padding: '6px 8px',
          backgroundColor: 'colors.primary_bg',
          borderRadius: '4px',
          cursor: 'move',
          boxShadow:
            '0px 0px 5px 1px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
        }}>
        {children}
      </Stack>
    </Draggable>
  );
};

BottomBarComponent.propTypes = {
  children: PropTypes.node
};

export default React.memo(BottomBarComponent);
