import { createSelector } from '@reduxjs/toolkit';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getExplore = (state) => state.explore;

export const getAllExploreLists = (state) => getExplore(state).exploreLists;
export const getExploreListByListId = (state, listId) =>
  (getAllExploreLists(state) || EMPTY_OBJECT)[listId];
export const getCompanyMetasByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.companyMetas;
export const getDiscoveriesIdsByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.discoveryIds;
export const getClustersIdByListId = (state, listId) => {
  if (!listId) return null;
  return getExploreListByListId(state, listId)?.clustersId;
};
export const getCompanyListHistoryByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.history;

export const getExploreLatestViewByListId = (state, listId) => {
  return getExploreListByListId(state, listId)?.latestView;
};

export const getCompanyListNameByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.name;

export const getListCustomColumnsByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.customColumns;
export const getListParametersByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.parameters;
export const getListCustomColumnByListIdAndColumnId = (state, listId, columnId) =>
  getListCustomColumnsByListId(state, listId)?.[columnId];
export const getListCustomColumnNameByListIdAndColumnId = (state, listId, columnId) =>
  getListCustomColumnByListIdAndColumnId(state, listId, columnId)?.name;
export const getListColumnsOrderByListId = (state, listId) =>
  getListParametersByListId(state, listId)?.columns_order;
export const getListDomainKnowledgeByListId = (state, listId) => {
  if (listId) {
    return getExploreListByListId(state, listId)?.domainKnowledge;
  }
  return null;
};
export const getListDomainKnowledgeResourceByListId = (state, listId, resourceId) =>
  getListDomainKnowledgeByListId(state, listId)?.[resourceId];

// receive state, listId
export const getMapDomainKnowledgeByListId = createSelector(
  getListDomainKnowledgeByListId,
  (listDomainKnowledgeByListId) =>
    listDomainKnowledgeByListId?.reduce((map, dkItem) => {
      map[dkItem.id] = dkItem;
      return map;
    }, {})
);
export const getDomainKnowledgeResourceByListIdAndResourceId = (state, listId, resourceId) =>
  getMapDomainKnowledgeByListId(state, listId)?.[resourceId];

export const getCompanyListDiscoveredByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.discovered;
export const getCompanyListProvidedByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.provided;
export const getCompanyListExampleLogosByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.exampleLogos;
export const getCompanyListTotalCompaniesByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.total;
export const getExploreFiltersBypass = (state) => getExplore(state).filtersBypass;
export const getExploreFiltersBypassById = (state, id) =>
  getExploreFiltersBypass(state)?.[id] || EMPTY_ARRAY;
export const getExploreFilters = (state) => getExplore(state).filters;
export const getExploreHiddenColumns = (state) => getExplore(state).hiddenColumns;
export const getExploreViews = (state) => getExplore(state).views;
export const getExploreFiltersById = (state, id) => getExploreFilters(state)[id];
export const getExploreHiddenColumnsById = (state, id) => getExploreHiddenColumns(state)[id];
export const getExploreViewsById = (state, id) => getExploreViews(state)?.[id];
export const getExploreViewByIdAndViewId = (state, id, viewId) =>
  getExploreViewsById(state, id)?.[viewId];
export const getIsExploreColumnHiddenById = (state, id, columnId) =>
  getExploreHiddenColumns(state, id)[columnId];
export const getExploreViewFiltersByIdAndViewId = (state, id, viewId) =>
  getExploreViewByIdAndViewId(state, id, viewId)?.filters;
export const getExploreViewHiddenColumnsByIdAndViewId = (state, id, viewId) =>
  getExploreViewByIdAndViewId(state, id, viewId)?.hiddenColumns;
export const getIsScreeningOpen = (state) => getExplore(state).isScreeningOpen;
export const getRunningDiscoveriesToHide = (state) => getExplore(state).hideDiscoveryRunningInTable;

export const getExploreMismatchCompanies = (state, listId) =>
  getExplore(state).mismatchedComapanyMetas?.[listId];

export const getAreCompaniesNotFound = createSelector(getCompanyMetasByListId, (companyMetas) => {
  if (!companyMetas) return false;
  const notFoundCompanies = companyMetas.filter((company) => company.notFound);
  return notFoundCompanies?.length > 0;
});

export const getExploreAllClustersSearchIds = (state, listId) =>
  getExploreListByListId(state, listId)?.clusters_record_ids;

export const getCompanyListIsShared = (state, listId) => {
  const list = getExploreListByListId(state, listId);
  if (!list) return null;
  return list && list.isShared;
};
export const getCompanyListOwner = (state, listId) => getExploreListByListId(state, listId)?.user;
export const getCompanyListCollaboratorsUsers = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsUsers;
export const getCompanyListCollaboratorsOrgs = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsOrgs;
export const getCompanyListCollaboratorsTeams = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsTeams;
export const getCompanyListIsMine = (state, listId, userId) => {
  if (!userId) {
    return false;
  }
  return getCompanyListOwner(state, listId)?.id === userId;
};

// recieve state, id
export const getCompanyListCollaborateInfo = createSelector(
  getCompanyListCollaboratorsUsers,
  getCompanyListCollaboratorsOrgs,
  getCompanyListCollaboratorsTeams,
  getCompanyListOwner,
  (resourceUsers, resourceOrgs, resourceTeams, resourceOwner) => ({
    resourceUsers,
    resourceOrgs,
    resourceTeams,
    resourceOwner
  })
);

export const getIsExploreListMonitored = (state, listId) =>
  getExploreListByListId(state, listId)?.isMonitored;

export const getExploreSubdomains = (state, listId) =>
  getExploreListByListId(state, listId)?.subdomains;

export const getExploredDomains = createSelector(getExploreSubdomains, (subdomains) => {
  if (!subdomains) return {};
  const returnSet = new Set();
  subdomains.forEach(({ parent_subdomain_id }) => {
    if (parent_subdomain_id) returnSet.add(parent_subdomain_id);
  });
  return returnSet;
});

export const getSubdomainsNames = createSelector(getExploreSubdomains, (subdomains) => {
  if (!subdomains) return {};
  return subdomains.reduce((acc, { id, name }) => {
    acc[id] = name;
    return acc;
  }, {});
});

export const getRanSubdomains = createSelector(getCompanyListHistoryByListId, (listHistory) => {
  return Object.values(listHistory).reduce((acc, { record_id, subdomain_id }) => {
    if (subdomain_id) acc[subdomain_id] = record_id;
    return acc;
  }, {});
});

export const getIsSubdomainHighlighted = createSelector(
  getExploreSubdomains,
  (state, listId, subdomainId) => subdomainId,
  (subdomains, subdomainId) => {
    if (!subdomains) return false;
    return (
      subdomains.filter(
        ({ id, shouldHightlight }) => Number(id) === Number(subdomainId) && shouldHightlight
      ).length > 0
    );
  }
);

export const subdomainIdToChildPrefixes = createSelector(
  getExploreSubdomains,
  (state, listId, subdomainId) => subdomainId,
  (subdomains, subdomainId) => {
    if (!subdomainId) return [];
    return Array.from(
      new Set(
        subdomains
          .filter(({ parent_subdomain_id }) => parent_subdomain_id === subdomainId)
          .map((sd) => sd.parameters?.prompt_prefix)
      )
    );
  }
);

export const getDomainResearchCategoryIsCATriggered = (state, listId, parentId, promptPrefix) => {
  return Boolean(
    getExploreListByListId(state, listId)?.triggredCustomAnalytics?.[`${parentId}-${promptPrefix}`]
  );
};

export const getDomainResearchIsDescriptionHidden = (state, listId) =>
  getExploreListByListId(state, listId)?.hideSubdomainsDescription || false;
