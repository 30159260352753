import { Button, Stack, Typography } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { createNewPostInCustomAnalyticsThreadAsyncAction } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import LoaderComponent from 'components/LoaderComponent';
import RowStack from 'components/RowStack';
import ModalComponent from 'components/modal/ModalComponent';
import WorkFlowPlanChart from 'components/work-flow/WorkFlowPlanChart';
import MLModal from 'design-system/MLModal';
import { PlanIcon } from 'icons/PlanIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import { getSavedQueryById } from 'selectors/saved-queries';

function WorkFlowPlannerDialog({ listId, postId, open, onClose }) {
  const dispatch = useDispatch();
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, getThreadKey(listId), postId)
  );
  const originalPlan = useSelector((state) => getSavedQueryById(state, postId))?.parameters;
  const hasChanges = JSON.stringify(plan) !== JSON.stringify(originalPlan);
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = React.useState(false);

  const handleSubmitPlan = () => {
    dispatch(
      createNewPostInCustomAnalyticsThreadAsyncAction({
        listId,
        plan,
        query: plan.question
      })
    );
    onClose();
  };

  const handleSaveChanges = () => {
    dispatch(updateSavedQueryAction(postId, plan));
  };

  const onCloseDialog = () => {
    if (originalPlan && hasChanges) {
      setIsSaveChangesModalOpen(true);
    } else {
      onClose();
    }
  };

  const onSaveAndExit = () => {
    handleSaveChanges();
    onClose();
  };

  return (
    <ModalComponent
      title="Adjust Workflow"
      titleIcon={<PlanIcon />}
      height="100%"
      open={open}
      onClose={onCloseDialog}
      topBarActions={
        plan?.steps && (
          <RowStack>
            {originalPlan && (
              <Button variant="outlined" onClick={handleSaveChanges} disabled={!hasChanges}>
                Save Changes
              </Button>
            )}
            <Button variant="contained" onClick={handleSubmitPlan}>
              Launch workflow
            </Button>
          </RowStack>
        )
      }>
      {plan ? (
        <WorkFlowPlanChart
          listId={listId}
          postId={postId}
          plan={plan?.steps}
          mainPrompt={plan?.question}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: 'primary.white'
          }}>
          <LoaderComponent />
        </Stack>
      )}
      <MLModal
        open={isSaveChangesModalOpen}
        onClose={() => setIsSaveChangesModalOpen(false)}
        title={'There are unsaved changes'}
        primaryButtonProps={{
          label: 'Save Changes',
          onClick: onSaveAndExit
        }}
        secondaryButtonProps={{
          label: 'Exit without saving',
          onClick: onClose
        }}>
        <Stack py="24px">
          <Typography variant="paragraph">
            Save your changes before exiting the workflow to avoid losing them.
          </Typography>
        </Stack>
      </MLModal>
    </ModalComponent>
  );
}
WorkFlowPlannerDialog.propTypes = {
  listId: PropTypes.number,
  postId: PropTypes.number,
  plan: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default withErrorBoundary(WorkFlowPlannerDialog);
