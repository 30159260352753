import { Box, CardMedia, Chip, Stack, Typography } from '@mui/material';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import RerunStack from 'components/tile/RerunStack';
import RunningSearchAction from 'components/tile/discovery/RunningSearchAction';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { RESEARCH_TYPES } from 'constants/researches';
import { RESOURCE_TYPES } from 'constants/resources';
import { TILE_TYPE } from 'constants/tiles';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearFiltersBypass, updateExploreView } from 'reducer/explore-slice';
import {
  getDiscoveryFailedById,
  getDiscoveryReadyById,
  getLastDiscoveryIdByListId
} from 'selectors/discoveries';
import {
  getCompanyListDiscoveredByListId,
  getCompanyListExampleLogosByListId,
  getCompanyListIsMine,
  getCompanyListIsShared,
  getCompanyListNameByListId,
  getCompanyListProvidedByListId,
  getExploreLatestViewByListId
} from 'selectors/explore';
import { getCurrentFolderId } from 'selectors/folders';
import { getUserId } from 'selectors/user';

import HomePageTile from './HomePageTile';

function HomePageDiscoveryTile({ id }) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [isOnHover, setIsOnHover] = useState(false);
  const userId = useOldStateSelector(getUserId);
  const isMine = useSelector((state) => getCompanyListIsMine(state, id, userId));
  const discoveryId = useSelector((state) => getLastDiscoveryIdByListId(state, id));
  const name = useSelector((state) => getCompanyListNameByListId(state, id));
  const folderId = useSelector(getCurrentFolderId);
  const isFail = useSelector((state) => getDiscoveryFailedById(state, discoveryId));
  const isRunning = !useSelector((state) => getDiscoveryReadyById(state, discoveryId));
  const provided = useSelector((state) => getCompanyListProvidedByListId(state, id));
  const discovered = useSelector((state) => getCompanyListDiscoveredByListId(state, id));
  const exampleLogos = useSelector((state) => getCompanyListExampleLogosByListId(state, id));
  const listView = useSelector((state) => getExploreLatestViewByListId(state, id));
  const shared = useSelector((state) => getCompanyListIsShared(state, id));

  const onClick = (event) => {
    event.stopPropagation();
    dispatch(sendUserEvent(USER_EVENTS.DISCOVERY_OPEN, { name, discoveryId, listId: id }));
    if (listView) {
      reduxDispatch(updateExploreView({ listId: id, view: listView }));
    }
    reduxDispatch(clearFiltersBypass({ listId: id }));
    navigate(listView ? `${EXPLORE_PREFIX}/${id}/views/${listView.id}` : `${EXPLORE_PREFIX}/${id}`);
  };

  return (
    <HomePageTile
      Icon={RESEARCH_TYPES.EXPLORE.Icon}
      showDelete={isOnHover && !isRunning}
      recordId={id}
      recordType={TILE_TYPE.DISCOVERY}
      resourceType={RESOURCE_TYPES.LIST}
      researchName={name}
      folderId={folderId}
      shared={shared}
      isMine={isMine}
      isOnHover={isOnHover}
      showMove
      setIsOnHover={setIsOnHover}
      onClick={onClick}
      content={
        <Stack
          direction="column"
          gap="24px"
          padding="16px"
          sx={{ height: '100%', boxSizing: 'border-box' }}>
          <Typography
            gutterBottom
            variant="h4"
            textAlign="left"
            color="colors.primary_text"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginBottom: 0
            }}>
            {name}
          </Typography>
          {provided <= 0 && discovered <= 0 && (
            <Typography variant="paragraph" textAlign="left">
              Click here to add companies
            </Typography>
          )}
          <Stack direction="row" gap="4px" alignItems="center">
            {exampleLogos
              ?.filter((logo_url) => logo_url)
              ?.slice(0, 6)
              .map((logo_url, index) => (
                <CardMedia
                  key={`${logo_url}-${index}`}
                  sx={{ width: '24px', height: '24px', fontSize: '0px', borderRadius: '4px' }}
                  component="img"
                  image={logo_url}
                  alt={logo_url}
                />
              ))}
            {discovered + provided > 6 && (
              <Box
                sx={{
                  width: provided + discovered - 6 > 99 ? '50px' : '30px',
                  height: '30px',
                  borderRadius: '15px',
                  backgroundColor: 'greyColors.grey50',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography variant="paragraph" color="greyColors.grey250">
                  +{provided + discovered - 6}
                </Typography>
              </Box>
            )}
          </Stack>
          <Stack direction="column" alignItems="flex-start" gap="4px" flex={1}>
            {provided > 0 && (
              <Typography variant="paragraphMedium" color="greyColors.grey150">
                <Typography variant="paragraphBold" color="colors.primary_text">
                  {provided}
                </Typography>
                {' Provided'}
              </Typography>
            )}
            {discovered > 0 && (
              <Typography variant="paragraphMedium" color="greyColors.grey150">
                <Typography variant="paragraphBold" color="colors.primary_text">
                  {discovered}
                </Typography>
                {' Discovered'}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
      actions={
        <React.Fragment>
          {isRunning && discoveryId ? (
            <RunningSearchAction id={discoveryId} />
          ) : (
            <RerunStack
              type={TILE_TYPE.DISCOVERY}
              id={discoveryId}
              companyListId={id}
              isOnHover={isOnHover}
              folderId={folderId}
            />
          )}
          {isFail && <Chip label="Failed"></Chip>}
        </React.Fragment>
      }
    />
  );
}

HomePageDiscoveryTile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default wrapWithError(HomePageDiscoveryTile);
