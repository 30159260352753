import { ACTIONS } from 'constants/actions';

export const initialState = {
  user: {},
  runs: {},
  insights: {},
  elements: {},
  variables: {},
  persons: {},
  currentSearchAutocomplete: {},
  personsSearchAutocomplete: {},
  currentSearchRun: {},
  debugMode: null,
  users: null,
  organizations: null,
  currentPptxElements: {},
  isLoadingRunFiles: {},
  opinions: {},
  clustersStatus: {},
  currentDiscoveryCompanyListId: null,
  companiesFilters: {},
  userSearches: {},
  nonMathlabsSearches: {},
  currentDiscoveryClusterLabel: null,
  discoveriesClusters: {},
  recentlyFinishedRuns: {},
  pptxInProgress: false,
  industriesMaps: {},
  adminCompanyLists: {},
  loadedResources: {}
};
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
export function reducer(state, action) {
  const { type } = action;
  switch (type) {
    case ACTIONS.ADD_USER_DATA: {
      const { user } = action;
      const newState = { ...state, user };
      return newState;
    }

    case ACTIONS.ADD_RUNS_DATA: {
      const { data, overwrite } = action;
      let newRuns = {};
      if (overwrite) {
        Object.values(data).forEach((runData) => {
          const currentRun = state.runs[runData.id] || {};
          newRuns[runData.id] = { ...currentRun, ...runData };
        });
      } else {
        const oldRuns = state.runs || {};
        newRuns = { ...oldRuns };
        Object.values(data).forEach((runData) => {
          const currentRun = oldRuns[runData.id] || {};
          newRuns[runData.id] = { ...runData, ...currentRun };
        });
      }
      const newState = { ...state, runs: { ...newRuns } };
      return newState;
    }

    case ACTIONS.ADD_RUN_SUMMARY: {
      const { recordId, data } = action;
      const currentRecordData = state.runs[recordId] || {};
      const newState = {
        ...state,
        runs: { ...state.runs, [recordId]: { ...currentRecordData, ...data } }
      };
      return newState;
    }

    case ACTIONS.ADD_RUN_INSIGHTS: {
      const { runId, insights } = action;
      const newState = {
        ...state,
        insights: { ...state.insights, [runId]: { ...insights } }
      };
      return newState;
    }

    case ACTIONS.ADD_RUN_CARD: {
      const { runId, insightId, card } = action;
      if (card?.card_id === null) {
        return;
      }
      const newInsight = state.insights[runId]?.[insightId]
        ? { ...state.insights[runId][insightId], card }
        : { card };
      const newRun = state.insights[runId]
        ? {
            ...state.insights[runId],
            [insightId]: newInsight
          }
        : { [insightId]: newInsight };
      const newState = {
        ...state,
        insights: {
          ...state.insights,
          [runId]: newRun
        }
      };
      return newState;
    }

    case ACTIONS.ADD_RUN_ELEMENTS: {
      const { runId, elements } = action;
      const newState = {
        ...state,
        elements: { ...state.elements, [runId]: { ...elements } }
      };
      return newState;
    }

    case ACTIONS.ADD_PERSONS_DATA: {
      const { data } = action;
      const newState = { ...state, persons: data };
      return newState;
    }

    case ACTIONS.ADD_PERSON_CARD: {
      const { personId, data } = action;
      const newPersonData = { ...state.persons[personId], card: data };
      const newPersons = { ...state.persons, [personId]: newPersonData };
      const newState = { ...state, persons: { ...newPersons } };
      return newState;
    }

    case ACTIONS.ADD_VARIABLES: {
      const { variables } = action;
      const newState = { ...state, variables };
      return newState;
    }

    case ACTIONS.ADD_OPINION: {
      const { runId, insightId, opinion } = action;
      const opinionData = { [runId]: { ...state.opinions?.[runId], [insightId]: opinion } };
      const newState = {
        ...state,
        opinions: {
          ...state.opinions,
          ...opinionData
        }
      };
      return newState;
    }

    case ACTIONS.ADD_OPINIONS: {
      const { opinions } = action;
      let records = {};
      opinions.forEach((opinion) => {
        const insight = { [opinion.insight_id]: opinion };
        const record = { [opinion.record_id]: { ...records[opinion.record_id], ...insight } };
        records = { ...records, ...record };
      });
      const newState = {
        ...state,
        opinions: {
          ...state.opinions,
          ...records
        }
      };
      return newState;
    }

    case ACTIONS.UPDATE_RUN_STATUSES: {
      const { runId, statuses } = action;
      const newRun = state.runs[runId] ? { ...state.runs[runId] } : {};
      newRun.statuses = statuses;
      const newState = {
        ...state,
        runs: { ...state.runs, [runId]: { ...newRun } }
      };
      return newState;
    }

    case ACTIONS.UPDATE_RUN_PROGRESS: {
      const { runId, progress } = action;
      const newRun = state.runs[runId] ? { ...state.runs[runId] } : {};
      if (!state.runs[runId]?.progress || progress > state.runs[runId].progress) {
        newRun.progress = progress;
        const newState = {
          ...state,
          runs: { ...state.runs, [runId]: { ...newRun } }
        };
        return newState;
      }
      return state;
    }

    case ACTIONS.UPDATE_DISCOVERY_STATUSES: {
      const { searchId, statuses } = action;
      if (!state.discoveries[searchId]) {
        return state;
      }
      const newDiscovery = { ...state.discoveries[searchId] };
      newDiscovery.statuses = statuses;
      const newState = {
        ...state,
        discoveries: { ...state.discoveries, [searchId]: { ...newDiscovery } }
      };
      return newState;
    }

    case ACTIONS.UPDATE_DISCOVERY_PROGRESS: {
      const { searchId, progress } = action;
      if (!state.discoveries[searchId]) {
        return state;
      }
      const newDiscovery = { ...state.discoveries[searchId] };

      if (
        !state.discoveries[searchId]?.progress ||
        progress > state.discoveries[searchId].progress
      ) {
        newDiscovery.progress = progress;
        const newState = {
          ...state,
          discoveries: { ...state.discoveries, [searchId]: { ...newDiscovery } }
        };
        return newState;
      }
      return state;
    }

    case ACTIONS.UPDATE_CLUSTERS_PROGRESS: {
      const { searchId, progress } = action;
      const newClusters = state.discoveriesClusters[searchId]
        ? { ...state.discoveriesClusters[searchId] }
        : {};
      if (
        !state.discoveriesClusters[searchId]?.progress ||
        progress > state.discoveriesClusters[searchId].progress
      ) {
        newClusters.progress = progress;
        const newState = {
          ...state,
          discoveriesClusters: { ...state.discoveriesClusters, [searchId]: { ...newClusters } }
        };
        return newState;
      }
      return state;
    }

    case ACTIONS.UPDATE_PERSON_DATA: {
      const { personId, data } = action;
      const newPersons = { ...state.persons, [personId]: data };
      const newState = { ...state, persons: newPersons };
      return newState;
    }

    case ACTIONS.UPDATE_PERSON_PROGRESS: {
      const { personId, progress } = action;
      const newPersons = state.persons[personId] ? { ...state.persons[personId] } : {};
      if (!state.persons[personId]?.progress || progress > state.persons[personId].progress) {
        newPersons.progress = progress;
        const newState = {
          ...state,
          persons: { ...state.persons, [personId]: { ...newPersons } }
        };
        return newState;
      }
      return state;
    }

    case ACTIONS.UPDATE_CURRENT_SEARCH_AUTOCOMPLETE: {
      const { term, status, suggestions } = action;
      // const allSuggestions = state.currentSearchAutocomplete?.suggestions || {};
      if (suggestions) {
        const newState = {
          ...state,
          currentSearchAutocomplete: {
            term,
            status,
            suggestions: suggestions
          }
        };
        return newState;
      }
      return state;
    }

    case ACTIONS.UPDATE_CURRENT_SEARCH_AUTOCOMPLETE_STATE: {
      const { term, status } = action;
      const newState = {
        ...state,
        currentSearchAutocomplete: {
          term,
          status,
          suggestions: state.currentSearchAutocomplete.suggestions
        }
      };
      return newState;
    }

    case ACTIONS.CLEAR_CURRENT_SEARCH_AUTOCOMPLETE: {
      const { term } = action;
      const newState = { ...state, currentSearchAutocomplete: { term, suggestions: {} } };
      return newState;
    }

    case ACTIONS.CLEAR_CURRENT_SEARCH_AUTOCOMPLETE_AFTER_FAILURE: {
      const { term } = action;
      const newState = {
        ...state,
        currentSearchAutocomplete: { term, status: 'failure', suggestions: {} }
      };
      return newState;
    }

    case ACTIONS.UPDATE_PERSONS_SEARCH_AUTOCOMPLETE: {
      const { suggestions, term } = action;
      const newPersonsSearchAutocomplete = {
        ...state.personsSearchAutocomplete,
        [term]: suggestions
      };
      const newState = { ...state, personsSearchAutocomplete: newPersonsSearchAutocomplete };
      return newState;
    }

    case ACTIONS.REMOVE_PERSONS_SEARCH_AUTOCOMPLETE: {
      const { term } = action;
      const newPersonsSearchAutocomplete = { ...state.personsSearchAutocomplete };
      delete newPersonsSearchAutocomplete[term];
      const newState = { ...state, personsSearchAutocomplete: newPersonsSearchAutocomplete };
      return newState;
    }

    case ACTIONS.UPDATE_CURRENT_SEARCH_RUN: {
      const { suggestion, status } = action;
      const newState = { ...state, currentSearchRun: { suggestion, status } };
      return newState;
    }

    case ACTIONS.DELETE_RUN: {
      const { runId } = action;
      const newRuns = { ...state.runs };
      delete newRuns[runId];
      const newState = { ...state, runs: newRuns };
      return newState;
    }

    case ACTIONS.DELETE_PERSON: {
      const { personId } = action;
      const newPersons = { ...state.persons };
      delete newPersons[personId];
      const newState = { ...state, persons: newPersons };
      return newState;
    }

    case ACTIONS.UPDATE_DEBUG_MODE: {
      const { isEnabled } = action;
      return { ...state, debugMode: isEnabled };
    }

    case ACTIONS.ADD_USERS_DATA: {
      const { data } = action;
      return { ...state, users: data };
    }

    case ACTIONS.MAKE_PPTX: {
      const { data } = action;
      return {
        ...state,
        currentPptxElements: data?.elements ? data.elements.map((image) => image.element_id) : []
      };
    }

    case ACTIONS.UPDATE_PPTX_IN_PROGRESS: {
      const { pptxInProgress } = action;
      return {
        ...state,
        pptxInProgress
      };
    }

    case ACTIONS.MAKE_RUN_FILES: {
      const { data } = action;
      const isLoadingState = state?.isLoadingRunFiles;
      if (data?.isLoading) {
        isLoadingState[data?.runId] = true;
      } else {
        delete isLoadingState[data?.runId];
      }
      return {
        ...state,
        isLoadingRunFiles: isLoadingState
      };
    }
    case ACTIONS.ADD_CLUSTERS_RECORDS: {
      const { clustersRecords } = action;
      const newClusters = {};
      Object.keys(clustersRecords).forEach((clustersId) => {
        newClusters[clustersId] = { ...clustersRecords[clustersId] };
        if (!newClusters[clustersId]?.data) {
          const oldClustersData = state.discoveriesClusters?.[clustersId]?.data;
          if (oldClustersData) {
            newClusters[clustersId].data = { ...oldClustersData };
          }
        }
      });
      const newState = {
        ...state,
        discoveriesClusters: { ...state.discoveriesClusters, ...newClusters }
      };
      return newState;
    }

    case ACTIONS.UPDATE_CLUSTERS_STATUS: {
      const { status, isError } = action;
      const newState = { ...state, clustersStatus: { status, isError } };
      return newState;
    }

    case ACTIONS.UPDATE_CURRENT_DISCOVERY_COMPANY_LIST_ID: {
      const { listId } = action;
      const newState = { ...state, currentDiscoveryCompanyListId: listId };
      return newState;
    }

    case ACTIONS.ADD_COMPANIES_FILTERS: {
      const { filters } = action;
      const newState = { ...state, companiesFilters: { ...filters } };
      return newState;
    }

    case ACTIONS.ADD_USER_SEARCHES: {
      const { runs, discoveries, persons } = action;
      const newState = {
        ...state,
        userSearches: { runs, discoveries, persons }
      };
      return newState;
    }

    case ACTIONS.ADD_NON_MATHLABS_SEARCHES: {
      const { searches } = action;
      const newState = {
        ...state,
        nonMathlabsSearches: searches
      };
      return newState;
    }

    case ACTIONS.ADD_ORGANIZATIONS_DATA: {
      const { data } = action;
      const newState = {
        ...state,
        organizations: data
      };
      return newState;
    }

    case ACTIONS.UPDATE_CURRENT_DISCOVERY_CLUSTER_LABEL: {
      const { label } = action;
      const newState = {
        ...state,
        currentDiscoveryClusterLabel: label
      };
      return newState;
    }

    case ACTIONS.ADD_DISCOVERY_CLUSTERS: {
      const { clusters, clustersId } = action;
      const newState = {
        ...state,
        discoveriesClusters: {
          ...state.discoveriesClusters,
          [clustersId]: clusters
        }
      };
      return newState;
    }

    case ACTIONS.UPDATE_CLUSTERS_STATUSES: {
      const { clustersId, statuses } = action;
      const newClusters = state.discoveriesClusters[clustersId]
        ? { ...state.discoveriesClusters[clustersId] }
        : {};
      newClusters.statuses = statuses;
      const newState = {
        ...state,
        discoveriesClusters: { ...state.discoveriesClusters, [clustersId]: { ...newClusters } }
      };
      return newState;
    }

    case ACTIONS.INCREASE_USER_SUBMISSIONS_COUNT: {
      const newState = {
        ...state,
        user: {
          ...state.user,
          submissions_count: state.user.submissions_count + 1
        }
      };
      return newState;
    }

    case ACTIONS.ADD_RECENTLY_FINISHED_RUN: {
      const { runId, runType } = action;
      const run = state?.[runType]?.[runId] || { id: runId };
      const newState = {
        ...state,
        recentlyFinishedRuns: { ...state.recentlyFinishedRuns, [runId]: { ...run, type: runType } }
      };
      return newState;
    }

    case ACTIONS.REMOVE_RECENTLY_FINISHED_RUN: {
      const { runId } = action;
      const newRecentlyFinishedRuns = { ...state.recentlyFinishedRuns };
      delete newRecentlyFinishedRuns[runId];
      const newState = {
        ...state,
        recentlyFinishedRuns: newRecentlyFinishedRuns
      };
      return newState;
    }

    case ACTIONS.UPDATE_ADMIN_COMPANY_LIST: {
      const { listId, list } = action;
      const newState = {
        ...state,
        adminCompanyLists: { ...state.adminCompanyLists, [listId]: list }
      };
      return newState;
    }

    case ACTIONS.UPDATE_ALL_ADMIN_COMPANY_LISTS: {
      const { lists } = action;
      const newState = {
        ...state,
        adminCompanyLists: lists.reduce(
          (acc, list) => ({ ...acc, [list.company_list_id]: list }),
          {}
        )
      };
      return newState;
    }

    case ACTIONS.ADD_USER_ORGANIZATION_USERS_DATA: {
      const { users } = action;
      const newState = {
        ...state,
        user: {
          ...state.user,
          organization: {
            ...state.user?.organization,
            users
          }
        }
      };
      return newState;
    }

    case ACTIONS.UPDATE_RESOURCE_LOADED: {
      const { resourceType } = action;
      const newState = {
        ...state,
        loadedResources: { ...state.loadedResources, [resourceType]: true }
      };
      return newState;
    }

    default:
      return state;
  }
}
