import { getPusherClient } from 'hooks/PusherProvider';
import { updateDomainReportProgress } from 'reducer/domain-reports-slice';
import { getPostProgressChannelName } from 'utils/pusher-utils';

import { loadDomainReportAction } from './load-domain-report-action';

export function bindDomainReportPusherAction({ listId, domainReportId, postId }) {
  return (dispatch) => {
    const pusher = getPusherClient();
    const channelName = getPostProgressChannelName(postId);
    let channel = pusher.channel(channelName);
    if (!channel) {
      channel = pusher.subscribe(channelName);
    }
    channel.bind('progress', (data) => {
      if (data.progress === 1) {
        pusher.unsubscribe(channelName);
        dispatch(loadDomainReportAction(listId));
      } else {
        dispatch(
          updateDomainReportProgress({
            listId,
            domainReportId,
            postId,
            progress: data.progress,
            message: data.message
          })
        );
      }
    });
  };
}
