import {
  addPostAnswerToThread,
  addPostQueryToThread,
  getThreadKey
} from 'reducer/custom-analytics';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';

export function selectWorkflowByQueryAction(listId, query) {
  return (dispatch) => {
    if (query.shouldAddToState) {
      dispatch(
        addPostQueryToThread({
          threadKey: getThreadKey(listId),
          postId: query.id,
          query: '',
          body: {},
          discoveryId: 1,
          messages: [],
          isWorkflow: true
        })
      );
      dispatch(
        addPostAnswerToThread({
          threadKey: getThreadKey(listId),
          threadId: 1,
          postId: query.id,
          message: '',
          responseType: '',
          messages: null,
          image: null,
          plan: query.parameters,
          isWorkflow: true
        })
      );
    }
    dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: query.id }));
  };
}
