import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { deleteSubdomainAction } from 'actions/domain-research/delete-subdomain-action';
import IconButtonComponent from 'components/IconButtonComponent';
import DeleteIcon from 'icons/DeleteIcon';
import MenuIcon from 'icons/MenuIcon';
import PreviewIcon from 'icons/PreviewIcon';
import VisibilityOffIcon from 'icons/VisibilityOffIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHideSubdomainsDescription } from 'reducer/explore-slice';
import { getDomainResearchIsDescriptionHidden } from 'selectors/explore';
import { DOMAIN_RESEARCH_COMPONENT_TYPES } from 'utils/subdomains';

import DeleteSubdomainsDialog from './DeleteSubdomainsDialog';

function CategoryActionsMenu({ listId, categorySubdomains, componentType }) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [openDeleteDialig, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const hideSubdomainsDescription = useSelector((state) =>
    getDomainResearchIsDescriptionHidden(state, listId)
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onDelete = () => {
    categorySubdomains.forEach((subdomain) => {
      dispatch(deleteSubdomainAction(listId, subdomain.id));
    });
    setOpenDeleteDialog(false);
    handleClose();
  };

  const onToggleDescriptionsVisibility = () => {
    dispatch(
      updateHideSubdomainsDescription({
        listId,
        hideSubdomainsDescription: !hideSubdomainsDescription
      })
    );
    handleClose();
  };

  const menuItems = [
    {
      id: 'hide_all_descriptions',
      title: `${hideSubdomainsDescription ? 'Show' : 'Hide'} all descriptions`,
      onClick: onToggleDescriptionsVisibility,
      icon: hideSubdomainsDescription ? <PreviewIcon color="currentColor" /> : <VisibilityOffIcon />
    },
    {
      id: 'delete',
      title:
        componentType === DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN ? 'Start Over' : 'Delete',
      onClick: () => setOpenDeleteDialog(true),
      icon: <DeleteIcon />
    }
  ];

  return (
    <React.Fragment>
      <IconButtonComponent id={`${componentType}-menu`} sx={{ padding: 0 }} onClick={handleOpen}>
        <MenuIcon color="currentColor" />
      </IconButtonComponent>
      <Menu
        MenuListProps={{ sx: { py: 0 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem key={item.id} onClick={item.onClick} sx={{ padding: '8px' }}>
            <Stack direction="row" gap="4px" justifyContent="flex-start" alignItems="center">
              {item.icon}
              <Typography variant="paragraph">{item.title}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      {openDeleteDialig && (
        <DeleteSubdomainsDialog
          open={openDeleteDialig}
          onClose={() => setOpenDeleteDialog(false)}
          onDelete={onDelete}
          componentType={componentType}
        />
      )}
    </React.Fragment>
  );
}

CategoryActionsMenu.propTypes = {
  listId: PropTypes.number.isRequired,
  categorySubdomains: PropTypes.array.isRequired,
  componentType: PropTypes.oneOf([
    DOMAIN_RESEARCH_COMPONENT_TYPES.CATEGORY,
    DOMAIN_RESEARCH_COMPONENT_TYPES.LEVEL
  ])
};

export default CategoryActionsMenu;
