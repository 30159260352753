import { promptConfigToPrompt } from './prompts-utils';

function updateClusters(clusters, companyTaggingData, companyMap) {
  const { company_meta_id } = companyTaggingData;
  const { reasoning } = companyTaggingData.message;
  const reasoningObj = JSON.parse(reasoning.replaceAll('\\n', ''));
  Object.entries(reasoningObj).forEach(([cluster, reason]) => {
    if (!clusters[cluster]) {
      clusters[cluster] = [];
    }
    clusters[cluster].push({ company: companyMap[company_meta_id], reasoning: reason });
  });
}

export function clusterBreakdownFromTaggingData(data, companies) {
  const failedParse = [];
  const clusters = {};
  if (!data) {
    return {};
  }
  const companyMap = companies.reduce(
    (acc, company) => ({
      ...acc,
      [company.company_meta_id]: company
    }),
    {}
  );
  data.forEach((companyTaggingData) => {
    const { company_meta_id } = companyTaggingData;
    try {
      updateClusters(clusters, companyTaggingData, companyMap);
    } catch (error) {
      failedParse.push({ company_meta_id, error });
    }
  });
  return {
    clusters,
    failedParse
  };
}

function processTopProblemsData(data) {
  try {
    return { topProblems: JSON.parse(data?.answer.replaceAll('\\n', '')).data };
  } catch (error) {
    return { error };
  }
}

export function formatTopProblemsData(data, companies) {
  const { topProblems, error } = processTopProblemsData(data);
  try {
    topProblems.forEach((problem) => {
      problem.examples = problem.examples.map((example) => {
        const company = companies.find(
          (item) => item.name.toLowerCase() === example.company.toLowerCase()
        );
        return { ...example, company };
      });
    });
    return { topProblems, error };
  } catch (error2) {
    return { error: [error, error2] };
  }
}

export const promptConfigToTaskParams = (promptConfig) => {
  if (!promptConfig) {
    return null;
  }
  const prompt = promptConfigToPrompt(promptConfig);
  return {
    question: prompt,
    columns: promptConfig?.columns
  };
};
const isTaskParamsEqual = (taskParams1, taskParams2) => {
  if (taskParams1.question !== taskParams2.question) {
    return false;
  }
  if (
    JSON.stringify((taskParams1.columns || []).sort((col1, col2) => col1 - col2)) !==
    JSON.stringify((taskParams2.columns || []).sort((col1, col2) => col1 - col2))
  ) {
    return false;
  }
  return true;
};

export const isTaskPromptConfigEqual = (pc1, pc2) => {
  if (!pc1 || !pc2) {
    return true;
  }
  return isTaskParamsEqual(promptConfigToTaskParams(pc1), promptConfigToTaskParams(pc2));
};
