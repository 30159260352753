import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BaseEdge, EdgeLabelRenderer, MarkerType, getBezierPath } from 'reactflow';
import 'reactflow/dist/style.css';
import { appTheme } from 'theme';

function ConditionEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const { label, condition } = data;

  return (
    <React.Fragment>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ stroke: appTheme.palette.greyColors.grey150 }}
        markerEnd={{
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: 'black'
        }}
      />
      <EdgeLabelRenderer>
        <Box
          sx={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            backgroundColor: condition ? 'greyColors.grey150' : 'colors.selected',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'colors.text_on_primary',
            boxShadow: '0px 0px 1px 0px #091E424F, 0px 3px 5px 0px #091E4233',
            padding: '6px 12px'
          }}>
          <Typography
            variant="paragraphMedium"
            color={condition ? 'colors.text_on_primary' : 'colors.primary_text'}
            textTransform="capitalize">
            {label}
          </Typography>
        </Box>
      </EdgeLabelRenderer>
    </React.Fragment>
  );
}

ConditionEdge.propTypes = {
  id: PropTypes.number,
  sourceX: PropTypes.number,
  sourceY: PropTypes.number,
  sourcePosition: PropTypes.number,
  targetX: PropTypes.number,
  targetY: PropTypes.number,
  targetPosition: PropTypes.number,
  data: PropTypes.number
};

export default ConditionEdge;
