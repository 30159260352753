import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function HistoryIcon({ isDisabled }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9927 5C11.0627 5 10.1761 5.18079 9.36546 5.50864C8.35651 5.91669 7.46374 6.55313 6.75178 7.35422L6.61017 7.54517H8.98983C9.26622 7.54517 9.49028 7.76902 9.49028 8.04517C9.49028 8.32131 9.26622 8.54517 8.98983 8.54517H5.98711C5.43433 8.54517 4.98621 8.09745 4.98621 7.54517V4.54517C4.98621 4.26902 5.21027 4.04517 5.48666 4.04517C5.76305 4.04517 5.98711 4.26902 5.98711 4.54517V6.70857L5.98743 6.70821C6.80275 5.78529 7.8283 5.05148 8.98991 4.5817C9.91787 4.20641 10.9318 4 11.9927 4C16.415 4 20 7.58172 20 12C20 16.4183 16.415 20 11.9927 20C7.73871 20 4.25951 16.6857 4.00091 12.5005C3.98386 12.2247 4.20955 12 4.48594 12C4.76233 12 4.98453 12.2248 5.00403 12.5005C5.26089 16.1329 8.29168 19 11.9927 19C15.8622 19 18.9991 15.866 18.9991 12C18.9991 8.13401 15.8622 5 11.9927 5ZM5.98711 7.54503L5.98729 7.54517H5.98711V7.54503ZM11.9925 9.04517C11.9925 8.76902 11.7685 8.54517 11.4921 8.54517C11.2157 8.54517 10.9916 8.76902 10.9916 9.04517V13.0452C10.9916 13.3213 11.2157 13.5452 11.4921 13.5452H14.9953C15.2717 13.5452 15.4957 13.3213 15.4957 13.0452C15.4957 12.769 15.2717 12.5452 14.9953 12.5452H11.9925V9.04517Z"
        fill={isDisabled ? appTheme.palette.greyColors.grey150 : 'currentColor'}
      />
    </svg>
  );
}

HistoryIcon.propTypes = {
  isDisabled: PropTypes.bool
};
