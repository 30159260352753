import { addPostQueryToThread, getThreadKey } from 'reducer/custom-analytics';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';

export function addEmptyWorkflowAction(listId) {
  return (dispatch) => {
    const threadKey = getThreadKey(listId);
    const newPostId = new Date().getTime();
    const plan = { question: '' };
    dispatch(
      addPostQueryToThread({
        threadKey,
        postId: newPostId,
        plan,
        query: '',
        body: {},
        discoveryId: 1,
        messages: [],
        isWorkflow: true
      })
    );
    dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: newPostId }));
  };
}
