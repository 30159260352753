export const CATEGORIES = Object.freeze({
  MY_SAVED_COLUMNS: 'Saved by me',
  SHARED_WITH_ME: 'Shared with me',
  BASIC: 'Basic',
  FINANCE: 'Finance',
  MANUAL: 'Manual'
});

export const SAVED_QUERY_ACTION_TYPES = Object.freeze({
  COLUMN: 'COLUMN',
  ASK: 'ASK',
  WORKFLOW: 'WORKFLOW'
});

export const getPlatterEmptyColumns = (actionType) => {
  if (actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }
  return [
    {
      data_name: 'custom_str',
      title: 'Text',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    },
    {
      data_name: 'custom_number',
      title: 'Number',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    },
    {
      data_name: 'custom_boolean',
      title: 'Yes/No',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    }
  ];
};

export const getPlatterBasicColumns = (actionType) => {
  if (actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }

  return [
    {
      data_name: 'company_website',
      title: 'Website',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'linkedin_url',
      title: 'Linkedin',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'cb_url',
      title: 'Crunchbase Link',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'hidden_gems',
      title: 'Hidden Gems',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    }
  ];
};

export const getPlatterFinanceColumns = (actionType, canFinanceData) => {
  if (!canFinanceData || actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }
  const columnType = 'finance_column';
  const columnInfo = { type: columnType, category: CATEGORIES.FINANCE };

  return [
    {
      data_name: 'earning',
      title: 'Earning ($M)',
      ...columnInfo
    },
    {
      data_name: 'ebitda',
      title: 'EBITDA ($M)',
      ...columnInfo
    },
    {
      data_name: 'net_income',
      title: 'Net Income ($M)',
      ...columnInfo
    },
    {
      data_name: 'operating_income',
      title: 'Operating Income ($M)',
      ...columnInfo
    }
  ];
};
