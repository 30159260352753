import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function RenderTextWithTooltip({ value, color, variant }) {
  if (!value) {
    return (
      <Typography variant={variant} color={color} noWrap display="block">
        {value}
      </Typography>
    );
  }
  const { data, text } = value;

  return (
    <Tooltip title={text || ''} arrow={true} sx={{ width: '100%' }}>
      <Typography textAlign="left" variant={variant} color={color} noWrap display="block">
        {data}
      </Typography>
    </Tooltip>
  );
}

RenderTextWithTooltip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderTextWithTooltip.defaultProps = {
  color: 'colors.primary_text',
  variant: 'paragraph'
};
