import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'utils/objects-utils';

import TableRowLoading from './TableRowLoading';

function RenderPartnershipLevelWrapper({ partnershipUrl, children }) {
  if (partnershipUrl) {
    return (
      <Link
        href={partnershipUrl}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );
  }
  return children;
}

function RenderPartnershipLevel({ value: payload }) {
  const value = payload?.value;

  const loading = value?.loading || payload?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (isEmpty(value)) return null;
  const { partnership_level: partnershipLevel, partnership_url: partnershipUrl } = value;

  return (
    <RenderPartnershipLevelWrapper partnershipUrl={partnershipUrl}>
      <Typography
        variant="paragraph"
        color={partnershipLevel ? 'colors.primary_text' : 'greyColors.grey150'}
        textTransform="capitalize">
        {partnershipLevel ? partnershipLevel.toLowerCase() : 'Not a partner'}
      </Typography>
    </RenderPartnershipLevelWrapper>
  );
}

RenderPartnershipLevelWrapper.propTypes = {
  partnershipUrl: PropTypes.string,
  children: PropTypes.node
};

RenderPartnershipLevel.propTypes = {
  value: PropTypes.object
};

export default RenderPartnershipLevel;
