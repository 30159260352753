import React from 'react';

function ViewFollowupIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 9L19.5 12L16.5 15"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.5 12H19" stroke="#5C5E76" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.4999 17.0023C13.8023 17.3225 13.0261 17.501 12.2083 17.501C9.17069 17.501 6.70825 15.0386 6.70825 12.001C6.70825 8.96342 9.17069 6.50099 12.2083 6.50099C13.0261 6.50099 13.8023 6.6795 14.4999 6.99969"
        stroke="#5C5E76"
        strokeWidth="1.00833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 12H4.5" stroke="#5C5E76" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 6V4.5" stroke="#5C5E76" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 19.5V18" stroke="#5C5E76" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ViewFollowupIcon;
