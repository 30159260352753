import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const LOGO_SIZE = '24px';

function ClusterExampleRow({ children: [left, right] }) {
  return (
    <Stack
      direction="row"
      gap="4px"
      sx={{ borderBottom: '1px solid', borderColor: 'colors.ui_border' }}>
      <Stack
        gap="8px"
        minWidth="300px"
        maxWidth="300px"
        sx={{
          borderRight: '1px solid',
          borderColor: 'colors.ui_border',
          paddingY: '13px',
          paddingLeft: '48px'
        }}
        direction="row"
        alignItems="center">
        {left}
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ paddingX: '16px', paddingY: '13px' }}>
        {right}
      </Stack>
    </Stack>
  );
}

function DomainReportClusterExample({ companies }) {
  return (
    <Stack
      sx={{
        color: 'colors.secondary_text',
        marginBottom: '32px'
      }}>
      <ClusterExampleRow>
        <Typography minWidth="300px" maxWidth="300px" variant="paragraph">
          Domain companies ({companies ? companies.length : 0})
        </Typography>
        <Typography variant="paragraph">Reasoning</Typography>
      </ClusterExampleRow>
      <Stack
        sx={{
          maxHeight: '400px',
          overflowY: 'scroll'
        }}>
        {companies.map(
          (exampleCompany, index) =>
            exampleCompany?.company && (
              <ClusterExampleRow key={index}>
                <Stack gap="8px" direction="row" alignItems="center">
                  <Avatar
                    src={exampleCompany?.company?.parameters?.logo_url}
                    sx={{ width: LOGO_SIZE, height: LOGO_SIZE }}
                    variant="rounded"
                  />
                  <Typography variant="paragraphMedium">{exampleCompany?.company?.name}</Typography>
                </Stack>
                <Typography variant="paragraph">{exampleCompany.reasoning}</Typography>
              </ClusterExampleRow>
            )
        )}
      </Stack>
    </Stack>
  );
}

ClusterExampleRow.propTypes = {
  children: PropTypes.node
};

DomainReportClusterExample.propTypes = {
  companies: PropTypes.array
};

export default DomainReportClusterExample;
