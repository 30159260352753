import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { appTheme } from 'theme';

import TableComponent from './TableComponent';

export default function MultiTableComponent({ tables }) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          marginBottom: 2,
          borderBottom: 1,
          borderColor: 'colors.ui_border'
        }}>
        <Tabs
          variant={mediumViewport ? 'standard' : 'scrollable'}
          value={tabValue}
          onChange={handleChange}
          textColor="secondary"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              backgroundColor: appTheme.palette.secondary.secondary3_100,
              height: '4px'
            }
          }}>
          {tables.map((table, index) => (
            <Tab key={index} label={table.data.name} />
          ))}
        </Tabs>
      </Box>
      {tables.map(
        (table, index) =>
          tabValue === index && (
            <TableComponent
              key={index}
              elementId={table.element_id}
              title={table.data.name}
              columns={table.data.columns}
            />
          )
      )}
    </React.Fragment>
  );
}

MultiTableComponent.propTypes = {
  title: PropTypes.string,
  tables: PropTypes.arrayOf(PropTypes.object)
};
