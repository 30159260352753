import PropTypes from 'prop-types';
import React from 'react';

export default function FilterIcon({ color }) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1281 4.54837C18.128 4.0614 17.7332 3.66667 17.2463 3.66667H4.75374C4.26672 3.66667 3.87192 4.06147 3.87192 4.54848V6.19113C3.87192 6.40226 3.94768 6.60638 4.08541 6.7664L9.05948 12.5451L9.05948 16.8033C9.05948 17.2079 9.33487 17.5606 9.72742 17.6587L11.8448 18.1881C12.4013 18.3272 12.9405 17.9063 12.9405 17.3326L12.9405 12.5451L17.9148 6.76644C18.0526 6.60639 18.1283 6.40221 18.1283 6.19103L18.1281 4.54837ZM17.2114 4.58333L17.2116 6.17821L12.2373 11.9569C12.0996 12.1169 12.0238 12.321 12.0238 12.5322L12.0238 17.288L9.97615 16.776L9.97615 12.5322C9.97615 12.321 9.9004 12.1169 9.76266 11.9569L4.78859 6.1782V4.58333H17.2114Z"
        fill={color}
      />
    </svg>
  );
}

FilterIcon.propTypes = {
  color: PropTypes.string
};

FilterIcon.defaultProps = {
  color: 'currentColor'
};
