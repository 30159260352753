import PropTypes from 'prop-types';
import React from 'react';

export default function PreviewIcon({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M3.5 11.5455C3.5 11.5455 6.77273 5 12.5 5C18.2273 5 21.5 11.5455 21.5 11.5455C21.5 11.5455 18.2273 18.0909 12.5 18.0909C6.77273 18.0909 3.5 11.5455 3.5 11.5455Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4997 13.9999C13.8553 13.9999 14.9543 12.901 14.9543 11.5454C14.9543 10.1898 13.8553 9.09082 12.4997 9.09082C11.1441 9.09082 10.0452 10.1898 10.0452 11.5454C10.0452 12.901 11.1441 13.9999 12.4997 13.9999Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PreviewIcon.propTypes = {
  color: PropTypes.string
};

PreviewIcon.defaultProps = {
  color: '#584CA7'
};
