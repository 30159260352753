import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { isEmpty } from 'utils/objects-utils';

function MultiSectionsSelectComponent({ containerSx, sections, title, tooltipText }) {
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [openSections, setOpenSections] = useState({});
  const [hoverOnButton, setHoverOnButton] = useState(false);

  const handleOpen = (event) => {
    if (sections.length > 0) {
      setAnchorEl(ref.current || event.target);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelectRowInSection = (section, itemId) => {
    const newitems = section.selectItemsSet ? [...section.selectItemsSet] : [];
    const itemIdIndex = newitems.indexOf(itemId);
    if (itemIdIndex === -1) {
      newitems.push(itemId);
    } else if (itemIdIndex > -1) {
      newitems.splice(itemIdIndex, 1);
    }
    section.onSelectionsChange(newitems);
  };

  const handleSingleSelection = (section, item) => {
    if (!item.disabled) {
      section.onSelectionsChange(item.id);
    }
  };

  const getMultipleSelectSection = (section, isStandalone) => {
    return (
      <Stack
        direction="column"
        sx={{
          display: openSections[section.id] || isStandalone ? 'flex' : 'none',
          height: openSections[section.id] || isStandalone ? `${section.items * 24}px` : '0px',
          transition: 'height 0.25s ease-out'
        }}>
        {section.items.map((item) => (
          <MenuItem
            key={item.id}
            sx={{ padding: '4px 0px' }}
            onClick={() => handleSelectRowInSection(section, item.id)}>
            <Stack direction="row" alignItems="center" gap="8px">
              <Checkbox
                checked={section.selectItemsSet?.has(item.id)}
                onChange={() => handleSelectRowInSection(section, item.id)}
                disabled={item.disabled}
              />
              <Typography variant="paragraphMedium" color="colors.primary_text">
                {item.name}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Stack>
    );
  };

  const getSingleSelectSection = (section, isStandalone) => {
    return (
      <Stack
        direction="column"
        padding="0px 4px"
        paddingLeft={isStandalone ? 0 : '16px'}
        sx={{
          display: openSections[section.id] || isStandalone ? 'flex' : 'none',
          height: openSections[section.id] || isStandalone ? `${section.items * 24}px` : '0px',
          transition: 'height 0.25s ease-out'
        }}>
        <RadioGroup sx={{ marginRight: '4px' }}>
          {section.items.map((item) => (
            <Stack
              key={item.id}
              direction="row"
              alignItems="center"
              width="100%"
              color={'colors.primary_text'}
              sx={{
                padding: '0px 8px',
                '&:hover': {
                  backgroundColor: item.disabled ? 'transparent' : 'rgba(0, 0, 0, 0.04)'
                }
              }}>
              <FormControlLabel
                sx={{ width: '100%' }}
                value={item.name}
                control={
                  <Stack direction="row" alignItems="center">
                    <Radio
                      disabled={item.disabled}
                      checked={section.selectItemsSet?.has(item.id)}
                      onClick={() => handleSingleSelection(section, item)}
                    />
                    {item.icon && (
                      <Box
                        height="24px"
                        alignItems="center"
                        color="colors.primary"
                        paddingRight="8px">
                        {item.icon}
                      </Box>
                    )}

                    <Typography variant="paragraphSemiBold">{item.name}</Typography>
                  </Stack>
                }
              />
            </Stack>
          ))}
        </RadioGroup>
      </Stack>
    );
  };

  const titleSx = useMemo(() => {
    let color = 'colors.primary_text';
    if (isEmpty(sections)) {
      color = 'greyColors.grey150';
    } else if (sections.every((section) => section.selectItemsSet.size <= 0)) {
      color = 'rgba(0, 0, 0, 0.6)';
    }
    return { color };
  }, [sections]);

  return (
    <Box sx={containerSx} width="150px">
      <Tooltip
        placement="top"
        arrow
        open={hoverOnButton && tooltipText}
        title={
          sections.length > 0 ? (
            <Typography
              fontSize={14}
              fontWeight={500}
              padding="12px"
              textAlign="left"
              sx={{ whiteSpace: 'pre-line' }}>
              {tooltipText}
            </Typography>
          ) : (
            <Typography fontSize={13} textAlign="center">
              {tooltipText}
            </Typography>
          )
        }>
        <Stack
          ref={ref}
          onPointerEnter={() => setHoverOnButton(true)}
          onPointerLeave={() => setHoverOnButton(false)}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          sx={{
            border: '1px solid',
            borderColor: 'colors.ui_border',
            borderRadius: '4px',
            padding: '3px 8px',
            cursor: sections.length > 0 ? 'pointer' : 'default',
            '&.Mui-focused': {
              borderColor: 'greyColors.grey150'
            },
            '&:hover': {
              borderColor: 'greyColors.grey150'
            }
          }}
          onClick={handleOpen}>
          <Typography variant="paragraphMedium" sx={titleSx} paddingLeft="5px">
            {title}
          </Typography>
          <Box
            sx={{
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <ArrowIcon rotation="180" color={sections.length > 0 ? null : 'rgba(0, 0, 0, 0.24)'} />
          </Box>
        </Stack>
      </Tooltip>
      <Popover
        id={'id'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        slotProps={{ paper: { sx: { backgroundColor: 'colors.primary_bg' }, ronen: 'sabag' } }}>
        <Stack
          direction="column"
          padding="4px"
          maxHeight="400px"
          sx={{ color: 'colors.primary_text' }}>
          {sections.length > 1 &&
            sections.map((section) => (
              <Stack key={section.id} direction="column">
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="8px"
                  sx={{
                    cursor: 'pointer',
                    padding: '8px 8px 8px 0px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                  onClick={() =>
                    setOpenSections((prevSections) => ({
                      ...prevSections,
                      [section.id]: !prevSections[section.id]
                    }))
                  }>
                  <ArrowIcon rotation={openSections[section.id] ? 180 : 90} />
                  <Typography typography="paragraphMedium">{section.title}</Typography>
                </Stack>
                {section.type === 'multiple'
                  ? getMultipleSelectSection(section)
                  : getSingleSelectSection(section)}
              </Stack>
            ))}
          {sections.length === 1 &&
            sections[0].type === 'multiple' &&
            getMultipleSelectSection(sections[0], true)}
          {sections.length === 1 &&
            sections[0].type === 'single' &&
            getSingleSelectSection(sections[0], true)}
        </Stack>
      </Popover>
    </Box>
  );
}

MultiSectionsSelectComponent.propTypes = {
  containerSx: PropTypes.object,
  sections: PropTypes.array,
  title: PropTypes.string,
  tooltipText: PropTypes.string
};

MultiSectionsSelectComponent.defaultProps = {
  containerSx: {},
  sections: []
};

export default MultiSectionsSelectComponent;
