import { addExploreDomainKnowledgeResource } from 'reducer/explore-slice';
import { addFolderSupportingResource } from 'reducer/folders-slice';

export function loadExploreSupportingResource(supportingResourceId, listId, folderId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.supportingResources.fetchSupportingResource(supportingResourceId).then((res) => {
      if (listId) {
        dispatch(addExploreDomainKnowledgeResource({ listId, resource: res.data }));
      }
      if (folderId) {
        dispatch(addFolderSupportingResource({ folderId, resource: res.data }));
      }
    });
  };
}
