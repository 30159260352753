/* eslint-disable max-lines */
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { loadAllClustersAction } from 'actions/clusters/load-all-clusters-action';
import { loadAllAdminListsAction } from 'actions/company_lists/load-all-admin-company-lists-action';
import { loadAllAdminCustomAnalytics } from 'actions/dev_and_or_admin/load-all-custom-analytics';
import { loadAllNonMathlabsSearchesAction } from 'actions/dev_and_or_admin/load-all-non-mathlabs-searches';
import { loadAllExploreLists } from 'actions/explore/load-all-explores-action';
import { loadAllPersons } from 'actions/persons/load-all-persons-action';
import { loadAllRuns } from 'actions/runs/load-all-runs-action';
import { getSchedulesAction } from 'actions/schedules/get-schedules-action';
import { loadAllUsers } from 'actions/users/load-all-users-action';
import ClustersTab from 'components/admin-tabs/ClustersTab';
import CustomAnalyticsAdminTab from 'components/admin-tabs/CustomAnalyticsAdminTab';
import DeepDiveTabAdmin from 'components/admin-tabs/DeepDiveTabAdmin';
import ExamplesTab from 'components/admin-tabs/ExamplesTab';
import ExploreTab from 'components/admin-tabs/ExploreTab';
import LeadersTab from 'components/admin-tabs/LeadersTab';
import ListActionsTab from 'components/admin-tabs/ListActionsTab';
import MonitoringSchedulesTab from 'components/admin-tabs/MonitoringSchedulesTab';
import OrganizationsTab from 'components/admin-tabs/OrganizationsTab';
import OrgnizationSelector from 'components/admin-tabs/OrgnizationSelector';
import SendPusherMessagesTab from 'components/admin-tabs/SendPusherMessagesTab';
import UsersTab from 'components/admin-tabs/UsersTab';
import { ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE } from 'constants/organizations';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCustomAnalytics } from 'selectors/admin';
import { getAdminCompanyLists } from 'selectors/adminCompanyLists';
import { getDiscoveriesClusters } from 'selectors/discovery-clusters';
import { getAllExploreLists } from 'selectors/explore';
import { getNonMathlabsSearches } from 'selectors/non-mathlabs-searches';
import { getOrganizations } from 'selectors/organizations';
import { getPersons } from 'selectors/persons';
import { getRuns } from 'selectors/runs';
import { getCompanyListSchedules } from 'selectors/schedules';
import { getUserIsAdmin } from 'selectors/user';
import { getUsers } from 'selectors/users';
import { organization_selector_value_to_filter } from 'utils/organization-utils';

const TABS = Object.freeze({
  NON_MATHLABS: 0,
  EXPLORE_LISTS: 1,
  CUSTOM_ANALYTICS: 2,
  DEEP_DIVE: 3,
  MONITORING_SCHEDULES: 4,
  LIST_ACTIONS: 5,
  USERS: 6,
  ORGANIZATIONS: 7,
  CLUSTERS: 8,
  LEADERS: 9,
  SEND_PUSHER_MESSAGES: 10
});

function AdminInnerScreen() {
  const reduxDispatch = useDispatch();
  const { state } = useAppState();
  const [filterId, setFilterId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const users = getUsers(state);
  const runs = getRuns(state);
  const persons = getPersons(state);
  const nonMathlabsSearches = getNonMathlabsSearches(state);
  const [value, setValue] = useState(0);
  const organizations = getOrganizations(state);
  const clusters = getDiscoveriesClusters(state);
  const lists = getAdminCompanyLists(state);
  const customAnalytics = useSelector(getAdminCustomAnalytics);
  const exploreLists = useSelector((reduxState) => getAllExploreLists(reduxState));
  const schedules = useSelector(getCompanyListSchedules);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(loadAllRuns({ all: true, num: 100 }));
    dispatch(loadAllPersons({ all: true, num: 100 }));
    dispatch(loadAllUsers());
    dispatch(loadAllNonMathlabsSearchesAction());
    dispatch(loadAllAdminListsAction());
    setIsLoading(false);
  }, []);

  useEffect(() => {
    reduxDispatch(loadAllClustersAction());
    reduxDispatch(loadAllExploreLists({ all: true, record_only: true }));
    reduxDispatch(getSchedulesAction({ all: true }));
  }, [reduxDispatch]);

  useEffect(() => {
    reduxDispatch(
      loadAllAdminCustomAnalytics(filterId === ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE)
    );
  }, [filterId, reduxDispatch]);

  const filter = organization_selector_value_to_filter(filterId);

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <Stack
        gap="8px"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '30px'
        }}>
        <h1>Admin Console</h1>
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          sx={{
            borderRadius: '4px',
            backgroundColor: 'colors.hover_on_gray_bg'
          }}>
          <OrgnizationSelector setOrganizationId={setFilterId} />
        </Stack>
        <Tabs value={value} onChange={handleChange} textColor="secondary" variant="scrollable">
          <Tab label="Non-Mathlabs" />
          <Tab label="Explore Lists" />
          <Tab label="Custom Analytics" />
          <Tab label="Deep Dive" />
          <Tab label="Monitoring Schedules" />
          <Tab label="List Actions" />
          <Tab label="Users" />
          <Tab label="Organizations" />
          <Tab label="Clusters" />
          <Tab label="Leaders" />
          <Tab label="Pusher Messages" />
        </Tabs>
      </Stack>
      {value === TABS.DEEP_DIVE && runs && (
        <DeepDiveTabAdmin
          runs={runs}
          hidden={value !== TABS.DEEP_DIVE}
          filter={filter}></DeepDiveTabAdmin>
      )}

      {value === TABS.LEADERS && persons && (
        <LeadersTab persons={persons} hidden={value !== TABS.LEADERS} filter={filter}></LeadersTab>
      )}
      {value === TABS.USERS && users && (
        <UsersTab
          users={users}
          hidden={value !== TABS.USERS}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          filter={filter}></UsersTab>
      )}
      {value === TABS.EXPLORE_LISTS && exploreLists && (
        <ExploreTab
          exploreLists={exploreLists}
          hidden={value !== TABS.EXPLORE_LISTS}
          filter={filter}
          users={users}
        />
      )}
      {value === TABS.LIST_ACTIONS && lists && (
        <ListActionsTab lists={lists} hidden={value !== TABS.LIST_ACTIONS} />
      )}
      {value === TABS.CUSTOM_ANALYTICS && lists && (
        <CustomAnalyticsAdminTab
          customAnalytics={customAnalytics}
          hidden={value !== TABS.CUSTOM_ANALYTICS}
        />
      )}
      {value === TABS.NON_MATHLABS && nonMathlabsSearches && (
        <ExamplesTab
          searches={nonMathlabsSearches}
          hidden={value !== TABS.NON_MATHLABS}
          filter={filter}></ExamplesTab>
      )}
      {value === TABS.ORGANIZATIONS && organizations && (
        <OrganizationsTab
          organizations={organizations}
          hidden={value !== TABS.ORGANIZATIONS}></OrganizationsTab>
      )}
      {value === TABS.CLUSTERS && clusters && (
        <ClustersTab
          clusters={clusters}
          hidden={value !== TABS.CLUSTERS}
          filter={filter}></ClustersTab>
      )}
      {value === TABS.SEND_PUSHER_MESSAGES && <SendPusherMessagesTab />}
      {value === TABS.MONITORING_SCHEDULES && schedules && (
        <MonitoringSchedulesTab
          schedules={schedules}
          hidden={value !== TABS.MONITORING_SCHEDULES}></MonitoringSchedulesTab>
      )}
    </Box>
  );
}

export default function AdminScreen() {
  const { state } = useAppState();
  const isAdmin = getUserIsAdmin(state);
  if (!isAdmin) {
    return <div>Forbidden Route!</div>;
  }
  return <AdminInnerScreen />;
}
