import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

function CAPlanNode({ data }) {
  const { label, functionUse } = data;
  const { action, action_variables } = functionUse || {};

  return (
    <Stack sx={{ padding: 0 }}>
      <Accordion
        sx={{
          boxSizing: 'border-box',
          border: '1px solid',
          borderColor: 'greyColors.grey150',
          color: 'colors.primary_text',
          backgroundColor: 'colors.hover_on_primary_bg',
          borderRadius: '4px',
          padding: 0,
          textAlign: 'left',
          margin: 0,
          boxShadow: '0px 1px 8px 0px #19213D14',
          minHeight: 0
        }}>
        <AccordionSummary
          expandIcon={functionUse ? <GridExpandMoreIcon /> : null}
          aria-controls="panel1-content"
          id="panel1-header">
          <Typography variant="paragraphMedium" color="greyColors.grey150">
            {label}
          </Typography>
        </AccordionSummary>
        {functionUse && (
          <AccordionDetails>
            <Stack sx={{ borderLeft: '0px solid black', paddingLeft: '8px', paddingY: '8px' }}>
              {action && (
                <Typography variant="paragraphMedium" sx={{ marginBottom: '8px' }}>
                  {action}{' '}
                </Typography>
              )}
              {action_variables &&
                Object.keys(action_variables).map((key, id) => (
                  <Typography key={id} variant="paragraph" sx={{ marginLeft: '16px' }}>
                    <Typography variant="paragraphMedium">{key}</Typography> :{' '}
                    {action_variables[key]}
                  </Typography>
                ))}
            </Stack>
          </AccordionDetails>
        )}
      </Accordion>
      <Handle type="target" position={Position.Top} />
      <Handle type="target" position={Position.Top} />
      {<Handle type="source" position={Position.Bottom} />}
    </Stack>
  );
}

CAPlanNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default CAPlanNode;
