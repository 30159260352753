import { useMediaQuery } from '@mui/material';
import { ELEMENT_HEIGHTS, MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CartesianGrid, Legend, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import {
  determineDomain,
  determineTickFormatter,
  determineTicks,
  getLineColorByIndex,
  mkTicks,
  unixTimeToTick
} from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';
import { RenderTimeSeriesLegend } from './RenderTimeSeriesLegend';

/* eslint-disable id-length */
export default function MultiTimeSeriesComponent({
  elementId,
  title,
  data,
  benchmarkLabels,
  xAxisName,
  yAxisName,
  yAxisName2,
  mode,
  isScatter
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const names = useMemo(() => data.map((dataset) => dataset.name), [data]);

  const datasets = useMemo(() => {
    return data.map((dataset) =>
      dataset.dates.map((date, index) => ({
        x: new Date(date).getTime(),
        y: dataset.values[index]
      }))
    );
  }, [data]);

  const xMin = Math.min(...datasets.map((dataset) => Math.min(...dataset.map((point) => point.x))));
  const xMax = Math.max(...datasets.map((dataset) => Math.max(...dataset.map((point) => point.x))));
  const ticks = mkTicks(xMin, xMax, 5).map((item) => (isNaN(item) ? null : item));
  const yAxes = useMemo(() => {
    return data.map((dataset) => (dataset.y_axis === 1 ? 'left' : 'right'));
  }, [data]);
  return (
    <RechartsBox
      title={title}
      mode={mode}
      height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
      <ScatterChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        margin={{
          top: mode === 'benchmark' || !mediumViewport ? 0 : 20,
          right: mode === 'benchmark' || !mediumViewport ? 0 : 40,
          bottom: mode === 'benchmark' || !mediumViewport ? 0 : 40,
          left: mode === 'benchmark' || !mediumViewport ? 0 : 40
        }}>
        <XAxis
          dataKey="x"
          type="number"
          name={xAxisName}
          domain={[xMin, xMax]}
          ticks={ticks}
          tickFormatter={(tick) => (tick ? unixTimeToTick(tick, ticks) : null)}
          label={{
            value: xAxisName,
            position: 'bottom',
            fill: appTheme.palette.greyColors.grey250
          }}
          stroke={appTheme.palette.greyColors.grey250}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          type="number"
          dataKey="y"
          yAxisId="left"
          domain={determineDomain(elementId, 'y')}
          ticks={determineTicks(elementId, 'y')}
          tickFormatter={determineTickFormatter(elementId, 'y')}
          name={yAxisName}
          label={
            yAxisName && {
              value: yAxisName,
              position: 'insideLeft',
              angle: -90,
              style: {
                textAnchor: 'middle'
              },
              fill: appTheme.palette.greyColors.grey250
            }
          }
          stroke={appTheme.palette.greyColors.grey250}
          axisLine={false}
          tickLine={false}
        />
        {yAxisName2 && (
          <YAxis
            type="number"
            dataKey="y"
            yAxisId="right"
            orientation="right"
            name={yAxisName2}
            label={{
              value: yAxisName2,
              position: 'insideRight',
              angle: 270,
              style: {
                textAnchor: 'middle'
              },
              fill: appTheme.palette.greyColors.grey250
            }}
            stroke={appTheme.palette.greyColors.grey250}
            axisLine={false}
            tickLine={false}
          />
        )}
        <Legend
          verticalAlign="bottom"
          align="right"
          wrapperStyle={{ paddingBottom: 10 }}
          content={(props) => (
            <RenderTimeSeriesLegend {...props} benchmarkLabels={benchmarkLabels} />
          )}
        />

        <CartesianGrid vertical={false} stroke={appTheme.palette.greyColors.grey50} />
        {datasets.map((dataset, index) => {
          return (
            <Scatter
              key={names[index] || `Series ${index}`}
              yAxisId={yAxes[index]}
              shape={isScatter || dataset.length < 2 ? 'circle' : null}
              fill={getLineColorByIndex(index)}
              line={!isScatter}
              strokeWidth={isScatter ? 1 : 2}
              name={names[index] || `Series ${index}`}
              data={dataset}
            />
          );
        })}
      </ScatterChart>
    </RechartsBox>
  );
}

MultiTimeSeriesComponent.propTypes = {
  elementId: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  yAxisName2: PropTypes.string,
  mode: PropTypes.oneOf(MODES).isRequired,
  isScatter: PropTypes.bool,
  benchmarkLabels: PropTypes.array
};
