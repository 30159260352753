import { useEffect, useRef, useState } from 'react';

export function useScrollPositionFilterByDirection(
  token,
  rootElementId = 'root',
  directional = true
) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isUpDirection, setIsUpDirection] = useState(false);
  const prevScrollRef = useRef({});

  useEffect(() => {
    const handler = (event) => {
      const newIsUpDirection = event.deltaY > 0;
      if (newIsUpDirection !== isUpDirection) {
        setIsUpDirection(newIsUpDirection);
      }
    };
    window.addEventListener('wheel', handler);
    return () => window.removeEventListener('wheel', handler);
  }, [isUpDirection]);

  useEffect(() => {
    const root = document.getElementById(rootElementId);
    const scrollHandler = () => {
      const newScroll = root.scrollTop;
      const prevScroll = prevScrollRef.current[token] || 0;
      const scrollDiffFromPrev = newScroll - prevScroll;

      if (directional) {
        if (
          (scrollDiffFromPrev < 0 && !isUpDirection) ||
          (scrollDiffFromPrev > 0 && isUpDirection)
        ) {
          prevScrollRef.current[token] = newScroll;
          setScrollPosition(newScroll);
        }
      } else {
        prevScrollRef.current[token] = newScroll;
        setScrollPosition(newScroll);
      }
    };
    root.addEventListener('scroll', scrollHandler);

    return () => root.removeEventListener('scroll', scrollHandler);
  }, [isUpDirection, token, rootElementId, directional]);

  return scrollPosition;
}
