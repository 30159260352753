import { getDomainKnowledgeResourceByListIdAndResourceId } from 'selectors/explore';
import { getFolderSupportingResourceByIds } from 'selectors/folders';
import { makeBlobDownload } from 'utils/download-utils';

export function downloadDomainKnowledgeResourceFileAction({
  listId,
  folderId,
  resourceId,
  onFinish
}) {
  return (_dispatch, getState, apiClient) => {
    let resource = null;
    if (folderId) {
      resource = getFolderSupportingResourceByIds(getState(), folderId, resourceId);
    }
    if (listId) {
      resource = getDomainKnowledgeResourceByListIdAndResourceId(getState(), listId, resourceId);
    }
    apiClient.supportingResources
      .downloadSupportingResourceFile(resourceId)
      .then((blob) => {
        makeBlobDownload(blob, resource.data.file_name);
        onFinish?.(true);
      })
      .catch(() => onFinish?.(false));
  };
}
