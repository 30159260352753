import { Stack, Typography } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import EditableMultilineTextarea from 'components/EditableMultilineTextarea';
import IconButtonComponent from 'components/IconButtonComponent';
import WorkFlowPlanNodeSettings from 'components/work-flow//WorkFlowPlanNodeSettings';
import WorkFlowPlanNodeDependencies from 'components/work-flow/WorkFlowPlanNodeDependencies';
import DependsOnIcon from 'icons/DependsOnIcon';
import SettingsIcon from 'icons/SettingsIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCustomAnalyticsPlanStepActionByThreadAndPostIdAndStepIndex,
  getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex
} from 'selectors/custom-analytics';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanNode({ data }) {
  const dispatch = useDispatch();
  const {
    listId,
    postId,
    stepId,
    stepIndex,
    queryIndex,
    label,
    color,
    Icon,
    width,
    height,
    dependsOn,
    usedBy,
    query: inputQuery,
    attributeName,
    showTopHandle = true,
    showBottomHandle = true,
    allowEdit = false
  } = data;
  const [isHover, setIsHover] = useState(false);
  const [dependenciesAnchorEl, setDependenciesAnchorEl] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(false);

  const threadKey = getThreadKey(listId);
  const stepAction = useSelector((state) =>
    getCustomAnalyticsPlanStepActionByThreadAndPostIdAndStepIndex(
      state,
      threadKey,
      postId,
      stepIndex
    )
  );
  const stepData = useSelector((state) =>
    getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex(
      state,
      threadKey,
      postId,
      stepIndex,
      queryIndex
    )
  );

  const query =
    typeof stepData?.query === 'object'
      ? stepData.query.user_question
      : stepData?.query || inputQuery;
  const searchTheWeb = typeof stepData?.query === 'object' ? stepData.query.search_the_web : null;
  const searchInTable =
    typeof stepData?.query === 'object' ? stepData.query.is_table_question : null;
  const columnIds = typeof stepData?.query === 'object' ? stepData.query.column_ids : null;
  const domainKnowledgeIds =
    typeof stepData?.query === 'object' ? stepData.query.domain_knowledge_ids : null;
  const answerType = typeof stepData?.query === 'object' ? stepData.query.answer_type : null;

  const onValueChange = (event) => {
    dispatch(updateQueryInPlanAction(listId, postId, stepId, { query: event.target.value || '' }));
  };

  const handleCloseDependencies = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDependenciesAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.target));
  };

  const handleToggleSettings = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSettingsAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.target));
  };

  return (
    <Stack
      // disable node dragging
      className="nodrag"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        width: width,
        height: height,
        padding: '16px',
        boxSizing: 'border-box',
        border: '1.5px solid',
        borderColor: applyThemeColorOpacity(color, 50),
        color: 'colors.primary_text',
        backgroundColor: applyThemeColorOpacity(color, 4),
        borderRadius: '16px',
        textAlign: 'left',
        margin: 0,
        minHeight: 0
      }}>
      <Stack direction="column" gap="8px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
          <Stack direction="row" alignItems="center" gap="8px">
            {Icon && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: applyThemeColorOpacity(color, 12),
                  borderRadius: '6px'
                }}
                color={color}>
                <Icon />
              </Stack>
            )}
            {label && (
              <Typography variant="paragraphBold" color={color}>
                {label}
              </Typography>
            )}
            {label && attributeName && (
              <Typography variant="paragraphMedium" color={color}>
                {' - '}
              </Typography>
            )}
            {attributeName && (
              <Typography variant="paragraphMedium" color={color}>
                {attributeName}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap="0px">
            {(stepAction === 'information' ||
              stepAction === 'filter' ||
              stepAction === 'analysis') && (
              <IconButtonComponent
                tag="Creation settings"
                onClick={handleToggleSettings}
                sx={{ visibility: isHover ? 'visible' : 'hidden' }}>
                <SettingsIcon />
              </IconButtonComponent>
            )}
            {(dependsOn?.length > 0 || usedBy?.length > 0) && (
              <IconButtonComponent tag="Workflow dependencies" onClick={handleCloseDependencies}>
                <DependsOnIcon />
              </IconButtonComponent>
            )}
          </Stack>
        </Stack>
        <EditableMultilineTextarea
          text={query}
          onTextChange={onValueChange}
          maxLines={5}
          maxWidth={360}
          allowEdit={allowEdit}
          placeholder="What this step should do?"
        />
      </Stack>
      {showTopHandle && (
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
      )}
      {showBottomHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', bottom: 0 }}
        />
      )}
      <WorkFlowPlanNodeDependencies
        anchorEl={dependenciesAnchorEl}
        onClose={handleCloseDependencies}
        dependsOn={dependsOn}
        usedBy={usedBy}
      />
      <WorkFlowPlanNodeSettings
        listId={listId}
        postId={postId}
        nodeId={stepId}
        anchorEl={settingsAnchorEl}
        onClose={handleToggleSettings}
        searchTheWeb={searchTheWeb}
        searchInTable={searchInTable}
        columnIds={columnIds}
        domainKnowledgeIds={domainKnowledgeIds}
        answerType={answerType}
      />
    </Stack>
  );
}

WorkFlowPlanNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowPlanNode;
