import { updateSavedQuery } from 'reducer/saved-queries-slice';

import { loadSavedQueriesAction } from './load-saved-queries-action';

export function updateSavedQueryAction(savedQueryId, data) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateSavedQuery({ savedQueryId, data }));
    apiClient.saved_queries.updateSavedQuery(savedQueryId, data).catch(() => {
      dispatch(loadSavedQueriesAction());
    });
  };
}
