import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable id-length*/
function CustomizedTreeContent({ x, y, width, height, size, name }) {
  const theme = useTheme();
  const bgColor = '#CFD5FC';
  const textColor = theme.palette.colors.dark_bg;
  const words = name ? name.split(' ') : [];
  const lineLength = Math.floor((width - 48) / 8);
  const small = Boolean(words.find((word) => word.length > lineLength));
  let currentLine = '';
  const lines = [];
  words.forEach((word, index) => {
    if (currentLine.length > 0 && currentLine.length + word.length > lineLength) {
      lines.push(currentLine);
      currentLine = word;
    } else {
      currentLine = currentLine === '' ? word : `${currentLine} ${word}`;
    }
    if (index === words.length - 1) {
      lines.push(currentLine);
    }
  });
  return (
    <g>
      <rect
        x={x}
        y={y}
        rx={6}
        ry={6}
        width={width}
        height={height}
        style={{
          fill: bgColor,
          stroke: '#fff',
          strokeWidth: 6
        }}
      />
      {!small &&
        lines.map((line, index) => (
          <text
            key={index}
            x={x + 12}
            y={y + 32 + index * 20}
            textAnchor="left"
            fill={textColor}
            stroke="none"
            style={theme.typography.paragraphMedium}
            fontSize={14}>
            {line}
          </text>
        ))}
      {small ? (
        <text
          x={width / 2 + x - 4}
          y={height / 2 + y + 6}
          fill={textColor}
          stroke="none"
          textAnchor="center"
          style={theme.typography.paragraphMedium}
          fontSize={14}>
          {size}
        </text>
      ) : (
        <text
          x={x + width - 32}
          y={y + 32}
          fill={textColor}
          stroke="none"
          textAnchor="right"
          style={theme.typography.paragraphMedium}
          fontSize={14}>
          {size}
        </text>
      )}
    </g>
  );
}

CustomizedTreeContent.propTypes = {
  root: PropTypes.object,
  depth: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  index: PropTypes.number,
  size: PropTypes.number,
  payload: PropTypes.object,
  colors: PropTypes.array,
  rank: PropTypes.number,
  name: PropTypes.string
};

export default CustomizedTreeContent;
