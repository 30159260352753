import { Avatar, Box, Tab, Tabs, Tooltip, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { appTheme } from 'theme';
import { insightTypeToIcon, insightTypeToTitle } from 'utils/insights-utils';

export default function InsightGroupMenu({ sections }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [top, setTop] = useState(0);
  const containerRef = useRef();

  const getIconForInsight = (insightType) => {
    const Icon = insightTypeToIcon(insightType);
    return <Icon />;
  };

  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));

  useEffect(() => {
    if (!containerRef.current) return;

    setTop(containerRef.current.offsetTop);
  }, [containerRef]);

  return (
    <Tabs
      ref={containerRef}
      orientation="vertical"
      variant="scrollable"
      value={activeTabIndex}
      onChange={(_event, newTabIndex) => setActiveTabIndex(newTabIndex)}
      sx={{
        display: mediumViewport ? 'initial' : 'none',
        backgroundColor: 'colors.primary_bg',
        position: 'sticky',
        height: 'fit-content',
        top,
        '.MuiTabs-indicator': {
          left: '0',
          width: '4px',
          backgroundColor: 'secondary.secondary6'
        }
      }}>
      {sections &&
        sections.map((section) => (
          <Tooltip
            key={section.id}
            title={insightTypeToTitle(section.title)}
            placement="right"
            arrow>
            <Tab
              icon={
                section.type === 'icon' ? (
                  getIconForInsight(section.data)
                ) : (
                  <Box
                    borderRadius="50%"
                    padding="1px"
                    border="1px solid"
                    borderColor="secondary.secondary2">
                    <Avatar alt={section.title} src={section.data} sx={{ width: 32, height: 32 }} />
                  </Box>
                )
              }
              iconPosition="start"
              to={`#${section.id}`}
              component={HashLink}
              smooth
              sx={{
                justifyContent: 'flex-start',
                gap: '8px',
                padding: '8px',
                minWidth: '56px',
                alignItems: 'center',
                color: 'colors.primary_text',
                '&:hover': { backgroundColor: 'colors.grey_bg', color: 'colors.primary_text' },
                '&.Mui-selected': {
                  color: 'colors.primary_text'
                }
              }}
            />
          </Tooltip>
        ))}
    </Tabs>
  );
}

InsightGroupMenu.propTypes = {
  sections: PropTypes.array
};
