import { getThreadKey, updateQueryInPlan } from 'reducer/custom-analytics';

export function updateQueryInPlanAction(listId, postId, stepId, queryDetails) {
  return (dispatch) => {
    const threadKey = getThreadKey(listId);
    const lastDashIndex = stepId.lastIndexOf('-');
    let actualStepId = stepId;
    let queryIndex = 0;
    if (lastDashIndex > 0) {
      actualStepId = stepId.substring(0, lastDashIndex);
      queryIndex = stepId.substring(lastDashIndex + 1);
    }

    dispatch(
      updateQueryInPlan({
        threadKey,
        postId,
        stepId: actualStepId,
        query: queryDetails.query,
        searchTheWeb: queryDetails.searchTheWeb,
        searchInTable: queryDetails.searchInTable,
        columnIds: queryDetails.columnIds,
        domainKnowledgeIds: queryDetails.domainKnowledgeIds,
        answerType: queryDetails.answerType,
        queryIndex: queryIndex
      })
    );
  };
}
