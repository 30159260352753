import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRAllCompanyMetaIdsByListId,
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRFiltersByListId
} from 'selectors/domain-reports';
import { getCompanyMetasByListId } from 'selectors/explore';

import DomainReportBox from '../DomainReportBox';
import DomainReportCompaniesBreakdown from './DomainReportCompaniesBreakdown';
import DomainReportDiscoveryBreakdown from './DomainReportDiscoveryBreakdown';
import DomainReportFiltersBreakdown from './DomainReportFiltersBreakdown';
import DomainReportProcessBar from './DomainReportProcessBar';

function DomainReportProcess({ listId }) {
  const allCompanyMetaIds = useSelector((state) =>
    getLatestDRAllCompanyMetaIdsByListId(state, listId)
  );
  const currentCompanyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const companyMetaIds = useSelector((state) => getLatestDRCompanyMetaIdsByListId(state, listId));
  const total = allCompanyMetaIds?.length || currentCompanyMetas?.length || 0;
  const final = companyMetaIds?.length;
  const filters = useSelector((state) => getLatestDRFiltersByListId(state, listId));
  const numFilters = filters ? Object.keys(filters).length : 0;
  return (
    <DomainReportBox title="Explore Process">
      <Stack gap="4px">
        <DomainReportProcessBar title="Total discovered" number={total} total={total}>
          <DomainReportDiscoveryBreakdown listId={listId} />
        </DomainReportProcessBar>
        <DomainReportProcessBar title="Removed by filtering" number={total - final} total={total}>
          {numFilters > 0 && <DomainReportFiltersBreakdown listId={listId} />}
        </DomainReportProcessBar>
        <DomainReportProcessBar title="Final list" number={final} total={total}>
          <DomainReportCompaniesBreakdown listId={listId} />
        </DomainReportProcessBar>
      </Stack>
    </DomainReportBox>
  );
}

DomainReportProcess.propTypes = {
  listId: PropTypes.number,
  companyMetaIds: PropTypes.array
};

export default DomainReportProcess;
