export const EXTEND_DOMAIN_TYPES = Object.freeze({
  SUBDOMAINS: 'Subdomains',
  PRODUCTS: 'Products',
  USER_INPUT: 'User Input'
});

export const EXTEND_DOMAIN_PREFIX = Object.freeze({
  [EXTEND_DOMAIN_TYPES.SUBDOMAINS]: 'Types of companies defined by the domain in the user input.',
  [EXTEND_DOMAIN_TYPES.PRODUCTS]: 'Products of the company in the user input.',
  [EXTEND_DOMAIN_TYPES.USER_INPUT]: null
});

export const getInstructions = (userInput, userPrefix) => `
Process user-provided inputs to extract specified types of items effectively.

Given the following inputs:

- Kind of items: ${userPrefix ? userPrefix : '**{Your custom query will go here}** <br>'}
- Source input: "{${userInput || "***Your item's name will go here***"}}"

#### Follow these instructions:

- Clearly interpret the user's specified kind of items and input source, even if complex or multi-component.
- Ensure extracted items are mutually exclusive and collectively exhaustive (MECE).
- Avoid irrelevant or generic responses.

#### Steps

1. **Input Interpretation**
   - Decode the "Kind of items" from the user's request.
   - Understand the context from the "Source input".

2. **Item Extraction**
   - Derive list of items that collectively cover all aspects of the user's request.
   - Provide short descriptions linked to the user's context.

3. **Quality Assurance**
   - Verify items directly relate to the user's needs.`;

export const generatePrompt = (userInput, userPrefix) => {
  return getInstructions(userInput, userPrefix);
};

export const DOMAIN_RESEARCH_COMPONENT_TYPES = Object.freeze({
  MAIN_DOMAIN: 'main domain',
  ITEM: 'Item',
  CATEGORY: 'Category',
  LEVEL: 'Level'
});

export const MAX_DISCOVERIES_TO_SUBMIT = 6;
