import BaseClient from './BaseClient';

const URL_PREFIX = 'saved_queries';

export default class SavedQueriesClient extends BaseClient {
  fetchUserSavedQueries() {
    return this.get(URL_PREFIX);
  }

  addSavedQuery(payload) {
    return this.post(URL_PREFIX, payload);
  }

  deleteSavedQuery(savedQueryId) {
    return this.httpDelete(`${URL_PREFIX}/${savedQueryId}`);
  }

  renameSavedQuery(savedQueryId, payload) {
    return this.put(`${URL_PREFIX}/${savedQueryId}/rename`, payload);
  }

  updateSavedQuery(savedQueryId, data) {
    return this.put(`${URL_PREFIX}/${savedQueryId}`, { data });
  }
}
