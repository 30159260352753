import PropTypes from 'prop-types';
import React from 'react';

export default function DependsOnIcon({ useDefaultColor }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.5 5.5C4.5 5.22386 4.72386 5 5 5H12C12.2761 5 12.5 5.22386 12.5 5.5V9.5C12.5 9.77614 12.2761 10 12 10H5C4.72386 10 4.5 9.77614 4.5 9.5V5.5Z"
        stroke={useDefaultColor ? '#5C5E76' : 'currentColor'}
      />
      <path
        d="M4.5 14.5C4.5 14.2239 4.72386 14 5 14H12C12.2761 14 12.5 14.2239 12.5 14.5V18.5C12.5 18.7761 12.2761 19 12 19H5C4.72386 19 4.5 18.7761 4.5 18.5V14.5Z"
        stroke={useDefaultColor ? '#5C5E76' : 'currentColor'}
      />
      <path
        d="M15.4995 17L16.5104 17L18.4993 17C19.0516 17 19.4993 16.5523 19.4993 16L19.4994 9C19.4994 8.17157 18.8278 7.49999 17.9994 7.49998L15 7.49996"
        stroke={useDefaultColor ? '#5C5E76' : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4992 19L14.999 16.9998L16.499 14.9997"
        stroke={useDefaultColor ? '#5C5E76' : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DependsOnIcon.propTypes = {
  useDefaultColor: PropTypes.bool
};

DependsOnIcon.defaultProps = {
  useDefaultColor: true
};
