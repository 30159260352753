import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { promptConfigToSearchPlaces } from 'utils/prompt-dropdown-utils';

import DREQDKeyValue from './DREQDKeyValue';

const columnsToColumnText = (columns) => {
  if (!columns) {
    return 'No columns';
  }
  if (columns.length === 1) {
    return `Using column ${columns[0]}`;
  }
  return `Using columns ${columns.join(', ')}`;
};

const searchPlacesToSearchText = (searchPlaces) => {
  if (!searchPlaces) {
    return 'No search places';
  }
  if (searchPlaces.length === 2) {
    return 'Search everywhere';
  }
  return `Search in ${searchPlaces.join(', ')}`;
};

const DREQDConfig = ({ promptConfig }) => {
  const searchPlaces = promptConfigToSearchPlaces(promptConfig);
  const searchText = searchPlacesToSearchText(searchPlaces);
  const columnText = columnsToColumnText(promptConfig?.columns);
  return (
    <Stack gap="24px">
      <DREQDKeyValue title="Query">{promptConfig?.question}</DREQDKeyValue>
      <DREQDKeyValue title="Search">{searchText}</DREQDKeyValue>
      <DREQDKeyValue title="Context">{columnText}</DREQDKeyValue>
    </Stack>
  );
};

DREQDConfig.propTypes = {
  promptConfig: PropTypes.object
};

export default DREQDConfig;
