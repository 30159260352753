import { Divider, Stack, Typography } from '@mui/material';
import BottomBarComponent from 'components/BottomBarComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import CloseIcon from 'icons/CloseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import { NewListIcon } from 'icons/NewListIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

import { NewListActionsMenu } from './NewListActionsMenu';
import { SelectedCompaniesComponent } from './SelectedCompaniesComponent';

export const BottomBarDiscovery = ({
  selectedCompanies,
  onClose,
  onRowsDeleted,
  onNewList,
  onDeepDive,
  onExtend,
  noRunsLeft
}) => {
  const numCompaniesSelected = selectedCompanies?.length;

  const maxLogosNum = 4;
  if (numCompaniesSelected <= 0) {
    return null;
  }

  return (
    <BottomBarComponent>
      <Stack flexDirection="row" gap="16px">
        <SelectedCompaniesComponent
          selectedCompanies={selectedCompanies}
          maxLogosNum={maxLogosNum}
        />
      </Stack>
      <Stack flexDirection="row" gap="16px">
        <NewListActionsMenu
          onDeepDive={onDeepDive}
          numSelected={numCompaniesSelected}
          noRunsLeft={noRunsLeft}
          onExtend={onExtend}></NewListActionsMenu>
        <IconButtonComponent onClick={onNewList} sx={{ color: 'secondary.secondary1' }}>
          <NewListIcon />
          <Typography variant="paragraph">Add To List</Typography>
        </IconButtonComponent>
        <IconButtonComponent
          tag="Remove"
          onClick={onRowsDeleted}
          sx={{ color: appTheme.palette.colors.negative_red, padding: '8px' }}>
          <DeleteIcon useDefaultColor={false} />
          <Typography variant="paragraph">Remove</Typography>
        </IconButtonComponent>
        <Divider
          orientation="vertical"
          sx={{ borderColor: 'colors.ui_border', height: '20px', alignSelf: 'center' }}
        />
        <IconButtonComponent onClick={onClose}>
          <CloseIcon></CloseIcon>
        </IconButtonComponent>
      </Stack>
    </BottomBarComponent>
  );
};

BottomBarDiscovery.propTypes = {
  selectedCompanies: PropTypes.array,
  onClose: PropTypes.func,
  onRowsDeleted: PropTypes.func,
  onNewList: PropTypes.func,
  onDeepDive: PropTypes.func,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func
};

export default React.memo(BottomBarDiscovery);
