import { Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import WorkFlowPlannerDialog from 'components/work-flow/WorkFlowPlannerDialog';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function CustomAnalyticsWorkflowMessageComponent({ listId, postId, isWorflowRun }) {
  const [openWorkflow, setOpenWorkflow] = useState(false);
  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="16px">
        <Typography variant="paragraphMedium" textAlign="start">
          {isWorflowRun ? 'Workflow completed' : 'Workflow created'}
        </Typography>
        {!isWorflowRun && (
          <IconButtonComponent tag="Show Workflow" onClick={() => setOpenWorkflow(true)}>
            <WorkflowIcon />
          </IconButtonComponent>
        )}
      </Stack>
      <WorkFlowPlannerDialog
        listId={listId}
        postId={postId}
        open={openWorkflow}
        onClose={() => setOpenWorkflow(false)}
      />
    </React.Fragment>
  );
}

CustomAnalyticsWorkflowMessageComponent.propTypes = {
  listId: PropTypes.number,
  postId: PropTypes.number,
  isWorflowRun: PropTypes.bool
};

export default CustomAnalyticsWorkflowMessageComponent;
