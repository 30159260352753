import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function QuickViewDivider({ orientation }) {
  if (orientation === 'vertical') {
    return <Box sx={{ borderLeft: '1px solid', borderColor: 'colors.ui_border' }} />;
  }
  return <Box sx={{ borderTop: '1px solid', borderColor: 'colors.ui_border' }} />;
}

QuickViewDivider.propTypes = {
  orientation: PropTypes.string
};

QuickViewDivider.defaultProps = {
  orientation: 'horizontal'
};

export default QuickViewDivider;
