import { useMediaQuery } from '@mui/material';
import { ELEMENT_HEIGHTS, MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import {
  dateStringToTick,
  getBenchmarkBarColorByIndexAndTotal,
  getLineColorByIndex,
  getStarColorByStarCount
} from 'utils/charts-utils';
import { limitLength } from 'utils/string-utils';

import RechartsBox from '../RechartsBox';
import StackedBarTooltipContent from './StackedBarTooltipContent';
import {
  CustomizedAxisTick,
  MonthAxisTick,
  QuarterAxisTick,
  QuarterYearAxisTick,
  YearAxisTick,
  monthsToQuartersStackedData,
  renderBarChartLabel,
  renderBarChartLabelBenchmark,
  renderBarChartLabelEnd,
  renderBarChartLegend
} from './bar-chart-utils';

const determineRadius = (array, index, isVertical) => {
  if (index === 0) {
    return isVertical ? [0, 0, 0, 0] : [0, 0, 2, 2];
  }
  if (index === array.length - 1) {
    return isVertical ? [0, 0, 0, 0] : [2, 2, 0, 0];
  }
  return 0;
};

const date = null;

const REMAINDER_NAME = 'Rest Of Company';
const TOTAL_NAME = 'total';
const MAX_TIME_BARS = 10;

/* eslint-disable id-length */
export default function StackedBarChartComponent({
  title,
  labels,
  benchmarkLabels,
  values,
  xAxisName,
  vertical,
  optionalFilters,
  setCardFilters,
  keyOrder,
  xSplitDate,
  yIsInt,
  isStars,
  mode
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const dataKeys = keyOrder ? keyOrder : Object.keys(values);
  const useQuarters = xSplitDate && labels.length > MAX_TIME_BARS;

  if (mode === 'benchmark') {
    dataKeys.push(REMAINDER_NAME);
  }
  const chars_per_label = Math.floor(50 / labels.length);

  const filterMap = useMemo(
    () =>
      optionalFilters?.reduce((map, filter) => {
        if (useQuarters && filter?.convert) {
          Object.keys(filter.convert).forEach(
            (key) =>
              (filter.convert[key] =
                filter.convert[key] === 'toYearMonth' ? 'toQuarter' : filter.convert[key])
          );
        }
        map[filter.mapping.stackbar] = filter;
        return map;
      }, {}) || {},
    [optionalFilters, useQuarters]
  );

  const data = useMemo(() => {
    if (useQuarters) {
      const result = monthsToQuartersStackedData(labels, values);
      return result;
    }
    return labels.map((xVal, index) => {
      let total = 0;
      const result = {
        label: xVal,
        benchmarkLabel: benchmarkLabels ? benchmarkLabels[index] : null,
        ...Object.keys(values).reduce((acc, key) => {
          total += values[key][index];
          return { ...acc, [key]: values[key][index] };
        }, {})
      };
      if (mode === 'benchmark') {
        result[REMAINDER_NAME] = 100 - total;
        result[TOTAL_NAME] = total;
      }
      return result;
    });
  }, [labels, values, mode, benchmarkLabels, useQuarters]);

  const maxCount = useMemo(
    () =>
      data.reduce((totalCount, category) => {
        const categoryCount = Object.values(category)
          .filter((item) => item !== category.label)
          .reduce((count, categorySectionCount) => count + categorySectionCount, 0);
        return Math.max(totalCount, categoryCount);
      }, 0),
    [data]
  );

  const determineBarColor = (index) => {
    if (isStars) {
      return getStarColorByStarCount(dataKeys[index]);
    }
    return mode === 'benchmark'
      ? getBenchmarkBarColorByIndexAndTotal(index, dataKeys.length)
      : getLineColorByIndex(index);
  };

  const determineTick = () => {
    if (xSplitDate) {
      return useQuarters ? <QuarterAxisTick /> : <MonthAxisTick />;
    }
    return chars_per_label < 10 ? <CustomizedAxisTick /> : { textOverflow: 'elipsis' };
  };
  const barChartBottomMargin = mediumViewport || chars_per_label >= 10 ? 40 : 80;
  return (
    <RechartsBox
      title={title}
      mode={mode}
      height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
      <BarChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        data={data}
        layout={vertical ? 'vertical' : 'horizontal'}
        margin={{
          top: mode === 'benchmark' || !mediumViewport ? 0 : 20,
          right: mode === 'benchmark' || !mediumViewport ? 0 : 40,
          bottom: mode === 'benchmark' ? 0 : barChartBottomMargin,
          left: mode === 'benchmark' || !mediumViewport ? 0 : 40
        }}>
        {vertical ? (
          <React.Fragment>
            <XAxis
              allowDecimals={false}
              tickCount={Math.min(5, maxCount + 1)}
              axisLine={false}
              type="number"
              tick={mode === 'benchmark' ? false : { fill: appTheme.palette.greyColors.grey250 }}
              tickLine={{ stroke: 'transparent' }}
            />
            <YAxis hide={true} type="category" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <XAxis
              dataKey="label"
              name={xAxisName}
              label={{
                value: xAxisName,
                position: 'bottom',
                fill: appTheme.palette.greyColors.grey250
              }}
              tickFormatter={
                date
                  ? (tick) => dateStringToTick(tick, labels)
                  : (tick) => limitLength(tick, chars_per_label)
              }
              stroke={appTheme.palette.greyColors.grey250}
              tick={determineTick()}
              axisLine={false}
              tickLine={false}
            />
            {xSplitDate && (
              <XAxis
                dataKey="label"
                axisLine={false}
                tickLine={false}
                tick={useQuarters ? <QuarterYearAxisTick /> : <YearAxisTick />}
                scale="band"
                interval={0}
                xAxisId="year"
              />
            )}
            <YAxis
              allowDecimals={!yIsInt}
              stroke={appTheme.palette.greyColors.grey250}
              axisLine={false}
              tickLine={false}
            />
          </React.Fragment>
        )}
        <Tooltip cursor={false} content={<StackedBarTooltipContent />} />
        <Legend
          wrapperStyle={{ width: mediumViewport ? 'initial' : '100%', bottom: 0 }}
          verticalAlign={'middle'}
          align={xAxisName ? 'center' : 'right'}
          iconType="circle"
          content={renderBarChartLegend}
        />
        {mode !== 'benchmark' && (
          <CartesianGrid
            vertical={vertical}
            horizontal={!vertical}
            stroke={appTheme.palette.greyColors.grey50}
          />
        )}
        {dataKeys.map((dataKey, index) => (
          <Bar
            stackId="a"
            isAnimationActive={!vertical}
            maxBarSize={vertical ? 24 : 60}
            key={dataKey}
            dataKey={dataKey}
            label={dataKey}
            onClick={({ label }) => {
              if (optionalFilters) {
                setCardFilters([
                  { key: filterMap.dataKey, value: dataKey },
                  { key: filterMap.label, value: label }
                ]);
              }
            }}
            fill={determineBarColor(index)}
            radius={determineRadius(dataKeys, index, vertical)}>
            {index === 0 && vertical && (
              <LabelList
                dataKey={mode === 'benchmark' ? 'benchmarkLabel' : 'label'}
                content={mode === 'benchmark' ? renderBarChartLabelBenchmark : renderBarChartLabel}
              />
            )}
            {index === dataKeys.length - 1 && vertical && mode === 'benchmark' && (
              <LabelList dataKey={TOTAL_NAME} content={renderBarChartLabelEnd} position="end" />
            )}
          </Bar>
        ))}
      </BarChart>
    </RechartsBox>
  );
}

StackedBarChartComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  benchmarkLabels: PropTypes.array,
  values: PropTypes.object,
  xAxisName: PropTypes.string,
  vertical: PropTypes.bool,
  optionalFilters: PropTypes.array,
  setCardFilters: PropTypes.func,
  xSplitDate: PropTypes.bool,
  yIsInt: PropTypes.bool,
  keyOrder: PropTypes.array,
  isStars: PropTypes.bool,
  mode: PropTypes.oneOf(MODES).isRequired
};
