import { Stack, Tab, Tabs } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { findArtAction } from 'actions/art/find-art-action';
import { getArtistsAction } from 'actions/art/get-artists-action';
import { predictArtAction } from 'actions/art/predict-art-action';
import FindPanel from 'components/art/FindPanel';
import PredictPanel from 'components/art/PredictPanel';
import { useAppState } from 'hooks/state-context';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearArtState, updatePredicted } from 'reducer/art-slice';
import { getFoundArtworks } from 'selectors/art';
import { getUserFeatureFlags, getUserIsDevOrAdmin } from 'selectors/user';
import { appTheme } from 'theme';
import { isEmpty } from 'utils/objects-utils';

const tabSx = {
  gap: '8px',
  color: appTheme.palette.colors.primary_text,
  '&:hover': {
    color: appTheme.palette.primary.primary25
  },

  '&.Mui-selected': {
    color: appTheme.palette.colors.primary
  },
  minHeight: '10px'
};

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
export default function ArtScreen() {
  const { state } = useAppState();
  const featureFlags = getUserFeatureFlags(state);
  const userIsDevOrAdmin = getUserIsDevOrAdmin(state);
  const [activeTab, setActiveTab] = useState(0);
  const [findLoading, setFindLoading] = useState(false);
  const [predictLoading, setPredictLoading] = useState(null);

  const [artistNameSelect, setArtistNameSelect] = useState(null);
  const [artworkName, setArtworkName] = useState('');
  const [externalOnly, setExternalOnly] = useState(false);

  const [localArtworks, setLocalArtworks] = useState({});
  const foundArtworks = useSelector(getFoundArtworks);

  useEffect(() => {
    if (
      (!isEmpty(foundArtworks) && isEmpty(localArtworks)) ||
      (!isEmpty(localArtworks) && isEmpty(foundArtworks))
    ) {
      setLocalArtworks({ ...(foundArtworks || {}) });
    }
  }, [setLocalArtworks, foundArtworks, localArtworks]);

  const dispatch = useDispatch();
  const { addToast } = useAppUtils();
  const onFind = () => {
    dispatch(findArtAction(artistNameSelect, artworkName, externalOnly, addToast, setFindLoading));
    dispatch(clearArtState());
  };
  const onPredictSuccess = () => setActiveTab(1);
  const onPredict = (id, artwork, similarArtworks) => {
    dispatch(
      predictArtAction(
        id,
        artwork.artist_name,
        artwork,
        similarArtworks,
        addToast,
        setPredictLoading,
        onPredictSuccess
      )
    );
    dispatch(updatePredicted({ data: {} }));
  };
  useEffect(() => {
    if (!featureFlags.includes('art') && !userIsDevOrAdmin) {
      return;
    }
    dispatch(getArtistsAction(addToast));
  }, [dispatch, addToast, featureFlags, userIsDevOrAdmin]);

  if (!featureFlags.includes('art') && !userIsDevOrAdmin) {
    return 'Page does not exist.';
  }

  const resetArtwork = (artWorkIndex) => {
    const newLocalArtworks = { ...localArtworks };
    newLocalArtworks[artWorkIndex] = foundArtworks[artWorkIndex];
    setLocalArtworks(newLocalArtworks);
  };

  const findInputParams = {
    artistNameSelect,
    setArtistNameSelect,
    artworkName,
    setArtworkName,
    externalOnly,
    setExternalOnly,
    localArtworks,
    setLocalArtworks,
    resetArtwork
  };
  return (
    <Stack
      sx={{
        padding: '32px',
        minHeight: 'calc(100vh - 50px)',
        backgroundColor: 'colors.hover_on_gray_bg'
      }}
      gap="32px"
      alignItems="center">
      <Stack
        alignItems="start"
        sx={{ backgroundColor: 'colors.grey_bg', borderRadius: '8px', padding: '16px' }}>
        <Tabs
          sx={tabSx}
          value={activeTab}
          onChange={(_event, newValue) => {
            setActiveTab(newValue);
          }}>
          <Tab label="Find" value={0} color="primary" sx={tabSx} />
          <Tab label="Predict" value={1} color="primary" sx={tabSx} />
        </Tabs>
        <Stack sx={{ margin: '16px', width: '600px' }}>
          {activeTab === 0 && (
            <FindPanel
              onPredict={onPredict}
              onFind={onFind}
              findLoading={findLoading}
              predictLoading={predictLoading}
              {...findInputParams}
            />
          )}
          {activeTab === 1 && (
            <PredictPanel isPredictLoading={predictLoading !== null} onPredict={onPredict} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
