import React from 'react';

function RefreshIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 10.9851C19.7554 9.22528 18.9391 7.59472 17.6766 6.34455C16.4142 5.09439 14.7758 4.29398 13.0137 4.06661C11.2516 3.83924 9.46362 4.19754 7.9252 5.0863C6.38678 5.97507 5.18325 7.34499 4.5 8.98506M4 4.98506V8.98506H8M4 12.9851C4.24456 14.7449 5.06093 16.3754 6.32336 17.6256C7.58579 18.8758 9.22424 19.6762 10.9863 19.9036C12.7484 20.1309 14.5364 19.7726 16.0748 18.8839C17.6132 17.9951 18.8168 16.6252 19.5 14.9851M20 18.9851V14.9851H16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RefreshIcon;
