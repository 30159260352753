import RenderCompanySource from 'components/elements/table/RenderCompanySource';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDiscoveryByDiscoveryId } from 'selectors/discoveries';
import { getCompanyListHistoryByListId, getCompanyMetasByListId } from 'selectors/explore';

export default function RenderDiscoveryRunFeedMessage({
  listId,
  searchId,
  showOnlyDiscoveredCompanies
}) {
  const discoveryRun = useSelector((state) => getDiscoveryByDiscoveryId(state, searchId));
  const listHistory = useSelector((state) => getCompanyListHistoryByListId(state, listId));
  const companies = useSelector((state) => getCompanyMetasByListId(state, listId));
  const discoveryRunData = useMemo(() => {
    if (!discoveryRun || !listHistory?.[discoveryRun.parameters.iteration]) {
      return null;
    }

    const discoveredCompanies = companies
      .filter((company) => company?.iteration_added === discoveryRun.parameters.iteration)
      .map((company) => ({
        logo_url: company.parameters.logo_url,
        text: company.cb_name || company.linkedin_name || company.name,
        show_also_if_empty: true
      }));
    return {
      ...listHistory[discoveryRun.parameters.iteration],
      time_submitted: null,
      alwaysOpen: true,
      discoveredCompanies: discoveredCompanies,
      outputData: discoveryRun.outputData,
      title: null,
      showOnlyDiscoveredCompanies
    };
  }, [discoveryRun, listHistory, companies, showOnlyDiscoveredCompanies]);

  if (!discoveryRunData) {
    return null;
  }
  return <RenderCompanySource value={discoveryRunData} />;
}

RenderDiscoveryRunFeedMessage.propTypes = {
  listId: PropTypes.number,
  searchId: PropTypes.number,
  showOnlyDiscoveredCompanies: PropTypes.bool
};
