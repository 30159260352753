import { addDefaultConfig } from 'reducer/domain-reports-slice';

export function loadDomainReportDefaultConfigAction() {
  return (dispatch, _getState, apiClient) => {
    apiClient.domainReports.fetchDefaultConfig().then((res) => {
      if (res.ok && res.data && res.data.default_config) {
        dispatch(addDefaultConfig(res.data));
      }
    });
  };
}
