import { Button, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import SendIcon from 'icons/SendIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getExploreFiltersById } from 'selectors/explore';
import { appTheme } from 'theme';

const TEXT_OPERATORS = Object.freeze({
  contains: 'Contains',
  not_contains: "Doesn't contain"
});

function TextColumnFilter({ listId, onUpdateFilter, columnId }) {
  const existingFilters = useSelector((reduxState) => getExploreFiltersById(reduxState, listId));
  const savedText = existingFilters?.[columnId]?.data?.[0] || '';
  const savedOperator = existingFilters?.[columnId]?.data?.[1] || '';
  const [text, setText] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operator, setOperator] = useState('');

  const [selectWidth, setSelectWidth] = useState('auto');

  useEffect(() => {
    const maxWidth = Math.max(...Object.values(TEXT_OPERATORS).map((option) => option.length));
    const minWidth = 200;
    const width = Math.max(minWidth, maxWidth * 14);
    setSelectWidth(`${width}px`);
  }, []);

  useEffect(() => {
    setText(savedText);
  }, [savedText]);

  useEffect(() => {
    setOperator(savedOperator || 'contains');
  }, [savedOperator]);

  const runFilter = () => {
    onUpdateFilter([text, operator]);

    setIsSubmitted(true);
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      if (!text || !text.trim().length) {
        onUpdateFilter(null);
        return;
      }
      event.target.blur();
      runFilter();
    }
  };

  const handleTermChange = (event) => {
    setText(event.target.value);
    setIsSubmitted(false);
  };

  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <Select
        size="small"
        value={operator}
        onChange={(event) => setOperator(event.target.value)}
        sx={{
          width: selectWidth,
          '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            minHeight: 0
          }
        }}>
        {Object.keys(TEXT_OPERATORS).map((operatorId, index) => (
          <MenuItem value={operatorId} key={index}>
            <Typography variant="paragraph">{TEXT_OPERATORS[operatorId]}</Typography>
          </MenuItem>
        ))}
      </Select>
      <TextField
        value={text}
        multiLine={true}
        autoFocus={false}
        rows={2}
        onChange={handleTermChange}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: (
            <Button onClick={runFilter}>
              <SendIcon />
            </Button>
          )
        }}
        size="small"
        sx={{
          '.MuiInputBase-root': {
            paddingRight: 0
          },
          '.MuiInputBase-input': {
            ...(isSubmitted ? appTheme.typography.paragraphBold : appTheme.typography.paragraph),
            color: isSubmitted ? 'primary.primary100' : 'colors.primary_text'
          }
        }}
      />
    </Stack>
  );
}

TextColumnFilter.propTypes = {
  listId: PropTypes.number,
  onUpdateFilter: PropTypes.func,
  columnId: PropTypes.string
};

export default TextColumnFilter;
