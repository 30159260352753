import BaseClient from './BaseClient';

const URL_PREFIX = 'llm_queries';

export default class LLMQueriesClient extends BaseClient {
  getIsColumnValuesContainNames(values, column_name) {
    return this.post(`${URL_PREFIX}/values-contain-names`, { values, column_name });
  }

  getNamesFromColumnValues(values, column_name, names_to_exclude) {
    return this.post(`${URL_PREFIX}/names-extraction`, { values, column_name, names_to_exclude });
  }

  generateNameForColumn(question) {
    return this.post(`${URL_PREFIX}/generate-query-name`, { question });
  }

  generateSubdomains(payload) {
    return this.post(`${URL_PREFIX}/generate-subdomains`, payload);
  }
}
