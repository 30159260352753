import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ScatterPlotLogoComponent from '../scatter/ScatterPlotLogoComponent';
import TableComponent from '../table/TableComponent';
import CompanySummaryCard from './CompanySummaryCard';

/* eslint-disable id-length */
export default function CompetitorsCompositeComponent({ title, landscape, table }) {
  const landscapeData = landscape.data.data;
  const xAxisValues = landscapeData.x;
  const yAxisValues = landscapeData.y;
  const xAxisName = landscapeData.x_axis_name;
  const yAxisName = landscapeData.y_axis_name;
  const { labels } = landscapeData;

  const [selectedIndex, setIndex] = useState(0);

  table.data.columns.forEach((col) => {
    if (col.data_type === 'chips') {
      col.values = col.values.map((val) => {
        if (!val) return [];
        if (Array.isArray(val)) return val;
        return val.split('; ').map((label) => {
          return { label: label.replace('_', ' '), color: 'colors.hover_on_gray_bg' };
        });
      });
    }
  });

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Stack>
            <ScatterPlotLogoComponent
              title={title}
              xAxisValues={xAxisValues}
              yAxisValues={yAxisValues}
              xAxisName={xAxisName}
              yAxisName={yAxisName}
              dataKeyX="x"
              dataKeyY="y"
              labels={labels}
              selectedIndex={selectedIndex}
              mode="card"
              elementId="CompetitorsLandscapeElement"
              clickHandler={setIndex}
            />
            <TableComponent
              elementId="CompetitorsTable"
              showTitle={true}
              title={table.data.name}
              columns={table.data.columns}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <CompanySummaryCard
            label={{ ...labels[selectedIndex], ...{ x: xAxisValues[selectedIndex] } }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

CompetitorsCompositeComponent.propTypes = {
  title: PropTypes.string,
  landscape: PropTypes.object,
  table: PropTypes.object,
  recordId: PropTypes.string
};
