import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { selectWorkflowByQueryAction } from 'actions/workflows/add-workflow-by-query-action';
import IconButtonComponent from 'components/IconButtonComponent';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import CustomAvatar from 'components/tile/CustomAvatar';
import MenuIcon from 'icons/MenuIcon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import { formatTimestamp } from 'utils/time-utils';

function WorkflowsCenterDialogRow({ query, listId }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const nestedComponentRef = useRef();

  const plan = useSelector((state) => {
    if (query.savedQueryId) {
      return query.parameters;
    }
    if (query.postId) {
      return getCustomAnalyticsPlanByThreadAndPostId(state, getThreadKey(listId), query.postId);
    }
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onOpenWorkflow = () => dispatch(selectWorkflowByQueryAction(listId, query));

  const menuItems = [
    {
      id: 'save',
      onClick: () => {
        if (nestedComponentRef.current) {
          nestedComponentRef.current.notifyFromParent();
        }
      },
      startIcon: (
        <SaveQueryDialog
          prompt={{ ...plan, saved_query_id: query.savedQueryId }}
          existColumnName={query.name}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          listId={listId}
          ref={nestedComponentRef}
          showIsBookmarked={false}
          isWorkflow={true}
        />
      ),
      text: null,
      // use function to avoid re-rendering when isSaved changes
      getText: () => (isSaved ? 'Remove from Workflow Library' : 'Save to Workflow Library')
    }
  ];

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        onClick={() => onOpenWorkflow(query)}
        sx={{ cursor: 'pointer' }}>
        <Stack direction="row" alignItems="center" gap="8px">
          {query.Icon && <query.Icon />}
          <Typography variant="paragraphMedium">{query.name}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {query.time_created && formatTimestamp(query.time_created)}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <CustomAvatar name={query.owner.username} pictureUrl={query.owner.picture} />
        </Stack>
      </TableCell>
      <TableCell align="right">
        <IconButtonComponent sx={{ padding: 0 }} onClick={handleOpen}>
          <MenuIcon color="currentColor" />
        </IconButtonComponent>
      </TableCell>

      <Menu
        MenuListProps={{ sx: { py: 0 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems.map(({ id, startIcon, onClick, text, getText }) => (
          <MenuItem key={id} onClick={onClick}>
            <ListItemIcon>{startIcon}</ListItemIcon>
            <ListItemText>{text || getText()}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </TableRow>
  );
}

WorkflowsCenterDialogRow.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object
};

export default WorkflowsCenterDialogRow;
