import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { createListFromSearchTerms } from 'actions/company_lists/create-list-from-column-action';
import IconButtonComponent from 'components/IconButtonComponent';
import RenderStr from 'components/elements/table/RenderStr';
import ModalComponent from 'components/modal/ModalComponent';
import { TOAST_TYPES } from 'constants/toasts';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteExtractedNames, removeExtractedName } from 'reducer/llm-queries-slice';
import {
  getExtractedNames,
  getExtractedNamesLoading,
  getExtractedNamesTitle
} from 'selectors/llm-queries';

function CreateListFromCompaniesDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyNames = useSelector(getExtractedNames);
  const title = useSelector(getExtractedNamesTitle);
  const isLoading = useSelector(getExtractedNamesLoading);
  const isOpen = isLoading || companyNames;
  const { addToast } = useAppUtils();

  const closeDialog = () => {
    dispatch(deleteExtractedNames());
  };

  const handleAddList = () => {
    dispatch(createListFromSearchTerms(companyNames, title, navigate));
    closeDialog();
  };

  const handleDownloadFile = () => {
    // eslint-disable-next-line require-unicode-regexp
    const csv = companyNames.map((name) => name.replace(/,/g, '')).join('\n');
    const csvContent = `data:text/csv;charset=utf-8,Company\n${csv}\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${title}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (companyNames && !isLoading && companyNames.length === 0) {
      addToast('No company names found in the selected data', TOAST_TYPES.ERROR);
      dispatch(deleteExtractedNames());
    }
  }, [companyNames, isLoading, addToast, dispatch]);

  const NameItem = ({ name }) => {
    const [isHover, setIsHover] = useState(false);
    return (
      <Stack
        direction="row"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        height="24px"
        border="1px solid"
        borderColor="colors.ui_border"
        backgroundColor="colors.grey_bg"
        borderRadius="4px"
        padding="4px">
        <RenderStr value={name} />
        {isHover && (
          <IconButtonComponent
            tag="Remove"
            sx={{ marginLeft: 'auto' }}
            onClick={() => dispatch(removeExtractedName({ name }))}>
            <DeleteIcon />
          </IconButtonComponent>
        )}
      </Stack>
    );
  };

  NameItem.propTypes = {
    name: PropTypes.string.isRequired
  };

  return (
    <ModalComponent
      title={title}
      open={isOpen}
      onClose={closeDialog}
      maxWidth={600}
      maxHeight={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDownloadFile} disabled={!companyNames}>
            Download CSV
          </Button>
          <Button variant="contained" onClick={handleAddList} disabled={!companyNames}>
            Create List
          </Button>
        </Stack>
      }>
      <Stack
        direction="column"
        gap="16px"
        sx={{ padding: '24px 48px' }}
        alignItems={'center'}
        justifyContent="center">
        {isLoading && (
          <Box height="100px" padding="48px">
            <div className="loader-component" style={{ height: '100px' }}>
              <div className="loader" />
            </div>{' '}
          </Box>
        )}
        {isLoading && <Typography>Extracting names from data...</Typography>}
        {companyNames && (
          <Stack direction="column" gap="16px">
            <Typography variant="paragraphSemiBold">
              We found {companyNames.length} company names in the selected data:
            </Typography>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {companyNames.map((name, index) => (
                <Grid key={index} item xs={6}>
                  <NameItem name={name} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
    </ModalComponent>
  );
}

CreateListFromCompaniesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  columnName: PropTypes.string,
  listId: PropTypes.string
};

export default CreateListFromCompaniesDialog;
