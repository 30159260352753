import { getGridStringOperators } from '@mui/x-data-grid-pro';
import RenderAddColumnHeader from 'components/elements/table/RenderAddColumnHeader';
import RenderAvatar from 'components/elements/table/RenderAvatar';
import RenderBulkCompanyName from 'components/elements/table/RenderBulkCompanyNameConnector';
import RenderBulkReportHeader from 'components/elements/table/RenderBulkReportHeaderConnector';
import RenderButton from 'components/elements/table/RenderButton';
import RenderChip from 'components/elements/table/RenderChip';
import RenderChips from 'components/elements/table/RenderChips';
import RenderCompanySource from 'components/elements/table/RenderCompanySource';
import RenderCustomAnalyticsHeader from 'components/elements/table/RenderCustomAnalyticsHeader';
import RenderCustomAnalyticsOutput from 'components/elements/table/RenderCustomAnalyticsOutput';
import { RenderCustomBoolean } from 'components/elements/table/RenderCustomBoolean';
import { RenderCustomNumber } from 'components/elements/table/RenderCustomNumber';
import RenderDatetime from 'components/elements/table/RenderDatetime';
import RenderDescription from 'components/elements/table/RenderDescription';
import RenderDescriptionWrap from 'components/elements/table/RenderDescriptionWrap';
import RenderDiscoveryInput from 'components/elements/table/RenderDiscoveryInput';
import RenderFiles from 'components/elements/table/RenderFiles';
import RenderGeneralColumnsOrComponent from 'components/elements/table/RenderGeneralColumnsOrComponent';
import { generatePartialRenderGenericExtraData } from 'components/elements/table/RenderGenericExtraData';
import RenderGrowthSignals from 'components/elements/table/RenderGrowthSignals';
import RenderLeadInvestors from 'components/elements/table/RenderLeadInvestors';
import RenderLeaders from 'components/elements/table/RenderLeaders';
import RenderLeftLeadership from 'components/elements/table/RenderLeftLeadership';
import RenderLevel from 'components/elements/table/RenderLevel';
import RenderLocations from 'components/elements/table/RenderLocations';
import RenderLogo from 'components/elements/table/RenderLogo';
import RenderMultipleUrlIcons from 'components/elements/table/RenderMultipleUrlIcons';
import RenderNumberWithDelimeters from 'components/elements/table/RenderNumberWithDelimeters';
import RenderPartnershipLevel from 'components/elements/table/RenderPartnershipLevel';
import RenderPeerCompanyName from 'components/elements/table/RenderPeerCompanyNameConnector';
import RenderPeerRevenuesV2 from 'components/elements/table/RenderPeerRevenuesV2';
import RenderRevPerHead from 'components/elements/table/RenderRevPerHead';
import RenderRevenues from 'components/elements/table/RenderRevenues';
import RenderRunningRow from 'components/elements/table/RenderRunnningRow';
import RenderStr from 'components/elements/table/RenderStr';
import RenderTextWithTooltip from 'components/elements/table/RenderTextWithTooltip';
import RenderUrl from 'components/elements/table/RenderUrl';
import RenderUrlIcon from 'components/elements/table/RenderUrlIcon';
import RenderVariableHeader from 'components/elements/table/RenderVariableHeader';

import { COLUMN_TYPES, COLUMN_TYPE_TO_TITLE } from './explore-table-columns';
import { EXTRA_DATA_COLUMN_TYPE } from './extra-data-utils';
import { getConsensusFromData } from './finance-utils';
import { localeStringToNumber, removeMarkdown, underscoreToTitle } from './string-utils';

/* eslint-disable max-lines */

const COLUMN_WIDTHS = {
  DiscoveryTable: {
    discovered: 110,
    add_column: 40
  }
};
const COLUMN_MIN_WIDTHS = {
  DiscoveryTable: {
    discovered: 110,
    company_name: 250,
    description: 250,
    summary: 250,
    year_founded: 120,
    round: 130,
    industries: 200,
    hq: 150,
    region: 150,
    iteration_added: 90,
    revenues: 125,
    lead_investors: 125,
    company_source: 150,
    created_at: 100,
    custom_analytics: 250,
    last_equity_round_message: 250,
    SAP_PARTNERSHIP_LEVEL: 110,
    AWS_PARTNERSHIP_LEVEL: 110,
    PITCHBOOK_SCRAPINGS: 126
  }
};
const COLUMN_FLEXS = {
  FullLinkedinTable: {
    person_name: 1,
    person_position: 1.5
  },
  TechPatentsTable: {
    title: 2
  },
  SummaryIPTable: {
    title: 2,
    technology: 2
  },
  CommercialXPTable: {
    job_title: 2,
    total_commercial_experience: 0.5,
    total_experience: 0.5
  },
  PublicEngagementsTable: {
    headline: 3,
    date: 0.5
  },
  RiskEventsSubTable: {
    Headline: 3
  },
  ProductTable: {
    Product: 2,
    'Most Descriptive Words': 3,
    Description: 4
  },
  ProductLaunchTable: {
    Headline: 3
  },
  InstitutionalInvestorsTable: {
    Shareholder: 2
  },
  FundingRoundsTable: {
    Investors: 3
  },
  StackQuestionsTable: {
    title: 2,
    view_count: 0.5,
    sentiment: 0.5,
    creation_date: 0.5
  },
  G2ProductReviewTable: {
    Title: 3,
    'Star Rating': 0.5,
    Date: 0.5,
    Sentiment: 0.5,
    Reviewer: 0.5,
    Role: 1,
    'Company Size': 0.5
  },
  ProductReviewTable: {
    Title: 3,
    'Star Rating': 0.5,
    'API Type': 0.5,
    Date: 0.5,
    Sentiment: 0.5,
    Reviewer: 1,
    Role: 1,
    Likes: 0.5,
    'Company Size': 0.5
  },
  VoiceOfCustomerOverviewTable: {
    title: 3,
    body: 3,
    date: 1,
    media_type: 0.5,
    source: 0.5,
    sentiment: 0.5
  },
  GrowthSignalsOverviewTable: {
    title: 3,
    date: 1,
    media_type: 1,
    source: 1,
    sentiment: 1.5
  },
  RiskAndHealthOverviewTable: {
    title: 3,
    date: 1,
    media_type: 1,
    source: 1,
    sentiment: 1.5
  },
  CapabilityOverviewTable: {
    title: 1,
    text: 3,
    media_type: 1,
    is_key: 1
  },
  IPTechOverviewTable: {
    date: 1,
    title: 4,
    media_type: 1
  },
  CompetitorsTable: {
    name: 2,
    tags: 5,
    size: 1,
    similarity: 1
  },
  RedditPostsTable: {
    title: 2,
    upvotes: 0.5,
    sentiment: 0.5,
    creation_date: 0.5,
    upvote_ratio: 0.5,
    num_comments: 0.5
  },
  B2DMentionsTable: {
    title: 2,
    body: 1,
    sentiment: 0.5,
    creation_date: 0.5,
    source: 1
  },
  DiscoveryTable: {
    company_name: 3,
    description: 3,
    summary: 3,
    disableFlex: {
      company_name: true,
      company_urls: true
    }
  }
};

export const determineRenderCell = (data_type, parent_type, extraParams) => {
  let Component = null;
  switch (data_type) {
    case 'url_icon':
      Component = RenderUrlIcon;
      break;
    case 'multiple_url_icons':
      Component = RenderMultipleUrlIcons;
      break;
    case 'url':
      Component = RenderUrl;
      break;
    case 'avatar':
      Component = RenderAvatar;
      break;
    case 'logo':
      Component = RenderLogo;
      break;
    case 'chips':
      Component = RenderChips;
      break;
    case 'datetime':
      Component = RenderDatetime;
      break;
    case 'str':
      Component = RenderStr;
      break;
    case 'custom_str':
      Component = RenderCustomAnalyticsOutput;
      break;
    case 'bulk_company_name':
      Component = RenderBulkCompanyName;
      break;
    case 'peer_company_name':
      Component = RenderPeerCompanyName;
      break;
    case 'chip':
      Component = RenderChip;
      break;
    case 'level':
      Component = RenderLevel;
      break;
    case 'description':
      Component = RenderDescription;
      break;
    case 'description_wrap':
      Component = RenderDescriptionWrap;
      break;
    case 'LEFT_LEADERSHIP':
      Component = RenderLeftLeadership;
      break;
    case 'revenues':
      Component = RenderRevenues;
      break;
    case 'peer_revenues':
      Component = RenderPeerRevenuesV2;
      break;
    case 'lead_investors':
      Component = RenderLeadInvestors;
      break;
    case 'company_source':
      Component = RenderCompanySource;
      break;
    case 'summary':
      Component = RenderDescription;
      break;
    case 'running':
      Component = RenderRunningRow;
      break;
    case 'files':
      Component = RenderFiles;
      break;
    case 'rev_per_head':
      Component = RenderRevPerHead;
      break;
    case 'number_with_delimeters':
      Component = RenderNumberWithDelimeters;
      break;
    case 'leaders':
      Component = RenderLeaders;
      break;
    case 'custom_analytics':
      Component = RenderCustomAnalyticsOutput;
      break;
    case 'predefined_custom_analytics':
      Component = RenderCustomAnalyticsOutput;
      break;
    case 'custom_number':
      Component = RenderCustomNumber;
      break;
    case 'custom_boolean':
      Component = RenderCustomBoolean;
      break;
    case 'basic_data_column':
      Component = RenderCustomAnalyticsOutput;
      break;
    case 'text_with_tooltip':
      Component = RenderTextWithTooltip;
      break;
    case 'GROWTH_SIGNALS':
      Component = RenderGrowthSignals;
      break;
    case 'SAP_PARTNERSHIP_LEVEL':
      Component = RenderPartnershipLevel;
      break;
    case 'AWS_PARTNERSHIP_LEVEL':
      Component = RenderPartnershipLevel;
      break;
    case 'discovery_input':
      Component = RenderDiscoveryInput;
      break;
    case 'button':
      Component = RenderButton;
      break;
    case 'locations':
      Component = RenderLocations;
      break;
    case 'finance_column':
      Component = RenderRevenues;
      break;
    default:
      if (parent_type && parent_type === EXTRA_DATA_COLUMN_TYPE) {
        Component = generatePartialRenderGenericExtraData(extraParams);
      }
      break;
  }
  return Component
    ? (props) => <RenderGeneralColumnsOrComponent Component={Component} {...props} />
    : null;
};
export const determineRenderHeader = (data_type) => {
  let Component = null;
  switch (data_type) {
    case 'bulk_company_name':
      Component = RenderBulkReportHeader;
      break;
    case 'variable':
      Component = RenderVariableHeader;
      break;
    case 'add_column':
      Component = RenderAddColumnHeader;
      break;
    case 'custom_analytics':
      Component = RenderCustomAnalyticsHeader;
      break;
    default:
      break;
  }

  return Component ? (props) => <Component {...props} /> : null;
};

export const determineCellBorder = (elementId) => {
  switch (elementId) {
    case 'DiscoveryTable':
      return true;
    default:
      return false;
  }
};

export const determinAlign = (col, tableIcons) => {
  if (col.data_type === 'url_icon') {
    if (tableIcons[col.icon]) {
      return tableIcons[col.icon]?.align ? tableIcons[col.icon]?.align : null;
    }
  }
  if (col.data_type === 'description') {
    return 'right';
  }
  return null;
};

const getMappedSize = (size) => {
  switch (size) {
    case '1 - 50': {
      return 4;
    }
    case '51 - 500': {
      return 3;
    }
    case '501 - 5000': {
      return 2;
    }
    case '5001+': {
      return 1;
    }
    default: {
      return 5;
    }
  }
};

const getMappedRevenueRange = (range) => {
  switch (range) {
    case '<$1M': {
      return 8;
    }
    case '$1M-$10M': {
      return 7;
    }
    case '$10M-$50M': {
      return 6;
    }
    case '$50M-$100M': {
      return 5;
    }
    case '$100M-$500M': {
      return 4;
    }
    case '$500M-$1B': {
      return 3;
    }
    case '$1B-$10B': {
      return 2;
    }
    case '$>10B': {
      return 1;
    }
    default: {
      return 9;
    }
  }
};

/*
  When the value is null we would like it to be last in the sorting.
  We need to know the sorting order (Asc/Desc) in order to know if
  to tag it higher or lower in the order.
*/
const sortDefault = (company1, company2, param1) => {
  const isDesc = param1.api.state.sorting.sortModel[0].sort === 'desc';
  const isComapny1Valid =
    company1 && (typeof company1 === 'string' || typeof company1 === 'number');
  const isComapny2Valid =
    company2 && (typeof company2 === 'string' || typeof company2 === 'number');
  if (!isComapny1Valid && !isComapny2Valid) return 0;
  if (!isComapny1Valid) return isDesc ? -1 : 1;
  if (!isComapny2Valid) return isDesc ? 1 : -1;
  return company1.localeCompare(company2);
};

const sortNumber = (company1, company2, param1) => {
  if (!company1 || !company2) {
    return sortDefault(company1, company2, param1);
  }
  return company2 - company1;
};

const sortSize = (size1, size2, param1) => {
  if (!size1 || !size2) {
    return sortDefault(size1, size2, param1);
  }
  return getMappedSize(size1) - getMappedSize(size2);
};

const sortCompanyName = (company1, company2, param1) => {
  return sortDefault(company1?.name, company2?.name, param1);
};

const sortChip = (company1, company2, param1) => {
  return sortDefault(company1.text, company2.text, param1);
};

const sortClusters = (company1, company2, param1) => {
  if (!company1?.clusters || !company2?.clusters) {
    return sortDefault(company1?.clusters, company2?.clusters, param1);
  }
  return company1.clusters.localeCompare(company2.clusters);
};

const sortCBRevenues = (range1, range2, param1) => {
  if (!range1 || !range2) {
    return sortDefault(range1, range2, param1);
  }
  return getMappedRevenueRange(range1) - getMappedRevenueRange(range2);
};

const sortYahoo = (number1, number2, param1) => {
  if (!number1 || !number2) {
    return sortDefault(number1, number2, param1);
  }
  const get_amount = (value) => {
    let newVal = localeStringToNumber(value.replace('$', '').replace('<', ''));
    newVal = parseFloat(newVal, 10);
    // eslint-disable-next-line require-unicode-regexp
    if (value.includes('<')) {
      newVal -= 0.0001;
    }
    return newVal;
  };

  const formatted1 = get_amount(number1);
  const formatted2 = get_amount(number2);

  return formatted2 - formatted1;
};

const sortRevenues = (obj1, obj2, param1) => {
  const get_amount = (obj) => {
    const consensus = getConsensusFromData(obj?.data);
    const clean = consensus?.clean_value_amount;
    if (clean) {
      return clean;
    }
    const lower = consensus?.value_range_lower;
    if (lower) {
      return lower + 0.0001;
    }
    const upper = consensus?.value_range_upper;
    if (upper) {
      return upper - 0.0001;
    }
    return 0;
  };

  const finalValue1 = get_amount(obj1);
  const finalValue2 = get_amount(obj2);
  return sortNumber(finalValue1, finalValue2, param1);
};

const sortLeadInvestors = (company1, company2, param1) => {
  const numInvestors1 = company1?.length;
  const numInvestors2 = company2?.length;
  return sortNumber(numInvestors1, numInvestors2, param1);
};

const sortCompanySource = (company1, company2, param1) => {
  const timeAdded1 = company1?.time_submitted;
  const timeAdded2 = company2?.time_submitted;
  if (!timeAdded1 || !timeAdded2) {
    return sortDefault(timeAdded1, timeAdded2, param1);
  }
  return new Date(timeAdded2) - new Date(timeAdded1);
};

const sortCreatedAt = (company1, company2, param1) => {
  if (!company1 || !company2) {
    return sortDefault(company1, company2, param1);
  }

  const company1Parts = company1.split('.');
  const company2Parts = company2.split('.');

  return (
    new Date(company2Parts[2], parseInt(company2Parts[1], 10) - 1, company2Parts[0]) -
    new Date(company1Parts[2], parseInt(company1Parts[1], 10) - 1, company1Parts[0])
  );
};

const sortNumberWithCommas = (company1, company2, param1) => {
  const num1 = company1 && Math.round(localeStringToNumber(company1));
  const num2 = company2 && Math.round(localeStringToNumber(company2));
  return sortNumber(num1, num2, param1);
};

const sortCustomAnalytics = (company1, company2, param1) => {
  return sortDefault(
    removeMarkdown(company1?.value?.answer),
    removeMarkdown(company2?.value?.answer),
    param1
  );
};
const sortCustomStr = (company1, company2, param1) => {
  return sortDefault(company1?.value, company2.value, param1);
};
const sortCustomNumber = (company1, company2, param1) => {
  return sortNumber(company1?.value, company2.value, param1);
};

const sortBoolean = (company1, company2, param1) => {
  const toNum = (value) => {
    if (value) return 1;
    if (value === false) return -1;
    return null;
  };
  return sortNumber(toNum(company1?.value), toNum(company2.value), param1);
};

const sortTextWithTooltip = (company1, company2, param1) => {
  return sortDefault(company1?.data, company2?.data, param1);
};

const sortGrowthSignals = (company1, company2, param1) => {
  return sortNumber(company1?.value?.percentage, company2?.value?.percentage, param1);
};

const sortPartnershipLevel = (column1, column2, param1) => {
  return sortDefault(column1?.value?.partnership_level, column2?.value?.partnership_level, param1);
};

const COLUMN_SORTS = {
  CompetitorsTable: {
    size: sortSize
  },
  DiscoveryTable: {
    employees: sortSize,
    company_name: sortCompanyName,
    discovered: sortChip,
    year_founded: sortNumber,
    iteration_added: sortNumber,
    clusters: sortClusters,
    cb_revenue_range: sortCBRevenues,
    revenues: sortRevenues,
    num_employees: sortNumberWithCommas,
    total_funding: sortYahoo,
    lead_investors: sortLeadInvestors,
    company_source: sortCompanySource,
    created_at: sortCreatedAt,
    leaders: sortLeadInvestors,
    custom_analytics: sortCustomAnalytics,
    predefined_custom_analytics: sortCustomAnalytics,
    custom_str: sortCustomStr,
    custom_number: sortCustomNumber,
    custom_boolean: sortBoolean,
    last_equity_round_type: sortTextWithTooltip,
    GROWTH_SIGNALS: sortGrowthSignals,
    SAP_PARTNERSHIP_LEVEL: sortPartnershipLevel,
    AWS_PARTNERSHIP_LEVEL: sortPartnershipLevel,
    finance_column: sortRevenues,
    default: sortDefault
  }
};

export function getColumnWidth(elementId, columnId, width) {
  if (width) {
    return width;
  }
  if (COLUMN_WIDTHS[elementId] && COLUMN_WIDTHS[elementId][columnId]) {
    return COLUMN_WIDTHS[elementId][columnId];
  }
  if (COLUMN_WIDTHS[elementId]?.allFields) {
    return COLUMN_WIDTHS[elementId].allFields;
  }
  return null;
}

export function getColumnMinWidth(elementId, columnId, dataType) {
  if (dataType?.includes('custom_analytics')) {
    return COLUMN_MIN_WIDTHS[elementId].custom_analytics;
  }
  if (COLUMN_MIN_WIDTHS[elementId]?.[columnId]) {
    return COLUMN_MIN_WIDTHS[elementId][columnId];
  }
  if (COLUMN_WIDTHS[elementId]?.allFields) {
    return COLUMN_WIDTHS[elementId].allFields;
  }
  return 100;
}
export function getColumnFlex(elementId, columnId, width) {
  if (width) {
    return null;
  }
  if (COLUMN_FLEXS[elementId] && COLUMN_FLEXS[elementId][columnId]) {
    return COLUMN_FLEXS[elementId][columnId];
  }
  if (COLUMN_FLEXS[elementId]?.disableFlex?.[columnId]) return null;
  return 1;
}
export function getColumnSort(elementId, columnId, dataType) {
  if (COLUMN_SORTS[elementId] && COLUMN_SORTS[elementId][columnId]) {
    return COLUMN_SORTS[elementId][columnId];
  }
  if (COLUMN_SORTS[elementId] && COLUMN_SORTS[elementId][dataType]) {
    return COLUMN_SORTS[elementId][dataType];
  }
  if (COLUMN_SORTS[elementId] && COLUMN_SORTS[elementId]?.default) {
    return COLUMN_SORTS[elementId].default;
  }
  return null;
}
export function stringFilterOperatorsByObjectKey(key) {
  return getGridStringOperators().map((operator) => {
    return {
      ...operator,
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          return operator?.getApplyFilterFn?.(filterItem)
            ? operator?.getApplyFilterFn?.(filterItem)?.({ ...params, value: params.value?.[key] })
            : true;
        };
      }
    };
  });
}

export const columnIdToTitle = (columnId) => {
  const columnType = Object.keys(COLUMN_TYPES).find((id) => COLUMN_TYPES[id] === columnId);
  if (!columnType) {
    return underscoreToTitle(columnId);
  }
  return COLUMN_TYPE_TO_TITLE?.[columnType] || underscoreToTitle(columnId);
};
