import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { loadRunSummary } from 'actions/runs/load-run-summary-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import CompanyActionsComponent from 'components/summary/company/CompanyActionsComponent';
import CompanyDetailsComponent from 'components/summary/company/CompanyDetailsComponent';
import SummaryCompanyDescriptionComponent from 'components/summary/company/SummaryCompanyDescriptionComponent';
import { dispatch } from 'hooks/AppStateProvider';
import { useOnError } from 'hooks/OnErrorProvider';
import { useAppState } from 'hooks/state-context';
import { useScrollPositionFilterByDirection } from 'hooks/useScrollPositionFilterByDirection';
import BackIcon from 'icons/BackIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getDeepDiveIsPublicMode } from 'selectors/deep-dive';
import { getRunCompanyLogoUrlById, getRunCompanyNameById } from 'selectors/runs';

const SCROLL_THRESHOLD = 150;

function CompanySummaryComponent({
  recordId,
  backTag,
  backHref,
  addBottomPadding,
  insightsbyGroups,
  elementsByGroups,
  doNotFetchData,
  is_deep_dive_screen
}) {
  const { state } = useAppState();
  const isPublicMode = useSelector(getDeepDiveIsPublicMode);
  const { forbiddenResource } = useOnError();
  const scrollPosition = useScrollPositionFilterByDirection(
    'CompanySummaryComponent',
    is_deep_dive_screen ? 'deep-dive-screen' : 'root'
  );
  const scrollPercentage = 1 - Math.min(1, scrollPosition / SCROLL_THRESHOLD);
  const name = getRunCompanyNameById(state, recordId);
  const logoUrl = getRunCompanyLogoUrlById(state, recordId);
  useEffect(() => {
    if (doNotFetchData) return;
    dispatch(loadRunSummary(recordId, forbiddenResource));
  }, [recordId, doNotFetchData, forbiddenResource]);

  return (
    <Stack
      direction="row"
      gap="16px"
      sx={{
        display: {
          xs: 'none',
          md: 'flex'
        },
        backgroundColor: 'primary.primary1',
        position: top === 0 ? 'initial' : 'sticky',
        top: is_deep_dive_screen ? 0 : '50px',
        zIndex: 'overviewSummary',
        paddingBottom: addBottomPadding ? `${Math.max(0, 32 * scrollPercentage)}px` : 0
      }}>
      <Box
        component="img"
        src={logoUrl}
        sx={{
          width: `80px`,
          height: `80px`,
          paddingRight: 0,
          paddingLeft: '24px',
          paddingBottom: 'auto',
          paddingTop: '24px',
          margin: 'auto'
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{ width: '1px', borderColor: 'secondary.secondary4', marginTop: '24px' }}
      />
      {!isPublicMode && backHref && (
        <IconButtonComponent
          variant="primary"
          tag={backTag}
          sx={{ alignSelf: 'flex-start', marginTop: '24px', padding: 0 }}>
          <Link component={RouterLink} to={backHref} color="colors.text_on_primary">
            <BackIcon />
          </Link>
        </IconButtonComponent>
      )}
      <Stack
        direction="column"
        gap="16px"
        sx={{
          flex: 1,
          gap: '16px',
          margin: '16px 16px 0 0'
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px'
          }}>
          <Typography
            variant="h1"
            color="colors.text_on_primary"
            textAlign="left"
            sx={{ flex: 1, textAlign: 'left' }}>
            {name}
          </Typography>
          <CompanyActionsComponent
            recordId={recordId}
            insightsbyGroups={insightsbyGroups}
            elementsByGroups={elementsByGroups}
          />
        </Box>
        <CompanyDetailsComponent recordId={recordId} />
        <SummaryCompanyDescriptionComponent
          recordId={recordId}
          scrollThreshold={SCROLL_THRESHOLD}
          is_deep_dive_screen={is_deep_dive_screen}
        />
      </Stack>
    </Stack>
  );
}

CompanySummaryComponent.propTypes = {
  recordId: PropTypes.string,
  backTag: PropTypes.string,
  backHref: PropTypes.string,
  addBottomPadding: PropTypes.bool,
  insightsbyGroups: PropTypes.object,
  elementsByGroups: PropTypes.object,
  doNotFetchData: PropTypes.object,
  is_deep_dive_screen: PropTypes.bool
};

export default wrapWithError(CompanySummaryComponent);
