import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { getPusherClient } from 'hooks/PusherProvider';
import throttle from 'lodash/throttle';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostRecordIdToRecords,
  addPostRecordIdToThread,
  addPostStatusToThread,
  getThreadKey,
  updatePostMessagesInThread,
  updatePostRecordIsRunning,
  updateThreadProccessingMode
} from 'reducer/custom-analytics';
import {
  getCustomAnalyticsThreadIdById,
  getCustomAnalyticsThreadSpecificPostById
} from 'selectors/custom-analytics';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getPostProgressChannelName } from 'utils/pusher-utils';

function fetchCustomAnalyticsRecordData(threadKey, postId, customAnalyticsecordId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.customAnalytics
      .getCustomAnalyticsRecordData(customAnalyticsecordId)
      .then((res) => {
        if (res.ok) {
          const {
            thread_id: threadId,
            message,
            messages,
            response_type: responseType,
            plan,
            image
          } = res.data;
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message,
              threadId,
              messages,
              responseType,
              image,
              plan
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' }
            })
          );
        }
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: '100%'
          })
        );
      })
      .catch(() => {
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: '100%'
          })
        );
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' }
          })
        );
      });
  };
}
const throttleFetch = throttle(
  (dispatch, apiClient, threadKey, postId, customAnalyticsRecordId) => {
    apiClient.customAnalytics.getCustomAnalyticsRecordData(customAnalyticsRecordId).then((res) => {
      if (res.ok) {
        const { messages } = res.data;
        if (messages) {
          dispatch(updatePostMessagesInThread({ threadKey, postId, messages }));
        }
      }
    });
  },
  2000
);

function fetchCustomAnalyticsRecordDataAndUpdateMessages(
  threadKey,
  postId,
  customAnalyticsRecordId
) {
  return (dispatch, _getState, apiClient) => {
    throttleFetch(dispatch, apiClient, threadKey, postId, customAnalyticsRecordId);
  };
}

export function pusherBindToCARecordId({
  listId,
  recordId,
  companyMetaId,
  threadKey,
  postId,
  isWorkFlowPlan
}) {
  return (dispatch, getState) => {
    const state = getState();
    const pusher = getPusherClient();
    const channelName = getPostProgressChannelName(postId);
    const channel = pusher.subscribe(channelName);
    channel.bind('partial_result', (data) => {
      const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
      if (post?.messages) {
        dispatch(fetchCustomAnalyticsRecordDataAndUpdateMessages(threadKey, postId, recordId));
        dispatch(
          addPostRecordIdToRecords({ threadKey, postId, customAnalyticsRecordId: recordId })
        );
      } else {
        const companies = listId
          ? getCompanyMetasByListId(state, listId)
          : [{ company_meta_id: companyMetaId }];
        const messages = (companies || []).map((company) => ({
          company_meta_id: company.company_meta_id,
          loading: true
        }));
        dispatch(updatePostMessagesInThread({ threadKey, postId, messages }));
        dispatch(fetchCustomAnalyticsRecordDataAndUpdateMessages(threadKey, postId, recordId));
      }
      dispatch(
        addPostStatusToThread({
          threadKey,
          postId,
          status: '',
          statusMessage: `${data.n_completed_companies} company results`
        })
      );
    });
    channel.bind('progress', (data) => {
      if (data.progress === 1) {
        pusher.unsubscribe(channelName);
        dispatch(fetchCustomAnalyticsRecordData(threadKey, postId, recordId));
        dispatch(
          updatePostRecordIsRunning({
            threadKey,
            postId,
            customAnalyticsRecordId: recordId,
            isRunning: false
          })
        );
      } else if (!isWorkFlowPlan) {
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: `${Math.round(data.progress * 100)}%`,
            statusMessage: data.message
          })
        );
      }
    });
  };
}

export function createNewPostInCustomAnalyticsThreadAsyncAction({
  listId,
  companyMetaId,
  query,
  useColumn,
  answerType,
  useGpt4,
  usePlanner,
  selectedCompanies,
  temperature,
  folderId,
  supportingResourceId,
  columns,
  forEachCompany,
  isSample,
  useTable,
  saved_query_id,
  action,
  plan,
  givenPostId
}) {
  return (dispatch, getState, apiClient) => {
    const threadKey = getThreadKey(listId, companyMetaId);
    const currentThreadId = getCustomAnalyticsThreadIdById(getState(), threadKey);
    const postId = givenPostId || new Date().getTime();
    const body = {
      question: query,
      company_list_id: listId,
      post_id: postId,
      is_table_question: useTable
    };
    if (folderId) {
      body.folder_id = folderId;
    }
    if (currentThreadId) {
      body.threadId = currentThreadId;
    }
    if (useColumn?.id) {
      body.column_id = useColumn?.id;
    }
    if (useGpt4) {
      body.model = CHAT_GPT_MODEL_VERSION;
    }
    if (usePlanner) {
      body.action = 'generate_plan_and_execute';
    }
    if (temperature) {
      body.temperature = temperature / 10;
    }
    if (answerType) {
      body.answer_type = answerType;
    }
    if (selectedCompanies) {
      body.company_meta_ids = selectedCompanies;
    }
    if (supportingResourceId) {
      body.supporting_resource_id = supportingResourceId;
    }
    if (columns) {
      body.columns = columns;
    }
    if (forEachCompany) {
      body.for_each_company = forEachCompany;
    }
    if (isSample) {
      body.is_sample = isSample;
    }
    if (saved_query_id) {
      body.saved_query_id = saved_query_id;
    }
    if (action) {
      body.action = action;
    }
    if (plan) {
      body.plan = plan;
    }
    dispatch(addPostQueryToThread({ threadKey, postId, query, body }));
    // dispatch(updateThreadProccessingMode({ threadKey, isProccessing: true }));
    apiClient.customAnalytics
      .queryCustomAnalyticsAssistantAsync(body)
      .then((res) => {
        if (res.ok) {
          const customAnalyticsRecordId = res.data.record_id;
          dispatch(addPostRecordIdToThread({ threadKey, postId, customAnalyticsRecordId }));
          dispatch(
            pusherBindToCARecordId({
              listId,
              recordId: customAnalyticsRecordId,
              threadKey,
              postId,
              companyMetaId,
              isWorkFlowPlan: Boolean(action)
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' }
            })
          );
        }
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      })
      .catch(() => {
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' }
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      });
  };
}
