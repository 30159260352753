import PropTypes from 'prop-types';
import React from 'react';

export default function FlagIcon({ color }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M2.5 9.375C2.5 9.375 3.125 8.75 5 8.75C6.875 8.75 8.125 10 10 10C11.875 10 12.5 9.375 12.5 9.375V1.875C12.5 1.875 11.875 2.5 10 2.5C8.125 2.5 6.875 1.25 5 1.25C3.125 1.25 2.5 1.875 2.5 1.875V9.375ZM2.5 9.375V13.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
FlagIcon.defaultProps = {
  color: '#00BD84'
};
FlagIcon.propTypes = {
  color: PropTypes.string
};
