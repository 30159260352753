import { Button, Menu, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { appTheme } from 'theme';
import { underscoreToTitle } from 'utils/string-utils';

import TableRowLoading from './TableRowLoading';

const renderFieldValue = (value, field) => {
  if (!field) {
    return value;
  }
  const { field_type, primary_subfield } = field;
  if (field_type === 'list') {
    return value.join(', ');
  } else if (field_type === 'list_object' && primary_subfield) {
    return value.map((item) => item?.[primary_subfield]).join(', ');
  }
  return value;
};

export default function RenderGenericExtraData({ value, color, extraParams }) {
  const loading = value?.value?.loading;
  const [showTooltip, setShowTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  const data = value?.value;
  const fields = extraParams?.info?.fields;
  const fieldsWithData =
    data &&
    fields &&
    Object.values(fields || {}).filter((field) => (data || {})?.[field.id]?.length);
  const thereIsNoData = !fieldsWithData || fieldsWithData.length === 0;

  if (!data) {
    return null;
  }
  if (thereIsNoData) {
    return (
      <Stack width="100%" padding={0}>
        <Stack direction="row" alignItems="center" width="100%" gap="8px" paddingX="8px">
          <Typography variant="paragraph" color={color} noWrap display="block">
            -
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' },
          textTransform: 'none'
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          gap="8px"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography variant="paragraph" color={color} noWrap display="block">
            {fieldsWithData.length} data field{fieldsWithData.length > 1 ? 's' : ''}
          </Typography>
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        slotProps={{
          paper: {
            style: {
              backgroundColor: appTheme.palette.secondary.secondary1,
              minWidth: '170px',
              maxWidth: '340px',
              maxHeight: '360px',
              padding: '12px'
            }
          }
        }}
        MenuListProps={{
          style: {
            backgroundColor: appTheme.palette.secondary.secondary1,
            padding: 0,
            marging: 0
          }
        }}>
        <Stack
          sx={{
            backgroundColor: 'secondary.secondary1',
            color: 'white'
          }}>
          {fieldsWithData.map((field, index) => {
            return (
              <Stack
                key={index}
                sx={{
                  paddingTop: index === 0 ? '0px' : '12px',
                  paddingBottom: index === fieldsWithData.length - 1 ? '0px' : '12px',
                  borderBottom: index === fieldsWithData.length - 1 ? null : '1px solid',
                  borderBottomColor: 'colors.primary_text'
                }}>
                <Typography
                  variant="paragraphMedium"
                  color="greyColors.grey150"
                  sx={{ lineHeight: '20px' }}>
                  {field.name || underscoreToTitle(field.id)}
                </Typography>
                <Typography variant="paragraph" sx={{ lineHeight: '20px' }}>
                  {renderFieldValue(data[field.id], field)}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Menu>
    </Stack>
  );
}

export const generatePartialRenderGenericExtraData = (extraParams) => {
  return function partialComponent(props) {
    return <RenderGenericExtraData {...props} extraParams={extraParams} />;
  };
};

RenderGenericExtraData.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  extraParams: PropTypes.object
};

RenderGenericExtraData.defaultProps = {
  color: 'colors.primary_text',
  variant: 'paragraph'
};
