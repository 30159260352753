import React from 'react';

export default function CheckIcon2() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6.667L8.68174 13.775C8.49206 13.9884 8.16249 13.9999 7.95839 13.8002L5 10.9061"
        stroke="#5C5E76"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}
