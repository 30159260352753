import { Box, Tooltip, Typography } from '@mui/material';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function RenderAddColumnHeader({ colDef }) {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const listId = colDef.field;
  const addNewColumn = () => dispatch(addExploreCustomColumnAction(listId));

  return (
    <Tooltip title="Add new text column" arrow={true} open={showTooltip}>
      <Box
        onClick={addNewColumn}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        sx={{
          cursor: 'pointer',
          padding: '8px',
          '&:hover': { backgroundColor: 'colors.selected' }
        }}>
        <Typography variant="h2" color="colors.primary_text">
          +
        </Typography>
      </Box>
    </Tooltip>
  );
}

RenderAddColumnHeader.propTypes = {
  colDef: PropTypes.object
};
