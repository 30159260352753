import { Tab, Tabs, Typography, useTheme } from '@mui/material';
import { VIEW_TYPES, VIEW_TYPES_BY_ORDER, getIsTabNew } from 'constants/discovery';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedView } from 'reducer/explore-table-ui';
import { getExploreListSelectedView } from 'selectors/explore-table-ui';
import { getUserFeatureFlags } from 'selectors/user';

const TABS = Object.freeze({
  List: 'List',
  Cluster: 'Clusters',
  Domain_Knowledge: 'Knowledge',
  Domain_Report: 'Report',
  Domain_Research: 'Domain Map'
});

const TABS_TO_VIEW_TYPE_MAP = Object.freeze({
  [TABS.List]: VIEW_TYPES.TABLE,
  [TABS.Cluster]: VIEW_TYPES.CLUSTERS,
  [TABS.Domain_Knowledge]: VIEW_TYPES.DOMAIN_KNOWLEDGE,
  [TABS.Domain_Report]: VIEW_TYPES.DOMAIN_REPORT,
  [TABS.Domain_Research]: VIEW_TYPES.DOMAIN_RESEARCH
});

const determineTabs = (canDomainReport) => {
  const tabs = [
    { name: TABS.List },
    { name: TABS.Domain_Research },
    { name: TABS.Cluster },
    { name: TABS.Domain_Knowledge }
  ];
  if (canDomainReport) {
    tabs.push({ name: TABS.Domain_Report });
  }

  return tabs.sort(
    ({ name: name1 }, { name: name2 }) =>
      VIEW_TYPES_BY_ORDER.indexOf(TABS_TO_VIEW_TYPE_MAP[name1]) -
      VIEW_TYPES_BY_ORDER.indexOf(TABS_TO_VIEW_TYPE_MAP[name2])
  );
};
export function DiscoveryViewTabs({ listId }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { state } = useAppState();
  const appTheme = useTheme();
  const canDomainReport = getUserFeatureFlags(state).includes('domain_report');
  const tabs = determineTabs(canDomainReport);
  const dispatch = useDispatch();
  const selectedView = useSelector((reduxState) => getExploreListSelectedView(reduxState, listId));
  const vewTypesByOrder = VIEW_TYPES_BY_ORDER?.filter((viewType) =>
    tabs.some((tab) => TABS_TO_VIEW_TYPE_MAP[tab.name] === viewType)
  );

  useEffect(() => {
    if (selectedView && vewTypesByOrder) {
      setActiveTabIndex(vewTypesByOrder.indexOf(selectedView));
    }
  }, [selectedView, vewTypesByOrder]);

  return (
    <Tabs
      value={activeTabIndex}
      textColor="secondary"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'colors.ui_border',
        minHeight: '10px'
      }}
      TabIndicatorProps={{
        style: { backgroundColor: appTheme.palette.colors.primary, height: '4px' }
      }}
      onChange={(_event, newTabIndex) =>
        dispatch(updateSelectedView({ listId, selectedView: vewTypesByOrder?.[newTabIndex] }))
      }>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          color={
            selectedView === vewTypesByOrder[index] ? appTheme.palette.colors.primary : 'primary'
          }
          sx={{
            width: 'auto',
            gap: '4px',
            '&:hover': {
              color: appTheme.palette.primary.primary25
            },
            minHeight: '10px'
          }}
          iconPosition="end"
          label={tab.name}
          icon={
            getIsTabNew(TABS_TO_VIEW_TYPE_MAP[tab.name]) && (
              <Typography
                variant="paragraphMedium"
                color="primary.primary50"
                display="flex"
                alignItems="center"
                fontSize={12}
                padding="0px 4px"
                height={16}
                sx={{ border: '1px solid', borderColor: 'primary.primary50', borderRadius: '2px' }}>
                New
              </Typography>
            )
          }
        />
      ))}
    </Tabs>
  );
}

DiscoveryViewTabs.propTypes = {
  listId: PropTypes.number
};
