import { Stack, Tooltip, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import CrunchbaseIcon from 'icons/CrunchbaseIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import PropTypes from 'prop-types';

import TableRowLoading from './TableRowLoading';

function RenderLocations({ value: data }) {
  if (!data) return null;
  if (data?.loading) {
    return <TableRowLoading />;
  }

  const LOGO_MAP = Object.freeze({
    crunchbase: <CrunchbaseIcon />,
    linkedin: <LinkedinIcon />
  });

  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: 800
          }
        }
      }}
      title={
        <Stack>
          {Object.keys(data).map((key) => (
            <Stack
              key={key}
              direction="row"
              alignItems="center"
              width="100%"
              gap="8px"
              paddingX="8px">
              <Typography variant="paragraph" noWrap display="block">
                {key}
              </Typography>
              <Stack
                direction="row"
                gap="8px"
                marginLeft="auto"
                paddingRight="16px"
                color="colors.text_on_primary">
                {Object.keys(data[key])
                  .sort((s1, s2) => s1.localeCompare(s2))
                  .map((source) => LOGO_MAP[source])}
              </Stack>
            </Stack>
          ))}
        </Stack>
      }>
      <Typography variant="paragraph">{Object.keys(data).join(', ')}</Typography>
    </Tooltip>
  );
}
export default wrapWithError(RenderLocations);

RenderLocations.propTypes = {
  value: PropTypes.array
};
