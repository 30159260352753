export const getDomainReports = (state) => state.domainReports;
export const getDomainReportsByListId = (state, listId) =>
  getDomainReports(state).domainReports?.[listId];
export const getLatestDomainReportByListId = (state, listId) => {
  const domainReports = getDomainReportsByListId(state, listId);
  if (!domainReports) return null;
  if (Object.keys(domainReports).length === 0) return null;
  return Object.values(domainReports || []).sort(
    (dr1, dr2) => dr2.domain_report_id - dr1.domain_report_id
  )[0];
};
export const getLatestDomainReportIdByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  return domainReport.domain_report_id;
};
export const getLatestDRCompanyMetaIdsByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.company_meta_ids;
};

export const getLatestDRAllCompanyMetaIdsByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.all_company_meta_ids;
};
export const getLatestDRFiltersByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.filters;
};
export const getLatestDRTaskByListId = (state, listId, taskType) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return Object.values(domainReport?.custom_analytics || {})?.find(
    (value) => value.task_type === taskType
  );
};
export const getLatestDRTaskStatusByListId = (state, listId, taskType) => {
  if (!listId || !taskType) return null;
  const task = getLatestDRTaskByListId(state, listId, taskType) || {};
  if (!task.status) return 'RUNNING';
  return task.status;
};

export const getLatestDRStatusByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  const taskTypes = Object.values(domainReport?.custom_analytics || {}).map(
    (value) => value.task_type
  );
  const taskStatuses = taskTypes.map((taskType) =>
    getLatestDRTaskStatusByListId(state, listId, taskType)
  );
  if (taskStatuses.length === 0) return 'RUNNING';
  if (taskStatuses.includes('FAILED')) return 'FAILED';
  if (taskStatuses.includes('RUNNING')) return 'RUNNING';
  if (taskStatuses.includes('SUCCEEDED')) return 'SUCCEEDED';
  return domainReport.status || 'RUNNING';
};
export const getLatestDRTaskProgressByListId = (state, listId, taskType) => {
  if (!listId || !taskType) return null;
  const task = getLatestDRTaskByListId(state, listId, taskType) || {};
  if (task.status === 'SUCCEEDED') {
    return 1;
  }
  if (!task.progress) return 0.05;
  return task.progress;
};
export const getLatestDRProgressByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  const taskTypes = Object.values(domainReport?.custom_analytics || {}).map(
    (value) => value.task_type
  );
  const taskProgresses = taskTypes.map((taskType) =>
    getLatestDRTaskProgressByListId(state, listId, taskType)
  );
  if (taskProgresses.length === 0) return 0;
  return (
    0.2 +
    0.8 * (taskProgresses.reduce((acc, progress) => acc + progress, 0) / taskProgresses.length)
  );
};
export const getDomainReportDefaultConfig = (state) => state.domainReports.defaultConfig;
export const getDomainReportTaskDefaultConfig = (state, taskType) =>
  state.domainReports.defaultConfig?.[taskType];
