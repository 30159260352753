import BaseClient from './BaseClient';

const URL_PREFIX = 'subdomains';

export default class SubdomainsClient extends BaseClient {
  addSubdomains(payload) {
    return this.post(URL_PREFIX, payload);
  }

  deleteSubdomain(subdomainId) {
    return this.httpDelete(`${URL_PREFIX}/${subdomainId}`);
  }
}
