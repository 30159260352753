import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatRevenueForPeersPerPerson } from 'utils/finance-utils';

export default function RenderRevPerHead({ value }) {
  return (
    <Typography
      textAlign="left"
      variant="paragraph"
      color="colors.primary_text"
      noWrap
      display="block">
      {value ? `${formatRevenueForPeersPerPerson(value)}k/person` : value}
    </Typography>
  );
}

RenderRevPerHead.propTypes = {
  value: PropTypes.array
};
