import { Popover, Stack, Typography } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import SelectWithAnswerTypeComponent from 'components/SelectWithAnswerTypeComponent';
import MultiSectionsSelectComponent from 'components/discovery/MultiSectionsSelectComponent';
import SlimMultiSelectPromptInputComponent from 'components/discovery/SlimMultiSelectComponent';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getListCustomColumnsByListId, getListDomainKnowledgeByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserIsDev } from 'selectors/user';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function WorkFlowPlanNodeSettings({
  listId,
  postId,
  nodeId,
  anchorEl,
  searchTheWeb,
  searchInTable,
  columnIds,
  domainKnowledgeIds,
  answerType,
  onClose
}) {
  const dispatch = useDispatch();

  const [localSearchInDk, setLocalSearchInDk] = useState(false);

  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const isDev = useOldStateSelector(getUserIsDev);
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderId = useSelector(getCurrentFolderId);
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );

  const onUpdateNodeSettings = useCallback(
    (queryDetails) => {
      dispatch(updateQueryInPlanAction(listId, postId, nodeId, queryDetails));
    },
    [listId, postId, nodeId, dispatch]
  );

  const { columns } = useMemo(() => {
    return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
  }, [listId, rows, customColumns, columnsSettings, isDev]);

  const allDomainKnowledges = useMemo(() => {
    const allOptions = [
      ...DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
      ...Object.values(domainKnowledge || {})
        .sort((item1, item2) => (item1.type < item2.type ? 1 : -1))
        .map((dk) => ({
          icon: RESOURCE_TYPE_TO_ICON[dk.type],
          ...dk
        }))
    ];

    return allOptions.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [domainKnowledge]);

  const sources = useMemo(() => {
    const newSources = [];
    if (searchTheWeb) {
      newSources.push('web');
    }
    if (searchInTable) {
      newSources.push('table');
    }
    if (localSearchInDk) {
      newSources.push('domainKnowledge');
    }
    return newSources;
  }, [searchTheWeb, searchInTable, localSearchInDk]);

  const dkTypesSet = useMemo(
    () =>
      Object.values(allDomainKnowledges).reduce((set, item) => {
        if (item.supporting_resource_type) {
          set.add(item.supporting_resource_type);
        }
        return set;
      }, new Set()),
    [allDomainKnowledges]
  );

  const contextSections = useMemo(() => {
    const sections = [];
    if (searchInTable) {
      sections.push({
        id: 'columns',
        type: 'multiple',
        title: 'Columns',
        items: Object.values(columns),
        selectItemsSet: new Set(columnIds),
        onSelectionsChange: (selectedColumns) =>
          onUpdateNodeSettings({ columnIds: selectedColumns })
      });
    }

    if (localSearchInDk) {
      const localDkTypesSet = new Set(
        domainKnowledgeIds?.filter((itemId) => dkTypesSet.has(itemId)) || []
      );

      const domainKnowledgeOptions = [
        ...Object.values(allDomainKnowledges || {})
          .sort((item1, item2) => {
            if (!item1.supporting_resource_type && !item2.supporting_resource_type) {
              // for items without type (all files/all answers..), keep the original order
              return 1;
            }
            const typeCompare = item1.supporting_resource_type?.localeCompare(
              item2.supporting_resource_type
            );
            if (typeCompare === 0) {
              return item1.name?.localeCompare(item2.name) || -1;
            }
            return typeCompare || -1;
          })
          .map((dk) => ({
            ...dk,
            disabled:
              (!dkTypesSet.has(dk.supporting_resource_type) && !dkTypesSet.has(dk.id)) ||
              localDkTypesSet.has(dk.supporting_resource_type),
            icon: RESOURCE_TYPE_TO_ICON[dk.supporting_resource_type] || RESOURCE_TYPE_TO_ICON[dk.id]
          }))
      ];

      sections.push({
        id: 'domain',
        type: 'multiple',
        title: 'Domain Knowledge',
        items: domainKnowledgeOptions,
        selectItemsSet: new Set(domainKnowledgeIds),
        onSelectionsChange: (selectedResourceType) => {
          const newDkAllTypes = new Set(
            selectedResourceType?.filter((itemId) => dkTypesSet.has(itemId)) || []
          );
          const filterOutSpecificWhenAllExist = selectedResourceType.filter(
            (dkId) => !newDkAllTypes.has(allDomainKnowledges[dkId]?.supporting_resource_type)
          );
          return onUpdateNodeSettings({ domainKnowledgeIds: filterOutSpecificWhenAllExist });
        }
      });
    }
    return sections;
  }, [
    searchInTable,
    localSearchInDk,
    columns,
    columnIds,
    domainKnowledgeIds,
    allDomainKnowledges,
    dkTypesSet,
    onUpdateNodeSettings
  ]);

  const [contextSelectorTitle, contextSelectorTooltip] = useMemo(() => {
    const selectedList = [];
    contextSections.forEach((section) => {
      if (section.selectItemsSet) {
        section.selectItemsSet.forEach((item) => {
          const itemName = section.items.find((elem) => elem.id === item)?.name;
          selectedList.push(itemName);
        });
      }
    });

    let text = '';
    let tooltipText = null;
    if (searchInTable && localSearchInDk) {
      if (selectedList.length > 0) {
        text = `Use selected context (${selectedList.length})`;
      } else {
        text = `Use specific context`;
      }
    } else if (searchInTable) {
      if (selectedList.length > 0) {
        text = `Use ${selectedList.length} column${selectedList.length > 1 ? 's' : ''}`;
      } else {
        text = `Use specific columns`;
      }
    } else if (localSearchInDk) {
      if (selectedList.length > 0) {
        text = `Use ${selectedList[0]}`;
        tooltipText = '';
      } else {
        text = `Use specific knowledge`;
      }
    } else {
      text = `Use specific context`;
      tooltipText = `Using specific context is possible only when searching within the table or domain knowledge`;
    }
    if (tooltipText === null && selectedList.length > 0) {
      tooltipText = selectedList.join('\n');
    }
    return [text, tooltipText];
  }, [searchInTable, localSearchInDk, contextSections]);

  const defualtSources = [
    { id: 'web', name: 'Web' },
    { id: 'table', name: 'Table' }
  ];

  const onSearchChange = (newSearchPlaces) => {
    const shouldSearchInWeb = newSearchPlaces.includes('web');
    const shouldSearchInTable = newSearchPlaces.includes('table');
    const shouldSearchInDk = newSearchPlaces.includes('domainKnowledge');
    onUpdateNodeSettings({
      searchTheWeb: shouldSearchInWeb,
      searchInTable: shouldSearchInTable
    });
    setLocalSearchInDk(shouldSearchInDk);
  };

  const onAnswerTypeChange = (newAnswerType) => {
    onUpdateNodeSettings({ answerType: newAnswerType });
  };

  return (
    <Popover
      id="dependencies"
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      slotProps={{
        paper: {
          sx: { padding: 0, backgroundColor: 'white', boxShadow: '0px 4px 15px 0px #00000024' }
        }
      }}>
      <Stack
        direction="column"
        gap="16px"
        sx={{
          backgroundColor: 'primary.white',
          padding: '16px',
          boxShadow: '0px 4px 15px 0px #00000024',
          borderRadius: '8px'
        }}>
        <Typography variant="paragraphSemiBlod" color="primary.primary150">
          Creation settings
        </Typography>
        {answerType && (
          <Stack direction="column" gap="4px">
            <Typography variant="paragraphMedium" color="secondary.secondary5_100">
              Response type
            </Typography>
            <SelectWithAnswerTypeComponent
              currentAnswerType={answerType}
              setAnswerType={onAnswerTypeChange}
              showTitle={true}
            />
          </Stack>
        )}
        <Stack direction="column" gap="4px">
          <Typography variant="paragraphMedium" color="secondary.secondary5_100">
            Search
          </Typography>
          <SlimMultiSelectPromptInputComponent
            selectedValues={sources}
            onValueChange={onSearchChange}
            allTag="Search everywhere"
            emptyTag="Select sources"
            sx={{ width: '200px' }}
            knowledge={allDomainKnowledges}
            defualtSources={defualtSources}
          />
        </Stack>
        <Stack direction="column" gap="4px">
          <Typography variant="paragraphMedium" color="secondary.secondary5_100">
            Context
          </Typography>
          <MultiSectionsSelectComponent
            containerSx={{
              flex: 1,
              width: '200px',
              maxWidth: '250px'
            }}
            sections={contextSections}
            title={contextSelectorTitle}
            tooltipText={contextSelectorTooltip}
          />
        </Stack>
      </Stack>
    </Popover>
  );
}
WorkFlowPlanNodeSettings.propTypes = {
  listId: PropTypes.number,
  postId: PropTypes.number,
  nodeId: PropTypes.string,
  anchorEl: PropTypes.object,
  searchTheWeb: PropTypes.bool,
  searchInTable: PropTypes.bool,
  columnIds: PropTypes.array,
  domainKnowledgeIds: PropTypes.array,
  answerType: PropTypes.string,
  onClose: PropTypes.func
};

export default WorkFlowPlanNodeSettings;
