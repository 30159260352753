/* eslint-disable max-lines-per-function */

/* eslint-disable max-lines */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { setIsListMonitoredAction } from 'actions/company_lists/set-is-monitored-action';
import { createSchedulesByRecordsAction } from 'actions/schedules/create-schedule-action';
import { deleteSchedulesByRecordsAction } from 'actions/schedules/delete-schedule-action';
import RenderMultipleLogos from 'components/elements/table/RenderMultipleLogos';
import ModalComponent from 'components/modal/ModalComponent';
import { DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES } from 'constants/discovery';
import { DEV_FREQUENCIES, MAX_SCHEDULED_PER_LIST, SCHEDULE_FREQUENCIES } from 'constants/schedules';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import MonitoringIcon from 'icons/MonitoringIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getCompanyListHistoryByListId,
  getCompanyListNameByListId,
  getIsExploreListMonitored
} from 'selectors/explore';
import { getCompanyListSchedulesById } from 'selectors/schedules';
import { getUserFeatureFlags } from 'selectors/user';
import { isEmpty } from 'utils/objects-utils';
import { getFrequencyFromCron } from 'utils/schedules-utils';
import { formatTimestamp } from 'utils/time-utils';

function ListMonitoringDialog({ companyListId, isListEmpty }) {
  const [selectedRuns, setSelectedRuns] = useState([]);
  const [frequency, setFrequency] = useState(SCHEDULE_FREQUENCIES.WEEKLY);
  const dispatch = useDispatch();
  const listHistory = useSelector((state) => getCompanyListHistoryByListId(state, companyListId));
  const companies = useSelector((state) =>
    getCompanyMetasMergedRowsByCompanyListId(state, companyListId)
  );
  const isListMonitored = useSelector((state) => getIsExploreListMonitored(state, companyListId));
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const listName = useSelector((state) => getCompanyListNameByListId(state, companyListId));
  const [openMonitoringDialog, setOpenMonitoringDialog] = useState(false);
  const schedules = useSelector((state) => getCompanyListSchedulesById(state, companyListId));
  const featureFlags = useOldStateSelector(getUserFeatureFlags);

  const savedFrequency = useMemo(() => {
    if (!isListMonitored || isEmpty(schedules)) return null;
    const freq = getFrequencyFromCron(schedules[0].cron);
    switch (freq) {
      case 'Day':
        return SCHEDULE_FREQUENCIES.DAILY;
      case 'Week':
        return SCHEDULE_FREQUENCIES.WEEKLY;
      case 'Month':
        return SCHEDULE_FREQUENCIES.MONTHLY;
      default:
        return null;
    }
  }, [schedules, isListMonitored]);

  const allRuns = useMemo(() => {
    if (!listHistory) return [];
    return Object.values(listHistory)
      .filter(
        (run) =>
          !isEmpty(run.discovery_data) &&
          run.iteration > 0 &&
          !run?.is_monitor &&
          Object.values(DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES).includes(run.discovery_type)
      )
      .sort((run1, run2) => new Date(run2.time_submitted) - new Date(run1.time_submitted))
      .map((run) => {
        return {
          ...run,
          discoveredCount: companies?.filter((company) => company.iteration === run.iteration)
            ?.length
        };
      });
  }, [listHistory, companies]);

  const restSelectedWithAllRuns = useCallback(
    (onlyIfEmpty, onlyIfRunMonitored) => {
      setSelectedRuns((prev) => {
        if (!onlyIfEmpty || isEmpty(prev)) {
          return allRuns
            .filter((run) => !onlyIfRunMonitored || run.is_monitor_seed)
            .map((run) => run.record_id)
            .slice(0, MAX_SCHEDULED_PER_LIST);
        }
        return prev;
      });
    },
    [allRuns]
  );

  useEffect(() => {
    restSelectedWithAllRuns(true, isListMonitored);
  }, [restSelectedWithAllRuns, isListMonitored]);

  const isAllSelected = selectedRuns.length === allRuns.length;

  const handleSelectedRunsChange = (recordId) => {
    if (recordId === 'all') {
      if (isAllSelected) {
        setSelectedRuns([]);
      } else {
        restSelectedWithAllRuns(false, isListMonitored);
      }
      return;
    }
    if (selectedRuns.includes(recordId)) {
      setSelectedRuns(selectedRuns.filter((includedRecordId) => includedRecordId !== recordId));
      return;
    }
    setSelectedRuns([...selectedRuns, recordId]);
  };

  const handleClose = () => {
    restSelectedWithAllRuns(false, isListMonitored);
    setFrequency(SCHEDULE_FREQUENCIES.WEEKLY);
    setOpenMonitoringDialog(false);
  };

  const onSubmitMonitor = () => {
    if (isListMonitored) {
      const runsToDelete = allRuns
        .filter((run) => run.is_monitor_seed && !selectedRuns.includes(run.record_id))
        .map((run) => run.record_id);
      if (runsToDelete.length > 0) {
        dispatch(deleteSchedulesByRecordsAction(companyListId, runsToDelete));
      }
      const runsToCreate = allRuns
        .filter((run) => !run.is_monitor_seed && selectedRuns.includes(run.record_id))
        .map((run) => run.record_id);
      if (runsToCreate.length > 0) {
        dispatch(createSchedulesByRecordsAction(companyListId, runsToCreate, frequency));
      }
      setOpenMonitoringDialog(false);
      return;
    }
    dispatch(createSchedulesByRecordsAction(companyListId, selectedRuns, frequency));
    setOpenMonitoringDialog(false);
  };

  const onCloseDeleteDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenDeleteDialog(false);
  };

  const onDeleteAllMonitors = (event) => {
    dispatch(setIsListMonitoredAction(companyListId, false));
    onCloseDeleteDialog(event);
    restSelectedWithAllRuns(false, false);
    setOpenMonitoringDialog(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={isListEmpty || isEmpty(allRuns) ? 'No queries to monitor' : ''}>
        <Box sx={{ color: 'colors.text_on_primary_bg' }}>
          <Button
            disabled={isListEmpty || isEmpty(allRuns)}
            variant="contained"
            startIcon={<MonitoringIcon />}
            onClick={() => {
              setOpenMonitoringDialog(true);
            }}
            sx={{ padding: '8px 16px' }}>
            Monitor
          </Button>
        </Box>
      </Tooltip>

      <ModalComponent
        open={openMonitoringDialog}
        onClose={handleClose}
        title={isListMonitored ? 'Edit Monitor' : 'Set Monitor'}
        maxWidth={500}>
        <Stack padding="24px" gap="16px">
          <Typography variant="paragraphBold" color="colors.primary_text">
            Queries
          </Typography>
          <FormControl fullWidth size="small">
            <Select
              value={Array.from(selectedRuns)}
              defaultValue="Select queries"
              multiple
              displayEmpty
              renderValue={() => {
                if (isAllSelected) {
                  return (
                    <Typography variant="paragraphMedium" color="colors.primary_text">
                      {`All Queries (${allRuns.length})`}
                    </Typography>
                  );
                }
                return (
                  <Typography variant="paragraphMedium" color="colors.primary_text">
                    {`${selectedRuns.length} selected out of ${allRuns.length}`}
                  </Typography>
                );
              }}>
              {allRuns.length > 0 && allRuns.length <= MAX_SCHEDULED_PER_LIST && (
                <MenuItem
                  value="all"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                  onClick={() => handleSelectedRunsChange('all')}>
                  <Checkbox checked={isAllSelected} />
                  <Typography variant="paragraphMedium" color="colors.primary_text">
                    All Queries
                  </Typography>
                </MenuItem>
              )}
              {allRuns.map((run) => (
                <Tooltip
                  key={run.record_id}
                  title={
                    selectedRuns.length >= MAX_SCHEDULED_PER_LIST &&
                    !selectedRuns.includes(run.record_id)
                      ? `You can select up to ${MAX_SCHEDULED_PER_LIST} queries`
                      : ''
                  }>
                  <div>
                    <MenuItem
                      key={run.record_id}
                      value={run.record_id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px'
                      }}
                      disabled={
                        selectedRuns.length >= MAX_SCHEDULED_PER_LIST &&
                        !selectedRuns.includes(run.record_id)
                      }>
                      <Stack
                        direction="row"
                        gap="4px"
                        alignItems="center"
                        width="100%"
                        border="1px solid black"
                        borderRadius="4px"
                        padding="4px 8px"
                        onClick={() => handleSelectedRunsChange(run.record_id)}>
                        <Checkbox checked={Boolean(selectedRuns.includes(run.record_id))} />
                        <Stack width="100%">
                          <Stack direction="row" width="100%" gap="8px">
                            <Typography variant="paragraphSemiBold" color="colors.primary_text">
                              {`${run.title} #${run.iteration} `}
                            </Typography>
                            <Typography variant="paragraph" color="colors.primary_text">
                              {`(${run.discoveredCount} discovered)`}
                            </Typography>
                            <Typography
                              marginLeft="auto"
                              variant="paragraphMedium"
                              paddingRight="4px"
                              color="colors.primary_text">
                              {formatTimestamp(run.time_submitted, 'dd.MM.yyyy')}
                            </Typography>
                          </Stack>
                          {run.discovery_type ===
                            DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES.PEERS && (
                            <RenderMultipleLogos value={run.discovery_data} />
                          )}
                          {run.discovery_type ===
                            DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES.TEXT && (
                            <Typography variant="paragraph">{run.discovery_data}</Typography>
                          )}
                        </Stack>
                      </Stack>
                    </MenuItem>
                  </div>
                </Tooltip>
              ))}
            </Select>
          </FormControl>
          <Typography variant="paragraphBold" color="colors.primary_text">
            Cadence
          </Typography>
          <Select
            size="small"
            value={savedFrequency || frequency}
            disabled={isListMonitored}
            onChange={(event) => setFrequency(event.target.value)}>
            {Object.values(SCHEDULE_FREQUENCIES)
              .filter(
                (freq) =>
                  featureFlags?.includes('all_monitoring_frequencies') ||
                  !DEV_FREQUENCIES.includes(freq)
              )
              .map((freq) => (
                <MenuItem key={freq} value={freq}>
                  {`${freq.charAt(0).toUpperCase()}${freq.slice(1)}`}
                </MenuItem>
              ))}
          </Select>
        </Stack>
        <Stack direction="row" justifyContent="space-between" padding="16px">
          <Button onClick={handleClose}> Cancel</Button>
          <Stack direction="row" gap="16px">
            {isListMonitored && (
              <Button onClick={() => setOpenDeleteDialog(true)} variant="outlined">
                Delete All
              </Button>
            )}
            {isListMonitored ? (
              <Box>
                <Button onClick={onSubmitMonitor} variant="contained">
                  Save
                </Button>
              </Box>
            ) : (
              <Button
                onClick={onSubmitMonitor}
                variant="contained"
                disabled={selectedRuns.length === 0}>
                Set Monitor
              </Button>
            )}
          </Stack>
        </Stack>
        <DeleteMonitoringDialog
          open={openDeleteDialog}
          onClose={onCloseDeleteDialog}
          listName={listName}
          onDelete={onDeleteAllMonitors}
        />
      </ModalComponent>
    </React.Fragment>
  );
}

function DeleteMonitoringDialog({ open, onClose, onDelete, listName }) {
  return (
    <ModalComponent
      title="Delete Monitoring"
      open={open}
      onClose={onClose}
      maxWidth={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onDelete}>
            Remove List Monitoring
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="24px" sx={{ padding: '24px' }}>
        <Typography variant="paragraphMedium">{`Are you sure you want to stop monitoring list "${listName}"?`}</Typography>
      </Stack>
    </ModalComponent>
  );
}

ListMonitoringDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyListId: PropTypes.number,
  isListEmpty: PropTypes.bool
};

DeleteMonitoringDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  listName: PropTypes.string
};

export default ListMonitoringDialog;
