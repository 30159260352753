import React from 'react';

export default function ShareIcon({ ...args }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...args}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1574 5.1499C15.2895 5.1499 14.5859 5.85345 14.5859 6.72133C14.5859 7.58921 15.2895 8.29276 16.1574 8.29276C17.0252 8.29276 17.7288 7.58921 17.7288 6.72133C17.7288 5.85345 17.0252 5.1499 16.1574 5.1499ZM13.5859 6.72133C13.5859 5.30117 14.7372 4.1499 16.1574 4.1499C17.5775 4.1499 18.7288 5.30117 18.7288 6.72133C18.7288 8.14149 17.5775 9.29276 16.1574 9.29276C15.347 9.29276 14.6242 8.91791 14.1529 8.33214L10.3436 11.1891C10.4084 11.4148 10.4432 11.6533 10.4432 11.8999C10.4432 12.1466 10.4084 12.3851 10.3436 12.6109L14.1528 15.4678C14.6241 14.882 15.347 14.5071 16.1574 14.5071C17.5775 14.5071 18.7288 15.6583 18.7288 17.0785C18.7288 18.4987 17.5775 19.6499 16.1574 19.6499C14.7372 19.6499 13.5859 18.4987 13.5859 17.0785C13.5859 16.8318 13.6207 16.5932 13.6856 16.3673L9.87642 13.5105C9.40511 14.0964 8.6822 14.4713 7.87172 14.4713C6.45156 14.4713 5.30029 13.3201 5.30029 11.8999C5.30029 10.4798 6.45156 9.32849 7.87172 9.32849C8.68223 9.32849 9.40516 9.70348 9.87648 10.2894L13.6856 7.43261C13.6207 7.20672 13.5859 6.96808 13.5859 6.72133ZM7.87172 10.3285C7.00385 10.3285 6.30029 11.032 6.30029 11.8999C6.30029 12.7678 7.00385 13.4713 7.87172 13.4713C8.7396 13.4713 9.44315 12.7678 9.44315 11.8999C9.44315 11.032 8.7396 10.3285 7.87172 10.3285ZM14.5859 17.0785C14.5859 16.2106 15.2895 15.5071 16.1574 15.5071C17.0252 15.5071 17.7288 16.2106 17.7288 17.0785C17.7288 17.9464 17.0252 18.6499 16.1574 18.6499C15.2895 18.6499 14.5859 17.9464 14.5859 17.0785Z"
        fill="currentColor"
      />
    </svg>
  );
}
