import { useMediaQuery } from '@mui/material';
import { MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React from 'react';
import { ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';

import RechartsBox from '../RechartsBox';
import ScatterTooltipContent from './ScatterTooltipContent';

/* eslint-disable id-length */
export default function ScatterPlotBaseComponent({
  title,
  xAxisName,
  yAxisName,
  dataKeyX,
  dataKeyY,
  mode,
  children
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  return (
    <RechartsBox title={title} mode={mode}>
      <ScatterChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        margin={{
          top: mode === 'benchmark' || !mediumViewport ? 0 : 20,
          right: mode === 'benchmark' || !mediumViewport ? 0 : 10,
          bottom: mode === 'benchmark' || !mediumViewport ? 0 : 20,
          left: mode === 'benchmark' || !mediumViewport ? 0 : 10
        }}>
        <XAxis
          type="number"
          dataKey={dataKeyX}
          name={xAxisName}
          tick={false}
          label={{
            value: xAxisName,
            position: 'bottom',
            fill: appTheme.palette.greyColors.grey250,
            offset: -10
          }}
          domain={['auto', 'auto']}
          padding={{ left: 60, right: 60 }}
          stroke={appTheme.palette.greyColors.grey250}
          axisLine={{ stroke: appTheme.palette.colors.ui_border }}
        />
        <YAxis
          type="number"
          dataKey={dataKeyY}
          name={yAxisName}
          tick={false}
          label={{
            value: yAxisName,
            position: 'insideLeft',
            angle: -90,
            style: {
              textAnchor: 'middle'
            },
            fill: appTheme.palette.greyColors.grey250,
            offset: 30
          }}
          domain={['auto', 'auto']}
          padding={{ bottom: 60, top: 60 }}
          stroke={appTheme.palette.greyColors.grey250}
          axisLine={{ stroke: appTheme.palette.colors.ui_border }}
        />
        <Tooltip cursor={false} content={<ScatterTooltipContent />} />
        {children}
      </ScatterChart>
    </RechartsBox>
  );
}

ScatterPlotBaseComponent.propTypes = {
  title: PropTypes.string,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  dataKeyX: PropTypes.string,
  dataKeyY: PropTypes.string,
  mode: PropTypes.oneOf(MODES).isRequired,
  children: PropTypes.any
};
