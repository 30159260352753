import { Box, Collapse, Stack, Typography } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';

function QuickViewExpandable({ title, disabled, children }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandedChange = () => setExpanded(!expanded);
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="4px"
        onClick={disabled ? null : handleExpandedChange}
        sx={{
          color: disabled ? 'colors.primary_text' : null,
          opacity: disabled ? 0.38 : 1,
          cursor: disabled ? null : 'pointer'
        }}>
        <Typography variant="paragraphBold" textTransform="capitalize">
          {title}
        </Typography>
        <Box
          sx={{
            transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)'
          }}>
          <ArrowIcon />
        </Box>
      </Stack>
      <Collapse in={expanded}>
        <Stack sx={{ marginTop: '12px' }}>{children}</Stack>
      </Collapse>
    </Stack>
  );
}

QuickViewExpandable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

export default QuickViewExpandable;
