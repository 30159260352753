import {
  DISCOVERY_TYPES,
  DISCOVERY_TYPES_TITLES,
  DRY_DISCOVERY_TYPE,
  LEGACY_CUSTOM_URL_DISCOVERY_TYPE
} from 'constants/discovery';

function createDisocoveryData(title, data) {
  if (title === DRY_DISCOVERY_TYPE) {
    return null;
  }
  if (title === DISCOVERY_TYPES_TITLES.PEERS) {
    return `Generated from ${data.map((item) => item.text).join(', ')}`;
  }
  return data;
}

export function sourceValueToHistory(value) {
  if (!value) {
    return null;
  }
  const { iteration, time_submitted, title, discovery_data: data, discovered } = value;
  return {
    index: iteration === 0 ? null : iteration,
    time_submitted,
    type:
      title === LEGACY_CUSTOM_URL_DISCOVERY_TYPE
        ? DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.URL]
        : title,
    total_companies: discovered,
    user_input: createDisocoveryData(title, data)
  };
}
