import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { loadSavedQueriesAction } from 'actions/saved_queries/load-saved-queries-action';
import ColumnsPlatterDialog from 'components/elements/table/filters/columns/ColumnsPlatterDialog';
import PreviewIcon from 'icons/PreviewIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';
import { getObjectLength } from 'utils/objects-utils';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import PromptDevMenu from './PromptDevMenu';

function PromptEndAdornment({
  promptConfig,
  setPromptConfig,
  isProccessing,
  handleSubmit,
  isDev,
  standalone,
  listId
}) {
  const numberOfComapnies = getObjectLength(
    useSelector((state) => getCompanyMetasByListId(state, listId))
  );
  const numberOfSelectedCompanies = getObjectLength(
    useSelector((state) => getExploreTableUICheckedCompaniesByListId(state, listId))
  );
  const allowPreview = promptConfig.forEachCompany && listId;
  const [openColumnsPlatter, setOpenColumnsPlatter] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSavedQueriesAction());
  }, [dispatch]);

  const previewTitle = useMemo(() => {
    if (!allowPreview) {
      return null;
    }
    if (numberOfSelectedCompanies > 0 && numberOfSelectedCompanies <= 5) {
      return 'Get a preview for the selected companies before adding the column to the table';
    }
    if (numberOfComapnies > 5) {
      return 'Get a preview for the first 5 companies before adding the column to the table';
    }
    return 'Get a preview of the column before adding it to the table';
  }, [numberOfComapnies, numberOfSelectedCompanies, allowPreview]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      sx={{ marginTop: standalone ? null : 'auto' }}>
      {listId && !promptConfig.useDiscover && (
        <Button
          sx={{ whiteSpace: 'nowrap' }}
          variant="outlined"
          onClick={() => {
            setOpenColumnsPlatter(true);
          }}>
          {promptConfig.forEachCompany ? 'Add' : 'Ask'} from library
        </Button>
      )}
      <Stack
        direction="row"
        gap="4px"
        alignItems="center"
        justifyContent="flex-end"
        marginLeft="auto"
        marginRight="4px">
        <Stack direction="row" gap="0px" alignItems="center">
          {isDev && <PromptDevMenu promptConfig={promptConfig} setPromptConfig={setPromptConfig} />}
        </Stack>
        {allowPreview && (
          <Tooltip arrow title={previewTitle}>
            <IconButton
              id="preview-button"
              sx={{ padding: '4px' }}
              onClick={(event) => handleSubmit(event, true)}
              disabled={isProccessing}>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        )}

        <Button
          variant="contained"
          sx={{
            color: isProccessing ? 'colors.ui_border' : 'primary.primary100'
          }}
          onClick={handleSubmit}
          disabled={isProccessing}>
          <Typography variant="paragraphMedium" color="colors.text_on_primary">
            {(promptConfig.forEachCompany && listId) || promptConfig.useDiscover ? 'Add' : 'Ask'}
          </Typography>
        </Button>
      </Stack>
      {listId && (
        <ColumnsPlatterDialog
          open={openColumnsPlatter}
          onClose={() => setOpenColumnsPlatter(false)}
          companyListId={listId}
          actionType={
            promptConfig.forEachCompany
              ? SAVED_QUERY_ACTION_TYPES.COLUMN
              : SAVED_QUERY_ACTION_TYPES.ASK
          }
        />
      )}
    </Stack>
  );
}

PromptEndAdornment.propTypes = {
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  isProccessing: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isDev: PropTypes.bool,
  standalone: PropTypes.bool,
  listId: PropTypes.number
};

export default PromptEndAdornment;
