import { Typography, alpha } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { handleErrorAction } from 'actions/users/handle-error-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { useScrollPosition } from 'hooks/useScrollDetector';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateExploreHiddenColumns } from 'reducer/explore-slice';
import {
  updateCompanyListCompaniesOrder,
  updateExploreFilteredCompanies
} from 'reducer/explore-table-ui';
import { getListColumnsOrderByListId } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';
import { appTheme } from 'theme';
import { isEmpty } from 'utils/objects-utils';
import {
  determinAlign,
  determineCellBorder,
  determineRenderCell,
  determineRenderHeader,
  getColumnFlex,
  getColumnMinWidth,
  getColumnSort,
  getColumnWidth
} from 'utils/table-utils';

import BenchmarkHeader from './BenchmarkHeader';
import CustomColumnMenu from './CustomColumnMenu';
import CustomPagination from './CustomPagination';
import DiscoveryTableLoader from './DiscoveryTableLoader';
import { tableIcons } from './RenderUrlIcon';
import './TableComponent.scss';
import TableComponentToolbar from './TableComponentToolbar';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
function DiscoveryTable({
  listId,
  viewId,
  title,
  columns,
  columnGrouping,
  showTitle,
  elementId,
  onCellClick,
  onColumnResize,
  onColumnsOrderChange,
  createEmptyRow,
  isLoadingRows,
  tableHeaderHeight,
  setIsFullScreen,
  filterRow,
  hiddenColumns,
  disableToolBar,
  toolbarArgs,
  scrollElement,
  runningRows,
  highlightedColumns
}) {
  const dispatch = useDispatch();
  const parentRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const draggedColumnState = useRef();
  const scrollPosition = useScrollPosition(scrollElement);
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(0);
  const [filteredCompanyMetaIds, setFilteredCompanyMetaIds] = useState(null);
  const apiRef = useGridApiRef();
  const stateFilteredCompanyMetaIds = useSelector((state) =>
    getExploreFilteredCompanies(state, listId)
  );
  const columnsOrder = useSelector((state) => getListColumnsOrderByListId(state, listId));
  const [sortModel, setSortModel] = useState({});

  useEffect(() => {
    if (apiRef.current) {
      const sortedRowIds = apiRef.current.getSortedRowIds();
      const orderedCompanies = sortedRowIds
        .map((id) => apiRef.current.getRow(id)?.company_name?.companyMetaId)
        .filter(Boolean);
      dispatch(updateCompanyListCompaniesOrder({ listId, companiesOrder: orderedCompanies }));
    }
  }, [dispatch, listId, apiRef]);

  const handleStateChange = (state) => {
    // Check if the state change includes sorting updates
    const newSortModel = state.sorting?.sortModel?.[0];
    if (newSortModel?.field !== sortModel?.field || newSortModel?.sort !== sortModel?.sort) {
      setSortModel(newSortModel);
      const sortedRowIds = apiRef.current.getSortedRowIds();
      const orderedCompanies = sortedRowIds
        .map((id) => apiRef.current.getRow(id)?.company_name?.companyMetaId)
        .filter(Boolean);
      dispatch(updateCompanyListCompaniesOrder({ listId, companiesOrder: orderedCompanies }));
    }
  };

  const handleHightlightedColumn = useCallback(
    (columnHeaderElement, columnId) => {
      const highlightBorderStyle = `2px dashed ${appTheme.palette.colors.link}`;
      const originalBorderStyle = columnHeaderElement.style.border;
      if (!originalBorderStyle?.includes('2px dashed')) {
        let useNewStyle = false;
        columnHeaderElement.style.border = highlightBorderStyle;
        const intervalId = setInterval(() => {
          columnHeaderElement.style.border = useNewStyle
            ? highlightBorderStyle
            : originalBorderStyle;
          useNewStyle = !useNewStyle;
        }, 500);
        setTimeout(() => {
          clearInterval(intervalId);
          columnHeaderElement.style.border = originalBorderStyle;
          dispatch(highlightColumnAction(listId, columnId, false));
        }, 5000);
      }
    },
    [listId, dispatch]
  );

  const animatedScrollToColumn = useCallback(
    (columnId) => {
      const colIndex = apiRef.current.getColumnIndex(columnId);
      if (colIndex >= 0) {
        apiRef.current.scrollToIndexes({ colIndex });
        requestAnimationFrame(() => {
          const columnHeader = apiRef.current.getColumnHeaderElement(columnId);
          if (columnHeader) {
            handleHightlightedColumn(columnHeader, columnId);
          }
        });
      } else {
        dispatch(highlightColumnAction(listId, columnId, false));
      }
    },
    [apiRef, listId, dispatch, handleHightlightedColumn]
  );

  const getOrUnhideColumnIdPromise = useCallback(
    (columnId) => {
      return new Promise((resolve) => {
        const colIndex = apiRef.current.getColumnIndex(columnId);
        if (colIndex === -1 && !searchParams.has('hidden_columns', columnId)) {
          searchParams.append('hidden_columns', columnId);
          setSearchParams(searchParams);
          dispatch(updateExploreHiddenColumns({ listId, columnsIds: [columnId], isHidden: false }));
        }
        resolve();
      });
    },
    [dispatch, apiRef, listId, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isEmpty(highlightedColumns)) return;
    highlightedColumns.forEach((columnId) => {
      getOrUnhideColumnIdPromise(columnId).then(() => animatedScrollToColumn(columnId));
    });
  }, [highlightedColumns, getOrUnhideColumnIdPromise, animatedScrollToColumn]);

  useEffect(() => {
    if (!parentRef.current) return;

    if (width !== parentRef.current.offsetWidth) {
      setWidth(parentRef.current.offsetWidth);
    }

    if (height !== parentRef.current.offsetHeight) {
      setHeight(parentRef.current.offsetHeight);
    }
  }, [parentRef.current?.offsetWidth, parentRef.current?.offsetHeight, width, height]);

  const sxExtra = determineCellBorder(elementId)
    ? {
        '.table-section': {
          border: '1px solid white',
          backgroundColor: 'colors.grey_bg'
        },
        '.MuiDataGrid-cell': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border'
        },
        '.MuiDataGrid-columnsPanelRow': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border'
        },
        '.MuiDataGrid-columnHeaders': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border',
          backgroundColor: 'colors.ui_border'
        },
        '.MuiDataGrid-columnHeader': {
          border: '1px solid white'
        },
        '.MuiDataGrid-columnHeader--filledGroup': {
          '.MuiDataGrid-columnHeaderTitleContainer': {
            borderBottom: 'none'
          }
        },
        '.running': { padding: 0, borderColor: 'white', borderLeft: 'none', borderRight: 'none' },
        '.highlighted': {
          backgroundColor: alpha(appTheme.palette.primary.primary0, 0.15),
          borderColor: 'greyColors.grey150'
        }
      }
    : {};

  const dataGridColumns = useMemo(() => {
    const companyColumnName = `Company ${rowsCount ? `(${rowsCount})` : ''}`;
    const colIdToGroupIndex = columnGrouping?.reduce((prev, group, index) => {
      group.children.forEach((col) => {
        prev[col.field] = index;
      });
      return prev;
    }, {});
    const colIdToColIndex = columnsOrder?.reduce((result, colId, index) => {
      result[colId] = index;
      return result;
    }, {});
    return columns
      .sort((col1, col2) => {
        return colIdToColIndex?.[col1.id] && colIdToColIndex?.[col2.id]
          ? colIdToColIndex?.[col1.id] - colIdToColIndex?.[col2.id]
          : colIdToGroupIndex?.[col1.id] - colIdToGroupIndex?.[col2.id];
      })
      .map((col) => {
        return {
          field: col.id,
          headerName: col.id === 'company_name' ? companyColumnName : col.name,
          flex:
            col.data_type === 'url_icon' || col.data_type === 'add_column'
              ? null
              : getColumnFlex(elementId, col.id, col.width),
          display: 'flex',
          width:
            col.data_type === 'url_icon' || col.data_type === 'add_column'
              ? 60
              : getColumnWidth(elementId, col.id, col.width),
          minWidth:
            col.data_type === 'url_icon' || col.data_type === 'add_column'
              ? 60
              : getColumnMinWidth(elementId, col.id, col.data_type),
          url: col?.url,
          icon: col?.icon,
          type: col?.data_type,
          description: col?.description,
          disableColumnMenu: !col.extraParams,
          disableReorder: false,
          hideSortIcons:
            col.data_type === 'url_icon' ||
            col.data_type === 'url' ||
            col.data_type === 'multiple_url_icons' ||
            col.disableSort,
          sortable:
            col.data_type !== 'url_icon' ||
            col.data_type === 'url' ||
            col.data_type === 'multiple_url_icons',
          align: determinAlign(col, tableIcons),
          headerAlign: 'left',
          renderHeader: col?.benchmarkLabel
            ? (props) => <BenchmarkHeader {...props} benchmarkLabel={col.benchmarkLabel} />
            : determineRenderHeader(col?.description ? 'variable' : col.data_type),
          renderCell:
            determineRenderCell(col.data_type) ||
            determineRenderCell(col.id, col.data_type, col.extraParams),
          valueFormatter: col.valueFormatter,
          ...(getColumnSort(elementId, col.id, col.data_type) && {
            sortComparator: getColumnSort(elementId, col.id, col.data_type)
          }),
          valueOptions: col.valueOptions,
          ...(col.filterOperators && {
            filterOperators: col.filterOperators
          }),
          filterable: !col.disableFilter,
          disableExport: col?.disableExport,
          colSpan: (_value, row) => {
            if (row.fullRow) return 50;
            return null;
          },
          extraParams: col.extraParams
        };
      });
  }, [columns, elementId, rowsCount, columnGrouping, columnsOrder]);

  const csvFields = useMemo(() => {
    const fields = ['company_name'];
    dataGridColumns
      .filter((column) => column.field !== 'company_name' && !column.hide && !column.disableExport)
      .forEach((column) => {
        fields.push(column.field);
      });
    return fields;
  }, [dataGridColumns]);

  const fisrtNoneHiddenColumnId = useMemo(
    () => dataGridColumns?.find((col) => col.hide !== true)?.field,
    [dataGridColumns]
  );

  const [dataGridRows, allRows] = useMemo(() => {
    const localDataGridRows = [];
    const localAllRows = [];
    const companyListMetaIds = [];
    const numRows = columns[0].values.length;
    const startIndex = runningRows?.length || 0;
    for (let index = 0; index < numRows; index++) {
      const row = columns.reduce((obj, col) => ({ ...obj, [col.id]: col.values[index] }), {
        id: index + startIndex
      });
      localAllRows.push(row);
      if (filterRow) {
        if (filterRow(row)) {
          localDataGridRows.push(row);
          companyListMetaIds.push(row.company_name.companyListMetaId);
        }
      } else {
        localDataGridRows.push(row);
      }
    }
    setRowsCount(localDataGridRows.length);
    setFilteredCompanyMetaIds((prevIds) => {
      return companyListMetaIds.sort().join(',') === (prevIds || []).sort().join(',')
        ? prevIds
        : companyListMetaIds;
    });
    if (createEmptyRow) {
      localDataGridRows.push(createEmptyRow(numRows + startIndex, fisrtNoneHiddenColumnId));
    }
    if (runningRows) {
      localDataGridRows.unshift(
        ...runningRows.map((row) => ({ ...row, [fisrtNoneHiddenColumnId]: row.runningData }))
      );
    }

    return [localDataGridRows, localAllRows];
  }, [columns, fisrtNoneHiddenColumnId, createEmptyRow, filterRow, runningRows]);

  // Stops constant dispatch of the same event
  useEffect(() => {
    if (
      [...(filteredCompanyMetaIds || [])].sort().join(',') !==
      [...(stateFilteredCompanyMetaIds || [])].sort().join(',')
    ) {
      dispatch(
        updateExploreFilteredCompanies({
          listId,
          filteredCompanies: [...(filteredCompanyMetaIds || [])]
        })
      );
    }
  }, [filteredCompanyMetaIds, stateFilteredCompanyMetaIds, dispatch, listId]);

  const numCompanies =
    dataGridRows.length - (createEmptyRow ? 1 : 0) - (runningRows ? runningRows.length : 0);

  const heights = {
    rowHeight: 42,
    headerHeight: 42,
    toolbarHeight: disableToolBar ? 0 : 50,
    marginTop: 6
  };

  const allRowsSize = dataGridRows.length * heights.rowHeight;
  const headerAndHeaderGroupSize = 2 * heights.headerHeight;
  const tableMaxHeight = allRowsSize + headerAndHeaderGroupSize + heights.toolbarHeight;
  const tableScrollbarSpace = 32;
  const aboveTableContentHeight = tableHeaderHeight + heights.marginTop;
  const isTableScrollable =
    windowSize[1] - aboveTableContentHeight - tableScrollbarSpace <= tableMaxHeight;
  const tableHeight = windowSize[1] - Math.max(0, aboveTableContentHeight - scrollPosition);
  useEffect(() => {
    setIsFullScreen(isTableScrollable);
  }, [isTableScrollable, setIsFullScreen]);

  useEffect(() => {
    if (!apiRef.current?.subscribeEvent) return;

    const unsubscribeDragStart = apiRef.current.subscribeEvent(
      'columnHeaderDragStart',
      (column) => {
        draggedColumnState.current = { draggedColumnId: column.field };
      }
    );
    const unsubscribeDragEnter = apiRef.current.subscribeEvent(
      'columnHeaderDragEnter',
      (column) => {
        if (
          column.field !== draggedColumnState?.current?.overColumnId &&
          column.field !== draggedColumnState?.current?.draggedColumnId
        ) {
          const currentState = apiRef.current?.exportState();
          if (currentState) {
            const orderColumns = [...currentState.columns.orderedFields];
            const draggedIndex = orderColumns.indexOf(draggedColumnState.current.draggedColumnId);
            const overIndex = orderColumns.indexOf(column.field);

            const swappedColumnId = orderColumns[draggedIndex];
            orderColumns[draggedIndex] = orderColumns[overIndex];
            orderColumns[overIndex] = swappedColumnId;

            draggedColumnState.current.overColumnId = column.field;

            apiRef.current?.restoreState({
              ...currentState,
              columns: {
                ...currentState.columns,
                orderedFields: orderColumns
              }
            });
          }
        }
      }
    );
    const unsubscribeDragEnd = apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
      draggedColumnState.current = null;
      const dataGridState = apiRef.current?.exportState?.();
      const newColumnsOrder = dataGridState?.columns?.orderedFields;
      if (
        newColumnsOrder &&
        (!columnsOrder ||
          newColumnsOrder.length !== columnsOrder.length ||
          !newColumnsOrder.every((colId, index) => colId === columnsOrder[index]))
      ) {
        onColumnsOrderChange(newColumnsOrder);
      }
    });
    return () => {
      unsubscribeDragStart();
      unsubscribeDragEnter();
      unsubscribeDragEnd();
    };
  }, [apiRef, onColumnsOrderChange, columnsOrder]);

  if (columns.length < 1) {
    return <Typography variant="h3">No table data found.</Typography>;
  }

  const columnShouldHide = (col) => {
    return (
      col.data_type === 'meta' || col.data_type === 'custom_analytics_meta' || hiddenColumns[col.id]
    );
  };
  const columnVisibilityModel = columns
    .filter(columnShouldHide)
    .map((col) => col.id)
    .reduce((acc, id) => {
      acc[id] = false;
      return acc;
    }, {});
  return (
    <div style={{ width: '100%' }}>
      {showTitle && (
        <React.Fragment>
          <Typography variant="paragraphBold" color="secondary.secondary6">
            {title}
          </Typography>
          <br />
        </React.Fragment>
      )}
      <DataGridPremium
        onError={(errorData) =>
          oldDispatch(handleErrorAction(errorData.error, errorData.errorInfo))
        }
        initialState={{
          pinnedColumns: { left: ['company_name'] }
        }}
        apiRef={apiRef}
        rows={dataGridRows}
        columns={dataGridColumns}
        columnHeaderHeight={heights.headerHeight}
        rowHeight={heights.rowHeight}
        autoHeight={!isTableScrollable}
        columnGroupingModel={columnGrouping}
        disableRowSelectionOnClick={true}
        rowsPerPageOptions={[Math.min(4, dataGridRows.length)]}
        hideFooter={true}
        columnVisibilityModel={columnVisibilityModel}
        pagination={false}
        page={currentPage}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onCellClick={onCellClick}
        onColumnResize={onColumnResize}
        onSortModelChange={(data) => {
          if (data?.[0]?.field) {
            dispatch(
              sendExploreEvent(
                USER_EVENTS.COLUMN_SORTING,
                listId,
                `Sorted list by ${data[0].field}`,
                {
                  column: data[0].field,
                  listId,
                  sort: data[0].sort
                }
              )
            );
          }
        }}
        getCellClassName={(params) => {
          if (params.value?.running) {
            return 'running';
          }
          if (params.row.company_name?.highlighted) {
            return 'highlighted';
          }
          return '';
        }}
        loading={isLoadingRows}
        slots={{
          pagination: CustomPagination,
          toolbar: disableToolBar ? null : TableComponentToolbar,
          loadingOverlay: DiscoveryTableLoader,
          columnMenu: CustomColumnMenu
        }}
        slotProps={{
          toolbar: {
            excelOptions: {
              fields: csvFields,
              fileName: title,
              getRowsToExport: () =>
                apiRef.current
                  ?.getSortedRows()
                  ?.filter((row) => row.company_name?.name)
                  ?.map((row) => row.id)
            },
            csvOptions: {
              fields: csvFields,
              fileName: title,
              getRowsToExport: () =>
                apiRef.current
                  ?.getSortedRows()
                  ?.filter((row) => row.company_name?.name)
                  ?.map((row) => row.id)
            },
            printOptions: { disableToolbarButton: true },
            isEmpty: numCompanies <= 0,
            elementId,
            viewId,
            allRows,
            filteredRowsNum: numCompanies,
            listId,
            ...toolbarArgs
          },
          loadingOverlay: {
            listId
          }
        }}
        sx={{
          marginTop: `${heights.marginTop}px`,
          height: tableHeight,
          ...sxExtra,
          zIndex: 0,
          '.MuiDataGrid-columnSeparator': {
            display: 'block',
            color: 'transparent'
          },
          '.MuiDataGrid-columnSeparator--resizable': {
            '&:hover': {
              color: 'transparent'
            },
            '&.MuiDataGrid-columnSeparator--resizing': {
              color: 'transparent'
            }
          },
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%'
          }
        }}
        onStateChange={handleStateChange}
      />
    </div>
  );
}

DiscoveryTable.propTypes = {
  listId: PropTypes.number,
  viewId: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  columnGrouping: PropTypes.arrayOf(PropTypes.object),
  showTitle: PropTypes.bool,
  elementId: PropTypes.string,
  pageSize: PropTypes.number,
  usePagination: PropTypes.bool,
  onCellClick: PropTypes.func,
  onColumnResize: PropTypes.func,
  onColumnsOrderChange: PropTypes.func,
  createEmptyRow: PropTypes.func,
  isLoadingRows: PropTypes.bool,
  tableHeaderHeight: PropTypes.number,
  setIsFullScreen: PropTypes.func,
  filterRow: PropTypes.func,
  hiddenColumns: PropTypes.object,
  disableToolBar: PropTypes.bool,
  toolbarArgs: PropTypes.object,
  scrollElement: PropTypes.object,
  runningRows: PropTypes.array,
  highlightedColumns: PropTypes.array
};

DiscoveryTable.defaultProps = {
  disableToolBar: false,
  hiddenColumns: {}
};

export default React.memo(DiscoveryTable);
