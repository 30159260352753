/* eslint-disable require-unicode-regexp */
import { Box, Tooltip } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { generateNameFromQuery } from 'actions/llm_queries/generate-name-for-column-action';
import { addSavedQueryAction } from 'actions/saved_queries/add-saved-query-action';
import { deleteSavedQueryAction } from 'actions/saved_queries/delete-saved-query-action';
import IconButtonComponent from 'components/IconButtonComponent';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import BookmarkIcon from 'icons/BookmarkIcon';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { isEmpty } from 'utils/objects-utils';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import SetQueryNameDialog from './SetQueryNameDialog';

const SaveQueryDialog = forwardRef(
  (
    {
      prompt,
      isSaved,
      setIsSaved,
      existColumnName,
      onSaveQuery,
      listId,
      showIsBookmarked,
      isWorkflow
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { addToast } = useAppUtils();

    const [columnName, setColumnName] = useState('');
    const [open, setOpen] = useState(false);
    const [genratingName, setGenratingName] = useState(false);

    const savedQueries = useSelector(getAllSavedQueries);
    const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));

    const [queryType, actionName] = useMemo(() => {
      if (prompt.question.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`)) {
        return [SAVED_QUERY_ACTION_TYPES.COLUMN, 'Column'];
      }
      if (isWorkflow) {
        return [SAVED_QUERY_ACTION_TYPES.WORKFLOW, 'Workflow'];
      }
      return [SAVED_QUERY_ACTION_TYPES.ASK, 'Question'];
    }, [prompt, isWorkflow]);

    const disableMessage = useMemo(() => {
      if (prompt?.supporting_resource_id) {
        return 'Saving query that uses added context is not supported';
      }
      if (isEmpty(customColumns) || isEmpty(prompt?.columns)) {
        return '';
      }
      const customColumnIds = Object.keys(customColumns);
      if (prompt?.columns?.some((column) => customColumnIds.includes(column))) {
        return 'Saving query that uses added columns in not supported';
      }
      return '';
    }, [customColumns, prompt]);

    useEffect(() => {
      if (existColumnName) {
        setColumnName(existColumnName.replace(/\s*\(.*?\)$/, ''));
      }
    }, [existColumnName]);

    const closeDialog = () => {
      setOpen(false);
      setColumnName(existColumnName?.replace(/\s*\(.*?\)$/, '') || '');
    };

    useEffect(() => {
      if (prompt?.saved_query_id) {
        setIsSaved(Boolean(savedQueries?.[prompt?.saved_query_id]));
      }
    }, [prompt?.saved_query_id, savedQueries, setIsSaved]);

    const handleSaveQuery = () => {
      if (isSaved) return;
      const promptToSave = { ...prompt };
      delete promptToSave?.company_meta_ids;
      delete promptToSave?.total_num_companies;
      delete promptToSave?.post_id;
      delete promptToSave?.folder_id;

      setIsSaved(true);
      dispatch(
        addSavedQueryAction({
          name: columnName,
          parameters: promptToSave,
          query_type: queryType,
          description: null,
          addToast,
          onSaveQuery
        })
      );
      closeDialog();
    };

    const onOpenDialog = async () => {
      if (isSaved && prompt?.saved_query_id) {
        setIsSaved(false);
        dispatch(deleteSavedQueryAction(prompt?.saved_query_id, addToast));
        return;
      }
      if (disableMessage) return;
      setOpen(true);

      if (!columnName) {
        setGenratingName(true);
        const generatedName = await dispatch(generateNameFromQuery(prompt.question));
        if (generatedName?.data?.answer) {
          setColumnName(generatedName?.data?.answer);
        }
        setGenratingName(false);
      }
    };

    useImperativeHandle(ref, () => ({
      notifyFromParent() {
        onOpenDialog();
      }
    }));

    return (
      <div>
        <Tooltip title={disableMessage}>
          <Box>
            <IconButtonComponent
              id="save-query-button"
              disabled={Boolean(disableMessage)}
              onClick={onOpenDialog}
              sx={{ padding: 0 }}
              tag={`${isSaved ? 'Remove from' : 'Save to'} ${actionName} Library`}
              extraProps={{ 'aria-pressed': isSaved }}>
              <BookmarkIcon
                isBookmarked={isSaved && showIsBookmarked}
                disabled={Boolean(disableMessage)}
              />
            </IconButtonComponent>
          </Box>
        </Tooltip>
        <SetQueryNameDialog
          title={`Save to ${actionName} Library`}
          open={open}
          onClose={closeDialog}
          columnName={columnName}
          setColumnName={setColumnName}
          onSaveQuery={handleSaveQuery}
          isDisabled={genratingName}
          actionName={actionName}
        />
      </div>
    );
  }
);

SaveQueryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  prompt: PropTypes.object,
  isSaved: PropTypes.bool,
  setIsSaved: PropTypes.func,
  existColumnName: PropTypes.string,
  onSaveQuery: PropTypes.func,
  showIsBookmarked: PropTypes.bool,
  isWorkflow: PropTypes.bool
};

SaveQueryDialog.defaultProps = {
  showIsBookmarked: true
};
SaveQueryDialog.displayName = 'SaveQueryDialog';

export default SaveQueryDialog;
