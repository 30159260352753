import { Box, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import DomainReportClusterExample from './DomainReportClusterExample';
import DomainReportClusterExpandable from './DomainReportClusterExpandable';

const TitleComponent = ({ title, description, size }) => {
  const height = size * 4;
  return (
    <Stack direction="row" alignItems="center" gap="24px" paddingY="16px">
      <Stack justifyContent="center" alignItems="center" sx={{ width: '40px', height: '40px' }}>
        <Box
          sx={{
            width: `${height * 2}px`,
            height: `${height * 2}px`,
            borderRadius: `${Math.ceil(height / 4)}px`,
            backgroundColor: '#CFD5FC'
          }}></Box>
      </Stack>
      <Stack gap="4px">
        <Typography variant="paragraphMedium" color="colors.primary_text">
          {title}
        </Typography>
        <Typography variant="paragraph" color="colors.secondary_text">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

function DomainReportClusterItem({ title, companies, size, loading }) {
  if (loading) {
    return (
      <Stack direction="row" gap="24px" paddingY="16px" alignItems="center">
        <Skeleton variant="circular" width="40px" height="40px" sx={{ borderRadius: '4px' }} />
        <Stack gap="8px">
          <Skeleton variant="text" height="32px" width="300px" />
          <Skeleton variant="text" height="20px" width="600px" />
        </Stack>
      </Stack>
    );
  }
  return (
    <DomainReportClusterExpandable
      titleComponent={
        <TitleComponent title={title} size={size} description={'Description of this domain'} />
      }>
      <DomainReportClusterExample companies={companies} />
    </DomainReportClusterExpandable>
  );
}

DomainReportClusterItem.propTypes = {
  title: PropTypes.string,
  companies: PropTypes.array,
  size: PropTypes.number,
  loading: PropTypes.bool
};
TitleComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.number
};

export default DomainReportClusterItem;
