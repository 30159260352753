import { Button, Stack, Typography } from '@mui/material';
import { exportDiscoveryQueriesHistoryAction } from 'actions/explore/export-discovery-queries-history-action';
import { loadDiscoveryCustomAnalyticsQuestionsHistoryAction } from 'actions/explore/load-discovery-custom-analytics-questions-history-action';
import LoaderComponent from 'components/LoaderComponent';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsThreadPostsById } from 'selectors/custom-analytics';
import { formatTimestampRelative } from 'utils/time-utils';

import PromptDisplayComponent from './PromptDisplayComponent';

function ExploreQueriesHistoryDialog({ listId, open, onClose }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const threadKey = `${getThreadKey(listId)}-history`;
  const historyThread = useSelector((state) => getCustomAnalyticsThreadPostsById(state, threadKey));

  const actualQueries = useMemo(() => {
    if (!historyThread) return null;
    return Object.values(historyThread)
      .sort((post1, post2) => new Date(post1.body.createdAt) - new Date(post2.body.createdAt))
      .reverse();
  }, [historyThread]);

  useEffect(() => {
    dispatch(loadDiscoveryCustomAnalyticsQuestionsHistoryAction(listId, () => setIsLoading(false)));
  }, [listId, dispatch]);

  const handleDownloadFile = () => {
    dispatch(exportDiscoveryQueriesHistoryAction(listId, actualQueries));
  };

  return (
    <ModalComponent
      title="Queries History"
      open={open}
      maxWidth={800}
      onClose={onClose}
      bottomBar={
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
          padding="0 24px"
          sx={{
            width: '100%'
          }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDownloadFile}>
            Export To CSV
          </Button>
        </Stack>
      }>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack direction="column" gap="16px" padding="24px">
          {actualQueries?.map((post) => (
            <Stack
              key={post.postId}
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap="16px"
              sx={{
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'greyColors.grey250',
                paddingRight: '8px'
              }}>
              <PromptDisplayComponent listId={listId} prompt={post.query} mode="plain" />
              <Typography variant="tiny" color="greyColors.grey200">
                {formatTimestampRelative(post.body.createdAt)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </ModalComponent>
  );
}

ExploreQueriesHistoryDialog.propTypes = {
  listId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ExploreQueriesHistoryDialog;
