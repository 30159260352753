import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';

function MaxLinesTypography({ maxLines, sx, children, ...rest }) {
  const ref = useRef();

  const [allowTooltip, setAllowTooltip] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    setAllowTooltip(ref.current?.scrollHeight > ref.current?.offsetHeight);
  }, [ref.current?.offsetHeight, ref.current?.scrollHeight]);

  const actualSx = useMemo(() => {
    const newSx = sx ? { ...sx } : {};
    newSx.display = '-webkit-box';
    newSx.overflow = 'hidden';
    newSx.WebkitBoxOrient = 'vertical';
    newSx.WebkitLineClamp = maxLines;
    return newSx;
  }, [maxLines, sx]);

  return (
    <Tooltip
      title={children}
      open={allowTooltip && isHover}
      slotProps={{ tooltip: { sx: { whiteSpace: 'pre-wrap' } } }}>
      <Typography
        ref={ref}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        whiteSpace="pre-line"
        sx={actualSx}
        {...rest}>
        {children}
      </Typography>
    </Tooltip>
  );
}

MaxLinesTypography.propTypes = {
  maxLines: PropTypes.number,
  sx: PropTypes.object,
  children: PropTypes.any
};

export default MaxLinesTypography;
