import { Box, Stack, Typography } from '@mui/material';
import { loadAllExploreLists } from 'actions/explore/load-all-explores-action';
import { loadAllPersons } from 'actions/persons/load-all-persons-action';
import { loadAllRuns } from 'actions/runs/load-all-runs-action';
import { getSchedulesAction } from 'actions/schedules/get-schedules-action';
import { removeRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import RunningSearchActionCompany from 'components/tile/company/RunningSearchAction';
import RunningSearchActionDiscovery from 'components/tile/discovery/RunningSearchAction';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import ArrowIcon from 'icons/ArrowIcon';
import RerunIcon from 'icons/RerunIcon';
import { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAllCurrentlyRunningDiscoveries, getAllDiscoveries } from 'selectors/discoveries';
import { getCurrentlyRunningPersons } from 'selectors/persons';
import { getAllRecentlyFinishedRuns, getCurrentlyRunningCompanies } from 'selectors/runs';

import IconButtonComponent from './IconButtonComponent';
import PusherListListener from './pusher/PusherListListener';
import RunningSearchActionPerson from './tile/person/RunningSearchAction';

const isHomePath = (path) => {
  if (path === '/') return true;
  if (path.startsWith('/folders/')) return true;
  return false;
};

export default function ProgressMonitoringComponent() {
  const [openProgressMonitor, setOpenProgressMonitor] = useState(true);
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const currentlyRunningCompanies = getCurrentlyRunningCompanies(state)(state);
  const currentlyRunningDiscoveries = useSelector((reduxState) =>
    getAllCurrentlyRunningDiscoveries(reduxState)
  );

  const currentlyRunningPresons = getCurrentlyRunningPersons(state)(state);

  const recentlyFinishedRuns = getAllRecentlyFinishedRuns(state);

  const currentLocation = useLocation();
  const isOnExploreList = currentLocation?.pathname?.includes('/explore/');
  const currentCompanyListId = isOnExploreList
    ? parseInt(currentLocation.pathname.split('/explore/').slice(-1), 10)
    : null;

  const allDiscoveries = useSelector((reduxState) => getAllDiscoveries(reduxState));

  // if we are on the home page, we already loaded the runs
  const [alreadyLoaded, setAlreadyLoaded] = useState(isHomePath(currentLocation?.pathname));

  useEffect(() => {
    if (alreadyLoaded) return;
    dispatch(loadAllRuns({ num: 50, overwrite: false }));
    dispatch(loadAllPersons({ num: 50 }));
    reduxDispatch(loadAllExploreLists({ num: 50 }));

    setAlreadyLoaded(true);
  }, [alreadyLoaded, reduxDispatch]);

  useEffect(() => {
    Object.values(recentlyFinishedRuns).forEach((run) => {
      if (
        (!state[run.type]?.[run.id] && !allDiscoveries?.[run.id]) ||
        (run.type === 'discoveries' &&
          currentCompanyListId &&
          allDiscoveries[run.id].companyListId === currentCompanyListId)
      ) {
        dispatch(removeRecentlyFinishedRun(run.id));
      }
    });
  }, [state, recentlyFinishedRuns, currentCompanyListId, allDiscoveries]);

  useEffect(() => {
    reduxDispatch(getSchedulesAction());
  }, [reduxDispatch]);
  const runTypeTypographyStyle = {
    variant: 'paragraphMedium',
    textAlign: 'left',
    padding: '24px 16px 4px 24px',
    color: 'colors.primary_text'
  };

  const uniqueListIds = useMemo(() => {
    return new Set(
      Object.values(currentlyRunningDiscoveries).map((search) => search.companyListId)
    );
  }, [currentlyRunningDiscoveries]);

  const runsSections = useMemo(() => {
    return [
      {
        title: 'Discover Companies',
        runs: Object.values(currentlyRunningDiscoveries).reduce((prev, search) => {
          if (allDiscoveries[search.id].companyListId !== currentCompanyListId) {
            prev[search.id] = search;
          }
          return prev;
        }, {}),
        runningSearchComponent: (props) => <RunningSearchActionDiscovery {...props} />,
        type: 'discoveries'
      },
      {
        title: 'Company Insights',
        runs: currentlyRunningCompanies,
        runningSearchComponent: (props) => <RunningSearchActionCompany {...props} />,
        type: 'runs'
      },
      {
        title: 'Leadership Reports',
        runs: currentlyRunningPresons,
        runningSearchComponent: (props) => <RunningSearchActionPerson {...props} />,
        type: 'persons'
      }
    ]
      .map((section) => ({
        ...section,
        sectionRecentlyFinishedRuns: Object.keys(recentlyFinishedRuns).filter(
          (runId) =>
            recentlyFinishedRuns[runId].type === section.type &&
            (state[section.type]?.[runId] || allDiscoveries?.[runId]) &&
            (section.type !== 'discoveries' ||
              !currentCompanyListId ||
              allDiscoveries[runId].companyListId !== currentCompanyListId)
        )
      }))
      .map((section) => ({
        ...section,
        sectionHasRuns:
          Object.keys(section.runs).length ||
          Object.keys(section.sectionRecentlyFinishedRuns).length
      }));
  }, [
    currentlyRunningDiscoveries,
    currentlyRunningCompanies,
    recentlyFinishedRuns,
    currentlyRunningPresons,
    currentCompanyListId,
    state,
    allDiscoveries
  ]);

  const hasRuns = useMemo(
    () => runsSections.filter((section) => section.sectionHasRuns).length > 0,
    [runsSections]
  );
  if (!hasRuns) {
    return null;
  }

  return (
    <Draggable>
      <Stack
        sx={{
          minWidth: '25%',
          width: 'fit-content',
          position: 'fixed',
          bottom: '5%',
          right: 24,
          zIndex: 'statusMonitor',
          boxShadow: '0px 4px 8px 0px rgba(11, 28, 56, 0.3)'
        }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            color: 'white',
            padding: '4px 16px',
            gap: '8px',
            backgroundColor: 'secondary.secondary1'
          }}>
          <RerunIcon />
          <Typography variant="paragraphMedium">Running Researches</Typography>
          <IconButtonComponent
            onClick={() => setOpenProgressMonitor(!openProgressMonitor)}
            sx={{
              transform: openProgressMonitor ? 'rotate(180deg)' : 'rotate(0)',
              marginLeft: 'auto'
            }}>
            <ArrowIcon color="white" />
          </IconButtonComponent>
        </Stack>
        {openProgressMonitor && (
          <Stack
            sx={{
              maxHeight: '300px',
              backgroundColor: 'colors.primary_bg',
              overflowY: 'scroll'
            }}>
            {uniqueListIds &&
              Array.from(uniqueListIds).map((listId) => (
                <PusherListListener key={listId} listId={listId} />
              ))}
            {runsSections
              .filter((section) => section.sectionHasRuns)
              .map((section) => (
                <Stack key={section.title}>
                  <Typography {...runTypeTypographyStyle}>{section.title}</Typography>
                  {section.sectionRecentlyFinishedRuns.map((runId) => (
                    <Box
                      padding="16px 24px"
                      key={runId}
                      borderBottom="1px solid"
                      borderColor="colors.ui_border">
                      {section.runningSearchComponent({
                        id: runId,
                        mode: 'monitor',
                        recentlyFinished: true
                      })}
                    </Box>
                  ))}
                  {Object.keys(section.runs).map((runId) => (
                    <Box
                      padding="16px 24px"
                      key={runId}
                      borderBottom="1px solid"
                      borderColor="colors.ui_border">
                      {section.runningSearchComponent({
                        id: runId,
                        recordId: section.runs[runId].recordId,
                        mode: 'monitor'
                      })}
                    </Box>
                  ))}
                </Stack>
              ))}
          </Stack>
        )}
      </Stack>
    </Draggable>
  );
}
