import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function RenderBulkReportHeader({ colDef, allowCheckbox, showCheckBox, isChecked, onCheck }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const helper = (event) => {
    const targetElement = event.target;
    if (!showTooltip && targetElement.scrollWidth > targetElement.clientWidth) {
      setShowTooltip(true);
    } else if (showTooltip) {
      setShowTooltip(false);
    }
  };

  const onMouseEnter = (event) => {
    helper(event);
    setIsHover(true);
  };

  return (
    <Tooltip title={colDef.headerName} arrow={true} open={showTooltip}>
      <Stack
        className="bulk-company-name"
        direction="row"
        gap="8px"
        alignItems="center"
        width="100%"
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => {
          setShowTooltip(false);
          setIsHover(false);
        }}>
        {allowCheckbox && (showCheckBox || isHover) && (
          <FormControlLabel
            sx={{ width: '16px', height: '16px', padding: '0' }}
            control={
              <Checkbox
                checked={isChecked}
                onClickCapture={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onCheck(!isChecked);
                }}
              />
            }
          />
        )}
        <Typography
          variant="paragraphSemiBold"
          color="colors.primary_text"
          paddingLeft={
            colDef.field === 'company_name' && (!allowCheckbox || !(showCheckBox || isHover))
              ? '29px'
              : 0
          }
          noWrap
          display="block">
          {colDef.headerName}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

RenderBulkReportHeader.propTypes = {
  colDef: PropTypes.object,
  showCheckBox: PropTypes.bool,
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  allowCheckbox: PropTypes.bool
};
export default React.memo(RenderBulkReportHeader);
