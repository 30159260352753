import { FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const selectStyle = {
  minWidth: '150px',
  width: 'auto',
  maxWidth: '300px',
  height: '28px',
  textAlign: 'left',
  backgroundColor: 'colors.primary_bg',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.ui_border'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.ui_border'
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      minWidth: 200,
      maxWidth: 300
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 100
  }
};

function SlimSelectComponent({
  selectedValue,
  allValues,
  emptyTag,
  onValueChange,
  inputLabel,
  allowNone,
  sx
}) {
  const allValuesMap = useMemo(
    () =>
      allValues.reduce((map, value) => {
        map[value.id] = value;
        return map;
      }, {}),
    [allValues]
  );

  const handleChange = (event) => {
    onValueChange(event.target?.value);
  };

  return (
    <Stack gap="4px" textAlign="left" sx={sx}>
      {inputLabel && (
        <Typography variant="caption" color="colors.primary_text" sx={{ marginLeft: '2px' }}>
          {inputLabel}:
        </Typography>
      )}
      <FormControl>
        <Select
          value={selectedValue}
          displayEmpty
          MenuProps={MenuProps}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography variant="paragraphMedium" color="greyColors.grey150">
                  {emptyTag}
                </Typography>
              );
            }
            return (
              <Typography variant="paragraphMedium" color="colors.primary_text">
                {allValuesMap[selected]?.name}
              </Typography>
            );
          }}
          sx={selectStyle}
          onChange={handleChange}>
          {allowNone && (
            <MenuItem value={null}>
              <Typography variant="paragraphMedium" color="greyColors.grey150">
                None
              </Typography>
            </MenuItem>
          )}
          {allValues &&
            allValues.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                <Typography variant="paragraphMedium" color="colors.primary_text">
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

SlimSelectComponent.propTypes = {
  maxWidth: PropTypes.number,
  selectedValue: PropTypes.array,
  allValues: PropTypes.array,
  onValueChange: PropTypes.func,
  allTag: PropTypes.string,
  inputLabel: PropTypes.string,
  emptyTag: PropTypes.string,
  allowNone: PropTypes.bool,
  sx: PropTypes.object
};

SlimSelectComponent.defaultProps = {
  emptyTag: '',
  sx: {}
};

export default SlimSelectComponent;
