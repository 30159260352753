import { useMediaQuery } from '@mui/material';
import PieChartLegendComponent from 'components/elements/pie/PieChartLegendComponent';
import { ELEMENT_HEIGHTS } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { appTheme } from 'theme';
import { determinePieColor } from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';
import PieChartActiveShapeComponent from './PieChartActiveShapeComponent';

export default function PieChartComponent({
  elementId,
  values,
  labels,
  title,
  mode,
  selected,
  setClickItem,
  overrideColor
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const [currentCellIndex, setCurrentCellIndex] = useState(-1);
  const [clickItemIndex, setClickItemIndex] = useState(labels?.indexOf(selected));
  const [parentWidth, setParentWidth] = useState();
  const [stateLabels, setStateLabels] = useState();

  useEffect(() => {
    setClickItemIndex(-1);
    setStateLabels(labels);
  }, [labels, setClickItemIndex]);

  useEffect(() => {
    if (setClickItem && stateLabels) {
      setClickItem(stateLabels[clickItemIndex]);
    }
  }, [clickItemIndex, setClickItem, stateLabels]);

  const selectedIndex = labels?.indexOf(selected);

  const data = useMemo(() => {
    const total = values?.reduce((partialSum, value) => partialSum + value, 0);
    const percentages = values?.map((value) => Math.round((100 * value) / total));

    return (
      values?.map((value, index) => ({
        value,
        name: labels[index],
        percentage: percentages[index]
      })) || []
    ).sort((item1, item2) => item2.value - item1.value);
  }, [values, labels]);
  const shouldCollapseLegend = parentWidth && parentWidth < 400;

  const determineStroke = (index) => {
    return index === currentCellIndex ? '8' : '0';
  };

  const determineColor = (index) => determinePieColor(index, overrideColor, labels, elementId);
  const nonPowerPointHeight = mediumViewport ? ELEMENT_HEIGHTS.SMALLER : 360;
  return (
    <RechartsBox
      title={title}
      height={mode === 'powerpoint' ? '100%' : nonPowerPointHeight}
      mode={mode}
      widthTracker={mode === 'powerpoint' ? null : setParentWidth}>
      <PieChart>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align={shouldCollapseLegend ? 'center' : 'right'}
          content={
            <PieChartLegendComponent
              setHoverItem={setCurrentCellIndex}
              clickItemIndex={selectedIndex}
              setClickItemIndex={setClickItemIndex}
              mode={mode}
            />
          }
          wrapperStyle={{
            top: shouldCollapseLegend && mediumViewport ? '90%' : '50%',
            left: '50%',
            width: shouldCollapseLegend ? '80%' : '48%',
            transform: shouldCollapseLegend ? 'translateX(-50%)' : 'translateY(-50%)'
          }}
        />
        <Pie
          cx={shouldCollapseLegend ? '50%' : '40%'}
          cy={mediumViewport ? '50%' : '25%'}
          data={data}
          innerRadius={mode === 'powerpoint' ? '48%' : 48}
          outerRadius={mode === 'powerpoint' ? '70%' : 70}
          activeIndex={selectedIndex}
          activeShape={PieChartActiveShapeComponent}
          paddingAngle={data.length > 1 ? 2 : 0}
          dataKey="value">
          {data.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={determineColor(index)}
              stroke={determineColor(index)}
              strokeWidth={determineStroke(index)}
            />
          ))}
        </Pie>
      </PieChart>
    </RechartsBox>
  );
}

PieChartComponent.propTypes = {
  elementId: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  defaultSelected: PropTypes.string,
  setClickItem: PropTypes.func,
  selected: PropTypes.string,
  overrideColor: PropTypes.string,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
