export const DOMAIN_REPORT_TASK_TYPES = Object.freeze({
  TAGGING: 'TAGGING',
  TOP_PROBLEMS: 'TOP_PROBLEMS',
  MARKET_OVERVIEW: 'MARKET_OVERVIEW'
});

export const DOMAIN_REPORT_TASK_TYPES_TO_TITLES = Object.freeze({
  [DOMAIN_REPORT_TASK_TYPES.TAGGING]: 'Cluster Analysis',
  [DOMAIN_REPORT_TASK_TYPES.TOP_PROBLEMS]: 'Top Problems',
  [DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW]: 'Market Overview'
});
