import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRTaskProgressByListId,
  getLatestDRTaskStatusByListId
} from 'selectors/domain-reports';

import DomainReportEditButton from './edit/DomainReportEditButton';

const RADIUS = 16;
const PROGRESS_HEIGHT = 6;

function DomainReportBox({ title, listId, taskType, editable, children }) {
  const [hover, setHover] = useState(false);
  const progress = useSelector((state) => getLatestDRTaskProgressByListId(state, listId, taskType));
  const status = useSelector((state) => getLatestDRTaskStatusByListId(state, listId, taskType));
  const showProgress = status !== 'FAILED' && progress && progress < 1 && progress > 0;
  const borderRadius = `${RADIUS}px`;
  const height = `${PROGRESS_HEIGHT}px`;
  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        overflowX: 'hidden',
        backgroundColor: 'colors.primary_bg',
        borderRadius,
        color: 'colors.primary_text',
        maxWidth: '802px'
      }}>
      {status === 'FAILED' && (
        <Box
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            width: '100%',
            height,
            backgroundColor: 'colors.negative_red'
          }}
        />
      )}
      {showProgress ? (
        <Box
          sx={{
            width: '100%',
            height,
            backgroundColor: 'colors.selected'
          }}>
          <Box
            sx={{
              borderBottomRightRadius: progress < 1 ? '9px' : '0px',
              borderBottomLeftRadius: '0px',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: progress < 1 ? '9px' : '16px',
              width: `${progress * 100}%`,
              height,
              backgroundColor: 'graphColors.graph13'
            }}
          />
        </Box>
      ) : null}
      <Stack
        gap="12px"
        sx={{
          paddingY: '24px',
          paddingX: '32px',
          maxWidth: '802px'
        }}>
        <Stack direction="row" gap="24px" alignItems="center" height="20px">
          <Typography variant="h4" color="colors.primary_text">
            {title}
          </Typography>
          {editable && taskType && hover && (
            <DomainReportEditButton listId={listId} taskType={taskType} />
          )}
        </Stack>
        {status === 'FAILED' ? <Typography variant="paragraph">Job failed.</Typography> : children}
      </Stack>
    </Stack>
  );
}

DomainReportBox.propTypes = {
  title: PropTypes.string,
  running: PropTypes.bool,
  listId: PropTypes.number,
  taskType: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.node
};

export default DomainReportBox;
