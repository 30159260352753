import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  savedQueries: {}
};

export const savedQueriesSlice = createSlice({
  name: 'saved_queries',
  initialState: initialSliceState,
  reducers: {
    addAllSavedQueries: (sliceState, { payload }) => {
      const { savedQueries } = payload;
      sliceState.savedQueries = savedQueries;
    },
    removeSavedQuery: (sliceState, { payload }) => {
      const { savedQueryId } = payload;
      delete sliceState.savedQueries[savedQueryId];
    },
    addSavedQuery: (sliceState, { payload }) => {
      const { savedQuery } = payload;
      sliceState.savedQueries[savedQuery.id] = savedQuery;
    },
    renameSavedQuery: (sliceState, { payload }) => {
      const { savedQueryId, name } = payload;
      sliceState.savedQueries[savedQueryId].name = name;
    },
    updateSavedQuery: (sliceState, { payload }) => {
      const { savedQueryId, data } = payload;
      if (sliceState.savedQueries[savedQueryId]) {
        sliceState.savedQueries[savedQueryId].parameters = data;
      }
    }
  }
});

export const {
  addAllSavedQueries,
  removeSavedQuery,
  addSavedQuery,
  renameSavedQuery,
  updateSavedQuery
} = savedQueriesSlice.actions;

export default savedQueriesSlice.reducer;
