import { addExploreSubdomains } from 'reducer/explore-slice';

export function addSubdomainsAction(
  company_list_id,
  parentId,
  newSubdomains,
  level,
  onCreated,
  streamed,
  parameters
) {
  return (dispatch, getState, apiClient) => {
    const subdomainsSubmissionPayload = {
      subdomains: newSubdomains,
      company_list_id,
      level,
      parent_subdomain_id: parentId,
      parameters
    };

    apiClient.subdomains.addSubdomains(subdomainsSubmissionPayload).then((res) => {
      if (res.ok && res.data) {
        const { subdomains } = res.data;
        let formattedSubdomains = subdomains;
        if (streamed) {
          formattedSubdomains = subdomains.map((subdomain) => ({
            ...subdomain,
            streamed: true
          }));
        }
        dispatch(
          addExploreSubdomains({ listId: company_list_id, subdomains: formattedSubdomains })
        );
        onCreated?.(subdomains);
      }
    });
  };
}
