/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  domainReports: {},
  defaultConfig: null
};

export const domainReportsSlice = createSlice({
  name: 'domainReport',
  initialState: initialSliceState,
  reducers: {
    addDefaultConfig: (sliceState, { payload }) => {
      const { default_config } = payload;
      sliceState.defaultConfig = default_config;
    },
    addDomainReport: (sliceState, { payload }) => {
      const { listId, data } = payload;
      const { domain_report_id, exists, status, domain_report } = data;
      if (!exists) {
        return;
      }
      if (!sliceState.domainReports[listId]) {
        sliceState.domainReports[listId] = {};
      }
      sliceState.domainReports[listId][domain_report_id] = {
        ...(domain_report || {}),
        domain_report_id,
        status: status || 'RUNNING'
      };
    },
    updateDomainReportProgress: (sliceState, { payload }) => {
      const { listId, domainReportId, postId, progress, message } = payload;
      if (!sliceState.domainReports[listId]) {
        return;
      }
      if (!sliceState.domainReports[listId][domainReportId]) {
        return;
      }
      if (!sliceState.domainReports[listId][domainReportId]?.custom_analytics) {
        return;
      }
      if (!sliceState.domainReports[listId][domainReportId]?.custom_analytics[postId]) {
        sliceState.domainReports[listId][domainReportId].custom_analytics[postId] = {};
      }
      sliceState.domainReports[listId][domainReportId].custom_analytics[postId].progress = progress;
      if (message) {
        sliceState.domainReports[listId][domainReportId].custom_analytics[postId].message = message;
      }
    }
  }
});

export const { addDomainReport, updateDomainReportProgress, addDefaultConfig } =
  domainReportsSlice.actions;

export default domainReportsSlice.reducer;
