import { Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addDiscoveryDomainKnowledgeResourceAction } from 'actions/explore/add-discovery-domain-knowledge-resource-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import AddDomainKnowledgeLinkDialog from 'components/domain-knowledge/AddDomainKnowledgeLinkDialog';
import DeleteDomainKnowledgeResourceDialog from 'components/domain-knowledge/DeleteDomainKnowledgeResourceDialog';
import DomainKnowledgeResourceTabPanel from 'components/domain-knowledge/DomainKnowledgeResourceTabPanel';
import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import AddFileIcon from 'icons/AddFileIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListDomainKnowledgeByListId } from 'selectors/explore';
import { getCurrentFolderSupportingResources } from 'selectors/folders';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { openChooseFile } from 'utils/download-utils';

function DomainKnowledgeTab({ listId, folderId }) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();

  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );

  const [openAddLink, setOpenAddLink] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);

  const { links, files, answers } = useMemo(() => {
    return domainKnowledge
      ? Object.values(domainKnowledge).reduce((result, item) => {
          if (!item) {
            return result;
          }
          if (!result[item.supporting_resource_type]) {
            result[item.supporting_resource_type] = [];
          }
          result[item.supporting_resource_type].push(item);
          return result;
        }, {})
      : {};
  }, [domainKnowledge]);

  const uploadFile = (file) => {
    if (file.size > 20 * 1000 * 1000) {
      addToast(
        `File size ${file.size / 1000 / 1000}MB is too big, file size could not be above 20MB`,
        TOAST_TYPES.ERROR
      );
      return;
    }
    dispatch(
      addDiscoveryDomainKnowledgeResourceAction({
        resourceName: file.name,
        resourceType: DOMAIN_KNOWLEDGE_RESOURCE_TYPE.FILES,
        data: file,
        listId,
        folderId
      })
    );
  };

  const uploadFiles = (filesList) => {
    Array.from(filesList).forEach((file) => uploadFile(file));
  };

  return (
    <Stack gap="64px">
      <Stack direction="row" justifyContent="space-between" gap="8px">
        <DomainKnowledgeResourceTabPanel
          title="links"
          data={links}
          listId={listId}
          folderId={folderId}
          setResourceToDelete={setResourceToDelete}
          resourceType="links"
          addElement={
            <IconButtonComponent onClick={() => setOpenAddLink(true)}>
              <Typography variant="h2" color="colors.primary_text">
                +
              </Typography>
            </IconButtonComponent>
          }></DomainKnowledgeResourceTabPanel>
        <DomainKnowledgeResourceTabPanel
          title="files"
          data={files}
          listId={listId}
          folderId={folderId}
          setResourceToDelete={setResourceToDelete}
          resourceType="files"
          addElement={
            <IconButtonComponent tag="Add Files" onClick={() => openChooseFile(uploadFiles, true)}>
              <AddFileIcon />
            </IconButtonComponent>
          }></DomainKnowledgeResourceTabPanel>
        <DomainKnowledgeResourceTabPanel
          title="Custom Analytics"
          data={answers}
          listId={listId}
          folderId={folderId}
          setResourceToDelete={setResourceToDelete}
          resourceType="answers"></DomainKnowledgeResourceTabPanel>
      </Stack>
      <AddDomainKnowledgeLinkDialog
        listId={listId}
        folderId={folderId}
        open={openAddLink}
        onClose={() => setOpenAddLink(false)}
      />
      <DeleteDomainKnowledgeResourceDialog
        listId={listId}
        folderId={folderId}
        supportingResource={resourceToDelete}
        open={Boolean(resourceToDelete)}
        onClose={() => setResourceToDelete(null)}
      />
    </Stack>
  );
}

DomainKnowledgeTab.propTypes = {
  listId: PropTypes.number,
  folderId: PropTypes.number
};

export default wrapWithError(DomainKnowledgeTab);
