import dagre from 'dagre';
import ColumnsIcon from 'icons/ColumnsIcon';
import DomainKnowledgeIcon from 'icons/DomainKnowledgeIcon';
import FilterIcon from 'icons/FilterIcon';
import FlagIcon from 'icons/FlagIcon';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const STEP_TYPES = Object.freeze({
  main_prompt: { label: 'Main Prompt', Icon: FlagIcon, color: 'coding.coding6' },
  information: { label: 'Add Columns', Icon: ColumnsIcon, color: 'graphColors.graph1' },
  filter: { label: 'Filter companies', Icon: FilterIcon, color: 'coding.coding5' },
  analysis: { label: 'Domain Knowledge', Icon: DomainKnowledgeIcon, color: 'coding.coding2' },
  discovery: { label: 'Discovery', Icon: DomainKnowledgeIcon, color: 'graphColors.graph3' }
});

function nodesFromPlan(plan, mainPrompt, listId, postId) {
  const nodes = [];
  nodes.push({
    id: 'main_prompt',
    type: 'workFlowMainPrompt',
    data: {
      listId,
      postId,
      label: STEP_TYPES.main_prompt.label,
      color: STEP_TYPES.main_prompt.color,
      Icon: STEP_TYPES.main_prompt.Icon,
      query: mainPrompt,
      showTopHandle: false,
      isEmptyPlan: !plan
    }
  });

  if (!plan) {
    nodes.push({
      id: 'generate_plan',
      type: 'WorkFlowGeneratePlan',
      data: { listId, postId, color: 'primary.white' }
    });
  }

  plan?.forEach((step, index) => {
    const { step_id: stepId, objective, function_use: functionUse } = step;
    const { action_variables: actionVariables } = functionUse || {};
    if (actionVariables?.queries?.length > 0) {
      nodes.push({
        id: stepId,
        type: actionVariables.queries.length > 1 ? 'workFlowGroup' : 'workFlow',
        data: {
          color: STEP_TYPES[objective].color,
          label: `${index + 1}. ${STEP_TYPES[objective]?.label || objective}`,
          Icon: STEP_TYPES[objective]?.Icon,
          showBottomHandle: index < plan.length - 1,
          dependsOn: actionVariables.queries[0].depends_on || [],
          attributeName:
            actionVariables.queries[0].attribute_name || actionVariables.queries[0].analysis_name,
          numberOfChildrens:
            actionVariables.queries.length > 1 ? actionVariables.queries.length : null,
          allowEdit: actionVariables.queries.length <= 1,
          listId,
          postId,
          stepId,
          stepIndex: index,
          queryIndex: 0
        }
      });

      if (actionVariables.queries.length > 1) {
        actionVariables?.queries?.forEach((query, parallelStepIndex) => {
          const parallelStepId = `${stepId}-${parallelStepIndex}`;
          nodes.push({
            id: parallelStepId,
            type: 'workFlow',
            parentId: stepId,
            extent: 'parent',
            data: {
              color: STEP_TYPES[objective].color,
              Icon: STEP_TYPES[objective]?.Icon,
              dependsOn: query.depends_on || [],
              attributeName: query.attribute_name || query.analysis_name,
              showTopHandle: false,
              showBottomHandle: false,
              allowEdit: true,
              listId,
              postId,
              stepId: parallelStepId,
              stepIndex: index,
              queryIndex: parallelStepIndex
            }
          });
        });
      }
    }
  });

  if (plan) {
    const attributeNameToNode = nodes
      .filter((node) => node.data.attributeName)
      .reduce((map, node) => {
        map[node.data.attributeName] = node;
        return map;
      }, {});

    const usedBy = nodes.reduce((map, node) => {
      node.data.dependsOn
        ?.filter((dependency) => attributeNameToNode[dependency])
        ?.forEach((dependency) => {
          if (!map[attributeNameToNode[dependency].id]) {
            map[attributeNameToNode[dependency].id] = [];
          }
          map[attributeNameToNode[dependency].id].push(node);
        });
      return map;
    }, {});

    nodes.forEach((node) => {
      if (node.data.dependsOn?.length > 0) {
        node.data.dependsOn = node.data.dependsOn
          .filter((dependency) => attributeNameToNode[dependency])
          .map((dependency) => ({
            title: dependency,
            Icon: attributeNameToNode[dependency]?.data?.Icon,
            color: attributeNameToNode[dependency]?.data?.color
          }));
      }
      if (usedBy[node.id]) {
        node.data.usedBy = usedBy[node.id].map((dependencyNode) => ({
          title: dependencyNode.data.attributeName,
          Icon: dependencyNode.data.Icon,
          color: dependencyNode.data.color
        }));
      }
    });
  }
  return nodes;
}

function edgesFromPlan(plan) {
  const edges = [];
  if (!plan) {
    edges.push({
      id: `main_prompt_to_generate_plan`,
      source: 'main_prompt',
      target: 'generate_plan',
      type: 'workFlow'
    });
  }

  if (plan?.length > 0) {
    edges.push({
      id: `main_prompt_to_${plan[0].step_id}`,
      source: 'main_prompt',
      target: plan[0].step_id,
      type: 'workFlow'
    });
  }
  edges.push();
  plan?.forEach((step) => {
    const { step_id: stepId, if_successful: nextStep } = step;

    edges.push({
      id: `${stepId}_to_${nextStep}`,
      source: stepId,
      target: nextStep,
      type: 'workFlow'
    });
  });
  return edges;
}

const getLayoutedElements = (nodes, edges) => {
  const nodeWidth = 600;
  const childNodeWidth = 450;
  const nodeHeight = 180;
  const mainNodeHeight = 240;
  const maxChildsInRow = 3;
  const widthPadding = 24;
  const topPadding = 64;
  const heightPadding = 24;

  dagreGraph.setGraph({ rankdir: 'TB' });

  nodes.forEach((node) => {
    let width = nodeWidth;
    if (node.data.numberOfChildrens > 0) {
      width = maxChildsInRow * childNodeWidth + (maxChildsInRow + 1) * widthPadding;
    } else if (node.parentId) {
      width = childNodeWidth;
    }
    node.data.width = width;
    node.data.height = nodeHeight;
    if (node.data.numberOfChildrens > 0) {
      node.data.height =
        topPadding +
        Math.ceil(node.data.numberOfChildrens / maxChildsInRow) * (nodeHeight + heightPadding);
    } else if (node.id === 'main_prompt') {
      node.data.height = mainNodeHeight;
    }
    dagreGraph.setNode(node.id, {
      width: node.data.width,
      height: node.data.height
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'top';
    node.sourcePosition = 'bottom';
    if (!node.data.numberOfChildrens) {
      node.style = { backgroundColor: 'white' };
    }

    let xPosition =
      node.type === 'workFlowMainPrompt'
        ? nodeWithPosition.x - node.data.width / 2
        : nodes[0].position.x;
    let yPosition = nodeWithPosition.y - node.data.height / 2;
    if (node.parentId) {
      const childIndex = parseInt(node.id.substring(node.parentId.length + 1), 10);
      xPosition = widthPadding + (childNodeWidth + widthPadding) * (childIndex % maxChildsInRow);
      yPosition =
        topPadding + Math.floor(childIndex / maxChildsInRow) * (nodeHeight + heightPadding);
    }
    if (node.type === 'workFlowGroup') {
      xPosition -= 423;
    }
    if (node.type === 'WorkFlowGeneratePlan') {
      xPosition = nodes[0].position.x + nodeWidth / 2 - 83;
    }

    node.position = {
      // eslint-disable-next-line id-length
      x: xPosition,
      // eslint-disable-next-line id-length
      y: yPosition
    };
    return node;
  });

  return { nodes, edges };
};

export function extractNodesAndEdges(plan, mainPrompt, listId, postId) {
  const nodes = nodesFromPlan(plan, mainPrompt, listId, postId);
  const edges = edgesFromPlan(plan);
  return getLayoutedElements(nodes, edges);
}
