import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import BottomBarComponent from 'components/BottomBarComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import PropTypes from 'prop-types';
import React from 'react';

export const DomainResearchBottomBar = ({ selectedDomains, bottomBarActions }) => {
  const numDomainsSelected = selectedDomains?.size;

  if (numDomainsSelected <= 0) {
    return null;
  }

  return (
    <BottomBarComponent>
      <Stack flexDirection="row" padding="0px 16px" gap="8px" alignItems="center" width={170}>
        <Typography variant="h3" color="primary.primary1">
          {numDomainsSelected}
        </Typography>
        <Typography variant="paragraphMedium" color="secondary.secondary1">
          {`Item${numDomainsSelected === 1 ? '' : 's'} selected`}
        </Typography>
      </Stack>
      <Divider sx={{ marginRight: '16px' }} orientation="vertical" variant="middle" flexItem />

      <Stack flexDirection="row" gap="16px" width="fit-content">
        {bottomBarActions.map(({ title, onClick, icon, endIcon, disabled, disabledMessage }) => (
          <Tooltip key={title} arrow title={disabled ? disabledMessage : ''} placement="top">
            <Box>
              <IconButtonComponent
                disabled={disabled}
                onClick={onClick}
                sx={{ color: 'secondary.secondary1' }}>
                {icon}
                <Typography paddingLeft={icon ? '4px' : 'auto'} variant="paragraphMedium">
                  {title}
                </Typography>
                {endIcon}
              </IconButtonComponent>
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </BottomBarComponent>
  );
};

DomainResearchBottomBar.propTypes = {
  selectedDomains: PropTypes.array,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onNewList: PropTypes.func,
  onDeepDive: PropTypes.func,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func,
  submitSubdomains: PropTypes.func,
  bottomBarActions: PropTypes.array
};

export default React.memo(DomainResearchBottomBar);
