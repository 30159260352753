import { loadListSubdomainsAction } from 'actions/explore/load-list-subdomains-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DOMAIN_RESEARCH_EVENTS } from 'constants/userEvents';
import { deleteExploreSubdomains } from 'reducer/explore-slice';
import { getExploreSubdomains } from 'selectors/explore';
import { isEmpty } from 'utils/objects-utils';

export function deleteSubdomainAction(listId, subdomainId) {
  return (dispatch, getState, apiClient) => {
    if (!subdomainId) {
      dispatch(deleteExploreSubdomains({ listId, subdomainIds: [subdomainId] }));
      return;
    }
    const subdomains = getExploreSubdomains(getState(), listId);

    const getSubdomainsToDeleteRecursive = (sdId) => {
      const subdomainChilds = subdomains.filter((sd) => sd.parent_subdomain_id === sdId);
      if (isEmpty(subdomainChilds)) {
        return [sdId];
      }
      const grandChilds = [].concat(
        ...subdomainChilds.map((sd) => getSubdomainsToDeleteRecursive(sd.id))
      );
      return [sdId, ...grandChilds];
    };
    const subdomainsToDelete = getSubdomainsToDeleteRecursive(subdomainId);

    dispatch(deleteExploreSubdomains({ listId, subdomainIds: subdomainsToDelete }));

    dispatch(
      sendUserEvent(DOMAIN_RESEARCH_EVENTS.DELETE_SUBDOMAINS, {
        company_list_id: listId,
        subdomain_id: subdomainId,
        num_subdomains_to_delete: subdomainsToDelete.length
      })
    );

    apiClient.subdomains
      .deleteSubdomain(subdomainId)
      .then(() => {
        dispatch(loadListSubdomainsAction(listId));
      })
      .catch(() => {
        dispatch(loadListSubdomainsAction(listId));
      });
  };
}
