import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function RowStack({ sx, children }) {
  return (
    <Stack direction="row" alignItems="center" gap="8px" sx={sx}>
      {children}
    </Stack>
  );
}

RowStack.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node
};

RowStack.defaultProps = {
  sx: {},
  children: null
};
