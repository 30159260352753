import { Chip, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { COLUMN_TYPES } from 'utils/explore-table-columns';

import QuickViewBox from './QuickViewBox';
import QuickViewKeyValue from './QuickViewFieldValue';

const renderIndustries = (industries) => {
  if (!industries) return null;
  return (
    <Stack direction="row" gap="8px" sx={{ flexWrap: 'wrap' }}>
      {industries.split(', ')?.map((industry, index) => (
        <Chip key={index} size="small" label={industry} sx={{ height: '24px' }} />
      ))}
    </Stack>
  );
};

function QuickViewCompanyInfo({ getFieldValue }) {
  return (
    <QuickViewBox title="Company Info">
      <Stack direction="row" sx={{ width: '100%' }} gap="24px" color="colors.primary_text">
        <Stack direction="row" justifyContent="space-between" minWidth="263px">
          <Stack gap="24px">
            <QuickViewKeyValue field="Employees" value={getFieldValue(COLUMN_TYPES.EMPLOYEES)} />
            <QuickViewKeyValue field="HQ" value={getFieldValue(COLUMN_TYPES.HQ)} />
            <QuickViewKeyValue field="Revenues" value={getFieldValue(COLUMN_TYPES.REVENUES)} />
          </Stack>
          <Stack gap="24px">
            <QuickViewKeyValue
              field="Year Founded"
              value={getFieldValue(COLUMN_TYPES.YEAR_FOUNDED)}
            />
            <QuickViewKeyValue
              field="Ownership"
              value={getFieldValue(COLUMN_TYPES.LAST_EQUITY_ROUND_TYPE)}
            />
            <QuickViewKeyValue field="Funds" value={getFieldValue(COLUMN_TYPES.TOTAL_FUNDING)} />
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <QuickViewKeyValue
            field="Description"
            value={getFieldValue(COLUMN_TYPES.DESCRIPTION)}
            gap="10px"
          />
        </Stack>
      </Stack>
      <QuickViewKeyValue
        field="Industries"
        gap="10px"
        value={getFieldValue(COLUMN_TYPES.INDUSTRIES)}
        renderVal={renderIndustries}
      />
    </QuickViewBox>
  );
}

QuickViewCompanyInfo.propTypes = {
  getFieldValue: PropTypes.func
};

export default QuickViewCompanyInfo;
