import { Avatar, Divider, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import TopProblemExpandable from './TopProblemExpandable';

const LOGO_SIZE = '24px';

function DomainReportTopProblemExample({ title, description, companies, loading }) {
  if (loading) {
    return (
      <Stack gap="16px" marginTop="16px">
        <Stack
          gap="12px"
          sx={{
            borderRadius: '16px',
            border: '1px solid',
            borderColor: 'colors.ui_border',
            padding: '24px',
            paddingBottom: '16px',
            color: 'colors.primary_text'
          }}>
          <Skeleton variant="rounded" height="20px" width="350px" />
          <Skeleton variant="rounded" height="40px" />
        </Stack>
      </Stack>
    );
  }
  const noExamples = !companies || companies.length === 0;
  return (
    <Stack
      sx={{
        borderRadius: '16px',
        border: '1px solid',
        borderColor: 'colors.ui_border',
        padding: '24px',
        color: 'colors.primary_text'
      }}>
      <TopProblemExpandable title={title} description={description} disabled={noExamples}>
        {companies &&
          companies.map((company, index) => (
            <Stack key={index} gap="12px">
              <Stack gap="8px" direction="row" alignItems="center">
                <Avatar
                  src={company?.company?.parameters?.logo_url}
                  sx={{ width: LOGO_SIZE, height: LOGO_SIZE }}
                  variant="rounded"
                />
                <Typography variant="paragraphMedium" color="colors.primary_text">
                  {company?.company?.name}
                </Typography>
              </Stack>
              <Typography variant="paragraph">{company.solution}</Typography>
              {index !== companies.length - 1 && <Divider flexItem />}
            </Stack>
          ))}
      </TopProblemExpandable>
    </Stack>
  );
}

DomainReportTopProblemExample.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  companies: PropTypes.array,
  loading: PropTypes.bool
};

export default DomainReportTopProblemExample;
