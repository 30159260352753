import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import DomainKnowledgeResourceTile from './DomainKnowledgeResourceTile';

function DomainKnowledgeResourceTabPanel({
  listId,
  folderId,
  data,
  setResourceToDelete,
  title,
  resourceType,
  addElement
}) {
  return (
    <Stack
      flex={1}
      direction="column"
      gap="8px"
      sx={{
        padding: '16px',
        borderRadius: '8px',
        background: 'white'
      }}>
      <Stack direction="row" alignItems="center" sx={{ height: '32px' }}>
        <Typography variant="h3" color="colors.primary" textTransform="capitalize" sx={{ flex: 1 }}>
          {title}
        </Typography>
        {addElement}
      </Stack>
      {data?.map((resource, index2) => (
        <DomainKnowledgeResourceTile
          key={index2}
          listId={listId}
          folderId={folderId}
          resourceType={resourceType}
          resource={resource}
          onDelete={() => setResourceToDelete(resource)}
        />
      ))}
    </Stack>
  );
}

DomainKnowledgeResourceTabPanel.propTypes = {
  listId: PropTypes.number,
  folderId: PropTypes.number,
  title: PropTypes.string.isRequired,
  addElement: PropTypes.element,
  data: PropTypes.array,
  setResourceToDelete: PropTypes.func.isRequired,
  resourceType: PropTypes.string
};

DomainKnowledgeResourceTabPanel.defaultProps = {
  data: [],
  resourceType: 'link'
};

export default DomainKnowledgeResourceTabPanel;
