import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const LoadingAnimation = ({ loadingText }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? `${prev}.` : '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Typography variant="paragraph">
      {loadingText}
      {dots}
    </Typography>
  );
};

export default LoadingAnimation;

LoadingAnimation.propTypes = {
  loadingText: PropTypes.string
};
