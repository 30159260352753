import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { clearCurrentSearchAutocomplete } from 'actions/autocomplete/make-new-search-autocomplete-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import GoToIcon from 'icons/GoToIcon';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentSearchAutocompleteSuggestionsByType } from 'selectors/current-search';
import { formatTimestampRelative } from 'utils/time-utils';

import LinkStack from './company/LinkStack';
import TitleStack from './company/TitleStack';

export default function SuggestionTile({ type, onRunSearch }) {
  const { state } = useAppState();
  const navigate = useNavigate();
  const suggestion = getCurrentSearchAutocompleteSuggestionsByType(state, type)?.[0];

  const goToCompanyInsights = (event) => {
    if (!suggestion.existing_record_id) {
      return;
    }
    event.stopPropagation();
    dispatch(sendUserEvent(USER_EVENTS.DEEP_DIVE_OPEN, { company: suggestion?.name }));
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${suggestion.existing_record_id}/${OVERVIEW_TAB.path}`);
    dispatch(clearCurrentSearchAutocomplete());
  };

  if (!suggestion) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <Stack gap="8px">
      {type === 'existing_suggestions' && (
        <Typography sx={{ marginBottom: '8px' }} variant="paragraph" color="colors.primary_text">
          You ran this research{' '}
          <Typography variant="paragraphSemiBold">
            {formatTimestampRelative(suggestion.time_submitted)}
          </Typography>
        </Typography>
      )}
      {suggestion.ambiguous_website && (
        <Alert severity="warning">
          Potential discrepancy in Linkedin & Website found. Not what you are looking for? Try
          searching by the company website.
        </Alert>
      )}
      <Card
        sx={{
          position: 'relative',
          padding: '24px',
          height: '252px',
          borderRadius: '4px',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_bg',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px'
        }}>
        <TitleStack type={type} id={null} />
        <CardContent sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Divider orientation="horizontal" flexItem sx={{ borderColor: 'colors.ui_border' }} />
          <LinkStack id={null} type={type} />
          <Typography
            variant="paragraph"
            color="colors.primary_text"
            align="left"
            alignSelf="flex-start"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              height: '60px',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical'
            }}>
            {suggestion.description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            padding: 0,
            alignSelf: 'flex-end'
          }}>
          {type === 'new_suggestions' && (
            <Button
              variant="contained"
              sx={{ height: '32px' }}
              onClick={onRunSearch}
              startIcon={<RerunIcon />}>
              Run Research
            </Button>
          )}
          {type === 'existing_suggestions' && (
            <Stack direction="row" gap="8px">
              <Button
                variant="outlined"
                sx={{ height: '32px' }}
                onClick={onRunSearch}
                startIcon={<RerunIcon />}>
                Rerun Research
              </Button>
              <Button
                variant="contained"
                sx={{ height: '32px' }}
                onClick={goToCompanyInsights}
                startIcon={<GoToIcon sx={{ color: 'white' }} />}>
                See Deep Dive
              </Button>
            </Stack>
          )}
        </CardActions>
      </Card>
    </Stack>
  );
}

SuggestionTile.propTypes = {
  onRunSearch: PropTypes.func,
  type: PropTypes.oneOf(['new_suggestions', 'existing_suggestions'])
};
