import { Stack, Typography } from '@mui/material';
import DependsOnIcon from 'icons/DependsOnIcon';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanGroupNode({ data }) {
  const {
    label,
    color,
    Icon,
    width,
    height,
    dependsOn,
    showTopHandle = true,
    showBottomHandle = true
  } = data;

  return (
    <Stack
      // disable node dragging
      className="nodrag"
      sx={{
        width: width,
        height: height,
        padding: '16px',
        boxSizing: 'border-box',
        color: 'colors.primary_text',
        backgroundColor: applyThemeColorOpacity(color, 4),
        borderRadius: '16px',
        textAlign: 'left',
        margin: 0,
        minHeight: 0
      }}>
      <Stack direction="column" gap="8px">
        <Stack direction="row" alignItems="center" gap="8px">
          {Icon && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 32,
                height: 32,
                backgroundColor: applyThemeColorOpacity(color, 12),
                borderRadius: '6px'
              }}>
              <Icon />
            </Stack>
          )}
          <Typography variant="paragraphBold" color={color}>
            {label}
          </Typography>
        </Stack>
        {dependsOn.length > 0 && (
          <Stack direction="row" alignItems="center" gap="8px">
            <DependsOnIcon />
            <Typography variant="paragraphMedium" color="colors.primary_text">
              {dependsOn.join(',')}
            </Typography>
          </Stack>
        )}
      </Stack>
      {showTopHandle && (
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
      )}
      {showBottomHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', bottom: 0 }}
        />
      )}
    </Stack>
  );
}

WorkFlowPlanGroupNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowPlanGroupNode;
