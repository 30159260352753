import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  loadedResources: {},
  currentOpenResourceName: null,
  currentOpenResourceType: null,
  currentOpenResourceId: null
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState: initialSliceState,
  reducers: {
    updateLodededResource: (sliceState, { payload }) => {
      const { resourceType } = payload;
      sliceState.loadedResources[resourceType] = true;
    },
    updateCurrentOpenResourceName: (sliceState, { payload }) => {
      const { resourceName, resourceType, resourceId } = payload;
      sliceState.currentOpenResourceName = resourceName;
      sliceState.currentOpenResourceType = resourceType;
      sliceState.currentOpenResourceId = resourceId;
    }
  }
});

export const { updateLodededResource, updateCurrentOpenResourceName } = resourcesSlice.actions;

export default resourcesSlice.reducer;
