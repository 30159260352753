import { Box, Stack, Typography } from '@mui/material';
import { deleteSearchRunAction } from 'actions/runs/delete-search-run-action';
import PusherRunProgressListener from 'components/pusher/PusherRunProgressListener';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getRunCompanyLogoUrlById,
  getRunCompanyNameById,
  getRunProgressById,
  getRunTimestampById
} from 'selectors/runs';

import FinishedRunningSearch from '../FinishedRunningSearch';

export default function RunningSearchAction({ id, mode, recordId, recentlyFinished }) {
  const { state } = useAppState();
  const navigate = useNavigate();
  const searchTimestamp = getRunTimestampById(state, id);
  const progress = getRunProgressById(state, id) ? getRunProgressById(state, id) : 1;
  const progressExistInState = Boolean(getRunProgressById(state, id));
  const name = getRunCompanyNameById(state, id);
  const logoUrl = getRunCompanyLogoUrlById(state, id);
  const handleCancel = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(deleteSearchRunAction(id));
  };

  const onSeeResults = () => {
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${id}/${OVERVIEW_TAB.path}`);
  };

  const runNameRender = (
    <React.Fragment>
      {mode === 'tile' ? (
        <Typography variant="tiny" color="colors.primary_text" textAlign="left" flex={1}>
          Running Research
        </Typography>
      ) : (
        <Stack direction="row" alignItems="center" gap="8px" width="100%">
          <Box
            component="img"
            src={logoUrl}
            sx={{
              width: `24px`,
              height: `24px`,
              paddingRight: 0,
              paddingBottom: 'auto',
              margin: 'auto'
            }}
          />
          <Typography variant="paragraphBold" color="primary.primary100" textAlign="left" flex={1}>
            {name}
          </Typography>
        </Stack>
      )}
    </React.Fragment>
  );

  if (mode === 'monitor' && recentlyFinished) {
    return (
      <FinishedRunningSearch runNameRender={runNameRender} runId={id} onSeeResults={onSeeResults} />
    );
  }
  return (
    <RunningSearchActionBase
      {...{
        handleCancel,
        progress,
        progressExistInState,
        searchTimestamp,
        mode
      }}>
      {runNameRender}
      {id && recordId && (
        <PusherRunProgressListener id={id} recordId={recordId} recordType="company" />
      )}
    </RunningSearchActionBase>
  );
}

RunningSearchAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.oneOf(['tile', 'monitor']),
  recentlyFinished: PropTypes.bool
};

RunningSearchAction.defaultProps = {
  mode: 'tile',
  recentlyFinished: false
};
