/* eslint-disable max-lines */
import { Grid2, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import {
  loadCompaniesAndUpdateColumns,
  updateCustomAnalyticsExtraDataForCompaniesInListAction,
  updateExtraDataByTypeForCompaniesInListAction
} from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { submitHiddenGemsAction } from 'actions/company_lists/submit-hidden-gems-action';
import { createNewPostInCustomAnalyticsThreadAsyncAction } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListCustomColumnsByListId } from 'selectors/explore';
import {
  getAllPredefinedColumns,
  getAllPredefinedTableQuestions,
  getExploreFilteredMetaIdsIfListFiltered,
  getExploreTableSelectedCompanies
} from 'selectors/explore-table-ui';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { getUserFeatureFlags, getUserId } from 'selectors/user';
import { COLUMN_TYPE_TO_FEATURE_FLAG_NAME } from 'utils/explore-table-columns';
import { EXTRA_DATA_COLUMN_TYPE } from 'utils/extra-data-utils';
import { isEmpty } from 'utils/objects-utils';
import {
  CATEGORIES,
  SAVED_QUERY_ACTION_TYPES,
  getPlatterBasicColumns,
  getPlatterEmptyColumns,
  getPlatterFinanceColumns
} from 'utils/platter-utils';

import { PlatterItemComponent } from './PlatterItemComponent';

const LEFT_LEADERSHIP_DATA_NAME = 'LEFT_LEADERSHIP';
const PITCHBOOK_SCRAPINGS_DATA_NAME = 'PITCHBOOK_SCRAPINGS';
const SAP_PARTNERSHIP_DATA_NAME = 'SAP_PARTNERSHIP_DATA';
const AWS_PARTNERSHIP_DATA_NAME = 'AWS_PARTNERSHIP_DATA';
const MSFT_PARTNERSHIP_DATA_NAME = 'MSFT_PARTNERSHIP_DATA';

// eslint-disable-next-line max-lines-per-function
function ColumnsPlatterDialog({ open, onClose, companyListId, actionType }) {
  const predefinedColumns = useSelector(getAllPredefinedColumns);
  const predefinedTableQuestions = useSelector(getAllPredefinedTableQuestions);
  const predefinedItems =
    actionType === SAVED_QUERY_ACTION_TYPES.COLUMN ? predefinedColumns : predefinedTableQuestions;
  const listCustomColumns = useSelector((state) =>
    getListCustomColumnsByListId(state, companyListId)
  );
  const dispatch = useDispatch();
  const { addToast, cancelToast } = useAppUtils();
  const toastRef = useRef();
  const [categoryValue, setCategoryValue] = useState(0);
  const sectionsContainerRef = useRef();
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canLeftLeadership = featureFlags?.includes('left_leadership');
  const canPitchbook = featureFlags?.includes('pitchbook_scrapings');
  const canPartnershipData = featureFlags?.includes('partnership_data');
  const canFinanceData = featureFlags?.includes('finance_column');
  const userId = useOldStateSelector(getUserId);

  const allSavedQueries = useSelector(getAllSavedQueries);

  const savedQueries = useMemo(() => {
    if (isEmpty(allSavedQueries)) {
      return [];
    }
    const getIsQueryMine = (query) => query.owner.id === userId;
    const transleteSavedQueryColumns = (columns) => {
      return columns.map((query) => {
        return {
          data_name: null,
          title: query.name,
          type: 'custom_analytics',
          parameters: { ...query.parameters, saved_query_id: query.id },
          category:
            query.isShared && !getIsQueryMine(query)
              ? CATEGORIES.SHARED_WITH_ME
              : CATEGORIES.MY_SAVED_COLUMNS,
          id: query.id
        };
      });
    };
    return transleteSavedQueryColumns(
      Object.values(allSavedQueries).filter((savedQuery) => savedQuery.type === actionType)
    ).sort((sq1, sq2) => {
      if (sq1.category !== sq2.category) {
        return sq1.category === CATEGORIES.MY_SAVED_COLUMNS ? -1 : 1;
      }
      return 0;
    });
  }, [allSavedQueries, userId, actionType]);

  const filteredMetaIdsIfFiltered = useSelector((state) =>
    getExploreFilteredMetaIdsIfListFiltered(state, companyListId)
  );
  const selectedCompanies = useSelector((state) =>
    getExploreTableSelectedCompanies(state, companyListId)
  );

  const columnsByCategories = useMemo(() => {
    if (!predefinedItems) return {};
    return [
      ...savedQueries,
      ...getPlatterEmptyColumns(actionType),
      ...getPlatterBasicColumns(actionType),
      ...getPlatterFinanceColumns(actionType, canFinanceData),
      ...predefinedItems
        .filter((item) => {
          if (item.data_name === LEFT_LEADERSHIP_DATA_NAME) {
            return canLeftLeadership;
          }
          if (item.data_name === PITCHBOOK_SCRAPINGS_DATA_NAME) {
            return canPitchbook;
          }
          if (item.data_name === SAP_PARTNERSHIP_DATA_NAME) {
            return canPartnershipData;
          }
          if (item.data_name === AWS_PARTNERSHIP_DATA_NAME) {
            return canPartnershipData;
          }
          if (item.data_name === MSFT_PARTNERSHIP_DATA_NAME) {
            return canPartnershipData;
          }
          return true;
        })
        .map((column) => {
          return {
            ...column,
            parameters: {
              question: column.question,
              answer_type: column?.answer_type || 'text',
              model: CHAT_GPT_MODEL_VERSION,
              fields: column?.fields
            }
          };
        })
    ]?.reduce((categories, column) => {
      const featureFlagName = COLUMN_TYPE_TO_FEATURE_FLAG_NAME?.[column.data_name?.toUpperCase()];
      if (featureFlagName && !featureFlags?.includes(featureFlagName)) {
        return categories;
      }
      categories[column.category] = categories[column.category] || [];
      categories[column.category].push(column);
      return categories;
    }, {});
  }, [
    predefinedItems,
    featureFlags,
    canLeftLeadership,
    canPitchbook,
    canPartnershipData,
    canFinanceData,
    savedQueries,
    actionType
  ]);

  const handleClose = () => {
    setCategoryValue(0);
    onClose();
  };

  const onColumnCreated = (columnSetting) => {
    cancelToast(toastRef.current);
    toastRef.current = null;
    const id = addToast('Adding new column succeed', TOAST_TYPES.SUCCESS);
    setTimeout(() => cancelToast(id), 2000);
    if (columnSetting?.type === EXTRA_DATA_COLUMN_TYPE) {
      dispatch(updateExtraDataByTypeForCompaniesInListAction(companyListId, columnSetting.id));
    }
    if (columnSetting.id) {
      dispatch(highlightColumnAction(companyListId, columnSetting.id, true));
    } else {
      dispatch(
        sendUserEvent(USER_EVENTS.FAILING_SCROLL_TO_COLUMN, {
          listId: companyListId,
          columnName: columnSetting.name,
          columnType: columnSetting.type,
          id: columnSetting.id,
          dataType: columnSetting.data_type,
          ...columnSetting
        })
      );
    }
  };

  const onCreateCustomAnalyticsColumn = (column, selectedCompanyMetaIds) => {
    if (column.type === 'predefined_custom_analytics') {
      dispatch(loadCompaniesAndUpdateColumns(companyListId, column.id));
    }
    dispatch(
      updateCustomAnalyticsExtraDataForCompaniesInListAction(
        companyListId,
        column.id,
        false,
        selectedCompanyMetaIds
      )
    );
    onColumnCreated(column);
  };
  const onCustomAnalyticsColumnClick = (column) => {
    let selectedCompanyMetaIds = null;
    if (selectedCompanies?.length > 0) {
      selectedCompanyMetaIds = selectedCompanies.map((company) => company.companyMetaId);
    } else if (filteredMetaIdsIfFiltered) {
      selectedCompanyMetaIds = filteredMetaIdsIfFiltered;
    }

    if (actionType === SAVED_QUERY_ACTION_TYPES.COLUMN) {
      dispatch(
        addExploreCustomColumnAction(
          companyListId,
          column.title,
          column.parameters,
          column.type,
          column.answer_type,
          (newCol) => onCreateCustomAnalyticsColumn(newCol, selectedCompanyMetaIds),
          column.data_name
        )
      );
      toastRef.current = addToast('Adding new column', TOAST_TYPES.INFO);
    } else if (actionType === SAVED_QUERY_ACTION_TYPES.ASK) {
      const { question, answer_type, model, columns, is_table_question, saved_query_id } =
        column.parameters;
      const useColumns = question.includes('{{using columns $')
        ? question.split('{{using columns $')[1].split('}}')[0].split(',')
        : columns;
      dispatch(
        createNewPostInCustomAnalyticsThreadAsyncAction({
          listId: companyListId,
          query: question,
          answerType: answer_type,
          useGpt4: model,
          selectedCompanies: selectedCompanyMetaIds,
          columns: useColumns,
          forEachCompany: false,
          useTable: is_table_question,
          saved_query_id
        })
      );
    }
  };

  const onColumnClick = (column) => {
    if (column.type?.includes('custom_analytics')) {
      onCustomAnalyticsColumnClick(column);
      handleClose();
      return;
    }
    if (column.data_name === 'hidden_gems') {
      dispatch(submitHiddenGemsAction(companyListId));
      handleClose();
      return;
    }
    const columnId = column.type === 'custom_column' ? null : column.data_name;
    const columnType = column.type === 'custom_column' ? column.data_name : column.type;

    dispatch(
      addExploreCustomColumnAction(
        companyListId,
        column.title,
        column.parameters,
        columnType,
        null,
        onColumnCreated,
        columnId
      )
    );
    if (column.type === 'finance_column') {
      dispatch(
        updateExtraDataByTypeForCompaniesInListAction(
          companyListId,
          COMPANY_EXTRA_DATA_TYPES.FINANCE
        )
      );
    }
    handleClose();
  };
  const isColumnInList = (column) =>
    listCustomColumns &&
    (Object.keys(listCustomColumns).includes(column.data_name) ||
      Object.values(listCustomColumns).some(
        (col) =>
          column?.parameters?.saved_query_id &&
          col?.info?.saved_query_id === column.parameters.saved_query_id
      ));

  const sectionTabStyle = (index) => {
    const selectedSx = { backgroundColor: 'colors.hover_on_gray_bg', color: 'primary.primary100' };
    const sx = index === categoryValue ? selectedSx : {};

    return {
      color: 'greyColors.grey200',
      alignItems: 'start',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: 'colors.hover_on_primary_bg',
        color: 'primary.primary100'
      },
      '&.Mui-selected': selectedSx,
      ...sx
    };
  };

  const handleCategoryChange = (event, newValue) => {
    setCategoryValue(newValue);
    const index = Object.keys(columnsByCategories).indexOf(newValue);
    sectionsContainerRef.current.children[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      title={`${actionType === SAVED_QUERY_ACTION_TYPES.COLUMN ? 'Column' : 'Question'} Library`}
      maxWidth={1100}>
      <Stack
        direction="row"
        sx={{ backgroundColor: 'colors.hover_on_gray_bg', gap: '16px', padding: 0 }}>
        <Stack
          sx={{
            backgroundColor: 'colors.grey_bg',
            gap: '16px',
            padding: '16px',
            minWidth: '170px'
          }}>
          <Tabs
            value={categoryValue}
            onChange={handleCategoryChange}
            orientation="vertical"
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}>
            {columnsByCategories &&
              Object.keys(columnsByCategories)?.map((category, index) => (
                <Tab
                  key={category}
                  value={category}
                  label={
                    <Typography variant="paragraphMedium" noWrap>
                      {category}
                    </Typography>
                  }
                  sx={sectionTabStyle(index)}></Tab>
              ))}
          </Tabs>
        </Stack>
        <Stack
          overflow="scroll"
          height="80vh"
          sx={{ gap: '16px', padding: '16px', width: '100%' }}
          ref={sectionsContainerRef}>
          {columnsByCategories &&
            Object.entries(columnsByCategories)?.map(([category, columns]) => (
              <Stack key={category} gap="8px" padding="4px" paddingTop="16px" id={category}>
                <Typography
                  variant={categoryValue === category ? 'paragraphBold' : 'paragraphMedium'}
                  color="primary.primary100">
                  {category}
                </Typography>
                <Stack direction="row">
                  <Grid2 container rowSpacing={1.5} columnSpacing={2}>
                    {columns?.map((column) => (
                      <Grid2 xs={4} key={column.data_name || column.id}>
                        <PlatterItemComponent
                          onClose={handleClose}
                          onClick={() => onColumnClick(column)}
                          isColumnInList={isColumnInList(column)}
                          title={column.title}
                          type={column.type}
                          parameters={column.parameters}
                          listId={companyListId}
                          id={column.id}
                          actionType={actionType}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

ColumnsPlatterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyListId: PropTypes.number,
  actionType: PropTypes.string
};

export default wrapWithError(ColumnsPlatterDialog);
