import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { addEmptyWorkflowAction } from 'actions/workflows/add-empty-workflow-action';
import ModalComponent from 'components/modal/ModalComponent';
import WorkFlowPlannerDialog from 'components/work-flow/WorkFlowPlannerDialog';
import { ICON_VARIANTS } from 'constants/icons';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AddIcon from 'icons/AddIcon';
import { ListIcon } from 'icons/ListIcon';
import ShareIcon from 'icons/ShareIcon';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';
import { getCustomAnalyticsThreadPostsById } from 'selectors/custom-analytics';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { getUser } from 'selectors/user';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import WorkflowsCenterDialogRow from './WorkflowsCenterDialogRow';

function WorkflowsCenterDialog({ open, listId, onClose }) {
  const dispatch = useDispatch();

  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );
  const allSavedQueries = useSelector(getAllSavedQueries);
  const threadKey = getThreadKey(listId);
  const caThreadPosts = useSelector((state) => getCustomAnalyticsThreadPostsById(state, threadKey));
  const currentUser = useOldStateSelector(getUser);

  const workflowSaveQueries = useMemo(() => {
    const queries = [];
    const savedQueryQuestions = allSavedQueries
      ? new Set(
          Object.values(allSavedQueries)
            .filter((query) => query.type === SAVED_QUERY_ACTION_TYPES.WORKFLOW)
            .map((query) => query.parameters.question)
        )
      : new Set();

    if (caThreadPosts && Object.keys(caThreadPosts).length > 0) {
      Object.values(caThreadPosts)
        .filter((post) => post.plan?.is_workflow && !savedQueryQuestions.has(post.plan?.question))
        .forEach((post) => {
          queries.push({
            id: post.postId,
            name: post.plan.question,
            owner: currentUser,
            postId: post.postId
          });
        });
    }

    if (allSavedQueries && Object.keys(allSavedQueries).length > 0) {
      Object.values(allSavedQueries)
        .filter((query) => query.type === SAVED_QUERY_ACTION_TYPES.WORKFLOW)
        .sort((q1, q2) => new Date(q2.time_created) - new Date(q1.time_created))
        .forEach((query) => {
          queries.push({
            ...query,
            Icon: query.isShared ? ShareIcon : ListIcon,
            shouldAddToState: true,
            savedQueryId: query.id
          });
        });
    }
    return queries;
  }, [allSavedQueries, caThreadPosts, currentUser]);

  const closeWorkflowDialog = () => {
    dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: null }));
  };

  const onCreateNewWorkflowClick = () => {
    dispatch(addEmptyWorkflowAction(listId, null, ''));
  };

  return (
    <React.Fragment>
      <ModalComponent
        title="Workflows"
        titleIcon={WorkflowIcon}
        open={open}
        maxWidth={800}
        onClose={onClose}>
        <Stack direction="column" gap="16px" sx={{ padding: '16px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
            <Typography variant="paragraphMedium" color="primary.primary150">
              {`${workflowSaveQueries.length} saved workflows`}
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon variant={ICON_VARIANTS.SECONDARY} />}
              onClick={onCreateNewWorkflowClick}>
              Create workflow
            </Button>
          </Stack>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Created on </TableCell>
                <TableCell align="right">Created by</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflowSaveQueries.map((query) => (
                <WorkflowsCenterDialogRow key={query.id} query={query} listId={listId} />
              ))}
            </TableBody>
          </Table>
        </Stack>
      </ModalComponent>
      {Boolean(selectedWorkflowId) && (
        <WorkFlowPlannerDialog
          listId={listId}
          postId={selectedWorkflowId}
          open={Boolean(selectedWorkflowId)}
          onClose={closeWorkflowDialog}
        />
      )}
    </React.Fragment>
  );
}

WorkflowsCenterDialog.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default WorkflowsCenterDialog;
