import { Stack, Typography } from '@mui/material';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRTaskByListId
} from 'selectors/domain-reports';
import { getCompanyMetasByListId } from 'selectors/explore';
import { formatTopProblemsData } from 'utils/domain-report-utils';

import DomainReportBox from '../DomainReportBox';
import DomainReportTopProblemExample from './DomainReportTopProblemExample';

const innerComponent = ({ topProblems }) => {
  return (
    <Stack gap="16px" marginTop="16px">
      {topProblems &&
        topProblems.map((problem, index) => (
          <DomainReportTopProblemExample key={index} {...problem} companies={problem.examples} />
        ))}
    </Stack>
  );
};

function DomainReportTopProblems({ listId }) {
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const companyMetaIds = useSelector((state) => getLatestDRCompanyMetaIdsByListId(state, listId));
  const filteredCompanies = companyMetas.filter((company) =>
    companyMetaIds.includes(company.company_meta_id)
  );
  const { data } =
    useSelector((state) =>
      getLatestDRTaskByListId(state, listId, DOMAIN_REPORT_TASK_TYPES.TOP_PROBLEMS)
    ) || {};
  const title = 'Top problems being solved';
  const { topProblems } = formatTopProblemsData(data, filteredCompanies) || {};
  return (
    <DomainReportBox
      title={title}
      listId={listId}
      taskType={DOMAIN_REPORT_TASK_TYPES.TOP_PROBLEMS}
      editable>
      <Typography variant="paragraph">
        Key challenges addressed by companies in this domain
      </Typography>
      {topProblems ? (
        innerComponent({ topProblems })
      ) : (
        <Stack>
          <DomainReportTopProblemExample loading />
          <DomainReportTopProblemExample loading />
        </Stack>
      )}
    </DomainReportBox>
  );
}

DomainReportTopProblems.propTypes = {
  listId: PropTypes.number
};

export default DomainReportTopProblems;
