import { addDomainReport } from 'reducer/domain-reports-slice';

import { bindDomainReportPusherAction } from './bind-domain-report-pusher-action';

export function submitDomainReportTaskAction({ listId, domainReportId, taskType, parameters }) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore
      .submitExploreListDomainReportTask(listId, domainReportId, taskType, parameters)
      .then((res) => {
        if (res.ok && res.data.exists) {
          dispatch(addDomainReport({ listId, data: res.data }));
          if (res.data?.domain_report?.custom_analytics) {
            Object.keys(res.data.domain_report.custom_analytics).forEach((key) => {
              if (!res.data.domain_report.custom_analytics?.[key]?.data) {
                dispatch(
                  bindDomainReportPusherAction({
                    listId,
                    domainReportId: res.data.domain_report_id,
                    postId: key
                  })
                );
              }
            });
          }
        }
      });
  };
}
