import React from 'react';

function SubdomainIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4301_4148)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 8C7.77614 8 8 8.22386 8 8.5V11H14.5C14.7761 11 15 11.2239 15 11.5C15 11.7761 14.7761 12 14.5 12H8V15.5C8 16.3284 8.67157 17 9.5 17H14.5C14.7761 17 15 17.2239 15 17.5C15 17.7761 14.7761 18 14.5 18H9.5C8.11929 18 7 16.8807 7 15.5V8.5C7 8.22386 7.22386 8 7.5 8Z"
          fill="#5C5E76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 5C6.67157 5 6 5.67157 6 6.5C6 7.32843 6.67157 8 7.5 8C8.32843 8 9 7.32843 9 6.5C9 5.67157 8.32843 5 7.5 5ZM5 6.5C5 5.11929 6.11929 4 7.5 4C8.88071 4 10 5.11929 10 6.5C10 7.88071 8.88071 9 7.5 9C6.11929 9 5 7.88071 5 6.5Z"
          fill="#5C5E76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 10.5C15.9477 10.5 15.5 10.9477 15.5 11.5C15.5 12.0523 15.9477 12.5 16.5 12.5C17.0523 12.5 17.5 12.0523 17.5 11.5C17.5 10.9477 17.0523 10.5 16.5 10.5ZM14.5 11.5C14.5 10.3954 15.3954 9.5 16.5 9.5C17.6046 9.5 18.5 10.3954 18.5 11.5C18.5 12.6046 17.6046 13.5 16.5 13.5C15.3954 13.5 14.5 12.6046 14.5 11.5Z"
          fill="#5C5E76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 16.5C15.9477 16.5 15.5 16.9477 15.5 17.5C15.5 18.0523 15.9477 18.5 16.5 18.5C17.0523 18.5 17.5 18.0523 17.5 17.5C17.5 16.9477 17.0523 16.5 16.5 16.5ZM14.5 17.5C14.5 16.3954 15.3954 15.5 16.5 15.5C17.6046 15.5 18.5 16.3954 18.5 17.5C18.5 18.6046 17.6046 19.5 16.5 19.5C15.3954 19.5 14.5 18.6046 14.5 17.5Z"
          fill="#5C5E76"
        />
      </g>
      <defs>
        <clipPath id="clip0_4301_4148">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SubdomainIcon;
