import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  workflows: {}
};

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: initialSliceState,
  reducers: {
    updateSelectedWorkflowIdByListId: (sliceState, { payload }) => {
      const { listId, selectedWorkflowId } = payload;
      if (!sliceState.workflows[listId]) {
        sliceState.workflows[listId] = {};
      }
      sliceState.workflows[listId].selectedWorkflowId = selectedWorkflowId;
    }
  }
});

export const { updateSelectedWorkflowIdByListId } = workflowsSlice.actions;

export default workflowsSlice.reducer;
