import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { useAppState } from 'hooks/state-context';
import Logo from 'icons/Logo';
import React, { useState } from 'react';
import { getUserEmail, getUserName, getUserPictureUrl } from 'selectors/user';
import { stringToCodeInt, toTitleCase } from 'utils/string-utils';

function ArtTopbarComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth0();
  const { state } = useAppState();
  const userName = getUserName(state);
  const userEmail = getUserEmail(state);
  const userPictureUrl = getUserPictureUrl(state);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        padding: { xs: '0 8px', md: '0 24px' },
        gap: '24px',
        height: '50px',
        alignItems: 'center',
        bgcolor: 'colors.primary_text',
        position: 'sticky',
        top: 0,
        zIndex: 'topBar',
        justifyContent: { xs: 'space-between', md: 'initial' }
      }}>
      <Stack direction="row" alignItems="center" gap="16px">
        <Box>
          <Logo style={{ color: 'white' }} />
        </Box>
      </Stack>
      <Typography sx={{ flex: 1 }} color="colors.text_on_primary" variant="h6" textAlign="left">
        {' '}
        Art Project
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          color: 'colors.text_on_primary',
          overflow: 'hidden'
        }}>
        <Stack
          id="user-menu-button"
          direction="row"
          gap="16px"
          alignItems="center"
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            paddingY: '2px',
            paddingX: '8px',
            border: '2px solid',
            borderColor: 'rgb(255,255,255,0)',
            borderRadius: '4px',
            '&:hover': {
              opacity: '0.8',
              color: 'primary.primary0',
              borderColor: 'primary.primary0'
            }
          }}>
          {(userName || userEmail) && (
            <Avatar
              alt={toTitleCase(userName || userEmail)}
              src={userPictureUrl}
              sx={{
                width: 28,
                height: 28,
                border: '2px solid currentcolor',
                backgroundColor: `graphColors.graph${
                  ((stringToCodeInt(userName || userEmail) + 3) % 12) + 1
                }`
              }}>
              {userPictureUrl ? null : toTitleCase(userName || userEmail).charAt(0)}
            </Avatar>
          )}
          <Typography variant="paragraphSemiBold">{userName || userEmail}</Typography>
        </Stack>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            id="logout-button"
            onClick={() => {
              handleClose();
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default wrapWithError(ArtTopbarComponent);
