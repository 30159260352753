import PropTypes from 'prop-types';
import React from 'react';

export const ArrowRightIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8536 6.64645C14.6583 6.45118 14.3417 6.45118 14.1464 6.64645C13.9512 6.84171 13.9512 7.15829 14.1464 7.35355L18.2929 11.5L4.5 11.5C4.22386 11.5 4 11.7239 4 12C4 12.2761 4.22386 12.5 4.5 12.5L18.2929 12.5L14.1464 16.6464C13.9512 16.8417 13.9512 17.1583 14.1464 17.3536C14.3417 17.5488 14.6583 17.5488 14.8536 17.3536L19.8536 12.3536C19.9473 12.2598 20 12.1326 20 12C20 11.8674 19.9473 11.7402 19.8536 11.6464L14.8536 6.64645Z"
        fill={color}
      />
    </svg>
  );
};

ArrowRightIcon.defaultProps = {
  color: '#807EF1'
};
ArrowRightIcon.propTypes = {
  color: PropTypes.string
};
