import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import SettingsIcon from './SettingsIcon';

function SettingsIconSmall({ color }) {
  return (
    <Box
      width="20px"
      height="20px"
      padding="2px"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      color={color}>
      <SettingsIcon color="currentColor" />
    </Box>
  );
}

export default SettingsIconSmall;

SettingsIconSmall.propTypes = {
  color: PropTypes.string
};

SettingsIconSmall.defaultProps = {
  color: null
};
