import { Stack, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { loadAllClustersAction } from 'actions/clusters/load-all-clusters-action';
import { loadAllAdminListsAction } from 'actions/company_lists/load-all-admin-company-lists-action';
import { loadAllNonMathlabsSearchesAction } from 'actions/dev_and_or_admin/load-all-non-mathlabs-searches';
import { loadAllExploreLists } from 'actions/explore/load-all-explores-action';
import { loadAllPersons } from 'actions/persons/load-all-persons-action';
import { loadAllRuns } from 'actions/runs/load-all-runs-action';
import { loadAllUsers } from 'actions/users/load-all-users-action';
import ClustersTab from 'components/admin-tabs/ClustersTab';
import DeepDiveTab from 'components/admin-tabs/DeepDiveTab';
import ExploreTab from 'components/admin-tabs/ExploreTab';
import LeadersTab from 'components/admin-tabs/LeadersTab';
import ListActionsTab from 'components/admin-tabs/ListActionsTab';
import OrgnizationSelector from 'components/admin-tabs/OrgnizationSelector';
import UsersTabDev from 'components/admin-tabs/UsersTabDev';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCompanyLists } from 'selectors/adminCompanyLists';
import { getDiscoveriesClusters } from 'selectors/discovery-clusters';
import { getAllExploreLists } from 'selectors/explore';
import { getPersons } from 'selectors/persons';
import { getRuns } from 'selectors/runs';
import { getUserIsDev } from 'selectors/user';
import { getUsers } from 'selectors/users';
import { organization_selector_value_to_filter } from 'utils/organization-utils';

const TABS = Object.freeze({
  EXPLORE_LISTS: 0,
  CLUSTERS: 1,
  DEEP_DIVE: 2,
  LIST_ACTIONS: 3,
  LEADERS: 4,
  USERS: 5
});
export default function DevScreen() {
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const runs = getRuns(state);
  const persons = getPersons(state);
  const users = getUsers(state);
  const clusters = getDiscoveriesClusters(state);
  const lists = getAdminCompanyLists(state);
  const exploreLists = useSelector((reduxState) => getAllExploreLists(reduxState));

  const isDev = getUserIsDev(state);
  const [value, setValue] = useState(0);
  const [filterId, setFilterId] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(loadAllRuns({ all: true }));
    dispatch(loadAllPersons({ all: true }));
    dispatch(loadAllUsers());
    dispatch(loadAllNonMathlabsSearchesAction());
    dispatch(loadAllAdminListsAction());
  }, [isDev]);

  useEffect(() => {
    reduxDispatch(loadAllClustersAction());
    reduxDispatch(loadAllExploreLists({ all: true, record_only: true }));
  }, [isDev, reduxDispatch]);

  if (!isDev) {
    return <div>Forbidden Route!</div>;
  }
  const filter = organization_selector_value_to_filter(filterId);

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '30px'
        }}>
        <h1>Dev Console</h1>
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          sx={{
            borderRadius: '4px',
            backgroundColor: 'colors.hover_on_gray_bg'
          }}>
          <OrgnizationSelector setOrganizationId={setFilterId} />
        </Stack>
        <Tabs value={value} onChange={handleChange} textColor="secondary">
          <Tab label="Explore Lists" />
          <Tab label="Clusters" />
          <Tab label="Deep Dive" />
          <Tab label="List Actions" />
          <Tab label="Leaders" />
          <Tab label="Users" />
        </Tabs>
      </Stack>
      {value === TABS.DEEP_DIVE && runs && (
        <DeepDiveTab
          runs={runs}
          hidden={value !== TABS.DEEP_DIVE}
          filter={filter}
          users={users}></DeepDiveTab>
      )}
      {value === TABS.LEADERS && persons && (
        <LeadersTab persons={persons} hidden={value !== TABS.LEADERS} filter={filter}></LeadersTab>
      )}
      {value === TABS.EXPLORE_LISTS && exploreLists && (
        <ExploreTab
          exploreLists={exploreLists}
          hidden={value !== TABS.EXPLORE_LISTS}
          filter={filter}
          users={users}
        />
      )}
      {value === TABS.LIST_ACTIONS && lists && (
        <ListActionsTab lists={lists} hidden={value !== TABS.LIST_ACTIONS} />
      )}
      {value === TABS.CLUSTERS && clusters && (
        <ClustersTab
          clusters={clusters}
          hidden={value !== TABS.CLUSTERS}
          filter={filter}></ClustersTab>
      )}
      {value === TABS.USERS && users && (
        <UsersTabDev users={users} hidden={value !== TABS.USERS} filter={filter}></UsersTabDev>
      )}
    </Box>
  );
}
