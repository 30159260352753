import { addDomainReport } from 'reducer/domain-reports-slice';

import { bindDomainReportPusherAction } from './bind-domain-report-pusher-action';

export function submitDomainReportAction({ listId, companyMetaIds, filters, allCompanyMetaIds }) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore
      .submitExploreListDomainReport(listId, companyMetaIds, filters, allCompanyMetaIds)
      .then((res) => {
        if (res.ok) {
          dispatch(
            addDomainReport({
              listId,
              data: {
                ...res.data,
                exists: true,
                domain_report: {
                  parameters: {
                    company_meta_ids: companyMetaIds,
                    filters,
                    all_company_meta_ids: allCompanyMetaIds
                  },
                  custom_analytics: res.data.custom_analytics
                }
              }
            })
          );
          Object.keys(res.data.custom_analytics).forEach((key) => {
            dispatch(
              bindDomainReportPusherAction({
                listId,
                domainReportId: res.data.domain_report_id,
                postId: key
              })
            );
          });
        }
      });
  };
}
