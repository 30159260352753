import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import LevelComponent from 'components/LevelComponent';
import PersonSummary from 'components/card/people/PersonSummary';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import SharedStack from 'components/tile/SharedStack';
import { RESOURCE_TYPES } from 'constants/resources';
import { useAppState } from 'hooks/state-context';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentFolderId } from 'selectors/folders';
import { getPersonRecordIdById, getPersons } from 'selectors/persons';
import { getUserOrganizationId } from 'selectors/user';
import { appTheme } from 'theme';

function PeopleCardInnerComponent({ personId, personData, mode, isResource }) {
  const { state } = useAppState();
  const sectionsContainerRef = useRef();
  const canCollaborate = isResource && getUserOrganizationId(state);
  const recordId = getPersonRecordIdById(state, personId);
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const currentFolderId = useSelector(getCurrentFolderId);
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const persons = getPersons(state);

  const personSummaryData = persons?.[personId];
  const nonNullpersonData = personData
    ? personData
    : {
        name: personSummaryData?.name,
        current_company_name: personSummaryData?.currentCompanyName,
        linkedin_url: personSummaryData?.linkedinUrl,
        twitter_url: personSummaryData?.twitterUrl,
        pic_url: personSummaryData?.imageUrl,
        job_title: personSummaryData?.currentRole
      };
  const copyCardUrlToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${
        currentFolderId ? '/folders/'.concat(currentFolderId) : ''
      }?personId=${personId}`
    );
  };

  const doCollaborate = () => {
    setCollaborateOpen(true);
  };

  const sections = useMemo(() => {
    if (!personData?.sections) return [];
    return personData.sections.map((section) => ({
      ...section,
      id: section.title.toLocaleLowerCase().replace(' ', '-')
    }));
  }, [personData?.sections]);

  const handleTabClick = (index) => {
    setCurrentSectionIndex(index);
    sectionsContainerRef.current.children[index].scrollIntoView();
  };

  return (
    <Card
      id={nonNullpersonData.name}
      sx={{
        width: '100%',
        height: mode === 'normal' ? '100%' : 'fit-content',
        overflow: mode === 'normal' ? 'auto' : 'hidden'
      }}>
      <Stack direction="column" padding="32px" gap="40px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <PersonSummary personData={nonNullpersonData} />
          <Stack direction="row" gap="8px">
            {canCollaborate && (
              <Button variant="contained" onClick={doCollaborate}>
                Collaborate
              </Button>
            )}{' '}
            {canCollaborate && recordId && (
              <SharedStack
                searchId={personId}
                resourceType={RESOURCE_TYPES.PERSON}
                size={32}
                folderId={currentFolderId}
              />
            )}
            {recordId && canCollaborate && (
              <CollaborateDialog
                open={collaborateOpen}
                onClose={() => setCollaborateOpen(false)}
                id={recordId}
                searchId={personId}
                resourceType={RESOURCE_TYPES.PERSON}
                folderId={currentFolderId}
              />
            )}
            {personId && (
              <IconButtonComponent
                tag="Share"
                variant="secondary"
                onClick={copyCardUrlToClipboard}
                message="The card link was copied to your clipboard."
                sx={{ alignSelf: 'flex-start' }}>
                <ShareIcon />
              </IconButtonComponent>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap="16px"
          flex={3}
          display={mediumViewport ? 'flex' : 'none'}
          flexWrap="wrap">
          {sections.map((section, index) => (
            <Stack
              key={section.title}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              sx={{
                maxWidth: '350px',
                cursor: 'pointer',
                padding: '16px',
                borderRadius: '4px',
                border: '1px solid',
                borderColor:
                  currentSectionIndex === 'index' ? 'secondary.secondary3_100' : 'colors.selected',
                boxShadow:
                  currentSectionIndex === 'index'
                    ? '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)'
                    : 'none'
              }}
              onClick={() => handleTabClick(index)}>
              <Typography variant="paragraphBold" color="secondary.secondary2">
                {section.title}
              </Typography>
              <Box sx={{ width: '128px' }}>
                <LevelComponent level={section.level} />
              </Box>
            </Stack>
          ))}
        </Stack>
        <Divider flexItem />
        <Stack ref={sectionsContainerRef} direction="column" padding="32px" gap="40px">
          {sections
            .filter((section) => section.points.length > 0)
            .map((section) => (
              <Stack
                key={section.title}
                id={section.id}
                direction="column"
                gap="16px"
                flex={7}
                sx={{
                  backgroundColor: 'colors.grey_bg',
                  padding: '16px',
                  borderRadius: '4px',
                  border: '1px solid',
                  borderColor: 'colors.selected',
                  textAlign: 'left'
                }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="paragraphBold" color="colors.primary_text">
                    {section.title}
                  </Typography>
                  <LevelComponent level={section.level} showBgColor={false} />
                </Stack>
                <Divider sx={{ borderWidth: '1px', borderColor: 'colors.selected' }} />
                <Stack direction="column" gap="24px">
                  {section.points?.map((point) => (
                    <Stack key={point.title} dirsction="column" gap="8px">
                      <Typography variant="paragraphBold" color="secondary.secondary6">
                        {point.title}
                      </Typography>
                      {point.bullets?.map((bullet, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="center"
                          gap="4px"
                          flexWrap="wrap"
                          sx={{ maxWidth: '70%' }}>
                          {bullet.map((part, partIndex) => {
                            if (part.type === 'text') {
                              return (
                                <Typography
                                  key={`${point.title}-${part.value}-${partIndex}`}
                                  variant="paragraph"
                                  color="colors.primary_text">
                                  {part.value}
                                </Typography>
                              );
                            }
                            return (
                              <Link
                                key={`${point.title}-${part.value}-${partIndex}`}
                                href={part?.url}
                                underline="none"
                                target="_blank"
                                rel="noreferrer">
                                <Stack direction="row" gap="4px">
                                  <Avatar
                                    src={part.src}
                                    sx={{ width: 18, height: 18 }}
                                    variant="rounded"
                                  />
                                  <Typography variant="paragraphBold" color="primary.primary2">
                                    {part.value}
                                  </Typography>
                                </Stack>
                              </Link>
                            );
                          })}
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Card>
  );
}

PeopleCardInnerComponent.propTypes = {
  personId: PropTypes.number,
  personData: PropTypes.object,
  mode: PropTypes.oneOf(['normal', 'stretch']).isRequired,
  isResource: PropTypes.bool
};

export default wrapWithError(PeopleCardInnerComponent);
