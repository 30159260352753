import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DOMAIN_RESEARCH_EVENTS } from 'constants/userEvents';
import {
  addExploreSubdomains,
  removeExploreSubdomainsLoading,
  updateTriggredCustomAnalytics
} from 'reducer/explore-slice';
import { getExploreSubdomains } from 'selectors/explore';
import { getClientId } from 'selectors/general';
import { isEmpty } from 'utils/objects-utils';
import { EXTEND_DOMAIN_PREFIX } from 'utils/subdomains';

import { addSubdomainsAction } from './add-subdomains-action';

export function submitSubdomainAction(
  extend_type,
  company_list_id,
  parentId,
  text,
  level,
  userPrefix
) {
  return (dispatch, getState, apiClient) => {
    // const prompt = generatePrompt(text, userPrefix);
    const prompt_prefix = userPrefix || EXTEND_DOMAIN_PREFIX[extend_type];
    const client_id = getClientId(getState());
    const parameters = { prompt_prefix, extend_type };
    const dummySubdomains = Array.from({ length: 5 }, () => ({
      level,
      parent_subdomain_id: parentId,
      loading: true,
      parameters
    }));
    dispatch(addExploreSubdomains({ listId: company_list_id, subdomains: dummySubdomains }));

    dispatch(
      sendUserEvent(DOMAIN_RESEARCH_EVENTS.GENERATE_SUBDOMAINS, {
        company_list_id,
        text,
        extend_type,
        user_prefix: userPrefix
      })
    );

    const removeLoading = () => {
      dispatch(
        removeExploreSubdomainsLoading({
          listId: company_list_id,
          parent_subdomain_id: parentId,
          promptPrefix: prompt_prefix
        })
      );
    };
    apiClient.llm_queries
      .generateSubdomains({
        text,
        input_for_subdomain_update: {
          company_list_id,
          parent_id: parentId,
          level,
          client_id,
          parameters
        },
        prompt: prompt_prefix
      })
      .then(async (res) => {
        if (res.ok && res.data?.subdomains) {
          // wait for the subdomains returned from pusher to be saved
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 5000));

          // all generated subdomains were supposed to be send via pusher and be added to the store.
          // but in case of any error, we need to check if there are any new subdomains that were not added
          const savedSubdomains = new Set(
            getExploreSubdomains(getState(), company_list_id)
              ?.filter(
                (sd) =>
                  sd.parent_subdomain_id === parentId &&
                  sd.parameters?.prompt_prefix === prompt_prefix
              )
              ?.map((sd) => sd.name) || []
          );
          const newSubdomains = res.data.subdomains
            .filter((sd) => !savedSubdomains.has(sd.name))
            .map(({ name, description }) => ({
              name,
              description
            }));
          if (!isEmpty(newSubdomains)) {
            dispatch(
              addSubdomainsAction(
                company_list_id,
                parentId,
                newSubdomains,
                level,
                removeLoading,
                true,
                parameters
              )
            );
            return;
          }

          removeLoading();
        } else if (res?.data?.triggered_custom_analytics) {
          dispatch(
            updateTriggredCustomAnalytics({
              listId: company_list_id,
              triggredCustomAnalytics: true,
              parentId,
              promptPrefix: prompt_prefix
            })
          );
        }
      });
  };
}
