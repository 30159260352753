import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { addNewPublicShareLinkAction } from 'actions/public_share_links/add-new-public-share-link-action';
import { deletePublicShareLinkAction } from 'actions/public_share_links/delete-public-share-link-action';
import { loadAllPublicShareLinksAction } from 'actions/public_share_links/load-all-public-share-links-action';
import IconButtonComponent from 'components/IconButtonComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { COMPANY_INSIGHTS_PREFIX, PUBLIC_SHARE_PREFIX } from 'constants/app-routes';
import CopyIcon from 'icons/CopyIcon';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getIsLoadingPublicLinksByRunId,
  getIsPublicLinksAddingInProgressByRunId,
  getRunPublicShareLinks
} from 'selectors/puclic-shares';

function CompanyPublicShareDialog({ recordId, title, open, onClose }) {
  const { tabPath } = useParams();
  const dispatch = useDispatch();
  const publicLinks = useSelector((state) => getRunPublicShareLinks(state, recordId));
  const isLoadingLinks = useSelector((state) => getIsLoadingPublicLinksByRunId(state, recordId));
  const inAddProgress = useSelector((state) =>
    getIsPublicLinksAddingInProgressByRunId(state, recordId)
  );

  useEffect(() => {
    if (!open) return;

    dispatch(loadAllPublicShareLinksAction(recordId));
  }, [dispatch, recordId, open]);

  const handleAddnewPublicLink = () => {
    dispatch(addNewPublicShareLinkAction(recordId));
  };

  const getPublicLink = (uuid) => {
    return `${window.location.origin}${PUBLIC_SHARE_PREFIX}/${uuid}`;
  };

  const getPrivateLink = () => {
    return `${window.location.origin}${COMPANY_INSIGHTS_PREFIX}/${recordId}/${
      tabPath || 'overview'
    }`;
  };

  const copyToClipboard = (link) => navigator.clipboard.writeText(link);
  const copyPrivateLinkUrl = () => copyToClipboard(getPrivateLink());
  const copyPublicShareLinkUrl = (uuid) => copyToClipboard(getPublicLink(uuid));

  const deletePublicShareLink = (uuid) => {
    dispatch(deletePublicShareLinkAction(recordId, uuid));
  };

  const publicLinkUuid =
    !isLoadingLinks && !inAddProgress && publicLinks && Object.keys(publicLinks).length > 0
      ? Object.keys(publicLinks)[0]
      : null;

  return (
    <ModalComponent title={title} open={open} onClose={onClose} bulk={true} minWidth="800px">
      <Stack
        direction="column"
        alignItems="center"
        alignContent="space-between"
        padding="24px"
        sx={{ width: '100%', boxSizing: 'border-box' }}>
        <Stack direction="row" alignItems="center" gap="8px" sx={{ width: '100%' }}>
          <Typography variant="paragraphBold" color="colors.primary_text">
            Private link:
          </Typography>
          <Typography variant="paragraph" color="primary.primary2" sx={{ flex: 1 }}>
            {getPrivateLink()}
          </Typography>
          <IconButtonComponent
            variant="secondary"
            tag="copy link"
            onClick={() => copyPrivateLinkUrl()}
            message="The private link was copied to your clipboard.">
            <CopyIcon />
          </IconButtonComponent>
        </Stack>
        {(inAddProgress || isLoadingLinks) && <CircularProgress />}
        {publicLinkUuid && (
          <Stack direction="row" alignItems="center" gap="8px" sx={{ width: '100%' }}>
            <Typography variant="paragraphBold" color="colors.primary_text">
              Public link:
            </Typography>
            <Typography variant="paragraph" color="primary.primary2" sx={{ flex: 1 }}>
              {getPublicLink(publicLinkUuid)}
            </Typography>
            <IconButtonComponent
              variant="secondary"
              tag="copy link"
              onClick={() => copyPublicShareLinkUrl(publicLinkUuid)}
              message="The public link was copied to your clipboard.">
              <CopyIcon />
            </IconButtonComponent>
            <IconButtonComponent
              variant="secondary"
              tag="delete"
              onClick={() => deletePublicShareLink(publicLinkUuid)}>
              <DeleteIcon />
            </IconButtonComponent>
          </Stack>
        )}
        <Typography variant="paragraphBold" color="colors.primary_text" marginTop="32px">
          Anyone with public link can access the data
        </Typography>
      </Stack>
      <Divider flexItem sx={{ margin: '8px 0' }} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        padding="8px"
        sx={{ width: '100%', boxSizing: 'border-box' }}>
        <Button
          variant="contained"
          disabled={isLoadingLinks || inAddProgress}
          onClick={handleAddnewPublicLink}>
          Generate New Public Link
        </Button>
      </Stack>
    </ModalComponent>
  );
}

CompanyPublicShareDialog.propTypes = {
  recordId: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default CompanyPublicShareDialog;
