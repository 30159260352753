import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

export const SelectedCompaniesComponent = ({ selectedCompanies, maxLogosNum }) => {
  const numCompaniesSelected = selectedCompanies?.length || 0;

  return (
    <Stack flexDirection="row" gap="16px" paddingLeft="24px">
      <Stack flexDirection="row" gap="6px" alignItems="center" justifyContent="flex-end">
        <Typography variant="h3" color="colors.primary_text">
          {numCompaniesSelected}
        </Typography>
        <Typography variant="paragraph" color="colors.primary_text">{`compan${
          numCompaniesSelected === 1 ? 'y' : 'ies'
        }`}</Typography>
        <Typography variant="paragraph" color="colors.primary_text">
          selected
        </Typography>
        {numCompaniesSelected > 0 && (
          <Divider
            orientation="vertical"
            sx={{ borderColor: 'colors.ui_border', height: '20px', alignSelf: 'center' }}
          />
        )}
      </Stack>

      <Stack direction="row" gap="8px" minWidth={`${24 * maxLogosNum + 8 * (maxLogosNum - 1)}px`}>
        {selectedCompanies
          ?.filter((row) => row.logo_url)
          ?.slice(0, maxLogosNum)
          .map((row) => (
            <Box
              key={row.hash || row.name}
              sx={{ width: '24px', height: '24px' }}
              component="img"
              src={row.logo_url}
              alt={row.name}
            />
          ))}
      </Stack>
      <Box
        sx={{
          width: '26px',
          height: '26px',
          borderRadius: '15px',
          backgroundColor: 'greyColors.grey50',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          visibility: numCompaniesSelected > maxLogosNum ? 'visible' : 'hidden'
        }}>
        <Typography
          variant="paragraph"
          color="greyColors.grey250"
          sx={{
            fontSize: '14px'
          }}>
          +{numCompaniesSelected - maxLogosNum}
        </Typography>
      </Box>
    </Stack>
  );
};

SelectedCompaniesComponent.propTypes = {
  selectedCompanies: PropTypes.array,
  maxLogosNum: PropTypes.number
};
